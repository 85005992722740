import React from 'react'
import { Link, Redirect } from 'react-router-dom'

import { Text, ITextProps } from 'office-ui-fabric-react'

import styles from './index.module.scss'
import menuItems from './menuItems'
import AddSVG from './add.svg'
import PurchaseSVG from './purchase.svg'
import ListSVG from './list.svg'

import parse from 'html-react-parser'
import DocumentTitle from '../common/document-title'

import useTokenInitializer from '../../hooks/token-initializer'

const InventoryIssuePage: React.FC = () => {
  const iconList: { [key: string]: string } = {
    AddSVG: AddSVG,
    PurchaseSVG: PurchaseSVG,
    ListSVG: ListSVG,
  }
  DocumentTitle('Inventory', 'นำสินค้าออก')
  useTokenInitializer()

  return (
    <section className={styles.section1}>
      <h1 className={styles.headerTitle}>นำสินค้าออก</h1>
      <div className={styles.menuItemsWrapper}>
        {menuItems.map(({ name, imgName, linkTo }, index) => (
          <Link key={index} to={linkTo}>
            <button key={index} style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}>
              <img src={iconList[imgName]} />
              <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
                {parse(name)}
              </Text>
            </button>
          </Link>
        ))}
      </div>
    </section>
  )
}

export default InventoryIssuePage
