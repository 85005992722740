import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../common/table'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import { debounce } from 'lodash'
import PaginationContainer from '../../pagination/index'
import { urlApi } from '../common/configUrlApi'
import { FormatDateTime } from '../../common/dateFormat'
import useTokenInitializer from '../../hooks/token-initializer'
import EnhancedTable from '../../common/table/doc/firstAndLastColBtnPaginationBack'
import { HeadCell } from '../../common/table/config'

const InventoryIssueListSOPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const [dataListSO, setDataListSO] = useState<any>([])
  const [currentQueryString, setCurrentQueryString] = useState('')
  const [currentQueryDate, setCurrentQueryDate] = useState(null)

  // Pgaination
  const [page, setPage] = React.useState(0)
  const [pageList, setPageList] = React.useState<any>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = React.useState(0)

  DocumentTitle('Inventory', 'นำสินค้าออก')
  useTokenInitializer()

  useEffect(() => {
    // For not dowload page if dowloaded
    const findPage = pageList.find((ele: any) => `${ele}` === `${page}`)
    if (findPage || findPage === 0) return

    // Where to put the query
    const q = `query listGoodReceivePage($pageIndex: Int, $maxResults: Int) {
      listSalesOrder(pageIndex: $pageIndex, maxResults: $maxResults) {
        totalCount
        pageIndex
        maxResults
        results {
          id
          internalDocumentId
          documentTypeId
          documentStatusId
          referenceNumber
          remark
          createdAt
          updatedAt
          lineItem {
            id
            lineNumber
            skuId
            qty
            uomId
            packId
          }
        }
      }
    }`
    // Where to put the variables
    const v = { pageIndex: page, maxResults: rowsPerPage }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const listSalesOrder = result.data.data.listSalesOrder.results
      const listSO: any[] = []

      listSalesOrder.map((data: any) => {
        const mapper = {
          id: data.id,
          soCode: data.internalDocumentId,
          soDate: FormatDateTime(data.updatedAt),
          key: data.lineItem,
        }
        return listSO.push(mapper)
      })
      setLoading(false)
      setDataListSO([...dataListSO, ...listSO])
      setTotalCount(result.data.data.listSalesOrder.totalCount)

      setPageList([...pageList, page]) // For not reload new page if dowloaded
    })
  }, [page, rowsPerPage, currentQueryDate, currentQueryString])

  const createDocIssueRefSO = (value: number) => {
    // Where to put the query
    const q = `mutation createOrEditGoodIssue($input: CreateGoodIssue!) {
      createGoodIssue: createOrEditGoodIssue(input: $input) {
        id
        documentTypeId
        documentStatusId
        salesOrderDocumentId
        lineItem {
          id
          skuId
          qty
          uomId
          packId
          packLineItem {
            id
            skuId
            runningNumber
            qty
            uomId
            packId
            scanBy
            scanAt
            voided
            serialNumber
          }
        }
      }
    }`

    // Where to put the variables
    const matchDoc = dataListSO.find((doc: any) => `${doc.id}` === `${value}`)

    const lineItem: any[] = []
    matchDoc.key.map((data: any) => {
      const mapperLineItem = {
        lineNumber: data.lineNumber,
        skuId: data.skuId,
        qty: data.qty,
        uomId: data.uomId,
        packId: data.packId,
      }
      lineItem.push(mapperLineItem)
    })

    // Where to put the variables
    const v = {
      input: {
        salesOrderDocumentId: value,
        lineItem: lineItem,
      },
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        props.history.push(`/inventory/issue/ref/so/${result.data.data.createGoodIssue.id}`)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }

  const request = debounce((value, setState) => {
    setState(value)
  }, 1000)

  const debouceRequest = useCallback((value, setState) => request(value, setState), [])

  const onChangeString = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryString)
  }

  const onChangeDate = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryDate)
  }

  const rowBody: any = dataListSO

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'docID', numeric: false, disablePadding: false, label: 'เลขที่เอกสารจัดขาย' },
    { id: 'date', numeric: false, disablePadding: false, label: 'วันที่' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  return (
    <>
      <section className={styles.headerWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>นำสินค้าออก</h1>
        <p>กรุณาเลือกรายการ</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสเอกสาร"
          onChange={onChangeString}
          style={{ maxWidth: '385.6px', display: 'flex' }}
        />
        <input type="date" className="inputForSearch" onChange={onChangeDate} style={{ maxWidth: '385.6px' }} />
      </section>
      <section className="tableListMui">
        <EnhancedTable
          colHead={headCells}
          rows={rowBody}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
          fuctionForFirstButton={createDocIssueRefSO}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/issue">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventoryIssueListSOPage
