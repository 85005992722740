import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import { getComparator, stableSort, EnhancedTableProps, useStyles, Order } from '../../config'
import { Link } from 'react-router-dom'

const EnhancedTable: React.FC<any> = (props: any): JSX.Element => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<any>('calories')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const { colHead, rows, link } = props

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              colHead={colHead}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow hover tabIndex={-1} key={row.name}>
                      {Object.keys(row).map(function (key, index) {
                        if (key === 'id') {
                          return (
                            <TableCell padding="checkbox">
                              <Link className="styleBtnForTable" to={`${link}/${row.id}`}>
                                เลือก
                              </Link>
                            </TableCell>
                          )
                        }
                        return (
                          <TableCell key={index} component="th" id={labelId} scope="row">
                            {row[key]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default EnhancedTable

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort, colHead } = props
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" rowSpan={2}>
          <span>เลือก</span>
        </TableCell>
        {colHead.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            rowSpan={
              headCell.label !== 'ซื้อ' &&
              headCell.label !== 'คงคลัง' &&
              headCell.label !== 'คงเหลือสุทธิ' &&
              headCell.label !== 'จอง'
                ? 2
                : 1
            }
            colSpan={
              headCell.label !== 'ซื้อ' &&
              headCell.label !== 'คงคลัง' &&
              headCell.label !== 'คงเหลือสุทธิ' &&
              headCell.label !== 'จอง'
                ? 1
                : 2
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>
          <span>แผ่น</span>
        </TableCell>
        <TableCell>
          <span>ห่อ</span>
        </TableCell>
        <TableCell>
          <span>แผ่น</span>
        </TableCell>
        <TableCell>
          <span>ห่อ</span>
        </TableCell>
        <TableCell>
          <span>แผ่น</span>
        </TableCell>
        <TableCell>
          <span>ห่อ</span>
        </TableCell>
        <TableCell>
          <span>แผ่น</span>
        </TableCell>
        <TableCell>
          <span>ห่อ</span>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
