import { useEffect } from 'react'
import { decodeTokenIfNeeded } from '../redux/modules/token'
import { useDispatch } from 'react-redux'

const useTokenInitializer = () => {
  const dispatch = useDispatch()

  // Decode Token If needed
  useEffect(() => {
    if (localStorage.getItem('accessToken') !== null) {
      // Check if there is token
      dispatch(decodeTokenIfNeeded())
    }
  }, [dispatch])

  return
}
export default useTokenInitializer
