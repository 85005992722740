import React from 'react'
import { Link, Redirect } from 'react-router-dom'

import { Text, ITextProps } from 'office-ui-fabric-react'

import styles from './index.module.scss'
import menuItems from './menuItems'
import ListSVG from './list.svg'

import parse from 'html-react-parser'
import DocumentTitle from '../common/document-title'

import useTokenInitializer from '../../hooks/token-initializer'

const InventoryApprovePage: React.FC = (props: any) => {
  const iconList: { [key: string]: string } = {
    // AddSVG: AddSVG,
    ListSVG: ListSVG,
  }
  DocumentTitle('Inventory', 'การอนุมัติ')
  useTokenInitializer()

  return (
    <section className={styles.section1}>
      <h1 className={styles.headerTitle}>การอนุมัติ</h1>
      <p className={styles.subHeaderTitle}>กรุณาเลือกรายการ</p>
      <div className={styles.menuItemsWrapper}>
        {menuItems.map(({ name, imgName, linkTo }, index) => {
          return (
            <Link key={index} to={linkTo}>
              <button key={index} style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}>
                <img src={iconList[imgName]} />
                <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
                  {parse(name)}
                </Text>
              </button>
            </Link>
          )
        })}
      </div>
    </section>
  )
}

export default InventoryApprovePage
