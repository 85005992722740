import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import SummaryTopTableComponant from '../common/summaryTopTable'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import { urlApi } from '../common/configUrlApi'
import GroupBtnDocument from '../common/groupBtnDocuemnt'

import useTokenInitializer from '../../hooks/token-initializer'
import { FormatDateTime } from '../../common/dateFormat'

import EnhancedTable from '../../common/table/item/paginationByFront/firstColBtnandInput'
import { HeadCell } from '../../common/table/config'

export interface ILineItems {
  lineNumber: number
  itemID: string
  pack: string
  quantity: number
  quantityPerPack: number
  status: string
  by: string
  timeStamp: string
  description: string
}

const InventoryReceiveDocumentIDPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const [documentID, setDocumentID] = useState('')
  const [remarkDate, setRemarkDate] = useState('')
  const [packingList, setPackingList] = useState('')
  const [documentRef, setDocumentRef] = useState('')
  const [documentStatusId, setDocumentStatusId] = useState('')
  const [lineItems, setLineItems] = useState<(string | ILineItems)[]>([])

  const [summaryTopTable, setSummaryTopTable] = useState<any>([])
  DocumentTitle('Inventory', 'นำสินค้าเข้า')
  useTokenInitializer()

  // For Query Doc Receive
  useEffect(() => {
    const q = `query viewGoodReceive($documentId: ID!){
      viewGoodReceive(id: $documentId){
        id
        internalDocumentId
        documentTypeId
        remarkDate
        packingList
        documentStatusId
        purchaseOrderDocumentId
        lotNumber
        purchaseOrder {
          internalDocumentId
        }
        packLineItem {
          id
          skuId
          runningNumber
          qty
          uomId
          packId
          scanBy
          scanAt
          voided
          serialNumber
          sku {
            name
            productCode
            qtyPerPack(packId: 1)
            product {
              name
            }
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      documentId: props.match.params.id,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const viewGoodReceive = result.data.data.viewGoodReceive
      const listMapper: any[] = []
      viewGoodReceive.packLineItem.map((data: any) => {
        const mapper = {
          // lineNumber: data.serialNumber,
          itemID: `${data.sku.productCode}`,
          pack: data.runningNumber,
          status: data.scanBy ? 'สแกนแล้ว' : 'รอสแกน',
          quantity: data.qty ? data.qty : 0,
          quantityPerPack: data.sku.qtyPerPack,
          serialNumber: data.serialNumber,
          by: data.scanBy,
          timeStamp: data.scanAt ? FormatDateTime(data.scanAt) : '',
          description: data.sku.product.name,
        }
        listMapper.push(mapper)
      })
      setLoading(false)
      setDocumentStatusId(viewGoodReceive.documentStatusId)
      setRemarkDate(viewGoodReceive.remarkDate?.split('T')[0])
      setPackingList(viewGoodReceive.packingList)
      setLineItems(listMapper)
      setDocumentID(viewGoodReceive.internalDocumentId)
      setDocumentRef(viewGoodReceive.purchaseOrder ? viewGoodReceive.purchaseOrder.internalDocumentId : '')
    })
  }, [])

  useEffect(() => {
    // Pretend this is axios
    const clone_lineItems: ILineItems[] = []
    lineItems.map((data) => {
      if (typeof data !== 'string') {
        return clone_lineItems.push(data)
      }
    })

    const ameliaWatson = clone_lineItems.reduce<{ [itemId: string]: ILineItems[] }>((previousValue, currentValue) => {
      const packNumberList: any[] = previousValue[currentValue.itemID] || []
      // console.log('packNumberList', packNumberList)
      packNumberList.push(currentValue)
      previousValue[currentValue.itemID] = packNumberList
      return previousValue
    }, {})

    const packSKU: { packList: number; totalPiece: number; totalPieceAndPack: number; totalPackReceive: number }[] = []

    const plastic = Object.entries(ameliaWatson).reduce<(ILineItems | string)[]>(
      (lineItemListReducer, [itemId, packList]) => {
        const totalPiece = packList.reduce<number>((pv, cv) => {
          return pv + cv.quantity
        }, 0)
        const totalPieceAndPack = packList.reduce<number>((pv, cv) => {
          return pv + cv.quantityPerPack
        }, 0)
        const totalPackReceive = packList.filter((ele) => {
          if (ele.quantity > 0) {
            return ele
          } else return
        }).length

        const warcraft3 = `รหัส ${itemId} - ${packList[0].description} - ${Math.ceil(
          totalPiece / (totalPieceAndPack / packList.length),
        )}/${packList.length} ห่อ = ${totalPiece}/${totalPieceAndPack} แผ่น`
        lineItemListReducer.push(warcraft3)
        lineItemListReducer.push(...packList)

        packSKU.push({
          packList: packList.length,
          totalPiece: totalPiece,
          totalPieceAndPack: totalPieceAndPack,
          totalPackReceive: totalPackReceive,
        })

        return lineItemListReducer
      },
      [],
    )
    setSummaryTopTable(packSKU)
    setLineItems(plastic)
  }, [documentID])

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'createAt', numeric: false, disablePadding: false, label: 'รหัสสินค้า' },
    { id: 'Pack', numeric: false, disablePadding: false, label: 'Pack' },
    { id: 'scan', numeric: false, disablePadding: false, label: 'สแกน' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'จำนวนแผ่น' },
    { id: 'qtyperpack', numeric: false, disablePadding: false, label: 'แผ่น/ห่อ' },
    { id: 'sn', numeric: false, disablePadding: false, label: 'S/N' },
    { id: 'by', numeric: false, disablePadding: false, label: 'โดย' },
    { id: 'date', numeric: false, disablePadding: false, label: 'เมื่อ' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  return (
    <>
      <div className={styles.pageWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>นำสินค้าเข้า</h1>
        <p>
          ใบนำสินค้าเข้า {documentID} - อ้างอิง {documentRef} - {documentStatusId}
        </p>
        <p style={{ margin: '15px 0 0' }}>วันที่นำเข้า</p>
        <input
          type="date"
          className="inputForSearch"
          placeholder="วันที่นำเข้า"
          value={remarkDate}
          disabled={true}
          style={{ maxWidth: '386.5px', display: 'flex' }}
        />
        <p style={{ margin: '15px 0 0' }}>เลขที่ใบรายการสินค้าเข้า</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="เลขที่ใบรายการสินค้าเข้า"
          value={packingList}
          disabled={true}
          style={{ maxWidth: '386.5px', display: 'flex' }}
        />
        <p style={{ margin: '15px 0 0' }}>จังหวัด</p>
        <select
          name="type"
          className={`${styles.selectFilter} ${styles.hiddenInDesktop}`}
          style={{ maxWidth: '385.6px', margin: '10px 10px 0 0' }}
        >
          <option value="all">จังหวัด</option>
        </select>
        <p style={{ margin: '15px 0 0' }}>ประเภทการนำเข้า</p>
        <select
          name="type"
          className={`${styles.selectFilter} ${styles.hiddenInDesktop}`}
          style={{ maxWidth: '385.6px', margin: '10px 10px 0 0' }}
        >
          <option value="all">ประเภท</option>
          <option value="ซื้อ">ซื้อ</option>
          <option value="โอนเข้า">โอนเข้า</option>
          <option value="รับคืน">รับคืน</option>
          <option value="รับอื่นๆ">รับอื่นๆ</option>
        </select>
      </div>
      <SummaryTopTableComponant summaryTopTable={summaryTopTable} />
      <section className={`tableListMui`}>
        <EnhancedTable colHead={headCells} rows={lineItems} removeButtonClassUniqueSKU={true} />
      </section>
      <section className={styles.groupbtn}>
        <GroupBtnDocument documentStatusId={documentStatusId} linkGoBack="/inventory/receive/list/po" />
      </section>
    </>
  )
}

export default InventoryReceiveDocumentIDPage
