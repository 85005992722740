interface IMenuItem {
  name: string
  imgName: string
  linkTo: string
  fuction: boolean
}
const menuItems: IMenuItem[] = [
  { name: 'สร้างงานส่ง', imgName: 'AddSVG', linkTo: '/inventory/delivery-note/list/order', fuction: false },
  { name: 'ดูรายการส่งของ', imgName: 'ListSVG', linkTo: '/inventory/delivery-note/document/list', fuction: false },
  { name: 'ย้อนกลับ', imgName: '', linkTo: '/inventory', fuction: false },
  { name: '', imgName: '', linkTo: '#', fuction: false },
]

export default menuItems
