import React, { useEffect } from 'react'
import { useFormikContext, withFormik } from 'formik'
import styles from './index.module.scss'
import DocumentTitle from '../../inventory/common/document-title'
import { divisionList } from '../common/AttributesDivision'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { urlApiHr } from '../common/configApiHr'

import useTokenInitializer from '../../hooks/token-initializer'

const HrDivisionIDPage: React.FC = (props: any) => {
  DocumentTitle('Hr', 'จัดการตำแหน่ง')
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  useTokenInitializer()

  useEffect(() => {
    const q = `query($divisionName: DIVISION_NAME!) {
      viewDivision(divisionName: $divisionName) {
        divisionName
        positionList {
          positionId
          divisionName
          name
          deletedAt
        }
      }
    }
    `
    // Where to put the variables
    const v = {
      divisionName: props.match.params.id,
    }

    axios({
      url: urlApiHr,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const viewDivision = result.data.data.viewDivision
        setFieldValue('loading', false, false)
        setFieldValue('listPosition', viewDivision.positionList, false)
        setFieldValue('divisionName', viewDivision.divisionName, false)
        setFieldValue(
          'division',
          divisionList.find((ele) => ele.divisionName === viewDivision.divisionName)?.name,
          false,
        )
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [])

  return (
    <>
      <section className={styles.section1}>
        <div className={`loader loader-default ${values.loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>รายชื่อพนักงาน</h1>
        <span className={styles.subHeaderTitle}>แผนก{values.division}</span>
      </section>
      {/* <section className={styles.section2}>
        <Link
          to={{
            pathname: `/hr/division/position/add`,
            state: { divisionName: values.divisionName, division: values.division },
          }}
          className={`styleBtn`}
        >
          เพิ่มตำแหน่ง
        </Link>
      </section> */}
      <div style={{ margin: '0 25px', maxWidth: '100px', minWidth: '100px', width: '100px' }}>
        <table className={styles.widthTable}>
          <thead className={styles.widthTable}>
            <tr className={styles.widthTable}>
              <th className={`borderLeftRadius ${styles.widthTable}`}>เลือก</th>
              <th className={`borderRightRadius ${styles.widthTable}`}>ชื่อแผนก</th>
            </tr>
          </thead>
          <tbody>
            {values.listPosition.map((data: any, index: number) => (
              <tr key={index}>
                <td>
                  {index + 1}
                  {/* <Link
                    to={{
                      pathname: `/hr/division/position/${data.positionId}`,
                      state: { divisionName: values.divisionName, division: values.division, name: data.name },
                    }}
                  >
                    <button className="styleBtnForTable" type="button">
                      แก้ไข
                    </button>
                  </Link> */}
                </td>
                <td>{data.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <section className={styles.section3}>
        <button type="button" className={` styleBtn`} onClick={() => props.history.push('/hr/division')}>
          ย้อนกลับ
        </button>
      </section>
    </>
  )
}

const EnhancedHrDivisionIDPage = withFormik({
  mapPropsToValues: () => ({
    loading: true,
    listPosition: [],
    division: '',
    divisionName: '',
  }),
  validate: (values) => {
    console.log(values)
  },
  handleSubmit: (values, { setFieldValue }) => {
    console.log(values)
  },
})(HrDivisionIDPage)

export default EnhancedHrDivisionIDPage
