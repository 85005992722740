import { makeActionCreator } from '../generate_action_creator'
import axios from 'axios'
import { urlApi } from '../../../inventory/common/configUrlApi'
import { urlApiHr } from '../../../hr/common/configApiHr'
// Constants

// Map between FACT enum and URI when query
export const FACTS: any = {
  STATIONS: 'viewSkuPropertyAllListType',
  REASON: 'reasonFact',
  LISTDIVISION: 'listDivision',
  LISTTEAM: 'listTeam',
}

// Actions
const FETCH_DATA_REQUEST = 'api/fact/FETCH_DATA_REQUEST'
const FETCH_DATA_FAILURE = 'api/fact/FETCH_DATA_FAILURE'
const FETCH_DATA_SUCCESS = 'api/fact/FETCH_DATA_SUCCESS'

const _FACT_DEFAULT = {
  isFetching: false,
  didInvalidate: false,
  lastUpdated: -1,
  items: [],
}

const initialState: any = {}
Object.keys(FACTS).map((key: any) => {
  initialState[FACTS[key]] = { ..._FACT_DEFAULT }
})

// Reducer:
export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        [action.factName]: {
          ...state[action.factName],
          isFetching: true,
        },
      }
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        [action.factName]: {
          ...state[action.factName],
          isFetching: false,
        },
      }
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        [action.factName]: {
          ...state[action.factName],
          isFetching: false,
          lastUpdated: action.receivedAt,
          items: action.items,
        },
      }
    default:
      return state
  }
}

// Action Creators

const requestFact = makeActionCreator(FETCH_DATA_REQUEST, 'factName')
const receiveFailure = makeActionCreator(FETCH_DATA_FAILURE, 'factName')

function receiveFact(factName: any, data: any) {
  return {
    type: FETCH_DATA_SUCCESS,
    factName,
    receivedAt: Date.now(),
    items: data,
  }
}

function shouldFetchFact(state: any, factName: any) {
  const fact = state.api.fact[factName]
  if (fact.lastUpdated < 0) {
    return true
  } else if (fact.isFetching) {
    return false
  } else {
    return fact.didInvalidate
  }
}

export function fetchFact(factName: any) {
  return (dispatch: any) => {
    dispatch(requestFact(factName))

    let q: any
    let urlConfig: any
    if (factName === 'viewSkuPropertyAllListType') {
      urlConfig = urlApi
      q = `query viewSkuPropertyAllListType($pgIdx: Int, $pgSz: Int) {
        viewSkuPropertyAll {
          listType(pageIndex: $pgIdx, maxResults: $pgSz) {
            totalCount
            pageIndex
            results {
              id
              code
              name
              uri
            }
          }
          listColor(pageIndex: 0, maxResults: 9999) {
            totalCount
            pageIndex
            results {
              id
              code
              name
            }
          }
          listProduct(pageIndex: 0, maxResults: 9999) {
            totalCount
            pageIndex
            results {
              id
              code
              name
            }
          }
          listSurface(pageIndex: 0, maxResults: 9999) {
            totalCount
            pageIndex
            results {
              id
              code
              name
            }
          }
        }
      }`
    } else if (factName === 'reasonFact') {
      urlConfig = urlApi
      q = `query {
        listPhysicalCountAdjustReason {
          id
          name
          description
        }
      }`
    } else if (factName === 'listDivision') {
      urlConfig = urlApiHr
      q = `{
        listDivision {
          divisionName
          positionList {
            positionId
            divisionName
            name
            deletedAt
          }
        }
      }`
    } else if (factName === 'listTeam') {
      urlConfig = urlApiHr
      q = `query ($showRemove: Boolean){
        listTeam(showRemove: $showRemove) {
          results {
            teamId
            teamName
            memberList
            deletedAt
          }
        }
      }`
    }

    // Where to put the variables
    const v = {
      pgIdx: 0,
      pgSz: 100000,
    }
    return axios({
      url: urlConfig,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          // console.log('res.data.data', res.data.data)
          dispatch(receiveFact(factName, res.data.data))
        } else {
          dispatch(receiveFailure(factName))
        }
      })
      .catch((err) => console.log(err.response))
    //   })
  }
}

export function fetchFactIfNeeded(factName: any) {
  return (dispatch: any, getState: any) => {
    if (shouldFetchFact(getState(), factName)) {
      // Dispatch a thunk from thunk!
      return dispatch(fetchFact(factName))
    } else {
      // Let the calling code know there's nothing to wait for.
      return Promise.resolve()
    }
  }
}
