import { CognitoUserPool } from 'amazon-cognito-identity-js'

const poolData = {
  UserPoolId: 'ap-southeast-1_C69klTed9',
  ClientId: '3ccqkh4265vekgiq215kvppd55',
}

export default new CognitoUserPool(poolData)

export const accessKeyId = 'AKIA5BW4RILWJAWVHZM3'
export const secretAccessKey = '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'

export const UserPoolId = 'ap-southeast-1_C69klTed9'
export const ClientId = '3ccqkh4265vekgiq215kvppd55'
