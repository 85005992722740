import React, { useEffect, useState } from 'react'
import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../common/table'
import * as AWS from 'aws-sdk'
import { bucketRegion } from '../../common/configAws'
import { accessKeyId, secretAccessKey, UserPoolId } from '../common/UserPool'

import styles from './index.module.scss'
import DocumentTitle from '../common/document-title'
import { findPropertiesHr } from '../common/fildProperties'
import useTokenInitializer from '../../hooks/token-initializer'
import axios from 'axios'
import { urlApiHr } from '../common/configApiHr'

const HrMainPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const [listData, setListData] = useState<any>([])
  const [listDataSecond, setListDataSecond] = useState<any>([])

  const colHead: string[] = [
    'รหัสพนักงาน',
    'ชื่อ นามสกุล',
    'อีเมล',
    'แผนก',
    'ตำแหน่ง',
    // 'ทีม',
    'สถานะ',
    'ใช้งานล่าสุด',
    'สมัครเมื่อ',
  ]

  // First Run get all list user in cognito
  useEffect(() => {
    const params = {
      UserPoolId: UserPoolId,
    }
    const test = new Promise((resolve, reject) => {
      AWS.config.update({
        region: bucketRegion,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      })
      const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
      cognitoidentityserviceprovider.listUsers(params, (err, data: any) => {
        if (err) {
          console.log(err)
          reject(err)
        } else {
          const listUser: any = []
          data.Users.map((user: any) => {
            const mapper = {
              key: user.Username,
              Username: user.Username.slice(0, 8),
              Fullname: findPropertiesHr(user.Attributes, 'name'),
              Email: findPropertiesHr(user.Attributes, 'email'),
              // แผนก ตำแหน่ง ทีม
              Division: findPropertiesHr(user.Attributes, 'custom:division')
                ? findPropertiesHr(user.Attributes, 'custom:division')
                : 'Admin',
              Position: findPropertiesHr(user.Attributes, 'custom:position')
                ? findPropertiesHr(user.Attributes, 'custom:position')
                : 'Admin',
              // Team: findPropertiesHr(user.Attributes, 'custom:team')
              //   ? findPropertiesHr(user.Attributes, 'custom:team')
              //   : 'Admin',
              status: user.UserStatus,
              updatedAt: new Date(user.UserLastModifiedDate).toISOString().replace('T', ' ').split('.')[0],
              createdAt: new Date(user.UserCreateDate).toISOString().replace('T', ' ').split('.')[0],
            }
            listUser.push(mapper)
          })
          setListData(listUser)

          resolve(data)
        }
      })
    })
  }, [])

  // Second Run Get list Team in gql
  useEffect(() => {
    if (listData.length === 0) return

    const q = `query ($showRemove: Boolean){
      listTeam(showRemove: $showRemove) {
        results {
          teamId
          teamName
          memberList
          deletedAt
        }
      }
    }
    `
    const v = {
      showRemove: false,
    }

    axios({
      url: urlApiHr,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((res) => {
        const listTeam = res.data.data.listTeam.results
        console.log('listTeam', listTeam)
        const prepareList: any = []
        listTeam.map((list: any) => {
          prepareList.push(`${list.teamId}ทีม ${list.teamName}`)
          list.memberList.map((member: any) => {
            prepareList.push(listData.find((ele: any) => ele.key === member))
          })
        })
        setListDataSecond(prepareList)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [listData])
  useTokenInitializer()

  DocumentTitle('Sale', 'CRM')
  return (
    <>
      <section className={styles.section1}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>จัดการทีม</h1>
        <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [USER NAME]</span>
      </section>
      <section className={styles.section2}>
        <div>
          <input
            type="text"
            className="inputForSearch"
            placeholder="ชื่อทีม / รหัสพนักงาน / ชื่อ นามสกุล / อีเมล"
            style={{ maxWidth: '300px', display: 'flex' }}
          />
          <button type="button" className="styleBtn" onClick={() => props.history.push('/hr/teams/add')}>
            สร้างทีม
          </button>
        </div>
      </section>
      <div style={{ margin: '0 25px' }}>
        <TableCommonCompanant
          colHead={colHead}
          rowBody={listDataSecond}
          typeHead={TABLE_HEAD_TYPES.FIRST_COLUMN_IS_BUTTON_TEAM}
          typeBody={TABLE_BODY_TYPES.NONE}
          link="/hr/team"
        />
      </div>

      <section className={styles.section2}>
        <button type="button" className="styleBtn" onClick={() => props.history.push('/hr')}>
          ย้อนกลับ
        </button>
      </section>
    </>
  )
}

export default HrMainPage
