import React, { useRef, useState, useEffect, createRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import styles from './index.module.scss'
import biowoodLogo from '../../navtop/logo.svg'
import axios from 'axios'
import { urlApi } from '../common/configUrlApi'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'

const WhyComponentToPrint: React.FC<{ data: any }> = (data: any) => {
  const [split, setSplit] = useState<any[]>([])

  useEffect(() => {
    setSplit(_.chunk<any>(data.data, 15))
  }, [data.data])

  const makePage = (dataTable: any, keyProp: number) => {
    return (
      <div className={styles.page} key={keyProp}>
        <div className={styles.subpage}>
          {/* Section 1 My Company */}
          <div className={styles.row}>
            <div className={styles.col9}>
              <div className={styles.logo}>
                <img src={biowoodLogo} width="180" />
              </div>
              <div className={styles.myAddressCompony}>
                <p className={styles.margin5}>บริษัท จีอาร์เอ็ม (ประเทศไทย) จำกัด</p>
                <p className={styles.margin5}>(สำนักงานใหญ่) 55/32 ซอย หมู่บ้านกลางเมืองเดอะปารีส</p>
                <p className={styles.margin5}>รัชวิภา แขวง ลาดยาว เขตจตุจักร กรุงเทพมหานคร 10900</p>
                <p className={styles.margin5}>เลขประจำตัวผู้เสียภาษีอากร 0415552000958</p>
                <p className={styles.margin5}>โทร. 081 357 9456 / 086 418 9584</p>
              </div>
            </div>

            <div className={styles.col3}>
              <p className={styles.margin5}>{data.documentStatusId === 'DRAFT' ? 'DRAFT' : 'ต้นฉบับ/ Original'}</p>
              <h2 className={styles.margin5}>ใบนำเข้าสินค้า</h2>
              <p className={styles.margin5}>(Goods Received)</p>
              <div className={styles.groupNoAndDate}>
                <div className={styles.groupNo}>
                  <div className={styles.subGroupNo}>
                    <p className={styles.margin5}>เลขที่ / No.</p>
                  </div>
                  <div>
                    <p className={styles.margin5}> {data.internalDocumentID} </p>
                  </div>
                </div>
                <div className={styles.groupDate}>
                  <div className={styles.subGroupNo}>
                    <p className={styles.margin5}>วันที่ / Date.</p>
                  </div>
                  <div>
                    <p className={styles.margin5}> {data.documentDate} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Section 2 Info Customer */}
          <div className={styles.row}>
            <div className={styles.col9}>
              <div className={styles.infoCustomer}>
                {/* <p className={styles.margin5}>รหัส/Code :</p>
              <p className={styles.margin5}>ชื่อ ลูก ค้า/Customer :</p>
              <p className={styles.margin5}>เลขประจำตัวผู้เสียภาษีอากร/Tax ID. :</p>
              <p className={styles.margin5}>ที่อยู่/ Address</p> */}
              </div>
            </div>

            <div className={styles.col3}>
              <div className={styles.groupDocumentNo}>
                <div className={styles.groupNo}>
                  {/* <div className={styles.subGroupNo}>
                  <p className={styles.margin5}>เอกสารอ้างอิง/Ref.No.</p>
                </div>
                <div>
                  <p className={styles.margin5}> test </p>
                </div> */}
                </div>
                <div className={styles.groupDate}>
                  <div className={styles.subGroupNo}>
                    <p className={styles.margin5}>หมายเลขใบสั่ง ซื้อ /PO.</p>
                  </div>
                  <div>
                    <p className={styles.margin5}> {data.documentRef} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Section 4 Table  */}
          <div className={styles.row}>
            <table className={styles.tablePDF}>
              <thead>
                <tr>
                  <th>
                    ลำดับ <br /> (Item)
                  </th>
                  <th>
                    รหัสสินคา <br /> (Code)
                  </th>
                  <th>
                    สี <br /> (Color)
                  </th>
                  <th>
                    ผิว <br /> (Surface)
                  </th>
                  <th>
                    รายละเอียด <br /> (Desciption)
                  </th>
                  <th>ขนาด(หนา x กว้าง x ยาว) </th>
                  <th>
                    แผ่น <br /> (Pieces)
                  </th>
                  <th>
                    รวม (เมตร/แผ่น) <br /> (Meter/Pcs)
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataTable.map((data: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{data.index}</td>
                      <td>{data.productCode}</td>
                      <td>{data.color}</td>
                      <td>{data.surface}</td>
                      <td>{data.description}</td>
                      <td>{data.spec}</td>
                      <td>{data.qty}</td>
                      <td>{data.uom}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {/* Section 5 Remark */}
          <div className={styles.row} style={{ height: '120px' }}>
            <p className={styles.margin5}>หมายเหตุ/Remark :</p>
          </div>
          {/* Section 6 Sign */}
          <div className={styles.row} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
            <div>
              <p style={{ fontSize: '13px', margin: '10px' }}>ลงชื่อ...................................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>วันที่/Date........................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>ผู้รับสินค้า/Received</p>
            </div>
            <div>
              <p style={{ fontSize: '13px', margin: '10px' }}>ลงชื่อ...................................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>วันที่/Date........................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>ผู้ส่งสินค้า/Delivered by</p>
            </div>
            <div>
              <p style={{ fontSize: '13px', margin: '10px' }}>ลงชื่อ...................................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>วันที่/Date........................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>ผู้ตรวจสอบ/Inspected by</p>
            </div>
            <div>
              <p style={{ fontSize: '13px', margin: '10px' }}>ลงชื่อ...................................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>วันที่/Date........................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>ผู้ออกเอกสาร</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <div>{split.map((e, i) => makePage(e, i))}</div>
}

/**
 * This is a wrapper, as the react-to-printer requires a component
 * in order to render and print.
 * There might be other/better ways of doing this, but for now, this
 * works.
 * NOTE: If no such wrapper, react-to-print complains about:
 * "work only Class based components"
 */
class FunctionalComponentWrapper extends React.Component<{
  data: any
  internalDocumentID: string
  documentDate: string
  documentRef: string
  documentStatusId: string
}> {
  constructor(props: {
    data: any
    internalDocumentID: string
    documentDate: string
    documentRef: string
    documentStatusId: string
  }) {
    super(props)
  }
  render() {
    return <WhyComponentToPrint {...this.props} />
  }
}

export const InventoryPdfReceive = (props: any) => {
  const componentRef: { current: any } = useRef()
  const [data, setData] = useState<any>([])

  const [internalDocumentID, setInternalDocumentID] = useState('')
  const [documentStatusId, setDocumentStatusId] = useState('')
  const [documentDate, setDocumentDate] = useState('')
  const [documentRef, setDocumentRef] = useState('')
  const [lineItems, setLineItems] = useState<any[]>([])

  // Fetch Docuement Receive
  useEffect(() => {
    const q = `query viewGoodReceive($documentId: ID!){
      viewGoodReceive(id: $documentId){
        id
        documentTypeId
        documentStatusId
        internalDocumentId
        updatedAt
        purchaseOrderDocumentId
        purchaseOrder {
          internalDocumentId
        }

        lotNumber
        packLineItem {
          id
          skuId
          runningNumber
          qty
          uomId
          packId
          scanBy
          scanAt
          voided
          serialNumber
          sku {
            productCode
            name
            profile
            spec
            color {
              name
            }
            product {
              name
            }
            type {
              name
            }
            surface {
              name
            }
            qtyPerPack(packId: 1)
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      documentId: props.match.params.id,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const viewGoodReceive = result.data.data.viewGoodReceive
        console.log('viewGoodReceive', viewGoodReceive)
        const listMapper: any[] = []
        viewGoodReceive.packLineItem.map((data: any, index: any) => {
          const mapper = {
            index: index + 1,
            productCode: data.sku.productCode,
            color: data.sku.color.name,
            surface: data.sku.surface.name,
            description: data.sku.name,
            spec: data.sku.spec,
            qty: data.qty,
            uom: 'แผ่น',
          }
          listMapper.push(mapper)
        })
        setLineItems(listMapper)
        setInternalDocumentID(viewGoodReceive.internalDocumentId)
        setDocumentStatusId(viewGoodReceive.documentStatusId)
        setDocumentRef(viewGoodReceive.purchaseOrder ? viewGoodReceive.purchaseOrder.internalDocumentId : '')
        setDocumentDate(new Date(viewGoodReceive.updatedAt).toISOString().replace('T', ' ').split('.')[0])
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [])

  const handlePrint = useReactToPrint({
    content: () => {
      return componentRef.current
    },
  })

  function padArray<T = any>(arr: T[], padToSize: number, padderObject: T): T[] {
    const copyOfArray = [...arr]
    const needToPad = copyOfArray.length % padToSize !== 0
    const padAmount = 15 - (copyOfArray.length % padToSize)
    if (!needToPad) {
      return copyOfArray
    }
    for (let i = 0; i < padAmount; i++) {
      copyOfArray.push(padderObject)
    }
    return copyOfArray
  }

  useEffect(() => {
    setData(
      padArray([...lineItems], 15, {
        index: '',
        productCode: '',
        color: '',
        surface: '',
        description: '',
        spec: '',
        qty: '',
        uom: '',
      }),
    )
  }, [lineItems])

  return (
    <>
      <button
        onClick={handlePrint}
        type="button"
        className="styleBtn"
        style={{ margin: '20px auto -40px', display: 'flex' }}
      >
        พิมพ์!
      </button>
      <FunctionalComponentWrapper
        ref={componentRef}
        data={data}
        internalDocumentID={internalDocumentID}
        documentDate={documentDate}
        documentRef={documentRef}
        documentStatusId={documentStatusId}
      />
    </>
  )
}
