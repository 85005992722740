import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import { urlApi } from '../common/configUrlApi'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from '../../common/configAws'
import ModalAlert from '../common/modalNotFormik'
import { useBoolean } from '@uifabric/react-hooks'

const InventoryItemMasterDataListPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(false)

  const [dataListUpdate, setDataListUpdate] = useState<any>([])

  const [successSubmit, setSuccessSubmit] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  DocumentTitle('Inventory', 'นำเข้าข้อมูล')

  const colHead: string[] = [
    'Upload',
    'สถานะอัพโหลดรูป',
    'สถานะอัพโหลดรูป 2D',
    'รหัสสินค้า',
    'รายละเอียด', //รายละเอียด
    'ชื่อสินค้า',
    'ประเภท', //ประเภท
    'ขนาด(หนา x กว้าง x ยาว)', //SPEC
    'ผิว', //FINISH
    'สี', //COLOR
    'จำนวนแผ่นต่อห่อ',
    'รูปภาพสินค้า',
    'รูปภาพสินค้า 2D',
  ]

  const rowBody: any = dataListUpdate

  const handleImportFile = (e: any) => {
    setLoading(true)
    const file = e.target.files[0]
    if (e.target.files === 0) return
    const fileReader = new FileReader()
    fileReader.readAsText(file, 'utf8')
    fileReader.onload = function () {
      const q = `mutation ($csvString: String!){
        addOrEditSkuByCSV(csvString: $csvString) {
          skuArr {
            id
            name
            productCode
            typeId
            type {
              id
              code
              name
            }
            uri
            spec
            uri2D
            surface {
              name
            }
            color {
              name
            }
            product {
              name
            }
            qtyPerPack(packId: 1)
            pricePerMeter
            kilogramPerMeter
            filename
            filename2D
          }
        }
      }`
      // Where to put the variables
      const v = {
        csvString: fileReader.result,
      }
      axios({
        url: urlApi,
        method: 'post',
        data: {
          query: q,
          variables: v,
        },
      })
        .then((result) => {
          console.log('result', result)
          const addOrEditSkuByCSV = result.data.data.addOrEditSkuByCSV.skuArr
          const newArray: any = []
          addOrEditSkuByCSV.map((data: any, index: number) => {
            const mapper = {
              index: index,
              upload: '',
              status: 'รออัพโหลด',
              status2D: 'รออัพโหลด',
              id: data.id,
              productId: data.product.name,
              productCode: data.productCode, //รายละเอียด
              name: data.name,
              profile: data.type.name, //ประเภท
              spec: data.spec, //SPEC
              finish: data.surface.name, //FINISH
              color: data.color.name, //COLOR
              qtyPerPack: data.qtyPerPack,
              picName: data.filename,
              picName2D: data.filename2D,
            }
            newArray.push(mapper)
          })
          showModal()
          setSuccessSubmit('เพิ่มสำเร็จ')
          setDataListUpdate(newArray)
          setLoading(false)
        })
        .catch((err) => {
          showModal()
          setSuccessSubmit('เกิดปัญหา')
          setErrMsg('กรุณาเช็คข้อมูลที่นำเข้าว่าถูกต้องตามแบบหรือไม่')
          console.log('err', err.response)
        })
    }
  }

  const uploadImageUriAndUri2D = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = Array.from(e.target.files)
    // console.log('file', file)
    // console.log('dataListUpdate', dataListUpdate)
    if (file.length !== 0) {
      const albumPhotosKey = encodeURIComponent('ItemMasterData') + '/'

      file.map((dataFile: any) => {
        setLoading(true)
        const resItem = dataListUpdate.find((ele: any) => ele.picName === dataFile.name)
        const resItem2D = dataListUpdate.find((ele: any) => ele.picName2D === dataFile.name)
        if (resItem) {
          const photoKey = albumPhotosKey + resItem.id

          // Use S3 ManagedUpload class as it supports multipart uploads
          const upload = new AWS.S3.ManagedUpload({
            params: {
              Bucket: albumBucketName,
              Key: photoKey,
              Body: dataFile,
              ContentType: dataFile.type,
            },
          })

          const promise = upload.promise()

          promise.then(
            function (dataAws) {
              const q = `mutation editSku($input: EditSku!) {
              editSku(input: $input) {
                id
                uri
              }
            }`
              // Where to put the variables
              const v = {
                input: {
                  id: resItem.id,
                  uri: dataAws.Location,
                },
              }

              axios({
                url: urlApi,
                method: 'post',
                data: {
                  query: q,
                  variables: v,
                },
              })
                .then((res) => {
                  console.log(res)
                  const newDataListUpdate = [...dataListUpdate]
                  newDataListUpdate[resItem.index].status = 'อัพโหลดแล้ว'
                  setDataListUpdate(newDataListUpdate)
                  setLoading(false)
                })
                .catch((err) => {
                  console.log(err)
                })
            },
            function (err) {
              console.log('err', err)
              return alert('There was an error uploading your photo: ')
            },
          )
        } else if (resItem2D) {
          const photoKey = albumPhotosKey + resItem2D.id + '2D'

          // Use S3 ManagedUpload class as it supports multipart uploads
          const upload = new AWS.S3.ManagedUpload({
            params: {
              Bucket: albumBucketName,
              Key: photoKey,
              Body: dataFile,
              ContentType: dataFile.type,
            },
          })

          const promise = upload.promise()

          promise.then(
            function (dataAws) {
              const q = `mutation editSku($input: EditSku!) {
              editSku(input: $input) {
                id
                uri2D
              }
            }`
              // Where to put the variables
              const v = {
                input: {
                  id: resItem2D.id,
                  uri2D: dataAws.Location,
                },
              }

              axios({
                url: urlApi,
                method: 'post',
                data: {
                  query: q,
                  variables: v,
                },
              })
                .then((res) => {
                  console.log(res)
                  const newDataListUpdate = [...dataListUpdate]
                  newDataListUpdate[resItem2D.index].status2D = 'อัพโหลดแล้ว'
                  setDataListUpdate(newDataListUpdate)
                  setLoading(false)
                })
                .catch((err) => {
                  console.log(err)
                })
            },
            function (err) {
              console.log('err', err)
              return alert('There was an error uploading your photo: ')
            },
          )
        } else {
          setLoading(false)
        }
      })
    }
  }

  return (
    <>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>นำเข้าข้อมูล</h1>
      </section>
      <section className={styles.groupButton}>
        <input type="file" id="file" onChange={(e: any) => handleImportFile(e)} accept=".csv" />
        <label htmlFor="file" className="styleBtn">
          อัพโหลดข้อมูล
        </label>
      </section>
      {dataListUpdate.length !== 0 && (
        <section className={styles.tableList}>
          <table style={{ margin: '5px 0' }}>
            <thead>
              <tr>
                {colHead.map((data: string, index: number) => {
                  if (data === 'Upload') {
                    return (
                      <th
                        key={index}
                        className={`${
                          index === 0 ? 'borderLeftRadius' : index === colHead.length - 1 ? 'borderRightRadius' : null
                        } `}
                      >
                        <input
                          type="file"
                          id={`file${index}`}
                          onChange={(e: any) => uploadImageUriAndUri2D(e)}
                          accept="image/*"
                          multiple
                        />
                        <label htmlFor={`file${index}`} className="styleBtnForTable">
                          อัพโหลดรูปทั้งหมด
                        </label>
                      </th>
                    )
                  }
                  return (
                    <th
                      key={index}
                      className={`${
                        index === 0 ? 'borderLeftRadius' : index === colHead.length - 1 ? 'borderRightRadius' : null
                      } `}
                    >
                      {data}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {rowBody.map((data: any, index: number) => (
                <tr key={index}>
                  <td>
                    <input
                      type="file"
                      id={`file${index}`}
                      onChange={(e: any) => uploadImageUriAndUri2D(e)}
                      accept="image/*"
                      multiple
                    />
                    <label htmlFor={`file${index}`} className="styleBtnForTable">
                      อัพโหลดรูป
                    </label>
                  </td>
                  <td>
                    <div className={data.status === 'อัพโหลดแล้ว' ? styles.tagGreen : styles.tagRed}>{data.status}</div>
                  </td>
                  <td>
                    <div className={data.status2D === 'อัพโหลดแล้ว' ? styles.tagGreen : styles.tagRed}>
                      {data.status2D}
                    </div>
                  </td>
                  <td>{data.productId}</td>
                  <td>{data.productCode}</td>
                  <td>{data.name}</td>
                  <td>{data.profile}</td>
                  <td>{data.spec}</td>
                  <td>{data.finish}</td>
                  <td>{data.color}</td>
                  <td>{data.qtyPerPack}</td>
                  <td>{data.picName}</td>
                  <td>{data.picName2D}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/item-master-data">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
      {ModalAlert(isModalOpen, hideModal, successSubmit, errMsg)}
    </>
  )
}

export default InventoryItemMasterDataListPage
