import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../common/table'
import SummaryTopTableComponant from '../common/summaryTopTable'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import { urlApi } from '../common/configUrlApi'
import GroupBtnDocument from '../common/groupBtnDocuemnt'

import useTokenInitializer from '../../hooks/token-initializer'
import { FormatDateTime } from '../../common/dateFormat'

import EnhancedTable from '../../common/table/item/paginationByFront/firstColBtnandInput'
import { HeadCell } from '../../common/table/config'

export interface ILineItems {
  lineNumber: number
  itemID: string
  pack: string
  quantity: number
  quantityPerPack: number
  status: string
  by: string
  timeStamp: string
}

const InventoryDocumentIssueIDPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const [documentID, setDocumentID] = useState('')
  const [documentRef, setDocumentRef] = useState('')
  const [lineItemsOld, setLineItemsOld] = useState<(string | ILineItems)[]>([])
  const [documentStatusId, setDocumentStatusId] = useState('')
  const [lineItems, setLineItems] = useState<(string | ILineItems)[]>([])

  const [summaryTopTable, setSummaryTopTable] = useState<any>([])
  DocumentTitle('Inventory', 'นำสินค้าออก')
  useTokenInitializer()

  useEffect(() => {
    const q = `query viewGoodIssue($documentId: ID!) {
      viewGoodIssue(id: $documentId) {
        id
        internalDocumentId
        documentTypeId
        documentStatusId
        salesOrderDocumentId
        salesOrder {
          internalDocumentId
        }
        lineItem {
          id
          skuId
          qty
          uomId
          packId
          lineNumber
          sku {
            productCode
            qtyPerPack(packId: 1)
            product {
              name
            }
            onHandStatistic {
              available
              ordered
              inStock
              committed
            }
          }
          packLineItem {
            id
            skuId
            runningNumber
            serialNumber
            qty
            uomId
            packId
            scanBy
            scanAt
            voided
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      documentId: props.match.params.id,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const viewGoodIssue = result.data.data.viewGoodIssue
        const listMapper: any[] = []
        viewGoodIssue.lineItem.map((data: any) => {
          const mapper = {
            lineNumber: data.lineNumber,
            itemID: `${data.skuId}`,
            pack: data.qty,
            piecePerPack: data.sku.qtyPerPack,
            total: data.sku.onHandStatistic.available - data.sku.onHandStatistic.committed,
            stock: data.sku.onHandStatistic.inStock,
            packLineItem: data.packLineItem,
            productCode: data.sku.product.productCode,
            description: data.sku.product.name,
          }
          listMapper.push(mapper)
        })
        setLoading(false)
        setLineItemsOld(listMapper)
        setDocumentStatusId(viewGoodIssue.documentStatusId)
        setDocumentID(viewGoodIssue.internalDocumentId)
        setDocumentRef(viewGoodIssue.salesOrder.internalDocumentId)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [])

  useEffect(() => {
    const packSKU: { packList: number; totalPiece: number; totalPieceAndPack: number; totalPackReceive: number }[] = []

    const newLineItems: any = []
    lineItemsOld.map((data: any) => {
      const newLinePacks: any = []
      let countQty = 0

      let totalPiece = 0 //For summaryTopTable
      let totalPieceAndPack = 0 //For summaryTopTable
      let totalPackReceive = 0

      totalPieceAndPack = totalPieceAndPack + data.piecePerPack * data.pack

      data.packLineItem.length > 0 &&
        data.packLineItem.map((data2: any) => {
          countQty = countQty + data2.qty
          const mapper = {
            // id: `${data2.serialNumber}`,
            itemID: `${data.productCode}`,
            pack: data2.runningNumber,
            status: data2.scanBy ? 'สแกนแล้ว' : 'รอสแกน',
            quantity: data2.qty ? data2.qty : 0,
            quantityPerPack: data.piecePerPack,
            total: data.total,
            stock: data.stock,
            serialNumber: `${data2.serialNumber}`,
            by: data2.scanBy,
            timeStamp: data2.scanAt ? FormatDateTime(data2.scanAt) : '',
          }
          totalPiece = totalPiece + data2.qty
          totalPackReceive = totalPackReceive + 1
          newLinePacks.push(mapper)
        })

      packSKU.push({
        packList: data.packLineItem.length,
        totalPiece: totalPiece,
        totalPieceAndPack: totalPieceAndPack,
        totalPackReceive: totalPackReceive,
      })

      newLineItems.push(
        `รหัส ${data.productCode} - ${data.description} - ${Math.ceil(countQty / (totalPieceAndPack / data.pack))}/${
          data.pack
        } ห่อ = ${countQty}/${totalPieceAndPack} แผ่น`,
      )
      newLineItems.push(...newLinePacks)
    })

    setSummaryTopTable(packSKU)
    setLineItems(newLineItems)
  }, [documentID])

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'createAt', numeric: false, disablePadding: false, label: 'รหัสสินค้า' },
    { id: 'Pack', numeric: false, disablePadding: false, label: 'Pack' },
    { id: 'scan', numeric: false, disablePadding: false, label: 'สแกน' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'จำนวนแผ่น' },
    { id: 'qtyperpack', numeric: false, disablePadding: false, label: 'แผ่น/ห่อ' },
    { id: 'totle', numeric: false, disablePadding: false, label: 'คงเหลือสุทธิ' },
    { id: 'stock', numeric: false, disablePadding: false, label: 'คงคลัง' },
    { id: 'sn', numeric: false, disablePadding: false, label: 'S/N' },
    { id: 'by', numeric: false, disablePadding: false, label: 'โดย' },
    { id: 'date', numeric: false, disablePadding: false, label: 'เมื่อ' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  return (
    <>
      <div className={styles.pageWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>นำสินค้าออก</h1>
        <p>
          ใบนำสินค้าออก {documentID} - อ้างอิง {documentRef} - {documentStatusId}
        </p>
      </div>
      <SummaryTopTableComponant summaryTopTable={summaryTopTable} />
      <section className={`tableListMui`}>
        <EnhancedTable colHead={headCells} rows={lineItems} removeButtonClassUniqueSKU={true} />
      </section>
      <section className={styles.groupbtn}>
        <GroupBtnDocument documentStatusId={documentStatusId} linkGoBack="/inventory/issue" />
      </section>
    </>
  )
}

export default InventoryDocumentIssueIDPage
