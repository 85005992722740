import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'
import { useId } from '@uifabric/react-hooks'

const dialogStyles = { main: { maxWidth: 450 } }

const dialogContentProps = {
  type: DialogType.normal,
  title: 'กรุณายืนยันคำสั่ง',
  closeButtonAriaLabel: 'Close',
  subText: 'คุณยังคงต้องการทำรายการดังต่อไปนี้ต่อไปหรือไม่?',
}

const GroupBtnDocument = (props: {
  onClickSaveDoc?: () => void
  onClickSendDoc?: () => void
  onClickNextDoc?: () => void
  onClickSumbitDoc?: () => void
  onClickRemoveDoc?: () => void
  documentStatusId: string
  linkGoBack: string
}): JSX.Element => {
  const [hideDialogRemoveDoc, setHideDialogRemoveDoc] = useState(true)
  const [hideDialogSubmitDoc, setHideDialogSubmitDoc] = useState(true)

  const labelId: string = useId('dialogLabel')
  const subTextId: string = useId('subTextLabel')

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
    }),
    [labelId, subTextId],
  )

  return (
    <>
      <Dialog
        hidden={hideDialogRemoveDoc}
        onDismiss={() => setHideDialogRemoveDoc(hideDialogRemoveDoc ? false : true)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setHideDialogRemoveDoc(hideDialogRemoveDoc ? false : true)
              if (props?.onClickRemoveDoc?.()) return props?.onClickRemoveDoc?.()
            }}
            text="ยืนยัน"
          />
          <DefaultButton onClick={() => setHideDialogRemoveDoc(hideDialogRemoveDoc ? false : true)} text="ยกเลิก" />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideDialogSubmitDoc}
        onDismiss={() => setHideDialogSubmitDoc(hideDialogSubmitDoc ? false : true)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setHideDialogSubmitDoc(hideDialogSubmitDoc ? false : true)
              if (props?.onClickSendDoc?.()) return props?.onClickSendDoc?.()
            }}
            text="ยืนยัน"
          />
          <DefaultButton onClick={() => setHideDialogSubmitDoc(hideDialogSubmitDoc ? false : true)} text="ยกเลิก" />
        </DialogFooter>
      </Dialog>

      <Link to="/inventory">
        <button type="button" className="styleBtn" style={{ padding: '7px 10px' }}>
          หน้าแรก
        </button>
      </Link>
      <Link to={`${props.linkGoBack}`}>
        <button type="button" className="styleBtn" style={{ padding: '7px 10px' }}>
          ย้อนกลับ
        </button>
      </Link>

      {props.documentStatusId !== 'COMPLETE' && props?.onClickRemoveDoc && (
        <button
          type="button"
          className="styleBtn"
          onClick={() => setHideDialogRemoveDoc(hideDialogRemoveDoc ? false : true)}
          style={{ padding: '7px 10px', border: '1px solid #F44336', backgroundColor: 'white', color: 'black' }}
        >
          ลบเอกสาร
        </button>
      )}

      {props?.onClickNextDoc && (
        <button
          type="button"
          className="styleBtn"
          onClick={() => props?.onClickNextDoc?.()}
          style={{ padding: '7px 10px' }}
        >
          ถัดไป
        </button>
      )}
      {props.documentStatusId !== 'COMPLETE' ? (
        <>
          {props?.onClickSaveDoc && (
            <button
              type="button"
              className="styleBtn"
              onClick={() => props?.onClickSaveDoc?.()}
              style={{ padding: '7px 10px' }}
            >
              บันทึก
            </button>
          )}

          {props?.onClickSendDoc && (
            <button
              type="button"
              className="styleBtn"
              onClick={() => setHideDialogSubmitDoc(hideDialogSubmitDoc ? false : true)}
              style={{ padding: '7px 10px' }}
            >
              ส่งเอกสาร
            </button>
          )}
        </>
      ) : (
        ''
      )}
      {props?.onClickSumbitDoc && (
        <button
          type="button"
          className="styleBtn"
          onClick={() => props?.onClickSumbitDoc?.()}
          style={{ padding: '7px 10px' }}
        >
          อนุมัติ
        </button>
      )}
    </>
  )
}

export default GroupBtnDocument
