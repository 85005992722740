import moment from 'moment'

const formatDateTime = 'DD-MM-YYYY HH:mm'
const formatDate = 'DD-MM-YYYY'

export const FormatDate = (date: string): string => {
  return moment(date).format(formatDate)
}

export const FormatDateTime = (date: string): string => {
  return moment(date).format(formatDateTime)
}
