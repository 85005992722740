import React, { ReactElement, useState, useEffect } from 'react'
import { useHistory, Route, Switch } from 'react-router-dom'
import ProtectedRoute, {
  ProtectedRouteInventory,
  ProtectedRouteInventoryHaveSale,
  ProtectedRouteDelivery,
  ProtectedRouteSale,
  ProtectedRouteHr,
  ProtectedRouteEngineer,
} from './ProtectedRoute'

import { ThemeProvider } from '@fluentui/react-theme-provider'
import { lightTheme } from './themes'

import NavTop from './components/navtop'
import NavSideComponent from './components/navside'
import InventoryNavSideComponent from './components/inventory/navside'
import SaleNavSideComponent from './components/sale/navside'
import EngineerNavSideComponent from './components/engineer/navside'

import NotFoundComponent from './components/404'

import LoginPage from './components/login'
import ForgotPasswordPage from './components/forgotPassword'

import MainPage from './components/main'

// --------- START --------- INVENTORY
import InventoryMainPage from './components/inventory/main-inventory'

// START --- RECEIPT
import InventoryReceivePage from './components/inventory/main-receive'
import InventoryReceiveListSKUPage from './components/inventory/page-receive-list-sku'
import InventoryReceiveListPOPage from './components/inventory/page-receive-list-po'
import InventoryReceiveRefPOPage from './components/inventory/page-goods-receive-ref-po'
import InventoryReceiveDocumentPage from './components/inventory/page-document-receive-list'
import InventoryReceiveDocumentIDPage from './components/inventory/page-document-receive'
// END --- RECEIPT

// START --- RECEIPT
import InventoryIssuePage from './components/inventory/main-issue'
import InventoryIssueListSKUPage from './components/inventory/page-issue-list-sku'
import InventoryIssueListSOPage from './components/inventory/page-issue-list-so'
import InventoryIssueRefSOPage from './components/inventory/page-goods-issue-ref-so'
import InventoryIssueDocumentPage from './components/inventory/page-document-issue-list'
import InventoryDocumentIssueIDPage from './components/inventory/page-document-issue'
// END --- RECEIPT

// START --- ITEM MASTER DATA
import InventoryItemMasterDataListImportPage from './components/inventory/page-item-master-data-list-import'
import InventoryItemMasterDataListPage from './components/inventory/page-item-master-data-list'
import InventoryItemMasterDataListIDPage from './components/inventory/page-item-master-data-list-id'
import InventoryItemMasterDataListEditIDPage from './components/inventory/page-item-master-data-list-id-edit'
import InventoryItemMasterDataListAddPage from './components/inventory/page-item-master-data-list-add'
import InventoryTypeMasterDataPage from './components/inventory/page-type-master-data'
import InventoryTypeMasterDataEditPage from './components/inventory/page-type-master-data-edit'
// END --- ITEM MASTER DATA

// START --- STOCK
import InventoryStockPage from './components/inventory/main-stock'
import InventoryCreateOrEditStockPage from './components/inventory/page-stock-create-or-edit'
import InventorySummaryStockPage from './components/inventory/page-stock-summary'
import InventoryStockDocumentPage from './components/inventory/page-document-stock-list'
// END --- STOCK

// START --- APPROVE
import InventoryApprovePage from './components/inventory/main-approve'
import InventoryCreateApprovePage from './components/inventory/page-approve-create-or-edit'
import InventoryApproveDocumentPage from './components/inventory/page-document-approve-list'
// END --- APPROVE

// START --- DELIVERY NOTE
import InventoryDeliveryNotePage from './components/inventory/main-delivery-note'
import InventoryDeliveryNoteListOrderPage from './components/inventory/page-delivery-note-list-order'
import InventoryDeliveryNoteListEmployeePage from './components/inventory/page-delivery-note-list-employee'
import InventoryDeliveryNoteListPage from './components/inventory/page-delivery-note-list'
import InventoryDeliveryNoteDetailAddPage from './components/inventory/page-delivery-note-detail-add'
import InventoryDeliveryNoteDetailPage from './components/inventory/page-delivery-note-detail'
import InventoryDeliveryNoteDetailCheckInPage from './components/inventory/page-delivery-note-detail-check-in'
// END --- DELIVERY NOTE

// START --- PURCHASE ORDER
import InventoryPOListPage from './components/inventory/main-purchase-order-list'
import InventoryPODocumentPage from './components/inventory/page-document-purchase-order'
// END --- PURCHASE ORDER

// START --- SALE ORDER
import InventorySOListPage from './components/inventory/main-sale-order-list'
import InventorySODocumentPage from './components/inventory/page-document-sale-order'
// END --- SALE ORDER

// START --- PDF
import { InventoryPdfReceive } from './components/inventory/pdf-receive'
import { InventoryPdfIssue } from './components/inventory/pdf-issue'
// END --- PDF

// --------- END --------- INVENTORY

// --------- END --------- SALE
import SaleMainPage from './components/sale/main-sale'
import SaleCalendarPage from './components/sale/calendar'
import SaleCrmPage from './components/sale/main-crm'
import SaleCrmIDPage from './components/sale/page-document-crm'
import SaleQuotationPage from './components/sale/main-quotation'
import { SalePdfQuotation } from './components/sale/pdf-quotation'
// --------- END --------- SALE

// --------- END --------- HR
import HrMainPage from './components/hr/main-hr'
import HrTeamPage from './components/hr/page-hr-team'
import HrTeamIDAddPage from './components/hr/page-hr-team-add'
import HrTeamIDPage from './components/hr/page-hr-team-id'
import HrAddPage from './components/hr/page-hr-add'
import HrDivisionPage from './components/hr/page-hr-division'
import HrDivisionIDPage from './components/hr/page-hr-division-id'
import HrPositionIDPage from './components/hr/page-hr-position-id'
// --------- END --------- HR

// --------- END --------- HR
import EngineerMainPage from './components/engineer/main-engineer'
import EngineerCardIDPage from './components/engineer/page-document-engineer'
// --------- END --------- HR

function App(): ReactElement {
  const [pathname, setPathname] = useState('')

  const history = useHistory()
  // Solve choose NavtopBar & Navigation for Route to new page
  history.listen((location, action) => {
    setPathname(history.location.pathname)
  })

  // Solve choose NavtopBar & Navigation for Reload
  useEffect(() => {
    setPathname(history.location.pathname)
  }, [history])

  const navSideBarRender = () => {
    if (pathname === '/') return <NavSideComponent />
    else if (pathname.indexOf('/hr') !== -1) return <NavSideComponent isHrModule={true} />
    else if (pathname.indexOf('/inventory') !== -1) return <InventoryNavSideComponent />
    else if (pathname.indexOf('/sale') !== -1) return <SaleNavSideComponent />
    else if (pathname.indexOf('/engineer') !== -1) return <EngineerNavSideComponent />
  }

  return (
    <ThemeProvider applyTo="body" theme={lightTheme}>
      <div className="App" style={{ display: 'flex' }}>
        {navSideBarRender()}
        <div style={{ width: '-webkit-fill-available', overflow: 'hidden' }}>
          <NavTop />
          <div className="wapperBody">
            <Switch>
              <Route path="/login" exact component={LoginPage} />
              <Route path="/forgot/password" exact component={ForgotPasswordPage} />

              <ProtectedRoute exact path="/" component={MainPage} />

              {/* --------- START --------- INVENTORY */}
              <ProtectedRouteInventoryHaveSale exact path="/inventory" component={InventoryMainPage} />

              {/* START --- ITEM MASTER DATA */}
              <ProtectedRouteInventoryHaveSale
                exact
                path="/inventory/item-master-data/import"
                component={InventoryItemMasterDataListImportPage}
              />

              <ProtectedRouteInventoryHaveSale
                exact
                path="/inventory/item-master-data"
                component={InventoryItemMasterDataListPage}
              />
              <ProtectedRouteInventory
                exact
                path="/inventory/item-master-data/add"
                component={InventoryItemMasterDataListAddPage}
              />
              <ProtectedRouteInventoryHaveSale
                exact
                path="/inventory/item-master-data/list/:id"
                component={InventoryItemMasterDataListIDPage}
              />
              <ProtectedRouteInventory
                exact
                path="/inventory/item-master-data/list/edit/:id"
                component={InventoryItemMasterDataListEditIDPage}
              />
              <ProtectedRouteInventory
                exact
                path="/inventory/item-master-data/type"
                component={InventoryTypeMasterDataPage}
              />
              <ProtectedRouteInventory
                exact
                path="/inventory/item-master-data/type/edit"
                component={InventoryTypeMasterDataEditPage}
              />
              {/* END --- ITEM MASTER DATA */}

              {/* START --- RECEIPT */}
              <ProtectedRouteInventory exact path="/inventory/receive" component={InventoryReceivePage} />
              <ProtectedRouteInventory
                exact
                path="/inventory/receive/list/sku"
                component={InventoryReceiveListSKUPage}
              />
              <ProtectedRouteInventory exact path="/inventory/receive/list/po" component={InventoryReceiveListPOPage} />
              <ProtectedRouteInventory
                exact
                path="/inventory/receive/ref/po/:id"
                component={InventoryReceiveRefPOPage}
              />
              <ProtectedRouteInventory
                exact
                path="/inventory/receive/document/list"
                component={InventoryReceiveDocumentPage}
              />
              <ProtectedRouteInventory
                exact
                path="/inventory/receive/document/list/:id"
                component={InventoryReceiveDocumentIDPage}
              />

              {/* END --- RECEIPT */}

              {/* START --- ISSUE */}
              <ProtectedRouteInventory exact path="/inventory/issue" component={InventoryIssuePage} />
              <ProtectedRouteInventory exact path="/inventory/issue/list/sku" component={InventoryIssueListSKUPage} />
              <ProtectedRouteInventory exact path="/inventory/issue/list/so" component={InventoryIssueListSOPage} />
              <ProtectedRouteInventory exact path="/inventory/issue/ref/so/:id" component={InventoryIssueRefSOPage} />
              <ProtectedRouteInventory
                exact
                path="/inventory/issue/document/list"
                component={InventoryIssueDocumentPage}
              />
              <ProtectedRouteInventory
                exact
                path="/inventory/issue/document/list/:id"
                component={InventoryDocumentIssueIDPage}
              />
              {/* END --- ISSUE */}

              {/* START --- STOCK */}
              <ProtectedRouteInventory exact path="/inventory/stock" component={InventoryStockPage} />
              <ProtectedRouteInventory exact path="/inventory/stock/:id" component={InventoryCreateOrEditStockPage} />
              <ProtectedRouteInventory
                exact
                path="/inventory/stock/summary/:id"
                component={InventorySummaryStockPage}
              />
              <ProtectedRouteInventory
                exact
                path="/inventory/stock/document/list"
                component={InventoryStockDocumentPage}
              />
              {/* END --- STOCK */}

              {/* START --- APPROVE  */}
              <ProtectedRouteInventory exact path="/inventory/approve" component={InventoryApprovePage} />
              <ProtectedRouteInventory exact path="/inventory/approve/:id" component={InventoryCreateApprovePage} />
              <ProtectedRouteInventory
                exact
                path="/inventory/approve/document/list"
                component={InventoryApproveDocumentPage}
              />
              {/* END --- APPROVE */}

              {/* START --- DELIVERY NOTE  */}
              <ProtectedRouteDelivery exact path="/inventory/delivery-note" component={InventoryDeliveryNotePage} />
              <ProtectedRouteInventory
                exact
                path="/inventory/delivery-note/list/order"
                component={InventoryDeliveryNoteListOrderPage}
              />
              <ProtectedRouteInventory
                exact
                path="/inventory/delivery-note/list/employee"
                component={InventoryDeliveryNoteListEmployeePage}
              />
              <ProtectedRouteInventory
                exact
                path="/inventory/delivery-note/details/add"
                component={InventoryDeliveryNoteDetailAddPage}
              />
              <ProtectedRouteDelivery
                exact
                path="/inventory/delivery-note/document/list"
                component={InventoryDeliveryNoteListPage}
              />
              <ProtectedRouteDelivery
                exact
                path="/inventory/delivery-note/detail/:id"
                component={InventoryDeliveryNoteDetailPage}
              />
              <ProtectedRouteDelivery
                exact
                path="/inventory/delivery-note/detail/check-in/:id"
                component={InventoryDeliveryNoteDetailCheckInPage}
              />
              {/* END --- DELIVERY NOTE    */}

              {/* START --- PURCHASE ORDER */}
              <ProtectedRouteInventory exact path="/inventory/purchase-orders" component={InventoryPOListPage} />
              <ProtectedRouteInventory
                exact
                path="/inventory/purchase-orders/:id"
                component={InventoryPODocumentPage}
              />
              {/* END --- PURCHASE ORDER */}

              {/* START --- SALE ORDER */}
              <ProtectedRouteInventory exact path="/inventory/sale-orders" component={InventorySOListPage} />
              <ProtectedRouteInventory exact path="/inventory/sale-orders/:id" component={InventorySODocumentPage} />
              {/* END --- SALE ORDER */}

              {/* START --- PDF */}
              <ProtectedRouteInventory exact path="/inventory/pdf/receive/:id" component={InventoryPdfReceive} />
              <ProtectedRouteInventory exact path="/inventory/pdf/issue/:id" component={InventoryPdfIssue} />
              {/* END --- PDF */}

              {/* --------- END --------- INVENTORY */}

              {/* --------- START --------- SALE */}
              <ProtectedRouteSale exact path="/sale" component={SaleMainPage} />

              <ProtectedRouteSale exact path="/sale/calendar" component={SaleCalendarPage} />

              <ProtectedRouteSale exact path="/sale/crm" component={SaleCrmPage} />
              <ProtectedRouteSale exact path="/sale/crm/add" component={SaleCrmIDPage} />
              <ProtectedRouteSale exact path="/sale/crm/card/:id" component={SaleCrmIDPage} />

              <ProtectedRouteSale exact path="/sale/quotation" component={SaleQuotationPage} />
              <ProtectedRouteSale exact path="/sale/quotation/:id" component={SaleQuotationPage} />
              <ProtectedRouteSale exact path="/sale/quotation/pdf/:id" component={SalePdfQuotation} />
              {/* --------- END --------- SALE */}

              {/* --------- START --------- HR */}
              <ProtectedRouteHr exact path="/hr" component={HrMainPage} />
              <ProtectedRouteHr exact path="/hr/teams" component={HrTeamPage} />
              <ProtectedRouteHr exact path="/hr/teams/add" component={HrTeamIDAddPage} />
              <ProtectedRouteHr exact path="/hr/team/:id" component={HrTeamIDPage} />
              <ProtectedRouteHr exact path="/hr/add" component={HrAddPage} />
              <ProtectedRoute exact path="/hr/view/:id" component={HrAddPage} />
              <ProtectedRoute exact path="/hr/edit/:id" component={HrAddPage} />
              <ProtectedRouteHr exact path="/hr/division" component={HrDivisionPage} />
              <ProtectedRouteHr exact path="/hr/division/:id" component={HrDivisionIDPage} />
              <ProtectedRouteHr exact path="/hr/division/position/:id" component={HrPositionIDPage} />
              {/* --------- END --------- HR */}

              {/* --------- START --------- ENGINEER */}
              <ProtectedRouteEngineer exact path="/engineer" component={EngineerMainPage} />
              <ProtectedRouteEngineer exact path="/engineer/card/:id" component={EngineerCardIDPage} />
              {/* --------- END --------- ENGINEER */}

              <Route component={NotFoundComponent} />
            </Switch>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App
