interface IMenuItem {
  name: string
  imgName: string
  linkTo: string
}
const menuItems: IMenuItem[] = [
  { name: 'เพิ่มโปรเจค', imgName: 'AddSVG', linkTo: '/sale/crm/add' },
  { name: 'ตารางทำงาน', imgName: 'ScheduleSVG', linkTo: '/sale/calendar' },
]

export default menuItems
