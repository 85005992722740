import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Link, Redirect } from 'react-router-dom'
import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../common/table'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import SummaryTopTableComponant from '../common/summaryTopTable'
import { urlApi } from '../common/configUrlApi'
import { FormatDateTime } from '../../common/dateFormat'
import useTokenInitializer from '../../hooks/token-initializer'

const InventorySODocumentPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const [internalDocumentID, setInternalDocumentID] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [lineItem, setLineItem] = useState([])
  const [summaryTopTable, setSummaryTopTable] = useState<any>([])

  DocumentTitle('Inventory', 'เอกสาร SO')
  useTokenInitializer()

  useEffect(() => {
    // Where to put the query
    const q = `query viewSalesOrder($documentId: ID!) {
      viewSalesOrder(id: $documentId) {
        id
        internalDocumentId
        createdAt
        documentTypeId
        documentStatusId
        lineItem {
          id
          lineNumber
          skuId
          qty
          uomId
          packId
          sku {
            productCode
            qtyPerPack(packId: 1)
          }
        }
      }
    }
    `
    // Where to put the variables
    const v = {
      documentId: props.match.params.id,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      console.log('result', result)
      const viewSalesOrder = result.data.data.viewSalesOrder
      const listPO: any = []
      const packSKU: any = []
      viewSalesOrder.lineItem.map((data: any, index: any) => {
        const mapper = {
          id: index + 1,
          poductCode: data.sku.productCode,
          qty: data.qty,
          piecePerPack: data.sku.qtyPerPack,
        }
        packSKU.push({
          packList: data.qty,
          totalPiece: data.sku.qtyPerPack,
          totalPieceAndPack: data.sku.qtyPerPack,
          totalPackReceive: data.qty,
        })

        return listPO.push(mapper)
      })
      setLoading(false)
      setSummaryTopTable(packSKU)
      setInternalDocumentID(viewSalesOrder.internalDocumentId)
      setCreatedAt(FormatDateTime(viewSalesOrder.createdAt))
      setLineItem(listPO)
    })
  }, [])

  const colHead: any = ['#', 'รหัสสินค้า', 'จำนวน', 'แผ่น/ห่อ']
  const rowBody: any = lineItem ? lineItem : []

  return (
    <>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>เอกสารจัดขาย</h1>
        <p>
          ใบ Sale Order {internalDocumentID} - สร้างเมื่อ {createdAt}
        </p>
      </section>
      <section className={styles.tableList}>
        <SummaryTopTableComponant summaryTopTable={summaryTopTable} />
        <TableCommonCompanant
          colHead={colHead}
          rowBody={rowBody}
          typeHead={TABLE_HEAD_TYPES.NONE}
          typeBody={TABLE_BODY_TYPES.NONE}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventorySODocumentPage
