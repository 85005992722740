interface IMenuItem {
  name: string
  imgName: string
  linkTo: string
  fuction: boolean
}
const menuItems: IMenuItem[] = [
  // { name: 'การอนุมัติ', imgName: 'AddSVG', linkTo: '/inventory/approve/15', fuction: false },
  { name: 'เอกสารการอนุมัติ', imgName: 'ListSVG', linkTo: '/inventory/approve/document/list', fuction: false },
  { name: 'ย้อนกลับ', imgName: '', linkTo: '/inventory', fuction: false },
  { name: '', imgName: '', linkTo: '#', fuction: false },
]

export default menuItems
