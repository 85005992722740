import React from 'react'
import clsx from 'clsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import {
  getComparator,
  stableSort,
  EnhancedTableForCheckboxProps,
  useStyles,
  Order,
  useToolbarStyles,
} from '../../config'

const EnhancedTable: React.FC<any> = (props: any): JSX.Element => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<any>('calories')

  const { colHead, rows, page, setPage, rowsPerPage, setRowsPerPage, totalCount, selected, setSelected } = props

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.findIndex((ele: any) => ele.id === id)

    if (selectedIndex === -1) {
      const clonesLineItemsActive = rows.find((ele: any) => ele.id === id)
      setSelected([clonesLineItemsActive, ...selected])
    } else if (selectedIndex === 0) {
      setSelected([...selected.slice(1)])
    } else if (selectedIndex === selected.length - 1) {
      setSelected([...selected.slice(0, -1)])
    } else if (selectedIndex > 0) {
      setSelected([...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1)])
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeValueRow = (value: any, key: string, id: string) => {
    const selectedIndex = selected.findIndex((ele: any) => ele.id === id)
    const findSelected = selected.find((ele: any) => `${ele.id}` === `${id}`)
    if (findSelected?.length === 0 || !findSelected) return

    findSelected[key] = value
    if (selectedIndex === 0) {
      setSelected([...selected.slice(1), findSelected])
    } else if (selectedIndex === selected.length - 1) {
      setSelected([...selected.slice(0, -1), findSelected])
    } else if (selectedIndex > 0) {
      setSelected([...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1), findSelected])
    }
  }

  const isSelected = (id: string) => selected.findIndex((ele: any) => ele.id === id) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
  console.log(selected)
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              colHead={colHead}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                      </TableCell>
                      {Object.keys(row).map(function (key, index) {
                        if (key === 'id') return // For Fuction create Receive
                        if (key === 'amountPack') {
                          // For Input number
                          return (
                            <TableCell key={index} component="th" id={labelId} scope="row">
                              <input
                                // id={`${rowIndex}`}
                                type="number"
                                // value={matchLineItem ? matchLineItem.amountPack : 0}
                                min={0}
                                className="inputOriginal"
                                onChange={(e) => {
                                  handleChangeValueRow(e.target.value, key, row.id)
                                }}
                              />
                            </TableCell>
                          )
                        }
                        return (
                          <TableCell key={index} component="th" id={labelId} scope="row">
                            {row[key]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default EnhancedTable

function EnhancedTableHead(props: EnhancedTableForCheckboxProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, colHead } = props
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {colHead.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles()
  const { numSelected } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {/* Nutrition */}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">{/* <DeleteIcon /> */}</IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">{/* EXPORT PDF , EXCEL */}</IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}
