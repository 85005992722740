import React, { useEffect, useState } from 'react'
import { useBoolean } from '@uifabric/react-hooks'

import styles from './index.module.scss'

import DocumentTitle from '../../inventory/common/document-title'
import axios from 'axios'
import { urlApiSale } from '../common/configApi'

import { Calendar, momentLocalizer } from 'react-big-calendar'

import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { findProperties } from '../common/findAdditionalProperties'
import { boardID } from '../common/configBoardID'
import ModalAlert from './modal'

import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'

const localizer = momentLocalizer(moment)

const SaleCalendarPage: React.FC = (props: any) => {
  const [cardList, setCardList] = useState<any[]>([])
  const [event, setEvent] = useState<any[]>([])
  const [eventRaw, setEventRaw] = useState<any[]>([])
  const [dataSelect, setDataSelect] = useState<any>(null)
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  const [fullname, setFullname] = useState('')
  const [division, setDivision] = useState('')
  const token = useSelector((state: any) => state.token)

  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
    setDivision(token.division)
  }, [token.name])

  useTokenInitializer()

  useEffect(() => {
    // Where to put the variables
    const v = {
      input: boardID,
    }

    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: qBoard,
        variables: v,
      },
    })
      .then((result: any) => {
        const cardList = result.data.data.board[0].cardList
        setCardList(cardList)
        let id = 0
        const listEvent: any = []
        const listEventRaw: any = []
        cardList.map((card: any) => {
          if (!card.comments) return
          if (fullname === 'Admin' || token.position === 'หัวหน้า Sales') {
            card.comments
              .filter((ele: any) => ele.commentType === 'meeting')
              .map((ev: any) => {
                const mapper: any = {
                  id: id,
                  title: `นัดประชุม ${card.title}`,
                  allDay: false,
                  start: new Date(ev.datetime),
                  end: new Date(ev.datetime),
                }
                const mapperRaw: any = {
                  id: id,
                  cardID: card.cardID,
                  assignee: card.assignee[0],
                  title: card.title,
                  contactName: findProperties(card.additionalProperties, 'contactName'),
                  contactNumber: findProperties(card.additionalProperties, 'contactNumber'),
                  contactCompany: findProperties(card.additionalProperties, 'contactCompany'),
                  datetime: ev.datetime,
                  location: ev.location,
                  googleMap: ev.googleMap,
                  description: ev.description,
                }
                id = id + 1
                listEvent.push(mapper)
                listEventRaw.push(mapperRaw)
              })
          }
          if (fullname === card.assignee[0]) {
            card.comments
              .filter((ele: any) => ele.commentType === 'meeting')
              .map((ev: any) => {
                const mapper: any = {
                  id: id,
                  title: `นัดประชุม ${card.title}`,
                  allDay: false,
                  start: new Date(ev.datetime),
                  end: new Date(ev.datetime),
                }
                const mapperRaw: any = {
                  id: id,
                  cardID: card.cardID,
                  assignee: card.assignee[0],
                  title: card.title,
                  contactName: findProperties(card.additionalProperties, 'contactName'),
                  contactNumber: findProperties(card.additionalProperties, 'contactNumber'),
                  contactCompany: findProperties(card.additionalProperties, 'contactCompany'),
                  datetime: ev.datetime,
                  location: ev.location,
                  googleMap: ev.googleMap,
                  description: ev.description,
                }
                id = id + 1
                listEvent.push(mapper)
                listEventRaw.push(mapperRaw)
              })
          }
        })
        setEvent(listEvent)
        setEventRaw(listEventRaw)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [fullname])

  const handleSelectEvent = (e: any) => {
    const res = eventRaw.find((raw) => raw.id === e.id)
    if (!res) return
    setDataSelect(res)
    showModal()
  }

  DocumentTitle('Sale', 'Calendar')
  return (
    <section className={styles.section1}>
      <h1 className={styles.headerTitle}>ตารางทำงาน</h1>
      <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [{fullname}]</span>

      <div style={{ margin: '20px 20px 30px 0' }}>
        <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={event}
          style={{ height: '100vh' }}
          onSelectEvent={(e) => handleSelectEvent(e)}
          eventPropGetter={(event, start, end, isSelected) => {
            const newStyle = {
              backgroundColor: '#33a7ff',
            }
            const res = eventRaw.find((raw) => raw.id === event.id)
            if (!res) {
              return {
                className: '',
                style: newStyle,
              }
            }
            const resFull = cardList.find((raw2) => raw2.cardID === res.cardID)
            const card = resFull
            const findIndexMeeting = card.comments
              .filter((ele: any) => ele.commentType === 'meeting')
              .findIndex((element: any) => element.datetime === res.datetime)

            const findCheckIn = card.comments
              .filter((ele: any) => ele.commentType === 'checkIn')
              .find((element: any) => element.number === findIndexMeeting)

            const findCheckOut = card.comments
              .filter((ele: any) => ele.commentType === 'checkOut')
              .find((element: any) => element.number === findIndexMeeting)

            const tzoffset = new Date().getTimezoneOffset() * 60000
            const localISOTime = new Date(Date.now() - tzoffset).toISOString()
            if (findCheckIn) {
              return {
                className: '',
                style: { backgroundColor: '#e6e6e6', color: 'black' },
              }
            }
            if (findCheckOut) {
              return {
                className: '',
                style: { backgroundColor: 'black' },
              }
            }
            if (res.datetime < localISOTime) {
              return {
                className: '',
                style: { backgroundColor: '#F44336' },
              }
            } else {
              return {
                className: '',
                style: newStyle,
              }
            }
          }}
        />
      </div>

      {ModalAlert(props, isModalOpen, hideModal, dataSelect, styles)}
    </section>
  )
}

export default SaleCalendarPage

const qBoard = `query($input: ID){
  board(boardID: $input) {
    boardID
        boardName
        statusList {
            statusName
            statusDisplay
            color
        }
        priorityList
        sprintList
        cardList {
          cardID
          title
          status
          updatedAt
          assignee
          additionalProperties {
             Key
            Value
          }
          comments {
            commentType
            description
            attachments
            createdAt
            datetime
            location
            googleMap
            number
            lat
            long
            note
          }
        }
  }
}`

const qCard = `query($boardID: ID!, $cardID: ID){
  card(boardID: $boardID, cardID: $cardID) {
    cardID
    title
    status
    assignee
    createdAt
    updatedAt
    comments {
			commentType
      description
      attachments
      createdAt
      datetime
      location
      googleMap
      number
      lat
      long
      note
    }
    additionalProperties {
			Key
    	Value
    }
  }
}`
