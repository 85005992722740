import React, { useEffect, useState } from 'react'
import { useBoolean } from '@uifabric/react-hooks'
import styles from './index.module.scss'
import SummaryTopTableComponant from '../common/summaryTopTable'
import axios from 'axios'
import DocumentTitle from '../common/document-title'

import useFactInitializer from '../../hooks/fact-initializer'
import { urlApi } from '../common/configUrlApi'
import ModalAlert from '../common/modalNotFormik'
import GroupBtnDocument from '../common/groupBtnDocuemnt'
import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'

import EnhancedTable from '../../common/table/item/paginationByFront/firstColBtnandInput'
import { HeadCell } from '../../common/table/config'

export interface ILineItems {
  lineNumber: number
  itemID: string
  pack: string
  quantity: number
  qtyExpected: number
  status: string
  by: string
  timeStamp: string
  description: string
  financeApprove: string
  managerApprove: string
}

const InventoryCreateApprovePage: React.FC = (props: any) => {
  const [checkBooleanFormStatus, setCheckBooleanFormStatus] = useState(false)

  const [documentID, setDocumentID] = useState('')
  const [internalDocumentID, setInternalDocumentID] = useState('')
  const [documentStatusId, setDocumentStatusId] = useState('')
  const [lineItems, setLineItems] = useState<(string | ILineItems)[]>([])

  const [summaryTopTable, setSummaryTopTable] = useState<any>([])

  const [fetchData, setFetchData] = useState<number>(0)
  const [fetchData2, setFetchData2] = useState<number>(0)

  const [successSubmit, setSuccessSubmit] = useState('')
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  const listPhysicalCountAdjustReason = useSelector(
    (state: any) => state.api.fact.reasonFact.items.listPhysicalCountAdjustReason,
  )

  const [fullname, setFullname] = useState('')
  const token = useSelector((state: any) => state.token)

  useTokenInitializer()

  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
  }, [token.name])

  DocumentTitle('Inventory', 'การอนุมัติ')
  useFactInitializer()

  // For Query Doc viewPhysicalCountApproval
  useEffect(() => {
    const q = `query viewPhysicalCountApproval($id: ID!) {
      viewPhysicalCountApproval(id: $id) {
        id
        internalDocumentId
        createdAt
        updatedAt
        documentTypeId
        documentStatusId
        remark
        lineItem {
          totalCount
          pageIndex
          maxResults
          results {
            id
            physicalCountLineItem {
              id
              skuId
              sku {
                productCode
                name
                product {
                  name
                }
              }
              remark
              reasonId
              qty
              qtyExpected
            }
            accountingUserId
            accountingApproveAt
            managerUserId
            managerApproveAt
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      id: props.match.params.id,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const viewPhysicalCountApproval = result.data.data.viewPhysicalCountApproval
        console.log('viewPhysicalCountApproval', viewPhysicalCountApproval)
        const listMapper: any[] = []
        viewPhysicalCountApproval.lineItem.results.map((data: any) => {
          const reasonId = listPhysicalCountAdjustReason.find(
            (reason: any) => `${reason.id}` == `${data.physicalCountLineItem.reasonId}`,
          )
          const mapper = {
            key: data.id,
            itemID: `${data.physicalCountLineItem.sku.productCode}`,
            quantity: data.physicalCountLineItem.qty ? data.physicalCountLineItem.qty : 0,
            qtyExpected: data.physicalCountLineItem.qtyExpected, //คงคลัง
            remark:
              `${reasonId?.name ? reasonId.name : ''}` +
              ' ' +
              `${data?.physicalCountLineItem?.remark ? data.physicalCountLineItem.remark : ''}`,
            financeApprove: data.accountingUserId ? 'ACCOUNTANT' : '',
            managerApprove: data.managerUserId ? 'MANAGER' : '',
            description: data.physicalCountLineItem.sku.product.name,
          }
          listMapper.push(mapper)
        })
        setDocumentStatusId(viewPhysicalCountApproval.documentStatusId)
        setLineItems(listMapper)
        setInternalDocumentID(viewPhysicalCountApproval.internalDocumentId)
        setDocumentID(viewPhysicalCountApproval.id)
        setFetchData2(fetchData2 + 1)

        // SET FEILD IF documentStatus === COMPLETE Return True Because Need to disable input
        setCheckBooleanFormStatus(viewPhysicalCountApproval.documentStatusId === 'COMPLETE' ? true : false)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [fetchData, listPhysicalCountAdjustReason])

  useEffect(() => {
    // Pretend this is axios
    const clone_lineItems: ILineItems[] = []
    lineItems.map((data) => {
      if (typeof data !== 'string') {
        return clone_lineItems.push(data)
      }
    })

    const ameliaWatson = clone_lineItems.reduce<{ [itemId: string]: ILineItems[] }>(
      (previousValue, currentValue: any) => {
        const packNumberList: any[] = previousValue[currentValue.itemID] || []
        packNumberList.push(currentValue)
        previousValue[currentValue.itemID] = packNumberList
        return previousValue
      },
      {},
    )

    const packSKU: { packList: number; totalPiece: number; totalPieceAndPack: number; totalPackReceive: number }[] = []

    const plastic = Object.entries(ameliaWatson).reduce<(ILineItems | string)[]>(
      (lineItemListReducer, [itemId, packList]) => {
        const totalPiece = packList.reduce<number>((pv, cv) => {
          return pv + cv.quantity
        }, 0)
        const totalPieceAndPack = packList.reduce<number>((pv, cv) => {
          return pv + cv.qtyExpected
        }, 0)
        const totalPackReceive = packList.filter((ele) => {
          if (ele.quantity > 0) {
            return ele
          } else return
        }).length

        const warcraft3 = `รหัส ${itemId} - ${packList[0].description} - ${Math.ceil(
          totalPiece / (totalPieceAndPack / packList.length),
        )}/${packList.length} ห่อ = ${totalPiece}/${totalPieceAndPack} แผ่น`

        lineItemListReducer.push(warcraft3)
        lineItemListReducer.push(...packList)

        packSKU.push({
          packList: packList.length,
          totalPiece: totalPiece,
          totalPieceAndPack: totalPieceAndPack,
          totalPackReceive: totalPackReceive,
        })

        return lineItemListReducer
      },
      [],
    )

    setSummaryTopTable(packSKU)
    setLineItems(plastic)
  }, [documentID, fetchData2])

  const onChangeFinanceLineItems = (index: any, value: string) => {
    const clonesLineItems = [...lineItems]

    const lineItem = clonesLineItems[index]
    if (typeof lineItem === 'string') {
      return
    }
    lineItem.financeApprove = value ? value : ''
    clonesLineItems.splice(index, 1, lineItem)
    setLineItems(clonesLineItems)
  }

  const onChangeManagerLineItems = (index: any, value: string) => {
    const clonesLineItems = [...lineItems]

    const lineItem = clonesLineItems[index]
    if (typeof lineItem === 'string') {
      return
    }
    lineItem.managerApprove = value ? value : ''
    clonesLineItems.splice(index, 1, lineItem)
    setLineItems(clonesLineItems)
  }

  const onClickSaveDoc: any = () => {
    const prepairSna: any = []

    lineItems.forEach((data: any) => {
      if (data.financeApprove) {
        prepairSna.push({
          physicalCountLineItemId: data.key,
          userUid: fullname,
          userTypeId: data.financeApprove,
        })
      }
      if (data.managerApprove) {
        prepairSna.push({
          physicalCountLineItemId: data.key,
          userUid: fullname,
          userTypeId: data.managerApprove,
        })
      }
    })

    const q = `mutation approvePhysicalCount($physicalCountDocumentId: ID!, $input: [PhysicalCountLineItemApprove!]!) {
      approvePhysicalCount(physicalCountDocumentId: $physicalCountDocumentId, input: $input) {
        id
      }
    }`

    // Where to put the variables
    const v = {
      physicalCountDocumentId: documentID,
      input: prepairSna,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        setFetchData(fetchData + 1)
        showModal()
        setSuccessSubmit('สำเร็จ')
        return true
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
        return false
      })
  }

  const onClickSumbitDoc = () => {
    // onClickSaveDoc()
    const q = `mutation submitPhysicalCountApproval($id: ID!) {
      submitPhysicalCountApproval(physicalCountApprovalDocumentId: $id) {
        id
      }
    }`
    // Where to put the variables
    const v = {
      id: props.match.params.id,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('result', result)
        showModal()
        setSuccessSubmit('สำเร็จ')
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
      })
  }

  const onClickRemoveDoc = () => {
    const q = `mutation physicalCountApprovalRemove($id: ID!) {
      physicalCountApprovalRemove(id: $id) {
        id
      }
    }`
    // Where to put the variables
    const v = {
      id: documentID,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        // console.log('result', result)
        showModal()
        setSuccessSubmit('สำเร็จ')
        props.history.goBack()
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
      })
  }

  const colHead: string[] = ['รหัสสินค้า', 'จำนวนแผ่น', 'คงคลัง', 'Action', 'บัญชี', 'ผู้บริหาร']

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'itemID', numeric: false, disablePadding: false, label: 'รหัสสินค้า' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'จำนวนแผ่น' },
    { id: 'stock', numeric: false, disablePadding: false, label: 'คงคลัง' },
    { id: 'sn', numeric: false, disablePadding: false, label: 'Action' },
    { id: 'by', numeric: false, disablePadding: false, label: 'บัญชี' },
    { id: 'date', numeric: false, disablePadding: false, label: 'ผู้บริหาร' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  return (
    <>
      <div className={styles.pageWrapper}>
        <h1 className={styles.headerTitle}>การอนุมัติ</h1>
        <p>
          ใบอนุมัติ {internalDocumentID} {documentStatusId}
        </p>
        <p>วันที่ 20/01/2021 - โดย [USER NAME]</p>
      </div>
      <SummaryTopTableComponant summaryTopTable={summaryTopTable} />
      <section className={`tableListMui`}>
        <EnhancedTable
          colHead={headCells}
          rows={lineItems}
          checkBooleanFormStatus={checkBooleanFormStatus}
          removeButtonClassUniqueSKU={true}
          onChangeFinanceLineItems={onChangeFinanceLineItems}
          onChangeManagerLineItems={onChangeManagerLineItems}
          type="approve"
        />
      </section>
      <section className={styles.groupbtn}>
        <GroupBtnDocument
          onClickSaveDoc={onClickSaveDoc}
          onClickSumbitDoc={onClickSumbitDoc}
          onClickRemoveDoc={onClickRemoveDoc}
          documentStatusId={documentStatusId}
          linkGoBack="/inventory/approve"
        />
      </section>
      {ModalAlert(isModalOpen, hideModal, successSubmit)}{' '}
    </>
  )
}

export default InventoryCreateApprovePage
