import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import DocumentTitle from '../common/document-title'

import { useBoolean } from '@uifabric/react-hooks'
import useFactInitializer from '../../hooks/fact-initializer'
import { useSelector } from 'react-redux'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from '../../common/configAws'
import { urlApi } from '../common/configUrlApi'
import ModalAlert from '../common/modalNotFormik'

import useTokenInitializer from '../../hooks/token-initializer'

const InventoryTypeMasterDataEditPage: React.FC = (props: any) => {
  const [name, setName] = useState<string>('')
  const [selectId, setSelectId] = useState<string>('1')
  const [imageUri, setImageUri] = useState<string>('')

  const [fetchData, setFetchData] = useState<number>(0)
  const [successSubmit, setSuccessSubmit] = useState('')
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  // FACT TYPE
  const [factTypes, setFactTypes] = useState<any>([])
  const viewSkuPropertyAllListType = useSelector(
    (state: any) => state.api.fact.viewSkuPropertyAllListType.items.viewSkuPropertyAll?.['listType'].results,
  )

  DocumentTitle('Inventory', 'เรียกดูสินค้า')
  useFactInitializer()
  useTokenInitializer()

  // GET LIST FACT TYPE
  useEffect(() => {
    if (viewSkuPropertyAllListType) setFactTypes(viewSkuPropertyAllListType)
  }, [viewSkuPropertyAllListType, fetchData])

  // Set Field Value
  useEffect(() => {
    const typeSelect = factTypes.find((element: any) => {
      return element.id === selectId
    })
    if (typeSelect) {
      setName(typeSelect.name)
      setImageUri(typeSelect.uri)
    } else return
  }, [selectId, factTypes])

  const handleSubmit = () => {
    const q = `mutation modifySkuPropertyType($input: SkuTypeEdit!) {
      editSkuProperty {
        editType(input: $input) {
          id
          code
          name
          uri
        }
      }
    }`
    // Where to put the variables
    const v = {
      input: {
        name: name,
        id: selectId,
        uri: imageUri,
      },
    }
    console.log('v', JSON.stringify(v))
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((res) => {
        setFetchData(fetchData + 1)
        showModal()
        setSuccessSubmit('แก้ไขสำเร็จ')
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
      })
  }

  const handleUploadImage = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]

    if (file.length !== 0) {
      const albumPhotosKey = encodeURIComponent('typeMasterData') + '/'

      const photoKey = albumPhotosKey + selectId

      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })

      const promise = upload.promise()

      promise.then(
        function (data) {
          alert('Successfully uploaded photo.')
          setImageUri(data.Location)
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  return (
    <>
      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>เรียกดูสินค้า</h1>
      </section>
      <section className={styles.cardItems}>
        {factTypes.map((data: any, index: any) => {
          return (
            <button
              key={index}
              type="button"
              className={`${styles.buttonCardItem} ${selectId === data.id ? styles.active : ''}`}
              onClick={() => setSelectId(data.id)}
            >
              <img src={data.uri ? data.uri : 'https://fakeimg.pl/150x90'} width="150" height="90" />
              <p>{data.name}</p>
            </button>
          )
        })}
      </section>
      <section className={styles.sectionItem}>
        <div>
          <div className={styles.skuImage}>
            <img src={imageUri ? imageUri : 'https://fakeimg.pl/190x150'} alt="SKU Image" width="190" height="150" />
            <input type="file" id="file" onChange={(e) => handleUploadImage(e)} accept="image/*" />
            <label htmlFor="file" className={`${styles.buttonUploadFile}`}>
              เลือกรูปภาพ
            </label>
          </div>
        </div>

        <div>
          <h4 style={{ margin: '10px 0 0' }}>ชื่อหมวดหมู่</h4>
          <input type="text" className="inputForSearch" value={name} onChange={(e) => setName(e.target.value)}></input>
          <h4 style={{ margin: '10px 0 0' }}>รหัสหมวดหมู่</h4>
          <input type="text" className="inputForSearch" value={selectId} disabled></input>
        </div>
      </section>

      <section className={styles.groupButtonDestop}>
        <button type="button" className="styleBtn" onClick={() => handleSubmit()}>
          บันทึก
        </button>
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/item-master-data">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
      {ModalAlert(isModalOpen, hideModal, successSubmit)}
    </>
  )
}

export default InventoryTypeMasterDataEditPage
