import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { Text, ITextProps } from 'office-ui-fabric-react'

import styles from './index.module.scss'
import menuItems from './menuItems'
import specialMenuItems from './specialMenuItems'

import SaleSVG from './sale.svg'
import InventorySVG from './inventory.svg'
import PurchaseSVG from './purchase.svg'
import AccoutingSVG from './accouting.svg'
import DeliverySVG from './delivery.svg'
import InstallSVG from './install.svg'
import PersonSVG from './person.svg'
import ApprovedSVG from './approved.svg'
import ManagementSVG from './management.svg'

import parse from 'html-react-parser'
import DocumentTitle from '../inventory/common/document-title'

import useTokenInitializer from '../hooks/token-initializer'
import { useSelector } from 'react-redux'

const InventoryMainPage: React.FC = () => {
  const iconList: { [key: string]: string } = {
    SaleSVG: SaleSVG,
    InventorySVG: InventorySVG,
    PurchaseSVG: PurchaseSVG,
    AccoutingSVG: AccoutingSVG,
    DeliverySVG: DeliverySVG,
    InstallSVG: InstallSVG,
    PersonSVG: PersonSVG,
    ApprovedSVG: ApprovedSVG,
    ManagementSVG: ManagementSVG,
  }

  const [fullname, setFullname] = useState('')
  const [division, setDivision] = useState('')
  const token = useSelector((state: any) => state.token)

  useTokenInitializer()

  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
    setDivision(token.division)
  }, [token])

  const Shortcut = () => {
    return (
      <>
        <h1 className={styles.headerTitle2}>ทางลัด</h1>
        <div className={styles.menuItemsWrapper}>
          {specialMenuItems.map(({ name, imgName, linkTo }, index) => (
            <Link key={index} to={linkTo}>
              <button key={index} style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}>
                <img src={iconList[imgName]} />
                <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
                  {parse(name)}
                </Text>
              </button>
            </Link>
          ))}
        </div>
      </>
    )
  }
  DocumentTitle('', '')

  const ComponantButton = (index: number, name: string, imgName: string) => {
    return (
      <button key={index} style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}>
        <img src={iconList[imgName]} />
        <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
          {parse(name)}
        </Text>
      </button>
    )
  }

  return (
    <section className={styles.section1}>
      <div className={styles.shortCutMobile}>{Shortcut()}</div>

      <h1 className={styles.headerTitle}>หน้าแรก</h1>
      <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [{fullname}]</span>
      <div className={styles.menuItemsWrapper}>
        {menuItems.map(({ name, imgName, linkTo }, index) => {
          if (name === 'คลังสินค้า' && division === 'การขาย') {
            return (
              <Link key={index} to={linkTo}>
                {ComponantButton(index, name, imgName)}
              </Link>
            )
          }
          return (
            <Link key={index} to={linkTo} style={division === name || !division ? {} : { display: 'none' }}>
              {ComponantButton(index, name, imgName)}
            </Link>
          )
        })}
      </div>

      <div className={styles.shortCutDesktop}>{Shortcut()}</div>
    </section>
  )
}

export default InventoryMainPage
