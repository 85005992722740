import React from 'react'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import * as AWS from 'aws-sdk'
import { accessKeyId, secretAccessKey, ClientId } from '../hr/common/UserPool'
import { bucketRegion } from '../common/configAws'

import styles from './index.module.scss'

import DocumentTitle from '../inventory/common/document-title'
import validateIgnoreField from '../common/validateIgnoreField'
import { getTheme, mergeStyleSets, FontWeights, Modal, IconButton, IIconProps } from 'office-ui-fabric-react'
import { useId } from '@uifabric/react-hooks'
import CorrectSVG from '../inventory/page-approve-create-or-edit/correct.svg'
import UnCorrectSVG from '../inventory/page-approve-create-or-edit/uncorrect.svg'

const ForgotPasswordPage: React.FC = (props: any) => {
  DocumentTitle('Forgot Password', '')
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()

  const cancelIcon: IIconProps = { iconName: 'Cancel' }
  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const titleId = useId('title')

  return (
    <Form>
      <div className={styles.loginWrapper}>
        <h3 className={styles.titleHeader}>ลืมรหัสผ่าน</h3>
        <Field
          name="email"
          className="inputForSearch"
          type="email"
          placeholder="อีเมลผู้ใช้งาน"
          disabled={values.nextStep}
          style={{
            ...(touched?.email && errors?.email && { border: '1px solid red' }),
          }}
        />

        {values.nextStep && (
          <>
            <Field name="password" className="inputForSearch" type="password" placeholder="New Pasword" />
            <Field name="codeConfirm" className="inputForSearch" type="text" placeholder="Verification Code" />
          </>
        )}

        <div className={styles.buttons}>
          <button type="submit" className="styleBtn" style={{ fontWeight: 'bold' }}>
            ยืนยัน
          </button>
          <button
            type="button"
            className={`styleBtn ${styles.buttonForgot}`}
            onClick={() => props.history.push('/login')}
          >
            ย้อนกลับ
          </button>
        </div>
      </div>

      <Modal
        titleAriaId={titleId}
        isOpen={values.isModalOpen}
        // onDismiss={() => props.history.push('/login')}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            // onClick={() => props.history.push('/login')}
          />
        </div>
        <div className={contentStyles.body}>
          {values.isSuccess && (
            <>
              <h1>Successful !!!</h1>
              <img src={CorrectSVG} width="70" style={{ margin: '10px' }} />

              <div>
                <button
                  type="button"
                  className="styleBtn"
                  style={{ float: 'right', margin: '20px 0 0', padding: '5px 8px' }}
                  onClick={() => props.history.push('/login')}
                >
                  กลับไปหน้าเข้าสู้ระบบ
                </button>
              </div>
            </>
          )}

          {values.isError && (
            <>
              <h1>{values.msgError}</h1>
              <img src={UnCorrectSVG} width="70" style={{ margin: '10px' }} />
              <p>กรุณาตรวจสอบข้อมูล</p>

              <button
                type="button"
                className="styleBtn"
                style={{ float: 'right', margin: '20px 0 0', padding: '5px 8px' }}
                onClick={() => {
                  setFieldValue('isError', false, false)
                  setFieldValue('msgError', '', false)
                  setFieldValue('isModalOpen', false, false)
                }}
              >
                ปิดหน้าต่าง
              </button>
            </>
          )}
        </div>
      </Modal>
    </Form>
  )
}

const EnhancedLoginPage = withFormik({
  mapPropsToValues: (props) => ({
    email: '',

    // NOT VALIDATE
    isSuccess: false,
    isError: false,
    msgError: '',
    nextStep: false,
    codeConfirm: '',
    password: '',
    isModalOpen: false,
  }),
  validate: (values) => {
    return validateIgnoreField(
      values,
      ['isError', 'msgError', 'isSuccess', 'nextStep', 'codeConfirm', 'password', 'isModalOpen'],
      '*',
    )
  },
  handleSubmit: (values, { setFieldValue }) => {
    AWS.config.update({
      region: bucketRegion,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    })
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

    if (!values.nextStep) {
      const params = {
        ClientId: ClientId /* required */,
        Username: values.email /* required */,
      }
      cognitoidentityserviceprovider.forgotPassword(params, function (err, data) {
        if (err) {
          setFieldValue('isError', true, false)
          setFieldValue('isModalOpen', true, false)
          setFieldValue('msgError', 'ไม่พบ Email นี้ในระบบ', false)
          console.log(err, err.stack)
        }
        // an error occurred
        else {
          setFieldValue('nextStep', true, false)
          console.log(data)
        } // successful response
      })
    } else {
      const params = {
        ClientId: ClientId /* required */,
        ConfirmationCode: values.codeConfirm /* required */,
        Password: values.password /* required */,
        Username: values.email /* required */,
      }
      cognitoidentityserviceprovider.confirmForgotPassword(params, function (err, data) {
        if (err) {
          setFieldValue('isModalOpen', true, false)
          setFieldValue('isError', true, false)
          setFieldValue('msgError', 'Fail ลองอีกครั้ง', false)
          console.log(err, err.stack)
        }
        // an error occurred
        else {
          setFieldValue('isModalOpen', true, false)
          setFieldValue('isSuccess', true, false)
          console.log(data)
        } // successful response
      })
    }
  },
})(ForgotPasswordPage)

export default EnhancedLoginPage

const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      // borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    textAlign: 'center',
    marginTop: '-25px',
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      h1: { margin: '0' },
      p: { margin: '0' },
    },
  },
})
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}
