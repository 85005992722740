import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import { getComparator, stableSort, EnhancedTableProps, useStyles, Order } from '../../config'
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'
import { useId } from '@uifabric/react-hooks'
import BinSVG from './bin.svg'

const EnhancedTable: React.FC<any> = (props: any): JSX.Element => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<any>('calories')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const {
    colHead,
    rows,
    onChangequantityLineItems,
    checkBooleanFormStatus,
    onClickPrintOneRow,
    onClickPrintSKURow,
    onClickPrintTotalRow,
    removeButtonClassUniqueSKU,
    handleRemoveLineItem,
    onChangeReasonIdLineItems,
    onChangeReamekLineItems,
    onChangeFinanceLineItems,
    onChangeManagerLineItems,
    listReason,
    disabledBool,
    type,
  } = props

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  // DIALOG
  const [hideDialogPrintTotalRow, setHideDialogPrintTotalRow] = useState(true)
  const [hideDialogPrintSKURow, setHideDialogPrintSKURow] = useState(true)
  const [indexgPrintSKURow, setIndexPrintSKURow] = useState(null)

  const [hideDialogPrintOneRow, setHideDialogPrintOneRow] = useState(true)
  const [indexgPrintOneRow, setIndexPrintOneRow] = useState(null)

  const labelId: string = useId('dialogLabel')
  const subTextId: string = useId('subTextLabel')

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
    }),
    [labelId, subTextId],
  )
  // DIALOG

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              colHead={colHead}
              setHideDialogPrintTotalRow={setHideDialogPrintTotalRow}
              hideDialogPrintTotalRow={hideDialogPrintTotalRow}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, rowIndex) => {
                  const labelId = `enhanced-table-checkbox-${rowIndex}`
                  if (typeof row === 'string') {
                    return (
                      <TableRow hover tabIndex={-1} key={rowIndex}>
                        <TableCell colSpan={colHead.length} className="uniqueSKU">
                          {!removeButtonClassUniqueSKU && (
                            <button
                              className="styleBtnForTable"
                              style={{
                                marginRight: '10px',
                                backgroundColor: 'white',
                                color: 'black',
                                padding: '3px 5px',
                              }}
                              type="button"
                              onClick={(e) => {
                                setHideDialogPrintSKURow(hideDialogPrintSKURow ? false : true)
                                setIndexPrintSKURow(rows[rowIndex + 1])
                              }}
                            >
                              พิมพ์
                            </button>
                          )}
                          {/* {row} */}
                          <span style={{ color: 'white' }}>{row}</span>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  return (
                    <TableRow hover tabIndex={-1} key={row.lineNumber}>
                      {Object.keys(row).map(function (key, index) {
                        if (key === 'description') return
                        if (type === 'approve') {
                          if (key === 'key') return
                          else if (key === 'reasons') {
                            return (
                              <TableCell key={index}>
                                <select
                                  id={`${rowIndex}`}
                                  name="reasonId"
                                  style={{ height: '30px', marginBottom: '5px', padding: '0 5px', maxWidth: '100px' }}
                                  onChange={(e) => {
                                    onChangeReasonIdLineItems &&
                                      onChangeReasonIdLineItems((e.target as HTMLElement).id, e.target.value)
                                  }}
                                  disabled={checkBooleanFormStatus}
                                >
                                  <option value=""></option>
                                  {listReason &&
                                    listReason.map((dataReason: any, index: number) => {
                                      return (
                                        <option
                                          value={dataReason.id}
                                          key={index}
                                          selected={row[key] && row[key].reasonId == dataReason.id ? true : false}
                                        >
                                          {dataReason.name}
                                        </option>
                                      )
                                    })}
                                </select>
                                <input
                                  id={`${rowIndex}`}
                                  type="text"
                                  value={row[key].remark}
                                  className="inputOriginal"
                                  style={
                                    row[key].reasonId == '1'
                                      ? { display: 'block', minWidth: '200px' }
                                      : { display: 'none' }
                                  }
                                  onChange={(e) => {
                                    onChangeReamekLineItems &&
                                      onChangeReamekLineItems((e.target as HTMLElement).id, e.target.value)
                                  }}
                                />
                              </TableCell>
                            )
                          } else if (key === 'financeApprove') {
                            return (
                              <TableCell key={index} component="th" id={labelId} scope="row">
                                <input
                                  id={`${rowIndex}`}
                                  type="checkbox"
                                  value={row[key] ? '' : 'ACCOUNTANT'}
                                  disabled={checkBooleanFormStatus}
                                  checked={row[key] ? true : false}
                                  onChange={(e) => {
                                    onChangeFinanceLineItems &&
                                      onChangeFinanceLineItems((e.target as HTMLElement).id, e.target.value)
                                  }}
                                />
                              </TableCell>
                            )
                          } else if (key === 'managerApprove') {
                            return (
                              <TableCell key={index} component="th" id={labelId} scope="row">
                                <input
                                  id={`${rowIndex}`}
                                  type="checkbox"
                                  value={row[key] ? '' : 'MANAGER'}
                                  disabled={checkBooleanFormStatus}
                                  checked={row[key] ? true : false}
                                  onChange={(e) => {
                                    onChangeManagerLineItems &&
                                      onChangeManagerLineItems((e.target as HTMLElement).id, e.target.value)
                                  }}
                                />
                              </TableCell>
                            )
                          }
                          return (
                            <TableCell key={index} component="th" id={labelId} scope="row">
                              {row[key]}
                            </TableCell>
                          )
                        } else if (key === 'lineNumber') {
                          return (
                            <TableCell padding="checkbox">
                              <button
                                className="styleBtnForTable"
                                type="button"
                                onClick={(e) => {
                                  setHideDialogPrintOneRow(hideDialogPrintOneRow ? false : true)
                                  setIndexPrintOneRow(row['lineNumber'])
                                }}
                              >
                                พิมพ์
                              </button>
                            </TableCell>
                          )
                        } else if (key === 'quantity') {
                          return (
                            <TableCell key={index}>
                              <input
                                id={`${rowIndex}`}
                                type="number"
                                min={0}
                                value={row[key]}
                                className="inputOriginal"
                                disabled={disabledBool || checkBooleanFormStatus}
                                onChange={(e) => {
                                  onChangequantityLineItems &&
                                    onChangequantityLineItems(
                                      (e.target as HTMLElement).id,
                                      Number.parseInt(e.target.value),
                                    )
                                }}
                              />
                            </TableCell>
                          )
                        } else if (key === 'id' && type === 'issueRefSo') {
                          return (
                            <TableCell key={index}>
                              <img
                                id={`${rowIndex}`}
                                src={BinSVG}
                                onClick={(e) => {
                                  handleRemoveLineItem && handleRemoveLineItem((e.target as HTMLElement).id)
                                }}
                              />
                            </TableCell>
                          )
                        } else if (key === 'reasons') {
                          return (
                            <TableCell key={index}>
                              <select
                                id={`${rowIndex}`}
                                name="reasonId"
                                style={{ height: '30px', marginBottom: '5px', padding: '0 5px', maxWidth: '100px' }}
                                onChange={(e) => {
                                  onChangeReasonIdLineItems &&
                                    onChangeReasonIdLineItems((e.target as HTMLElement).id, e.target.value)
                                }}
                                disabled={checkBooleanFormStatus}
                              >
                                <option value=""></option>
                                {listReason &&
                                  listReason.map((dataReason: any, index: number) => {
                                    return (
                                      <option
                                        value={dataReason.id}
                                        key={index}
                                        selected={row[key] && row[key].reasonId == dataReason.id ? true : false}
                                      >
                                        {dataReason.name}
                                      </option>
                                    )
                                  })}
                              </select>
                              <input
                                id={`${rowIndex}`}
                                type="text"
                                value={row[key].remark}
                                className="inputOriginal"
                                style={
                                  row[key].reasonId == '1'
                                    ? { display: 'block', minWidth: '200px' }
                                    : { display: 'none' }
                                }
                                onChange={(e) => {
                                  onChangeReamekLineItems &&
                                    onChangeReamekLineItems((e.target as HTMLElement).id, e.target.value)
                                }}
                              />
                            </TableCell>
                          )
                        } else if (row[key] === 'สแกนแล้ว' || row[key] === 'ส่งแล้ว') {
                          return (
                            <TableCell key={index}>
                              <label className="statusScanerGreen">{row[key]}</label>
                            </TableCell>
                          )
                        } else if (row[key] === 'รอสแกน') {
                          return (
                            <TableCell key={index}>
                              <label className="statusScanerRed">{row[key]}</label>
                            </TableCell>
                          )
                        } else if (row[key] === 'ไม่ครบ' || row[key] === 'รอส่งงาน') {
                          return (
                            <TableCell key={index}>
                              <label className="statusScanerOrange">{row[key]}</label>
                            </TableCell>
                          )
                        } else if (row[key] === 'หาย' || row[key] === 'ยกเลิก') {
                          return (
                            <TableCell key={index}>
                              <label className="statusScanerRed">{row[key]}</label>
                            </TableCell>
                          )
                        }
                        return (
                          <TableCell key={index} component="th" id={labelId} scope="row">
                            {row[key]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        hidden={hideDialogPrintTotalRow}
        onDismiss={() => setHideDialogPrintTotalRow(hideDialogPrintTotalRow ? false : true)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setHideDialogPrintTotalRow(hideDialogPrintTotalRow ? false : true)
              if (onClickPrintTotalRow) return onClickPrintTotalRow()
            }}
            text="ยืนยัน"
          />
          <DefaultButton
            onClick={() => setHideDialogPrintTotalRow(hideDialogPrintTotalRow ? false : true)}
            text="ยกเลิก"
          />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideDialogPrintSKURow}
        onDismiss={() => setHideDialogPrintSKURow(hideDialogPrintSKURow ? false : true)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setHideDialogPrintSKURow(hideDialogPrintSKURow ? false : true)
              if (onClickPrintSKURow) return onClickPrintSKURow(indexgPrintSKURow)
            }}
            text="ยืนยัน"
          />
          <DefaultButton onClick={() => setHideDialogPrintSKURow(hideDialogPrintSKURow ? false : true)} text="ยกเลิก" />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideDialogPrintOneRow}
        onDismiss={() => setHideDialogPrintOneRow(hideDialogPrintOneRow ? false : true)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setHideDialogPrintOneRow(hideDialogPrintOneRow ? false : true)
              if (onClickPrintOneRow) return onClickPrintOneRow(indexgPrintOneRow)
            }}
            text="ยืนยัน"
          />
          <DefaultButton onClick={() => setHideDialogPrintOneRow(hideDialogPrintOneRow ? false : true)} text="ยกเลิก" />
        </DialogFooter>
      </Dialog>
    </div>
  )
}

export default EnhancedTable

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort, colHead, setHideDialogPrintTotalRow, hideDialogPrintTotalRow } = props
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {colHead.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label === 'พิมพ์ทั้งหมด' ? (
                <button
                  className="styleBtnForTable"
                  style={{ marginRight: '10px' }}
                  type="button"
                  onClick={() => {
                    setHideDialogPrintTotalRow(hideDialogPrintTotalRow ? false : true)
                  }}
                >
                  {headCell.label}
                </button>
              ) : (
                headCell.label
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const dialogStyles = { main: { maxWidth: 450 } }

const dialogContentProps = {
  type: DialogType.normal,
  title: 'กรุณายืนยันคำสั่ง',
  closeButtonAriaLabel: 'Close',
  subText: 'คุณยังคงต้องการทำรายการดังต่อไปนี้ต่อไปหรือไม่?',
}
