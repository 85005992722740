import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import { debounce } from 'lodash'
import { urlApi } from '../common/configUrlApi'
import { FormatDateTime } from '../../common/dateFormat'

import useTokenInitializer from '../../hooks/token-initializer'
import EnhancedTable from '../../common/table/doc/firstAndLastColBtnPaginationBack'
import { HeadCell } from '../../common/table/config'

const InventorySOListPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)

  const [dataListSO, setDataListSO] = useState<any>([])
  const [currentQueryString, setCurrentQueryString] = useState('')
  const [currentQueryDate, setCurrentQueryDate] = useState(null)

  // Pgaination
  const [page, setPage] = React.useState(0)
  const [pageList, setPageList] = React.useState<any>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = React.useState(0)

  DocumentTitle('Inventory', 'เอกสาร SO')
  useTokenInitializer()

  useEffect(() => {
    // For not dowload page if dowloaded
    const findPage = pageList.find((ele: any) => `${ele}` === `${page}`)
    if (findPage || findPage === 0) return

    // Where to put the query
    const q = `query listSalesOrder($pageIndex: Int, $maxResults: Int, $internalDocumentIdPartial: String, $createdAtDay: DateTime){
      listSalesOrder(pageIndex: $pageIndex, maxResults: $maxResults, internalDocumentIdPartial: $internalDocumentIdPartial, createdAtDay: $createdAtDay) {
        totalCount
        pageIndex
        maxResults
        results {
          id
          internalDocumentId
          documentTypeId
          documentStatusId
          referenceNumber
          remark
          createdAt
          updatedAt
          lineItem {
            id
            skuId
            qty
            uomId
            packId
            lineNumber
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      pageIndex: page,
      maxResults: rowsPerPage,
      createdAtDay: currentQueryDate,
      internalDocumentIdPartial: currentQueryString,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const listSalesOrder = result.data.data.listSalesOrder.results
        const listSO: any[] = []
        listSalesOrder.map((data: any) => {
          const mapper = {
            id: data.id,
            poCode: data.internalDocumentId,
            poDate: FormatDateTime(data.updatedAt),
          }
          return listSO.push(mapper)
        })
        setLoading(false)
        setDataListSO([...dataListSO, ...listSO])
        setTotalCount(result.data.data.listSalesOrder.totalCount)

        setPageList([...pageList, page]) // For not reload new page if dowloaded
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [page, rowsPerPage, currentQueryDate, currentQueryString])

  const request = debounce((value, setState) => {
    setPage(0)
    setPageList([])
    setDataListSO([])
    setState(value)
  }, 1000)

  const debouceRequest = useCallback((value, setState) => request(value, setState), [])

  const onChangeString = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryString)
  }

  const onChangeDate = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryDate)
  }

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'productCode', numeric: false, disablePadding: false, label: 'เลขที่เอกสารจัดขาย' },
    { id: 'product', numeric: false, disablePadding: false, label: 'วันที่' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------
  const rowBody: any = dataListSO

  return (
    <>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>เอกสารจัดขาย</h1>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสเอกสาร"
          onChange={onChangeString}
          style={{ maxWidth: '385.6px', display: 'flex' }}
        />
        <input type="date" className="inputForSearch" onChange={onChangeDate} style={{ maxWidth: '385.6px' }} />
      </section>
      <section className="tableListMui">
        <EnhancedTable
          colHead={headCells}
          rows={rowBody}
          link="/inventory/sale-orders"
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventorySOListPage
