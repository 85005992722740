import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'

import SaleSVG from '../main/sale.svg'
import InventorySVG from '../main/inventory.svg'
import PurchaseSVG from '../main/purchase.svg'
import AccoutingSVG from '../main/accouting.svg'
import DeliverySVG from '../main/delivery.svg'
import InstallSVG from '../main/install.svg'
import PersonSVG from '../main/person.svg'
import ManagementSVG from '../main/management.svg'
import OtherSVG from './other.svg'
import { useSelector } from 'react-redux'
import { Text, ITextProps } from 'office-ui-fabric-react'
import parse from 'html-react-parser'

const InventoryNavSideComponent: React.FunctionComponent<any> = (props: any) => {
  const [selectMenu, setSelectMenu] = useState('')

  const [division, setDivision] = useState('')
  const token = useSelector((state: any) => state.token)
  useEffect(() => {
    if (!token.division) return
    setDivision(token.division)
  }, [token.division])

  const ComponantButton = (name: string, img: string) => {
    return (
      <>
        <img src={img} width="30" height="30" />
        <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
          {parse(name)}
        </Text>
      </>
    )
  }
  return (
    <nav className={styles.navSideBar}>
      {!props.isHrModule ? (
        <div className={styles.moduleSelected}>
          <h3>โมดูล</h3>
          <img src={OtherSVG} width="25" height="25" />
          <p>กรุณาเลือก</p>
        </div>
      ) : (
        <div className={styles.moduleSelected}>
          <h3>โมดูล</h3>
          <img src={PersonSVG} width="25" height="25" />
          <p>บุคคล</p>
        </div>
      )}

      <h3 className={styles.textMenu}>เมนู</h3>
      {menu.map(({ name, to, img }: any) => {
        if (name === 'คลังสินค้า' && division === 'การขาย') {
          return (
            <Link
              to={to}
              className={`${styles.buttonLink} ${selectMenu === name ? styles.active : ''}`}
              key={name}
              onClick={() => setSelectMenu(name)}
            >
              {ComponantButton(name, img)}
            </Link>
          )
        }
        return (
          <Link
            to={to}
            className={`${styles.buttonLink} ${selectMenu === name ? styles.active : ''}`}
            key={name}
            style={division === name || !division ? {} : { display: 'none' }}
            onClick={() => setSelectMenu(name)}
          >
            {ComponantButton(name, img)}
          </Link>
        )
      })}
    </nav>
  )
}

export default InventoryNavSideComponent

const menu: any = [
  { name: 'การขาย', img: SaleSVG, to: '/sale' },
  { name: 'คลังสินค้า', img: InventorySVG, to: '/inventory' },
  { name: 'จัดซื้อ', img: PurchaseSVG, to: '' },
  { name: 'บัญชี', img: AccoutingSVG, to: '' },
  { name: 'ขนส่ง', img: DeliverySVG, to: '/inventory/delivery-note' },
  { name: 'ติดตั้ง', img: InstallSVG, to: '/engineer' },
  { name: 'บุคคล', img: PersonSVG, to: '/hr' },
  { name: 'ผู้บริหาร', img: ManagementSVG, to: '' },
]
