import React, { useEffect, useState } from 'react'

import styles from './index.module.scss'
import { FormatDateTime } from '../../common/dateFormat'
import DocumentTitle from '../../inventory/common/document-title'
import axios from 'axios'

import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'
import { listStatus } from '../common/configStatus'
import { boardID, urlApiEngineer } from '../common/configAPIEngineer'

interface BoardStatus {
  statusName: string
  statusDisplay: string
  color: string
}

interface Card {
  cardID: string
  status: string
  dueDate: string
  title: string
  updatedAt: string
  assignee: string[]
  additionalProperties: { Key: string; Value: string }[]
}

interface BoardInformation {
  boardID: string
  boardName: string
  cardList: {
    cardID: string
    dueDate: string
    status: string
    title: string
    updatedAt: string
    assignee: string[]
    additionalProperties: { Key: string; Value: string }[]
  }[]
  statusList: BoardStatus[]
  // [key: string]: any
}

const InventoryMainPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(false)

  // Fact Status
  const [statusList, setStatusList] = useState<BoardStatus[]>(listStatus)

  // Combined Card Status
  const [cards, setCards] = useState<Card[]>([])

  const [fullname, setFullname] = useState('')
  const token = useSelector((state: any) => state.token)
  useTokenInitializer()

  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
  }, [token.name])

  DocumentTitle('Engineer', 'ติดตั้ง/ถอดแบบ')

  useEffect(() => {
    // Where to put the variables
    const v = {
      input: boardID,
    }

    axios({
      url: urlApiEngineer,
      method: 'post',
      data: {
        query: qBoard,
        variables: v,
      },
    })
      .then((result: { data: { data: { board: BoardInformation[] } } }) => {
        const board = result.data.data.board[0]
        console.log('board', board)
        setStatusList(board.statusList)
        setCards(board.cardList)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [])

  return (
    <section className={styles.section1}>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <h1 className={styles.headerTitle}>ติดตั้ง/ถอดแบบ</h1>
      <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [{fullname}]</span>
      <div className={styles.menuItemsWrapper} style={{ overflowX: 'scroll' }}>
        {statusListDisplay.map((status, index) => {
          cards.filter(
            (card) =>
              status.statusName.search(card.status) !== -1 &&
              (fullname === 'Admin' || token.position === 'หัวหน้า Sales' ? true : fullname === card.assignee[0]),
          )
          console.log('cards', cards)
          return (
            <div key={index} className={styles.newCol}>
              <div className={`${styles.tag} ${styles[`${status.color}Tag`]}`}>
                <h4>{status.statusDisplay}</h4>
                <p>
                  {
                    cards.filter(
                      (card) =>
                        status.statusName.search(card.status) !== -1 &&
                        (fullname === 'Admin' ? true : fullname === card.assignee[0]),
                    ).length
                  }
                  {' ' + 'รายการ'}
                </p>
              </div>
              {cards.filter(
                (card) =>
                  status.statusName.search(card.status) !== -1 &&
                  (fullname === 'Admin' || token.position === 'หัวหน้า Sales' ? true : fullname === card.assignee[0]),
              ).length === 0 && <h3>ไม่มีรายการ</h3>}
              {cards
                .filter(
                  (card) =>
                    status.statusName.search(card.status) !== -1 &&
                    (fullname === 'Admin' || token.position === 'หัวหน้า Sales' ? true : fullname === card.assignee[0]),
                )
                .map((data, index) => {
                  return (
                    <div
                      className={`${styles.tagCard} ${styles.borderGreen}`}
                      key={index}
                      onClick={() => props.history.push(`/engineer/card/${data.cardID}`)}
                    >
                      <h4>ชื่อโปรเจค: {data.title}</h4>
                      <p>วันที่รับงาน : demo</p>
                      <p>กำหนดเสร็จ : {data.dueDate && FormatDateTime(data.dueDate)}</p>
                      <p>Sales Manager : demo</p>
                      <p>Sales : {data.assignee[0]}</p>
                      <p className={styles.timeStamp}>
                        อัพเดทล่าสุด {data.updatedAt && FormatDateTime(data.updatedAt)}
                      </p>
                    </div>
                  )
                })}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default InventoryMainPage

const qBoard = `
query($input: ID){
  board(boardID: $input) {
    boardID
        boardName
        statusList {
            statusName
            statusDisplay
            color
        }
        cardList {
          cardID
          title
          status
          assignee
          dueDate
          createdAt
          updatedAt
          areaPosition {
            lineNumber
            name
            typeDistance
            distance
            direction
            remark
          }
          attachmentsSales {
            name
            url
            by
            createAt
            updateAt
          }
          attachmentsEngineers {
            name
            url
            by
            createAt
            updateAt
          }
          comments {
            commentType
            description
            attachments
            note
            createdAt
            by
            assignTo
            statusProgress
          }
          additionalProperties {
            Key
            Value
          }
        }
  }
}
`

const statusListDisplay = [
  {
    statusDisplay: 'ยังไม่เสร็จ',
    statusName: 'PROJECT_NEW',
    color: 'gray',
  },
  {
    statusDisplay: 'งานแก้',
    statusName:
      'PROJECT_DISAPPROVE_SALES_MANAGER/PROJECT_DISAPPROVE_ENG_MANAGER/PROJECT_REVIEW_DISAPPROVE_ENG_MANAGER/PROJECT_REVIEW_DISAPPROVE_SALES_MANAGER/PROJECT_REVIEW_DISAPPROVE_SALES',
    color: 'red',
  },
  {
    statusDisplay: 'ดำเนินการ',
    statusName: 'PROJECT_APPROVE_SALES_MANAGER/PROJECT_APPROVE_ENG_MANAGER/PROJECT_ONGOING',
    color: 'orange',
  },
  {
    statusDisplay: 'รอตรวจ',
    statusName: 'PROJECT_REVIEW_APPROVE_ENG_MANAGER/PROJECT_REVIEW_APPROVE_SALES_MANAGER',
    color: 'yellow',
  },
  {
    statusDisplay: 'เสร็จสิ้น',
    statusName: 'PROJECT_REVIEW_APPROVE_SALES_DONE',
    color: 'green',
  },
]
