import React, { useEffect, useState } from 'react'
import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../common/table'
import * as AWS from 'aws-sdk'
import { bucketRegion } from '../../common/configAws'
import { accessKeyId, secretAccessKey, UserPoolId } from '../common/UserPool'

import styles from './index.module.scss'
import menuItems from './menuItems'

import { Text, ITextProps } from 'office-ui-fabric-react'

import MainModuleSVG from './module.svg'
import SaleSVG from '../../main/sale.svg'
import InventorySVG from '../../main/inventory.svg'
import PurchaseSVG from '../../main/purchase.svg'
import AccoutingSVG from '../../main/accouting.svg'
import DeliverySVG from '../../main/delivery.svg'
import InstallSVG from '../../main/install.svg'
import PersonSVG from '../../main/person.svg'
import ApprovedSVG from '../../main/approved.svg'
import ManagementSVG from '../../main/management.svg'

import parse from 'html-react-parser'
import DocumentTitle from '../common/document-title'
import { findPropertiesHr } from '../common/fildProperties'
import { divisionList } from '../common/AttributesDivision'
import useTokenInitializer from '../../hooks/token-initializer'
import { decodeTeamIdList } from '../common/addTeamIfNotExists'
import { useSelector } from 'react-redux'
import useFactInitializer from '../../hooks/fact-initializer'
import { FormatDateTime } from '../../common/dateFormat'

const HrMainPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const [listData, setListData] = useState<any>([])
  const [listDataFinish, setListDataFinish] = useState<any>([])

  const listTeam = useSelector((state: any) => state.api.fact.listTeam.items.listTeam?.results)

  const [fullname, setFullname] = useState('')
  const token = useSelector((state: any) => state.token)
  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
  }, [token])

  useFactInitializer()

  const iconList: { [key: string]: string } = {
    MainModuleSVG: MainModuleSVG,
    SaleSVG: SaleSVG,
    InventorySVG: InventorySVG,
    PurchaseSVG: PurchaseSVG,
    AccoutingSVG: AccoutingSVG,
    DeliverySVG: DeliverySVG,
    InstallSVG: InstallSVG,
    PersonSVG: PersonSVG,
    ApprovedSVG: ApprovedSVG,
    ManagementSVG: ManagementSVG,
  }

  const colHead: string[] = [
    'เลือก',
    'รหัสพนักงาน',
    'ชื่อ นามสกุล',
    'อีเมล',
    'แผนก',
    'ตำแหน่ง',
    'ทีม',
    'สถานะ',
    'ใช้งานล่าสุด',
    'สมัครเมื่อ',
  ]

  useEffect(() => {
    const params = {
      UserPoolId: UserPoolId,
      // AttributesToGet: [
      //   'ATTRIBUTE_NAME',
      // ],
    }

    const test = new Promise((resolve, reject) => {
      AWS.config.update({
        region: bucketRegion,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      })
      const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
      cognitoidentityserviceprovider.listUsers(params, (err, data: any) => {
        if (err) {
          console.log(err)
          reject(err)
        } else {
          const listUser: any = []
          data.Users.map((user: any) => {
            const mapper = {
              Username: user.Username,
              EmployeeID: user.Username.slice(0, 8),
              Fullname: findPropertiesHr(user.Attributes, 'name'),
              Email: findPropertiesHr(user.Attributes, 'email'),

              // แผนก ตำแหน่ง ทีม
              Division: findPropertiesHr(user.Attributes, 'custom:division')
                ? findPropertiesHr(user.Attributes, 'custom:division')
                : 'Admin',
              Position: findPropertiesHr(user.Attributes, 'custom:position')
                ? findPropertiesHr(user.Attributes, 'custom:position')
                : 'Admin',
              Team: findPropertiesHr(user.Attributes, 'custom:team')
                ? decodeTeamIdList(findPropertiesHr(user.Attributes, 'custom:team'))?.map((data) => {
                    return listTeam?.find((ele: any) => data === ele.teamId).teamName + ' '
                  })
                : '',

              status: user.UserStatus,
              updatedAt: FormatDateTime(user.UserLastModifiedDate),
              createdAt: FormatDateTime(user.UserCreateDate),
            }
            listUser.push(mapper)
          })
          setListData(listUser)
          setLoading(false)
          resolve(data)
        }
      })
    })
  }, [listTeam])

  useTokenInitializer()

  DocumentTitle('Sale', 'CRM')

  useEffect(() => {
    const finishList: any = []
    divisionList.map((divisions) => {
      if (listData.filter((ele: any) => ele.Division === divisions.name).length === 0) return
      finishList.push(
        `แผนก${divisions.name} - ทั้งหมด ${listData.filter((ele: any) => ele.Division === divisions.name).length} คน`,
      )
      listData.filter((ele: any) => ele.Division === divisions.name).map((data: any) => finishList.push(data))
    })
    setListDataFinish(finishList)
  }, [listData])

  return (
    <>
      <section className={styles.section1}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>รายชื่อพนักงาน</h1>
        <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [{fullname}]</span>
        <div className={styles.menuItemsWrapper1}>
          {menuItems.map(({ name, imgName }, index) => (
            <button key={index} style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}>
              <img src={iconList[imgName]} />
              <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
                {parse(name)}
              </Text>
            </button>
          ))}
        </div>
        <button type="button" className="styleBtn" onClick={() => props.history.push('/hr/add')}>
          เพิ่มพนักงาน
        </button>
        <button type="button" className="styleBtn" onClick={() => props.history.push('/hr/division')}>
          จัดการตำแหน่ง
        </button>
        <button type="button" className="styleBtn" onClick={() => props.history.push('/hr/teams')}>
          ทีมทั้งหมด
        </button>
      </section>
      <div style={{ margin: '0 25px' }}>
        <TableCommonCompanant
          colHead={colHead}
          rowBody={listDataFinish}
          typeHead={TABLE_HEAD_TYPES.FIRST_COLUMN_IS_BUTTON}
          typeBody={TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON}
          link="/hr/view"
        />
      </div>
    </>
  )
}

export default HrMainPage
