import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Text, ITextProps } from 'office-ui-fabric-react'

import styles from './index.module.scss'
import menuItems from './menuItems'
import HomeSVG from './home.svg'
import SearchSVG from './search.svg'
import AddSVG from './add.svg'
import RemoveSVG from './remove.svg'
import CheckStockSVG from './check-stock.svg'
import PurchaseSVG from './purchase.svg'
import SaleOrderSVG from './sale-order.svg'
import ApproveSVG from './approve.svg'
import DeliveryNoteVG from './delivery-note.svg'
import HomeModuleSVG from './homeModule.svg'

import parse from 'html-react-parser'
import DocumentTitle from '../common/document-title'

import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'

const InventoryMainPage: React.FC = () => {
  const iconList: { [key: string]: string } = {
    HomeSVG: HomeSVG,
    SearchSVG: SearchSVG,
    AddSVG: AddSVG,
    RemoveSVG: RemoveSVG,
    CheckStockSVG: CheckStockSVG,
    PurchaseSVG: PurchaseSVG,
    SaleOrderSVG: SaleOrderSVG,
    DeliveryNoteVG: DeliveryNoteVG,
    ApproveSVG: ApproveSVG,
    HomeModuleSVG: HomeModuleSVG,
  }

  const [fullname, setFullname] = useState('')
  const token = useSelector((state: any) => state.token)
  useTokenInitializer()
  useEffect(() => {
    if (!token.raw_token) return
    setFullname(token.name)
  }, [token.name])

  DocumentTitle('Inventory', '')

  return (
    <section className={styles.section1}>
      <h1 className={styles.headerTitle}>หน้าแรก</h1>
      <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [{fullname}]</span>
      <div className={styles.menuItemsWrapper}>
        {menuItems.map(({ name, imgName, linkTo }, index) => (
          <Link key={index} to={linkTo}>
            <button key={index} style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}>
              <img src={iconList[imgName]} />
              <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
                {parse(name)}
              </Text>
            </button>
          </Link>
        ))}
      </div>
    </section>
  )
}

export default InventoryMainPage
