interface IMenuItem {
  name: string
  imgName: string
  linkTo: string
}
const menuItems: IMenuItem[] = [
  { name: 'สร้างรายการใหม่', imgName: 'AddSVG', linkTo: '/inventory/receive/list/sku' },
  { name: 'เลือกจาก <br /> Purchase Order', imgName: 'PurchaseSVG', linkTo: '/inventory/receive/list/po' },
  { name: 'ดูรายการนำเข้าสินค้า', imgName: 'ListSVG', linkTo: '/inventory/receive/document/list' },
  { name: 'ย้อนกลับ', imgName: '', linkTo: '/inventory' },
]

export default menuItems
