import React, { useState } from 'react'
import { Pagination } from './pagination'
import PropTypes from 'prop-types'

const PaginationContainer: React.FC<{ page: number; totalPages: number; handlePages: (page: number) => void }> = ({
  page,
  totalPages,
  handlePages,
}) => {
  return <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />
}

PaginationContainer.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePages: PropTypes.any.isRequired,
}

export default PaginationContainer
