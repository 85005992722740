import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { serialNumberProductCode } from '../common/serialNumberComplete'

import DocumentTitle from '../common/document-title'
import { debounce } from 'lodash'
import { urlApi } from '../common/configUrlApi'

import useTokenInitializer from '../../hooks/token-initializer'
import { FormatDateTime } from '../../common/dateFormat'

import EnhancedTable from '../../common/table/item/paginationByFront/firstColBtn'
import { HeadCell } from '../../common/table/config'

const InventoryItemMasterDataListIDPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)

  const [dataListSku, setDataListSku] = useState<any>([])
  const [currentQueryString, setCurrentQueryString] = useState('')
  const [data, setData] = useState([])

  const [productCode, setProductCode] = useState('')
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [profile, setProfile] = useState('')
  const [color, setColor] = useState('')
  const [spec, setSpec] = useState('')

  const [width, setWidth] = useState(0)
  const [lenght, setLenght] = useState(0)

  const [piecePerP, setPiecePerP] = useState('')
  const [weightPerM, setWeightPerM] = useState('')
  const [pricePerM, setPricePerM] = useState(0)

  const [finish, setFinish] = useState('')
  const [imageUri, setImageUri] = useState<string>('')
  const [imageUri2D, setImageUri2D] = useState<string>('')

  const [total, setTotal] = useState(0)
  const [inStock, setInStock] = useState(0)
  const [committed, setCommitted] = useState(0)
  const [ordered, setOrdered] = useState(0)

  const [relatedGoodReceiveList, setRelatedGoodReceiveList] = useState<any>([])
  const [relatedGoodIssueList, setRelatedGoodIssueList] = useState<any>([])

  DocumentTitle('Inventory', 'เรียกดูสินค้า')

  useTokenInitializer()

  useEffect(() => {
    // Where to put the query
    const q = `query viewOneSku($id: ID!) {
      viewSku(id: $id) {
        id
        name
        productCode
        profile
        spec
        width
        length
        uri
        uri2D
        qtyPerPack(packId: 1)
        pricePerMeter
        kilogramPerMeter
        colorId
        inventoryHistoryReceive {
            documentId
            internalDocumentId
            numberOfPack
            qtyPerDoc
            totalQtyUpToThisDoc
            orderedIn
            reservedOut
            createdAt
            goodReceive  {
              purchaseOrder {
                internalDocumentId
              }
            }
        }
        inventoryHistoryIssue {
            documentId
            internalDocumentId
            numberOfPack
            qtyPerDoc
            totalQtyUpToThisDoc
            orderedIn
            reservedOut
            createdAt
            goodIssue {
              salesOrder {
                internalDocumentId
              }
            }
        }
        onHandStatistic {
          available
          ordered
          inStock
          committed
        }
        product {
          name
        }
        color {
          id
          code
          name
        }
        surfaceId
        surface {
          id
          code
          name
        }
        typeId
        type {
          id
          code
          name
        }
        updatedAt
        createdAt
      }
    }`
    // Where to put the variables
    const v = {
      id: props.match.params.id,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const viewSku = result.data.data.viewSku
        setLoading(false)
        setCurrentQueryString('')
        setProductCode(viewSku.productCode)
        setName(viewSku.name)
        setCode(viewSku.product.name)
        setProfile(viewSku.type.name)
        setColor(viewSku.color.name)
        setSpec(viewSku.spec)
        setWidth(parseInt(viewSku.width))
        setLenght(parseInt(viewSku.length))
        setFinish(viewSku.surface.name)
        setImageUri(viewSku.uri)
        setImageUri2D(viewSku.uri2D)
        setPricePerM(parseInt(viewSku.pricePerMeter))
        setWeightPerM(viewSku.kilogramPerMeter)
        setPiecePerP(viewSku.qtyPerPack)
        setTotal(viewSku.onHandStatistic.available + viewSku.onHandStatistic.committed)
        setInStock(viewSku.onHandStatistic.inStock)
        setCommitted(viewSku.onHandStatistic.committed)
        setOrdered(viewSku.onHandStatistic.ordered)

        const newInventoryHistoryReceive: any[] = []
        if (viewSku.inventoryHistoryReceive.length === 0) {
          const mapper = {
            id: '-',
            lot: '-',
            date: '-',
            qty: '-',

            reservedOut: '-',
            reservedOutPack: '-',

            stock: '-',
            stockPack: '-',

            totalQtyUpToThisDoc: '-',
            totalQtyUpToThisDocPack: '-',

            orderedIn: '-',
            orderedInPack: '-',

            ref: '-',
          }
          newInventoryHistoryReceive.push(mapper)
        }
        viewSku.inventoryHistoryReceive.map((data: any) => {
          const mapper = {
            id: data.documentId,
            lot: data.internalDocumentId,
            date: FormatDateTime(data.createdAt),
            qty: data.numberOfPack,

            reservedOut: data.reservedOut,
            reservedOutPack: Math.ceil(data.reservedOut / viewSku.qtyPerPack),

            stock: data.totalQtyUpToThisDoc - data.qtyPerDoc,
            stockPack: Math.ceil(data.totalQtyUpToThisDoc - data.qtyPerDoc / viewSku.qtyPerPack),

            totalQtyUpToThisDoc: data.totalQtyUpToThisDoc,
            totalQtyUpToThisDocPack: Math.ceil(data.totalQtyUpToThisDoc / viewSku.qtyPerPack),

            orderedIn: data.orderedIn,
            orderedInPack: Math.ceil(data.orderedIn / viewSku.qtyPerPack),

            ref: data.goodReceive.purchaseOrder ? data.goodReceive.purchaseOrder : '-',
          }
          newInventoryHistoryReceive.push(mapper)
        })
        setRelatedGoodReceiveList(newInventoryHistoryReceive)

        const newInventoryHistoryIssue: any[] = []
        if (viewSku.inventoryHistoryIssue.length === 0) {
          const mapper = {
            id: '-',
            lot: '-',
            date: '-',
            qty: '-',

            reservedOut: '-',
            reservedOutPack: '-',

            stock: '-',
            stockPack: '-',

            totalQtyUpToThisDoc: '-',
            totalQtyUpToThisDocPack: '-',

            orderedIn: '-',
            orderedInPack: '-',

            ref: '-',
          }
          newInventoryHistoryIssue.push(mapper)
        }
        viewSku.inventoryHistoryIssue.map((data: any) => {
          const mapper = {
            id: data.documentId,
            lot: data.internalDocumentId,
            date: FormatDateTime(data.createdAt),
            qty: data.qtyPerDoc * -1,

            reservedOut: data.reservedOut,
            reservedOutPack: Math.ceil(data.reservedOut / viewSku.qtyPerPack),

            stock: data.totalQtyUpToThisDoc - data.qtyPerDoc,
            stockPack: Math.ceil(data.totalQtyUpToThisDoc - data.qtyPerDoc / viewSku.qtyPerPack),

            totalQtyUpToThisDoc: data.totalQtyUpToThisDoc,
            totalQtyUpToThisDocPack: Math.ceil(data.totalQtyUpToThisDoc / viewSku.qtyPerPack),

            orderedIn: data.orderedIn,
            orderedInPack: Math.ceil(data.orderedIn / viewSku.qtyPerPack),

            ref: data.goodIssue.salesOrder ? data.goodIssue.salesOrder : '-',
          }
          newInventoryHistoryIssue.push(mapper)
        })
        setRelatedGoodIssueList(newInventoryHistoryIssue)
      })
      .catch((err) => console.log(err.response))
  }, [props.match.params.id])

  // GET LIST ITEM MASTER DATA
  useEffect(() => {
    // Where to put the query
    const q = `query ($productCodePartial: String) {
      listSku(productCodePartial: $productCodePartial) {
        results {
          id
          name
          productCode
        }
        totalCount
        pageIndex
        maxResults
      }
    }`
    // Where to put the variables
    const v = {
      productCodePartial: currentQueryString,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const listSku = result.data.data.listSku.results
      const listSkuMapper: any[] = []
      listSku.map((data: any) => {
        const mapper = {
          id: data.id,
          productCode: data.productCode,
          name: data.name,
        }
        return listSkuMapper.push(mapper)
      })
      setDataListSku(listSkuMapper)
      // Redirect Page if currentQueryString match productCode
      if (currentQueryString === listSku[0].productCode) {
        return props.history.push(`/inventory/item-master-data/list/${listSku[0].id}`)
      }
      if (typeof serialNumberProductCode(currentQueryString) === 'string') {
        return props.history.push(`/inventory/item-master-data/list/${listSku[0].id}`)
      }
    })
  }, [currentQueryString])

  useEffect(() => {
    const filterDataOnCurrentQueryString = () => {
      // currentQueryString
      setData(
        dataListSku.filter(function (data: any) {
          // var removeSpaces = currentQueryString.replace(/\s/g, '');
          const regex = new RegExp(`${currentQueryString}`, 'i')
          const isMatch = regex.test(data.productCode) // || regex.test(docPO.description)
          if (isMatch) {
            if (currentQueryString.length === 9 && data.id) {
              props.history.push(`/inventory/item-master-data/list/${data.id}`)
            }
            return isMatch
          } else {
            if (typeof serialNumberProductCode(currentQueryString) === 'string') {
              const regex2 = new RegExp(`${serialNumberProductCode(currentQueryString)}`, 'i')
              const isMatch2 = regex2.test(data.productCode)
              if (isMatch2) {
                props.history.push(`/inventory/item-master-data/list/${data.id}`)
                return isMatch2
              } else {
                return isMatch2
              }
            } else {
              return
            }
          }
        }),
      )
    }
    filterDataOnCurrentQueryString()
  }, [currentQueryString, dataListSku])

  const request = debounce((value) => {
    setCurrentQueryString(value)
  }, 1000)

  const debouceRequest = useCallback((value) => request(value), [])

  const onChange = (e: any) => {
    debouceRequest(e.target.value)
  }

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'docID', numeric: false, disablePadding: false, label: 'เลขที่เอกสาร' },
    { id: 'createAt', numeric: false, disablePadding: false, label: 'วันที่สร้างเอกสาร' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'จำนวนห่อ' },
    { id: 'buy', numeric: true, disablePadding: false, label: 'ซื้อ' },
    { id: 'stock', numeric: true, disablePadding: false, label: 'คงคลัง' },
    { id: 'avaliable', numeric: true, disablePadding: false, label: 'คงเหลือสุทธิ' },
    { id: 'commited', numeric: true, disablePadding: false, label: 'จอง' },
    { id: 'ref', numeric: false, disablePadding: false, label: 'อ้างอิง' },
  ]

  const headCells2: HeadCell[] = [
    { id: 'docID', numeric: false, disablePadding: false, label: 'เลขที่เอกสาร' },
    { id: 'createAt', numeric: false, disablePadding: false, label: 'วันที่สร้างเอกสาร' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'จำนวนแผ่น' },
    { id: 'buy', numeric: true, disablePadding: false, label: 'ซื้อ' },
    { id: 'stock', numeric: true, disablePadding: false, label: 'คงคลัง' },
    { id: 'avaliable', numeric: true, disablePadding: false, label: 'คงเหลือสุทธิ' },
    { id: 'commited', numeric: true, disablePadding: false, label: 'จอง' },
    { id: 'ref', numeric: false, disablePadding: false, label: 'อ้างอิง' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  return (
    <>
      <div className={styles.pageWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>เรียกดูสินค้า</h1>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสสินค้า / Serial Number"
          style={{ maxWidth: '385.6px' }}
          onChange={onChange}
          autoFocus
        />
        <section className={styles.sectionItem}>
          <div>
            <h4 style={{ margin: '0 0 10px 0' }}>รูปภาพสินค้า</h4>
            <div className={styles.skuImage}>
              <img src={imageUri ? imageUri : 'https://fakeimg.pl/190x150'} alt="SKU Image" width="190" height="150" />
            </div>
            <h4 style={{ margin: '10px 0' }}>รูปภาพ 2D</h4>
            <div className={styles.skuImage}>
              <img
                src={imageUri2D ? imageUri2D : 'https://fakeimg.pl/190x150'}
                alt="SKU Image"
                width="190"
                height="150"
              />
            </div>
          </div>

          <div>
            <h3>รหัสสินค้า {productCode}</h3>
            {/* <h4 style={{ margin: '10px 0 0' }}>ชื่อสินค้า</h4>
            <input type="text" className="inputForSearch" value={name} disabled></input> */}
            <div className={styles.groupInput}>
              <div className={styles.colInput}>
                <h4>รายละเอียด</h4>
                <input type="text" className="inputForSearch" value={code} disabled></input>
                <h4>สี</h4>
                <input type="text" className="inputForSearch" value={color} disabled></input>
                <h4>ผิว</h4>
                <input type="text" className="inputForSearch" value={finish} disabled></input>
                <h4>น้ำหนักต่อเมตร</h4>
                <input type="text" className="inputForSearch" value={weightPerM} disabled></input>
                <h4>ตร.ม.ต่อแผ่น</h4>
                <input type="text" className="inputForSearch" value={(lenght * width) / 1000000} disabled></input>
              </div>
              <div className={styles.colInput}>
                <h4>ประเภท</h4>
                <input type="text" className="inputForSearch" value={profile} disabled></input>
                <h4>ขนาด(หนา x กว้าง x ยาว)</h4>
                <input type="text" className="inputForSearch" value={spec} disabled></input>
                <h4>จำนวนแผ่นต่อห่อ</h4>
                <input type="text" className="inputForSearch" value={piecePerP} disabled></input>
                <h4>ราคาต่อเมตร</h4>
                <input type="text" className="inputForSearch" value={pricePerM} disabled></input>
                <h4>ราคาต่อตร.ม.</h4>
                <input
                  type="text"
                  className="inputForSearch"
                  value={((lenght * width) / 1000000) * pricePerM}
                  disabled
                ></input>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className={styles.groupButtonDestop}>
        <button
          type="button"
          className="styleBtn"
          onClick={() => props.history.push(`/inventory/item-master-data/list/edit/${props.match.params.id}`)}
        >
          แก้ไขข้อมูล
        </button>
      </section>
      <section>
        <table className="summaryTopTableStyle table">
          <tr className="tr">
            <th className={`${styles.rowHeadTable} borderRightRadius th`} colSpan={2}>
              ซื้อ
            </th>
            <th className={`${styles.rowHeadTable} th`} colSpan={2}>
              คงคลัง
            </th>
            <th className={`${styles.rowHeadTable} th`} colSpan={2}>
              คงเหลือสุทธิ
            </th>

            <th className={`${styles.rowHeadTable} borderLeftRadius th`} colSpan={2}>
              จอง
            </th>
          </tr>
          <tr className="tr">
            <th className={`${styles.rowHeadTable} th`}>แผ่น</th>
            <th className={`${styles.rowHeadTable} th`}>ห่อ</th>
            <th className={`${styles.rowHeadTable} th`}>แผ่น</th>
            <th className={`${styles.rowHeadTable} th`}>ห่อ</th>
            <th className={`${styles.rowHeadTable} th`}>แผ่น</th>
            <th className={`${styles.rowHeadTable} th`}>ห่อ</th>
            <th className={`${styles.rowHeadTable} th`}>แผ่น</th>
            <th className={`${styles.rowHeadTable} th`}>ห่อ</th>
          </tr>
          <tr className="tr">
            <th className={`${styles.rowBodyTable} borderBottomRightRadius th`}>{ordered}</th>
            <th className={`${styles.rowBodyTable} th`}>{Math.ceil(ordered / parseInt(piecePerP))}</th>
            <th className={`${styles.rowBodyTable} th`}>{inStock}</th>
            <th className={`${styles.rowBodyTable} th`}>{Math.ceil(inStock / parseInt(piecePerP))}</th>
            <th className={`${styles.rowBodyTable} th`}>{total}</th>
            <th className={`${styles.rowBodyTable} th`}>{Math.ceil(total / parseInt(piecePerP))}</th>

            <th className={`${styles.rowBodyTable} th`}>{committed}</th>
            <th className={`${styles.rowBodyTable} borderBottomLeftRadius th`}>
              {Math.ceil(committed / parseInt(piecePerP))}
            </th>
          </tr>
        </table>
      </section>

      <section className={`tableListMui`}>
        <h4 style={{ margin: '20px 0 10px 0px' }}>รายการนำเข้า</h4>
        <EnhancedTable colHead={headCells} rows={relatedGoodReceiveList} link="/inventory/receive/document/list" />
      </section>

      <section className={`tableListMui`}>
        <h4 style={{ margin: '20px 0 10px 0px' }}>รายการนำออก</h4>
        <EnhancedTable colHead={headCells2} rows={relatedGoodIssueList} link="/inventory/issue/document/list" />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/item-master-data">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventoryItemMasterDataListIDPage
