interface IMenuItem {
  name: string
  imgName: string
  linkTo: string
}
const menuItems: IMenuItem[] = [
  { name: 'สร้างรายการใหม่', imgName: 'AddSVG', linkTo: '/inventory/issue/list/sku' },
  { name: 'เลือกจาก <br /> Sales Order', imgName: 'PurchaseSVG', linkTo: '/inventory/issue/list/so' },
  { name: 'ดูรายการนำออกสินค้า', imgName: 'ListSVG', linkTo: '/inventory/issue/document/list' },
  { name: 'ย้อนกลับ', imgName: '', linkTo: '/inventory' },
]

export default menuItems
