import React, { useEffect, useState } from 'react'
import { useBoolean } from '@uifabric/react-hooks'
import styles from './index.module.scss'
import SummaryTopTableComponant from '../common/summaryTopTable'
import { serialNumberComplete } from '../common/serialNumberComplete'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import { urlApi } from '../common/configUrlApi'
import ModalAlert from '../common/modalNotFormik'
import GroupBtnDocument from '../common/groupBtnDocuemnt'

import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'
import { FormatDateTime } from '../../common/dateFormat'

import EnhancedTable from '../../common/table/item/paginationByFront/firstColBtnandInput'
import { HeadCell } from '../../common/table/config'

export interface ILineItems {
  lineNumber: number
  itemID: string
  pack: string
  quantity: number
  quantityPerPack: number
  status: string
  by: string
  timeStamp: string
  description: string
}

const InventoryReceiveRefPOPage: React.FC = (props: any) => {
  DocumentTitle('Inventory', 'นำสินค้าเข้า')

  const [loading, setLoading] = useState<any>(true)

  const [documentID, setDocumentID] = useState('')
  const [remarkDate, setRemarkDate] = useState('')
  const [packingList, setPackingList] = useState('')
  const [internalDocumentID, setInternalDocumentID] = useState('')
  const [documentRef, setDocumentRef] = useState('')
  const [documentStatusId, setDocumentStatusId] = useState('')
  const [lineItems, setLineItems] = useState<(string | ILineItems)[]>([])

  const [summaryTopTable, setSummaryTopTable] = useState<any>([])

  const [correctSN, setCorrectSN] = useState<string>('')

  const [actionIput, setActionIput] = useState(false)
  const [fetchData, setFetchData] = useState<number>(0)
  const [fetchData2, setFetchData2] = useState<number>(0)

  const [successSubmit, setSuccessSubmit] = useState('')
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  const [inputSerialNumber, setInputSerialNumber] = useState('')

  const [fullname, setFullname] = useState('')
  const token = useSelector((state: any) => state.token)

  useTokenInitializer()

  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
  }, [token.name])

  // For Query Doc Receive
  useEffect(() => {
    const q = `query viewGoodReceive($documentId: ID!){
      viewGoodReceive(id: $documentId){
        id
        documentTypeId
        documentStatusId
        internalDocumentId
        purchaseOrderDocumentId
        remarkDate
        packingList
        purchaseOrder {
          internalDocumentId
        }
        lotNumber
        packLineItem {
          id
          skuId
          runningNumber
          qty
          uomId
          packId
          scanBy
          scanAt
          voided
          serialNumber
          sku {
            name
            productCode
            qtyPerPack(packId: 1)
            product {
              name
            }
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      documentId: props.match.params.id,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const viewGoodReceive = result.data.data.viewGoodReceive
      const listMapper: any[] = []
      viewGoodReceive.packLineItem.map((data: any) => {
        const mapper = {
          lineNumber: data.serialNumber,
          itemID: `${data.sku.productCode}`,
          pack: data.runningNumber,
          status: data.scanBy ? 'สแกนแล้ว' : 'รอสแกน',
          quantity: data.qty ? data.qty : 0,
          quantityPerPack: data.sku.qtyPerPack,
          serialNumber: data.serialNumber,
          by: data.scanBy,
          timeStamp: data.scanAt ? FormatDateTime(data.scanAt) : '',
          description: data.sku.product.name,
        }
        listMapper.push(mapper)
      })
      setLoading(false)
      setRemarkDate(viewGoodReceive.remarkDate?.split('T')[0])
      setPackingList(viewGoodReceive.packingList)
      setDocumentStatusId(viewGoodReceive.documentStatusId)
      setLineItems(listMapper)
      setInternalDocumentID(viewGoodReceive.internalDocumentId)
      setDocumentID(viewGoodReceive.id)
      setDocumentRef(viewGoodReceive.purchaseOrder ? viewGoodReceive.purchaseOrder.internalDocumentId : '')
      setFetchData2(fetchData2 + 1)
    })
  }, [fetchData])

  useEffect(() => {
    // Pretend this is axios
    const clone_lineItems: ILineItems[] = []
    lineItems.map((data) => {
      if (typeof data !== 'string') {
        return clone_lineItems.push(data)
      }
    })

    const ameliaWatson = clone_lineItems.reduce<{ [itemId: string]: ILineItems[] }>(
      (previousValue, currentValue: any) => {
        const packNumberList: any[] = previousValue[currentValue.itemID] || []
        packNumberList.push(currentValue)
        previousValue[currentValue.itemID] = packNumberList
        return previousValue
      },
      {},
    )

    const packSKU: { packList: number; totalPiece: number; totalPieceAndPack: number; totalPackReceive: number }[] = []

    const plastic = Object.entries(ameliaWatson).reduce<(ILineItems | string)[]>(
      (lineItemListReducer, [itemId, packList]) => {
        const totalPiece = packList.reduce<number>((pv, cv) => {
          return pv + cv.quantity
        }, 0)
        const totalPieceAndPack = packList.reduce<number>((pv, cv) => {
          return pv + cv.quantityPerPack
        }, 0)
        const totalPackReceive = packList.filter((ele) => {
          if (ele.quantity > 0) {
            return ele
          } else return
        }).length

        const warcraft3 = `รหัส ${itemId} - ${packList[0].description} - ${Math.ceil(
          totalPiece / (totalPieceAndPack / packList.length),
        )}/${packList.length} ห่อ = ${totalPiece}/${totalPieceAndPack} แผ่น`

        lineItemListReducer.push(warcraft3)
        lineItemListReducer.push(...packList)

        packSKU.push({
          packList: packList.length,
          totalPiece: totalPiece,
          totalPieceAndPack: totalPieceAndPack,
          totalPackReceive: totalPackReceive,
        })

        return lineItemListReducer
      },
      [],
    )
    // console.log('packSKU', packSKU)
    setSummaryTopTable(packSKU)
    setLineItems(plastic)
    setActionIput(true)
  }, [actionIput, documentID, fetchData2])

  const onChangequantityLineItems = (index: any, value: number) => {
    if (value === NaN) {
      // Just in-case a non-number gets in
      // But Nuk already make input cannot be character other than digits
      return
    }
    setActionIput(true)
    const clonesLineItems = [...lineItems]

    const lineItem = clonesLineItems[index]
    if (typeof lineItem === 'string') {
      return
    }
    lineItem.quantity = value ? value : 0
    if (lineItem.quantity >= lineItem.quantityPerPack) {
      lineItem.quantity = lineItem.quantityPerPack
    }
    clonesLineItems.splice(index, 1, lineItem)
    setLineItems(clonesLineItems)
    setActionIput(false)
  }

  const onClickPrintOneRow = (lineNumber: string) => {
    const q = `query($uu: String!, $snList: [String!]!) {
      serialNumberInformation(userUid: $uu, serialNumberList: $snList) {
        serialNumber
        sku {
          productName: name
          productNumber: productCode
          product {
            name
          }
          uri2D
          qtyPerPack(packId: 1)
          length
          width
          depth
          spec
          color {
            name
          }
          finish: surface {
            name
          }
          profile: type {
            name
          }
        }
        lotDate
      }
    }`
    // Where to put the variables
    const v = {
      uu: fullname,
      snList: [lineNumber],
    }
    console.log('v', v)
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const dataPost: any = []
      result.data.data.serialNumberInformation.map((ele: any) => {
        const mapper = {
          import_date: remarkDate,
          product_id: ele.sku.productNumber,
          description: ele.sku.product.name,
          packing_list: packingList,
          model: 'Original',
          color: ele.sku.color.name,
          surface: ele.sku.finish.name,
          width: ele.sku.width,
          thick: ele.sku.depth,
          length: ele.sku.length,
          qty: `${(lineItems.find((ele: any) => lineNumber === ele.serialNumber) as ILineItems).quantity}`,
          qty_per_pack: `${ele.sku.qtyPerPack}`,
          'image-2d': ele.sku.uri2D,
          serial_number: ele.serialNumber,
        }
        dataPost.push(mapper)
      })
      console.log('dataPost', dataPost)
      // CALL PRINT TO PRINTER
      axios({
        url: 'http://localhost:4000/zpl_print',
        method: 'post',
        data: dataPost,
      }).then((result) => {
        console.log('result', result)
      })
    })
  }

  const onClickPrintSKURow = (item: any) => {
    const listSerialNumber: string[] = []
    const listRawItem: any[] = []
    lineItems.map((data: any) => {
      if (data.itemID === item.itemID) {
        listSerialNumber.push(data.lineNumber)
        listRawItem.push(data)
      }
    })

    const q = `query($uu: String!, $snList: [String!]!) {
      serialNumberInformation(userUid: $uu, serialNumberList: $snList) {
        serialNumber
        sku {
          productName: name
          productNumber: productCode
          product {
            name
          }
          uri2D
          qtyPerPack(packId: 1)
          spec
          length
          width
          depth
          color {
            name
          }
          finish: surface {
            name
          }
          profile: type {
            name
          }
        }
        lotDate
      }
    }`
    // Where to put the variables
    const v = {
      uu: fullname,
      snList: [...listSerialNumber],
    }
    console.log('v', v)
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const dataPost: any = []
      result.data.data.serialNumberInformation.map((ele: any) => {
        // console.log(item)
        const mapper = {
          import_date: remarkDate,
          product_id: ele.sku.productNumber,
          description: ele.sku.product.name,
          packing_list: packingList,
          model: 'Original',
          color: ele.sku.color.name,
          surface: ele.sku.finish.name,
          width: ele.sku.width,
          thick: ele.sku.depth,
          length: ele.sku.length,
          qty: `${(lineItems.find((ele2: any) => ele2.lineNumber === ele.serialNumber) as ILineItems).quantity}`,
          qty_per_pack: `${ele.sku.qtyPerPack}`,
          'image-2d': ele.sku.uri2D,
          serial_number: ele.serialNumber,
        }
        dataPost.push(mapper)
      })
      console.log('dataPost', dataPost)
      // CALL PRINT TO PRINTER
      axios({
        url: 'http://localhost:4000/zpl_print',
        method: 'post',
        data: dataPost,
      }).then((result) => {
        console.log('result', result)
      })
    })
  }

  const onClickPrintTotalRow = () => {
    const listSerialNumber: string[] = []
    const listRawItem: any[] = []
    lineItems.map((data: any) => {
      if (typeof data !== 'string') {
        listRawItem.push(data)
        listSerialNumber.push(data.lineNumber)
      } else return
    })
    const q = `query($uu: String!, $snList: [String!]!) {
      serialNumberInformation(userUid: $uu, serialNumberList: $snList) {
        serialNumber
        sku {
          productName: name
          productNumber: productCode
          product {
            name
          }
          uri2D
          qtyPerPack(packId: 1)
          spec
          length
          width
          depth
          color {
            name
          }
          finish: surface {
            name
          }
          profile: type {
            name
          }
        }
        lotDate
      }
    }`
    // Where to put the variables
    const v = {
      uu: fullname,
      snList: [...listSerialNumber],
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const dataPost: any = []
      result.data.data.serialNumberInformation.map((ele: any) => {
        const mapper = {
          import_date: remarkDate,
          product_id: ele.sku.productNumber,
          description: ele.sku.product.name,
          packing_list: packingList,
          model: 'Original',
          color: ele.sku.color.name,
          surface: ele.sku.finish.name,
          width: ele.sku.width,
          thick: ele.sku.depth,
          length: ele.sku.length,
          qty: `${(lineItems.find((ele2: any) => ele2.lineNumber === ele.serialNumber) as ILineItems).quantity}`,
          qty_per_pack: `${ele.sku.qtyPerPack}`,
          'image-2d': ele.sku.uri2D,
          serial_number: ele.serialNumber,
        }
        dataPost.push(mapper)
      })
      console.log('dataPost', dataPost)
      // CALL PRINT TO PRINTER
      axios({
        url: 'http://localhost:4000/zpl_print',
        method: 'post',
        data: dataPost,
      }).then((result) => {
        console.log('result', result)
      })
    })
  }

  const onClickSaveDoc: any = () => {
    console.log('lineItems', lineItems)
    const prepairSna: any = []
    lineItems.map((data: any) => {
      if (typeof data === 'string') return
      // if (data.lineNumber && data.quantity) {
      prepairSna.push({ serialNumber: data.lineNumber, qty: data.quantity })
      // }
    })

    const q = `mutation ($uu: String!, $sna: [serialNumberAmount!]!, $input: EditGoodReceive!) {
      serialNumberAmountList(userUid: $uu, serialNumberAmount: $sna) {
        ok
        message
      }
      editGoodReceive(input: $input){
        id
        remarkDate
        packingList
      }
    }`
    // Where to put the variables
    const v = {
      uu: fullname,
      sna: prepairSna,
      input: {
        id: documentID,
        remarkDate: remarkDate ? new Date(remarkDate).toISOString() : null,
        packingList: packingList,
      },
    }
    console.log('v', v)
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('result', result)
        setFetchData(fetchData + 1)
        showModal()
        setSuccessSubmit('สำเร็จ')
        return true
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
        return false
      })
  }

  const onChangeSerialNumber = (value: string) => {
    setInputSerialNumber(value)
    if (value === '') {
      setCorrectSN('')
      return
    } else if (serialNumberComplete(value) !== 0) {
      // Serial number not correct
      setCorrectSN('S/N ไม่ถูกต้อง')
      return
    } else {
      setCorrectSN('')
      console.log('correct')
      // onClickSaveDoc()
      const q = `mutation scanSerialNumber ($sn: String!, $uu: String!){
        serialNumberScan(serialNumber: $sn, userUid: $uu) {
          ok
          message
        }
      }`
      // Where to put the variables
      const v = {
        sn: value,
        uu: fullname,
      }

      axios({
        url: urlApi,
        method: 'post',
        data: {
          query: q,
          variables: v,
        },
      }).then((result) => {
        setFetchData(fetchData + 1)
        setInputSerialNumber('')
      })
    }
  }

  const onClickSendDoc = () => {
    onClickSaveDoc()
    const q = `mutation submitGoodReceive($documentId: ID!) {
        submitGoodReceive(id: $documentId) {
          id
        }
      }`
    // Where to put the variables
    const v = {
      documentId: documentID,
    }
    // console.log('q', q)
    // console.log('v', v)
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        // console.log('result', result)
        showModal()
        setSuccessSubmit('สำเร็จ')
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
      })
  }

  const onClickRemoveDoc = () => {
    const q = `mutation goodReceiveRemove($id: ID!) {
      goodReceiveRemove(id: $id) {
        id
      }
    }`
    // Where to put the variables
    const v = {
      id: documentID,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        // console.log('result', result)
        showModal()
        setSuccessSubmit('สำเร็จ')
        props.history.goBack()
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
      })
  }

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'printAll', numeric: false, disablePadding: false, label: 'พิมพ์ทั้งหมด' },
    { id: 'createAt', numeric: false, disablePadding: false, label: 'รหัสสินค้า' },
    { id: 'Pack', numeric: false, disablePadding: false, label: 'Pack' },
    { id: 'scan', numeric: false, disablePadding: false, label: 'สแกน' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'จำนวนแผ่น' },
    { id: 'qtyperpack', numeric: false, disablePadding: false, label: 'แผ่น/ห่อ' },
    { id: 'sn', numeric: false, disablePadding: false, label: 'S/N' },
    { id: 'by', numeric: false, disablePadding: false, label: 'โดย' },
    { id: 'date', numeric: false, disablePadding: false, label: 'เมื่อ' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------
  return (
    <>
      <div className={styles.pageWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>นำสินค้าเข้า</h1>
        <p>
          ใบนำสินค้าเข้า {internalDocumentID} - อ้างอิง {documentRef} - {documentStatusId}
        </p>
        <p className="errMsg">{correctSN ? correctSN : ''}</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="Serial Number"
          value={inputSerialNumber}
          onChange={(e) => {
            onChangeSerialNumber(e.target.value)
          }}
          style={{ maxWidth: '386.5px' }}
          autoFocus
        />
        <p style={{ margin: '15px 0 0' }}>วันที่นำเข้า</p>
        <input
          type="date"
          className="inputForSearch"
          placeholder="วันที่นำเข้า"
          value={remarkDate}
          // disabled={remarkDate ? true : false}
          onChange={(e) => setRemarkDate(e.target.value)}
          style={{ maxWidth: '386.5px', display: 'flex' }}
        />
        <p style={{ margin: '15px 0 0' }}>เลขที่ใบรายการสินค้าเข้า</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="เลขที่ใบรายการสินค้าเข้า"
          value={packingList}
          onChange={(e) => setPackingList(e.target.value)}
          style={{ maxWidth: '386.5px', display: 'flex' }}
        />
        <p style={{ margin: '15px 0 0' }}>จังหวัด</p>
        <select
          name="type"
          className={`${styles.selectFilter} ${styles.hiddenInDesktop}`}
          style={{ maxWidth: '385.6px', margin: '10px 10px 0 0' }}
        >
          <option value="all">จังหวัด</option>
        </select>
        <p style={{ margin: '15px 0 0' }}>ประเภทการนำเข้า</p>
        <select
          name="type"
          className={`${styles.selectFilter} ${styles.hiddenInDesktop}`}
          style={{ maxWidth: '385.6px', margin: '10px 10px 0 0' }}
        >
          <option value="all">ประเภท</option>
          <option value="ซื้อ">ซื้อ</option>
          <option value="โอนเข้า">โอนเข้า</option>
          <option value="รับคืน">รับคืน</option>
          <option value="รับอื่นๆ">รับอื่นๆ</option>
        </select>
      </div>
      <SummaryTopTableComponant summaryTopTable={summaryTopTable} />
      <section className={`tableListMui`}>
        <EnhancedTable
          colHead={headCells}
          rows={lineItems}
          onChangequantityLineItems={onChangequantityLineItems}
          onClickPrintOneRow={onClickPrintOneRow}
          onClickPrintSKURow={onClickPrintSKURow}
          onClickPrintTotalRow={onClickPrintTotalRow}
          removeButtonClassUniqueSKU={false}
        />
      </section>
      <section className={styles.groupbtn}>
        <GroupBtnDocument
          onClickSaveDoc={onClickSaveDoc}
          onClickSendDoc={onClickSendDoc}
          onClickRemoveDoc={onClickRemoveDoc}
          documentStatusId={documentStatusId}
          linkGoBack="/inventory/receive/document/list"
        />
      </section>
      {ModalAlert(isModalOpen, hideModal, successSubmit)}
    </>
  )
}

export default InventoryReceiveRefPOPage
