import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useHistory, Link } from 'react-router-dom'

import HomeSVG from '../../inventory/main-inventory/home.svg'
import InstallSVG from '../../main/install.svg'

const EngineerNavSideComponent: React.FunctionComponent = (props: any) => {
  const [pathname, setPathname] = useState('')

  const history = useHistory()
  // Solve choose NavtopBar & Navigation for Route to new page
  history.listen((location, action) => {
    setPathname(history.location.pathname)
  })

  // Solve choose NavtopBar & Navigation for Reload
  useEffect(() => {
    setPathname(history.location.pathname)
  }, [history])

  return (
    <nav className={styles.navSideBar}>
      <Link to="/">
        <div className={styles.moduleSelected}>
          <h3>โมดูล</h3>
          <img src={InstallSVG} width="25" height="25" />
          <p>ติดตั้ง</p>
        </div>
      </Link>

      <h3 className={styles.textMenu}>เมนู</h3>
      {menu.map(({ name, to, img }: any) => (
        <Link
          to={to}
          className={`${styles.buttonLink} ${
            pathname.indexOf(`${to}`) !== -1 && to !== '/engineer' ? styles.active : ''
          }`}
          key={name}
        >
          <img src={img} width="30" height="30" />
          <p>{name}</p>
        </Link>
      ))}
    </nav>
  )
}

export default EngineerNavSideComponent

const menu: any = [
  {
    name: 'หน้าหลัก',
    to: '/engineer',
    img: HomeSVG,
  },
]
