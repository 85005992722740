import React from 'react'
import styles from './index.module.scss'
import { Pivot, PivotItem } from '@fluentui/react'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import { componantChildComment } from './componantChildComment'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from '../../common/configAws'
import { useSelector } from 'react-redux'

const tzoffset = new Date().getTimezoneOffset() * 60000
const localISOTime = new Date(Date.now() - tzoffset).toISOString()

export const componantParentComment = () => {
  const { values, errors, touched, setFieldValue, submitForm } = useFormikContext<any>()
  const [selectedKey, setSelectedKey] = React.useState(0)

  const token: any = useSelector((state: any) => state.token)
  const handlePivotClick = (item: PivotItem | undefined) => {
    if (item !== undefined && item.props.itemKey) {
      setSelectedKey(parseInt(item.props.itemKey))
    }
  }

  const handleUploadImage = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]

    if (file.length !== 0) {
      const fileName = file.name
      const albumPhotosKey = encodeURIComponent('Engineer') + '/' + encodeURIComponent(`${values.title}`) + '/'

      const photoKey = albumPhotosKey + fileName
      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })

      const promise = upload.promise()

      promise.then(
        function (data) {
          console.log('data', data)
          if (values.imageUri.length !== 0) {
            setFieldValue('imageUri', [...values.imageUri, data.Location], false)
          } else {
            setFieldValue('imageUri', [data.Location], false)
          }
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  const handleSubmitComment = (commentType: any, value: string) => {
    const mapper = {
      commentType: commentType,
      description: value,
      attachments: values.imageUri ? values.imageUri : [],
      createdAt: localISOTime,
      by: token.name,
    }

    if (values.comments.length !== 0) {
      setFieldValue('comments', [...values.comments, mapper], false)
      setFieldValue('comment', '', false)
      setFieldValue('imageUri', [], false)
      submitForm()
    } else {
      setFieldValue('comments', [mapper], false)
      setFieldValue('comment', '', false)
      setFieldValue('imageUri', [], false)
      submitForm()
    }
  }

  return (
    <div className={styles.logComment}>
      <h1 className={`${styles.headerTitleBody} ${styles.hiddenInMpbile}`}>การเคลื่อนไหว</h1>
      <Pivot
        className={styles.tabWrapper}
        aria-label="Pivot Tab Selection"
        selectedKey={String(selectedKey)}
        onLinkClick={handlePivotClick}
      >
        <PivotItem headerText="บันทึก" itemKey="0" />
        <PivotItem headerText="Approve" itemKey="1" />
        <PivotItem headerText="ไม่ Approve" itemKey="2" />
        <PivotItem headerText="ส่งงาน" itemKey="3" />
        <PivotItem headerText="รับงาน" itemKey="4" />
        <PivotItem headerText="แก้งาน" itemKey="5" />
      </Pivot>
      <div className={`${styles.width100} ${styles.newRow}`}>
        <div className={styles.mainWrapper}>
          {selectedKey == 0 && (
            <>
              <div className={`${styles.width100} ${styles.newRow}`}>
                <Field
                  name="comment"
                  className={styles.styleTextarea}
                  placeholder="รายละเอียด"
                  as="textarea"
                  rows={3}
                />
                <div>
                  <input type="file" id="file" onChange={(e) => handleUploadImage(e)} accept="image/*" />
                  <label htmlFor="file" className={`${styles.buttonUploadFile} ${styles.width100}`}>
                    เพิ่มรูป
                  </label>
                  <button
                    className={`styleBtn ${styles.width100}`}
                    style={{ padding: '5px' }}
                    onClick={() => handleSubmitComment('textImage', values.comment)}
                    type="button"
                    // disabled={values.cardID ? false : true}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
              <div className={`${styles.width100} ${styles.newRow}`}>
                {values.imageUri.length !== 0
                  ? values.imageUri.map((data: any, index: number) => (
                      <img key={index} src={data} width="70" height="70" />
                    ))
                  : ''}
              </div>
              {componantChildComment()}
            </>
          )}
          {selectedKey == 1 && (
            <>
              <div className={`${styles.width100} ${styles.newRow}`}>{componantChildComment()}</div>
            </>
          )}
          {selectedKey == 2 && (
            <>
              <div className={`${styles.width100} ${styles.newRow}`}>{componantChildComment()}</div>
            </>
          )}
          {selectedKey == 3 && (
            <>
              <div className={`${styles.width100} ${styles.newRow}`}>{componantChildComment()}</div>
            </>
          )}
          {selectedKey == 4 && (
            <>
              <div className={`${styles.width100} ${styles.newRow}`}>{componantChildComment()}</div>
            </>
          )}
          {selectedKey == 5 && (
            <>
              <div className={`${styles.width100} ${styles.newRow}`}>{componantChildComment()}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
