import React from 'react'
import { Link } from 'react-router-dom'

import { Text, ITextProps } from 'office-ui-fabric-react'

import styles from './index.module.scss'
import menuItems from './menuItems'
import AddSVG from './add.svg'
import ListSVG from './list.svg'

import parse from 'html-react-parser'
import DocumentTitle from '../common/document-title'
import axios from 'axios'
import { urlApi } from '../common/configUrlApi'

import useTokenInitializer from '../../hooks/token-initializer'

const InventoryStockPage: React.FC = (props: any) => {
  const iconList: { [key: string]: string } = {
    AddSVG: AddSVG,
    ListSVG: ListSVG,
  }
  DocumentTitle('Inventory', 'เช็ค Stock')
  useTokenInitializer()

  const createDocPhysicalCountRefPO = () => {
    // Where to put the query
    const q = `mutation ($pi: Int, $mr: Int) {
      document: createOrEditPhysicalCount {
        id
        internalDocumentId
        createdAt
        updatedAt
        documentTypeId
        documentStatusId
        remark
        lastScanAt
        lineItem(pageIndex: $pi, maxResults: $mr) {
          totalCount
          pageIndex
          maxResults
          results {
            id
            skuId
            lotNumber
            runningNumber
            qty
            updatedAt
            createdAt
            uomId
            packId
            scanBy
            scanAt
          }
        }
      }
    }
    `

    const v = {
      pi: 0,
      mr: 99999,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        // console.log('post doc', result.data.data)
        props.history.push(`/inventory/stock/${result.data.data.document.id}`)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }

  return (
    <section className={styles.section1}>
      <h1 className={styles.headerTitle}>เช็ค Stock</h1>
      <p className={styles.subHeaderTitle}>กรุณาเลือกรายการ</p>
      <div className={styles.menuItemsWrapper}>
        {menuItems.map(({ name, imgName, linkTo, fuction }, index) => {
          if (fuction) {
            return (
              <button
                key={index}
                style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}
                onClick={() => createDocPhysicalCountRefPO()}
              >
                <img src={iconList[imgName]} />
                <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
                  {parse(name)}
                </Text>
              </button>
            )
          }
          return (
            <Link key={index} to={linkTo}>
              <button key={index} style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}>
                <img src={iconList[imgName]} />
                <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
                  {parse(name)}
                </Text>
              </button>
            </Link>
          )
        })}
      </div>
    </section>
  )
}

export default InventoryStockPage
