import React, { useEffect } from 'react'
import { useFormikContext, withFormik, Field, Form } from 'formik'
import styles from './index.module.scss'
import DocumentTitle from '../../inventory/common/document-title'
import axios from 'axios'
import { urlApiHr } from '../common/configApiHr'
import validateIgnoreField from '../../common/validateIgnoreField'
import ModalAlert from '../common/modal'

import useTokenInitializer from '../../hooks/token-initializer'

const HrDivisionIDPage: React.FC = (props: any) => {
  DocumentTitle('Hr', 'จัดการตำแหน่ง')
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  useTokenInitializer()
  useEffect(() => {
    setFieldValue('division', props.location.state.division, false)
    setFieldValue('divisionName', props.location.state.divisionName, false)
    if (props.match.params.id === 'add') return

    setFieldValue('positionID', props.match.params.id, false)
    setFieldValue('name', props.location.state.name, false)
  }, [])

  return (
    <Form>
      <section className={styles.section1}>
        <div className={`loader loader-default ${values.loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>เพิ่ม/แก้ไขตำแหน่ง</h1>
        <span className={styles.subHeaderTitle}>แผนก{values.division}</span>
      </section>
      <section className={styles.section2}>
        <p className={styles.subHeaderTitle}>ชื่อตำแหน่ง</p>
        <div>
          <Field
            name="name"
            className="inputForSearch"
            type="text"
            placeholder="ชื่อตำแหน่ง"
            style={{
              maxWidth: '300px',
              ...(touched?.name && errors?.name && { border: '1px solid red' }),
            }}
          />
        </div>
        <button type="button" className="styleBtn" onClick={() => props.history.goBack()}>
          ย้อนกลับ
        </button>
        <button type="submit" className="styleBtn">
          บันทึก
        </button>
      </section>
      {ModalAlert(values.isModalOpen, values.successSubmit, setFieldValue)}
    </Form>
  )
}

const EnhancedHrDivisionIDPage = withFormik({
  mapPropsToValues: () => ({
    loading: false,
    division: '',
    divisionName: '',
    positionID: '',
    name: '',

    // Modal
    isModalOpen: false,
    successSubmit: '',
  }),
  validate: (values) => {
    return validateIgnoreField(
      values,
      ['loading', 'division', 'divisionName', 'positionID', 'isModalOpen', 'successSubmit'],
      '*',
    )
  },
  handleSubmit: (values, { setFieldValue }) => {
    const qEditPosition = `mutation($input: PositionEdit!) {
      editPosition(input: $input) {
        positionId
      }
    }`

    // Where to put the variables
    const vEditPosition = {
      input: {
        positionId: values.positionID,
        divisionName: values.divisionName,
        name: values.name,
      },
    }

    const qAddPosition = `mutation($input: PositionAdd!) {
      addPosition(input: $input) {
        positionId
      }
    }`

    // Where to put the variables
    const vAddPosition = {
      input: {
        divisionName: values.divisionName,
        name: values.name,
      },
    }

    axios({
      url: urlApiHr,
      method: 'post',
      data: {
        query: values.positionID ? qEditPosition : qAddPosition,
        variables: values.positionID ? vEditPosition : vAddPosition,
      },
    })
      .then((result) => {
        console.log(result)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'เพิ่มสำเร็จ', false)
      })
      .catch((err) => {
        console.log('err', err.response)
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
        setFieldValue('isModalOpen', true, false)
      })
  },
})(HrDivisionIDPage)

export default EnhancedHrDivisionIDPage
