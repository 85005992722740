import React, { useEffect, useState } from 'react'
import { useBoolean } from '@uifabric/react-hooks'
import styles from './index.module.scss'
import { urlApi } from '../common/configUrlApi'
import SummaryTopTableComponant from '../common/summaryTopTable'
import { serialNumberComplete } from '../common/serialNumberComplete'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import ModalAlert from '../common/modalNotFormik'
import GroupBtnDocument from '../common/groupBtnDocuemnt'
import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'
import { FormatDateTime } from '../../common/dateFormat'

import EnhancedTable from '../../common/table/item/paginationByFront/firstColBtnandInput'
import { HeadCell } from '../../common/table/config'

export interface ILineItems {
  lineNumber: number
  itemID: string
  pack: string
  quantity: number
  quantityPerPack: number
  status: string
  by: string
  timeStamp: string
  description: string
}

const InventoryIssueRefSOPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)

  const [rawData, setRawData] = useState<any>([])
  const [documentID, setDocumentID] = useState('')
  const [internalDocumentID, setInternalDocumentID] = useState('')
  const [documentRef, setDocumentRef] = useState('')
  const [documentStatusId, setDocumentStatusId] = useState('')
  const [lineItemsOld, setLineItemsOld] = useState<(string | ILineItems)[]>([])
  const [lineItems, setLineItems] = useState<(string | ILineItems)[]>([])

  const [summaryTopTable, setSummaryTopTable] = useState<any>([])

  const [correctSN, setCorrectSN] = useState<string>('')

  const [actionIput, setActionIput] = useState(false)
  const [fetchData, setFetchData] = useState<number>(0)
  const [fetchData2, setFetchData2] = useState<number>(0)

  const [successSubmit, setSuccessSubmit] = useState('')
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  const [inputSerialNumber, setInputSerialNumber] = useState('')

  DocumentTitle('Inventory', 'นำสินค้าออก')

  useEffect(() => {
    const q = `query viewGoodIssue($documentId: ID!) {
      viewGoodIssue(id: $documentId) {
        id
        internalDocumentId
        documentTypeId
        documentStatusId
        salesOrderDocumentId
        salesOrder {
          internalDocumentId
        }
        lineItem {
          id
          skuId
          qty
          uomId
          packId
          lineNumber
          sku {
            name
            productCode
            product {
              name
            }
            qtyPerPack(packId: 1)  
            onHandStatistic {
              available
              ordered
              inStock
              committed
            }
          }
          packLineItem {
            id
            skuId
            runningNumber
            serialNumber
            qty
            uomId
            packId
            scanBy
            scanAt
            voided
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      documentId: props.match.params.id,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const viewGoodIssue = result.data.data.viewGoodIssue
        const listMapper: any[] = []
        viewGoodIssue.lineItem.map((data: any) => {
          const mapper = {
            lineNumber: data.lineNumber,
            itemID: `${data.skuId}`,
            pack: data.qty,
            piecePerPack: data.sku.qtyPerPack,
            total: data.sku.onHandStatistic.available - data.sku.onHandStatistic.committed,
            stock: data.sku.onHandStatistic.inStock,
            packLineItem: data.packLineItem,
            productCode: data.sku.productCode,
            description: data.sku.product.name,
          }
          listMapper.push(mapper)
        })
        // // console.log('listMapper', listMapper)
        setLoading(false)
        setRawData(viewGoodIssue)
        setLineItemsOld(listMapper)
        setDocumentID(viewGoodIssue.id)
        setInternalDocumentID(viewGoodIssue.internalDocumentId)
        setDocumentStatusId(viewGoodIssue.documentStatusId)
        setDocumentRef(viewGoodIssue.salesOrder ? viewGoodIssue.salesOrder.internalDocumentId : '')
        setFetchData2(fetchData2 + 1)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [fetchData])

  useEffect(() => {
    const packSKU: {
      packList: number
      totalPiece: number
      totalPieceAndPack: number
      totalPackReceive: number
    }[] = []

    const newLineItems: any = []
    lineItemsOld.map((data: any) => {
      const newLinePacks: any = []
      let countQty = 0

      let totalPiece = 0 //For summaryTopTable
      let totalPieceAndPack = 0 //For summaryTopTable
      let totalPackReceive = 0

      totalPieceAndPack = totalPieceAndPack + data.piecePerPack * data.pack

      data.packLineItem.length > 0 &&
        data.packLineItem.map((data2: any) => {
          countQty = countQty + data2.qty
          const mapper = {
            id: `${data2.serialNumber}`,
            itemID: `${data.productCode}`,
            pack: data2.runningNumber,
            status: data2.scanBy ? 'สแกนแล้ว' : 'รอสแกน',
            quantity: data2.qty ? data2.qty : 0,
            quantityPerPack: data.piecePerPack,
            total: data.total,
            stock: data.stock,
            serialNumber: `${data2.serialNumber}`,
            by: data2.scanBy,
            timeStamp: data2.scanAt ? FormatDateTime(data2.scanAt) : '',
          }
          totalPiece = totalPiece + data2.qty
          totalPackReceive = totalPackReceive + 1
          newLinePacks.push(mapper)
        })

      packSKU.push({
        packList: data.pack,
        totalPiece: totalPiece,
        totalPieceAndPack: totalPieceAndPack,
        totalPackReceive: totalPackReceive,
      })

      newLineItems.push(
        `รหัส ${data.productCode} - ${data.description} - ${Math.ceil(countQty / (totalPieceAndPack / data.pack))}/${
          data.pack
        } ห่อ = ${countQty}/${totalPieceAndPack} แผ่น`,
      )
      newLineItems.push(...newLinePacks)
    })
    console.log('packSKU', packSKU)
    setSummaryTopTable(packSKU)
    setLineItems(newLineItems)
    setActionIput(false)
  }, [actionIput, documentID, fetchData2])

  const [fullname, setFullname] = useState('')
  const token = useSelector((state: any) => state.token)

  useTokenInitializer()

  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
  }, [token.name])
  console.log('lineItemsOld', lineItemsOld)
  console.log('lineItem', lineItems)
  const onChangequantityLineItems = (index: any, value: number) => {
    if (value === NaN) {
      // Just in-case a non-number gets in
      // But Nuk already make input cannot be character other than digits
      return
    }

    const clonesLineItems = [...lineItems]

    const lineItem = clonesLineItems[index]
    if (typeof lineItem === 'string') {
      return
    }
    lineItem.quantity = value ? value : 0
    clonesLineItems.splice(index, 1, lineItem)
    setLineItems(clonesLineItems)
    setActionIput(true)
  }

  const handleRemoveLineItem = (index: any) => {
    const clonesLineItems: any = [...lineItems]
    console.log('clonesLineItems', clonesLineItems)

    let lineNumberIndex = 0
    clonesLineItems.some((data: any, indexCloneLineItem: any) => {
      if (index == indexCloneLineItem) {
        return true
      } else if (typeof data === 'string') {
        lineNumberIndex = lineNumberIndex + 1
        return false
      } else {
        return false
      }
    })

    const q = `mutation ($sn: String!, $ln: Int!, $docId: Int!) {
      goodIssueRemoveSerialNumber(
        goodIssueDocumentId: $docId,
        serialNumber: $sn,
        lineNumber: $ln
      ) {
        id
        documentTypeId
        documentStatusId
        referenceNumber
        remark
        createdAt
        updatedAt
        salesOrderDocumentId
        lineItem {
          id
          lineNumber
          skuId
          qty
          uomId
          packId
          packLineItem {
            id
            skuId
            runningNumber
            qty
            uomId
            packId
            scanBy
            scanAt
            voided
            serialNumber
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      sn: clonesLineItems[index].id,
      docId: parseInt(documentID),
      ln: lineNumberIndex,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('res', result)
        clonesLineItems.splice(index, 1)
        setLineItems(clonesLineItems)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }

  // For scan S/N
  const onChangeSerialNumber = (value: string) => {
    // serialNumberComplete(value)
    setInputSerialNumber(value)
    if (value === '') {
      setCorrectSN('')
      return
    } else if (serialNumberComplete(value) !== 0) {
      // Serial number not correct
      setCorrectSN('S/N ไม่ถูกต้อง')
      return
    } else {
      setCorrectSN('')
      // onClickSaveDoc()
      const q = `query serialNumberStatistic($serialNumber: String!) {
        serialNumberStatistic(serialNumber: $serialNumber) {
          skuId
          sku {
            id
            name
            productCode
            profile
            color {
              id
              code
              name
            }
            spec
            surface {
              id
              code
              name
            }
          }
          qty
          uomId
          packId
        }
      }`
      // Where to put the variables
      const v = {
        serialNumber: value,
      }
      axios({
        url: urlApi,
        method: 'post',
        data: {
          query: q,
          variables: v,
        },
      })
        .then((result) => {
          const serialNumberStatistic = result.data.data.serialNumberStatistic
          const matchProductCode = rawData.lineItem.find(
            (product: any) => `${product.sku.productCode}` === `${serialNumberStatistic.sku.productCode}`,
          )
          if (matchProductCode) {
            const q2 = `mutation goodIssueScanSerialNumberToUse(
              $goodIssueDocumentId: Int!,
              $lineNumber: Int!
              $serialNumber: String!,
              $userUid: String!
            ) {
              goodIssueScanSerialNumberToUse(
                goodIssueDocumentId: $goodIssueDocumentId, 
                lineNumber: $lineNumber, 
                serialNumber: $serialNumber, 
                userUid: $userUid
              ) {
                id
                documentTypeId
                documentStatusId
                referenceNumber
                remark
                createdAt
                updatedAt
                salesOrderDocumentId
                lineItem {
                  id
                  lineNumber
                  skuId
                  qty
                  uomId
                  packId
                  packLineItem {
                    id
                    skuId
                    runningNumber
                    qty
                    uomId
                    packId
                    scanBy
                    scanAt
                    voided
                    serialNumber
                  }
                }
              }
            }
            `
            // Where to put the variables
            const v2 = {
              goodIssueDocumentId: parseInt(documentID),
              lineNumber: matchProductCode.lineNumber,
              serialNumber: value,
              userUid: fullname,
            }
            console.log('v2', v2)
            // {
            //   userUid: '54616d616b692d6b756e',
            //   goodIssueDocumentId: parseInt(documentID),
            //   serialNumberList: [
            //     {
            //       serialNumber: value,
            //       lineNumber: matchProductCode.lineNumber,
            //       qty: serialNumberStatistic.qty,
            //       uomId: parseInt(serialNumberStatistic.uomId),
            //     },
            //   ],
            // }
            axios({
              url: urlApi,
              method: 'post',
              data: {
                query: q2,
                variables: v2,
              },
            })
              .then((result2) => {
                setFetchData(fetchData + 1)
                setInputSerialNumber('')
              })
              .catch((err2) => {
                console.log('err2', err2.response)
                setCorrectSN(`${err2.response.data.errors[0].message}`)
              })
          }
        })
        .catch((err) => {
          console.log('err', err.response)
          setCorrectSN('S/N ไม่มีในระบบ')
        })
    }
  }

  // For save qty
  const onClickSaveDoc: any = () => {
    const prepairSna: any = []

    let lineNumberIndex = 0
    lineItems.map((data: any) => {
      if (typeof data === 'string') {
        return (lineNumberIndex = lineNumberIndex + 1)
      } else {
        prepairSna.push({
          serialNumber: data.id,
          lineNumber: lineNumberIndex,
          qty: data.quantity,
          uomId: 2,
        })
      }
    })

    const q = `mutation scanSerialNumberToIssue($goodIssueDocumentId: Int!, $userUid: String!, $serialNumberList: [goodIssueScanSerialNumber!]!) {
      goodIssueScanSerialNumber(goodIssueDocumentId: $goodIssueDocumentId, userUid: $userUid, serialNumberList: $serialNumberList) {
        id
        documentTypeId
        documentStatusId
        salesOrderDocumentId
        lineItem {
          id
          skuId
          qty
          uomId
          packId
          packLineItem {
            id
            skuId
            runningNumber
            qty
            uomId
            packId
            scanBy
            scanAt
            voided
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      userUid: fullname,
      goodIssueDocumentId: parseInt(documentID),
      serialNumberList: prepairSna,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('result', result)
        setFetchData(fetchData + 1)
        showModal()
        setSuccessSubmit('สำเร็จ')
      })
      .catch((err) => {
        console.log('err', err)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
      })
  }

  const onClickSendDoc = () => {
    onClickSaveDoc()
    const q = `mutation submitGoodIssue($documentId: ID!) {
      submitGoodIssue(id: $documentId) {
        id
        documentTypeId
        documentStatusId
        salesOrderDocumentId
        lineItem {
          id
          skuId
          qty
          uomId
          packId
          packLineItem {
            id
            skuId
            runningNumber
            qty
            uomId
            packId
            scanBy
            scanAt
            voided
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      documentId: documentID,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      console.log('result', result)
      showModal()
      setSuccessSubmit('สำเร็จ')
    })
  }

  const onClickRemoveDoc = () => {
    const q = `mutation goodIssueRemove($id: ID!) {
      goodIssueRemove(id: $id) {
        id
      }
    }`
    // Where to put the variables
    const v = {
      id: documentID,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        // console.log('result', result)
        showModal()
        setSuccessSubmit('สำเร็จ')
        props.history.goBack()
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
      })
  }

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'delete', numeric: false, disablePadding: false, label: 'ลบ' },
    { id: 'createAt', numeric: false, disablePadding: false, label: 'รหัสสินค้า' },
    { id: 'Pack', numeric: false, disablePadding: false, label: 'Pack' },
    { id: 'scan', numeric: false, disablePadding: false, label: 'สแกน' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'จำนวนแผ่น' },
    { id: 'qtyperpack', numeric: false, disablePadding: false, label: 'แผ่น/ห่อ' },
    { id: 'totle', numeric: false, disablePadding: false, label: 'คงเหลือสุทธิ' },
    { id: 'stock', numeric: false, disablePadding: false, label: 'คงคลัง' },
    { id: 'sn', numeric: false, disablePadding: false, label: 'S/N' },
    { id: 'by', numeric: false, disablePadding: false, label: 'โดย' },
    { id: 'date', numeric: false, disablePadding: false, label: 'เมื่อ' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  return (
    <>
      <div className={styles.pageWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>นำสินค้าออก</h1>
        <p>
          ใบนำสินค้าออก {internalDocumentID} - อ้างอิง {documentRef} - {documentStatusId}
        </p>
        <p className="errMsg">{correctSN ? correctSN : ''}</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="Serial Number"
          value={inputSerialNumber}
          onChange={(e) => {
            onChangeSerialNumber(e.target.value)
          }}
          style={{ maxWidth: '385.6px' }}
          autoFocus
        />

        <p style={{ margin: '15px 0 0' }}>วันที่นำออก</p>
        <input
          type="date"
          className="inputForSearch"
          placeholder="วันที่นำออก"
          style={{ maxWidth: '386.5px', display: 'flex' }}
        />
        <p style={{ margin: '15px 0 0' }}>ประเภทการนำออก</p>
        <select
          name="type"
          className={`${styles.selectFilter} ${styles.hiddenInDesktop}`}
          style={{ maxWidth: '385.6px', margin: '10px 10px 0 0' }}
        >
          <option value="all">ประเภท</option>
          <option value="ขาย">ขาย</option>
          <option value="โอนออก">โอนออก</option>
          <option value="ส่งคืน">ส่งคืน</option>
          <option value="จ่ายอื่นๆ">จ่ายอื่นๆ</option>
        </select>
      </div>
      <SummaryTopTableComponant summaryTopTable={summaryTopTable} />
      <section className={`tableListMui`}>
        <EnhancedTable
          colHead={headCells}
          rows={lineItems}
          onChangequantityLineItems={onChangequantityLineItems}
          handleRemoveLineItem={handleRemoveLineItem}
          removeButtonClassUniqueSKU={true}
          type="issueRefSo"
        />
      </section>
      <section className={styles.groupbtn}>
        <GroupBtnDocument
          onClickSendDoc={onClickSendDoc}
          onClickSaveDoc={onClickSaveDoc}
          onClickRemoveDoc={onClickRemoveDoc}
          documentStatusId={documentStatusId}
          linkGoBack="/inventory/issue"
        />
      </section>
      {ModalAlert(isModalOpen, hideModal, successSubmit)}
    </>
  )
}

export default InventoryIssueRefSOPage
