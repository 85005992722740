interface IMenuItem {
  name: string
  imgName: string
  linkTo: string
}
const menuItems: IMenuItem[] = [
  { name: 'เลือก Module', imgName: 'HomeModuleSVG', linkTo: '/' },
  { name: 'เรียกดูสินค้า', imgName: 'SearchSVG', linkTo: '/inventory/item-master-data' },
  { name: 'นำสินค้าเข้า', imgName: 'AddSVG', linkTo: '/inventory/receive' },
  { name: 'นำสินค้าออก', imgName: 'RemoveSVG', linkTo: '/inventory/issue' },
  { name: 'เช็ค Stock', imgName: 'CheckStockSVG', linkTo: '/inventory/stock' },
  { name: 'การอนุมัติ', imgName: 'ApproveSVG', linkTo: '/inventory/approve' },
  { name: 'การส่งสินค้า', imgName: 'DeliveryNoteVG', linkTo: '/inventory/delivery-note' },
  { name: 'ดูข้อมูล <br/>Purchase Order', imgName: 'PurchaseSVG', linkTo: '/inventory/purchase-orders' },
  { name: 'ดูข้อมูล <br/>Sale Order', imgName: 'SaleOrderSVG', linkTo: '/inventory/sale-orders' },
  { name: '', imgName: '', linkTo: '' },
]

export default menuItems
