import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom'

import DocumentTitle from '../common/document-title'

import { useBoolean } from '@uifabric/react-hooks'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from '../../common/configAws'
import { urlApi } from '../common/configUrlApi'
import ModalAlert from '../common/modalNotFormik'
import useTokenInitializer from '../../hooks/token-initializer'

const InventoryItemMasterDataListAddPage: React.FC = () => {
  const [ID, setID] = useState<string>('')
  const [productCode, setProductCode] = useState('')
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [profile, setProfile] = useState('')
  const [color, setColor] = useState('')

  const [width, setWidth] = useState('')
  const [lenght, setLenght] = useState('')
  const [depth, setDepth] = useState('')

  const [piecePerP, setPiecePerP] = useState('')
  const [weightPerM, setWeightPerM] = useState('')
  const [pricePerM, setPricePerM] = useState('')

  const [finish, setFinish] = useState('')
  const [imageUri, setImageUri] = useState<string>('')
  const [imageUri2D, setImageUri2D] = useState<string>('')

  const [listColor, setListColor] = useState([])
  const [listProduct, setListProduct] = useState([])
  const [listSurface, setListSurface] = useState([])
  const [listType, setListType] = useState([])

  const [successSubmit, setSuccessSubmit] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  DocumentTitle('Inventory', 'เรียกดูสินค้า')
  useTokenInitializer()

  useEffect(() => {
    // Where to put the query
    const q = `query viewSkuPropertyAllListType($pgIdx: Int, $pgSz: Int) {
      viewSkuPropertyAll {
        listType(pageIndex: $pgIdx, maxResults: $pgSz) {
          totalCount
          pageIndex
          results {
            id
            code
            name
          }
        }
        listColor(pageIndex: 0, maxResults: 9999) {
          totalCount
          pageIndex
          results {
            id
            code
            name
          }
        }
        listProduct(pageIndex: 0, maxResults: 9999) {
          totalCount
          pageIndex
          results {
            id
            code
            name
          }
        }
        listSurface(pageIndex: 0, maxResults: 9999) {
          totalCount
          pageIndex
          results {
            id
            code
            name
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      pgIdx: 0,
      pgSz: 9999,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const viewSkuPropertyAll = result.data.data.viewSkuPropertyAll
        setListColor(viewSkuPropertyAll.listColor.results)
        setListProduct(viewSkuPropertyAll.listProduct.results)
        setListSurface(viewSkuPropertyAll.listSurface.results)
        setListType(viewSkuPropertyAll.listType.results)
      })
      .catch((err) => console.log(err.response))
  }, [])

  const handleSubmit = () => {
    const q = `mutation addSku($input: NewSku!) {
      addSku(input: $input) {
        id
        isActive
        name
        productCode
        profile
        productId
        colorId
        surfaceId
        typeId
      }
    }`

    const findProduct: any = listProduct.find((ele: any) => ele.name === code)
    // Where to put the variables
    const v = {
      input: {
        name: name,
        productId: findProduct ? findProduct.id : code,
        surfaceId: finish,
        colorId: color,
        typeId: profile,
        spec: `${depth}x${width}x${lenght}`,
        length: parseInt(lenght),
        width: parseInt(width),
        depth: parseInt(depth),
        qtyPerPack: parseFloat(piecePerP),
        pricePerMeter: parseFloat(pricePerM),
        kilogramPerMeter: parseFloat(weightPerM),
        uomGroupId: 1,
        packGroupId: 1,
        profile: 'any',
      },
    }
    console.log(JSON.stringify(v))
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((res) => {
        setID(res.data.data.addSku.id)
        setProductCode(res.data.data.addSku.productCode)
        showModal()
        setSuccessSubmit('เพิ่มสำเร็จ')
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
        if (err.response.data.errors[0].message === `Unexpected error value: "sku.productCode_UNIQUE must be unique"`) {
          console.log('>>>>')
          setErrMsg('รหัสสินค้าซ้ำ')
        }
        if (err.response.data.errors[0].message === `Product with such name already exists!`) {
          setErrMsg('ชื่อสินค้าซ้ำ')
        }
      })
  }

  const handleUploadImage = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })
    const file: any = e.target.files[0]
    if (file.length !== 0) {
      const albumPhotosKey = encodeURIComponent('ItemMasterData') + '/'
      const photoKey = albumPhotosKey + ID
      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })
      const promise = upload.promise()
      promise.then(
        function (data) {
          alert('Successfully uploaded photo.')
          setImageUri(data.Location)
          const q = `mutation editSku($input: EditSku!) {
            editSku(input: $input) {
              id
              uri
            }
          }`
          // Where to put the variables
          const v = {
            input: {
              id: ID,
              uri: data.Location,
            },
          }
          axios({
            url: urlApi,
            method: 'post',
            data: {
              query: q,
              variables: v,
            },
          })
            .then((res) => {
              console.log(res)
            })
            .catch((err) => {
              console.log(err)
            })
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  const handleUploadImage2D = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]

    if (file.length !== 0) {
      const albumPhotosKey = encodeURIComponent('ItemMasterData') + '/'

      const photoKey = albumPhotosKey + ID + '2D'

      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })

      const promise = upload.promise()

      promise.then(
        function (data) {
          alert('Successfully uploaded photo.')
          setImageUri2D(data.Location)

          const q = `mutation editSku($input: EditSku!) {
            editSku(input: $input) {
              id
              uri
            }
          }`
          // Where to put the variables
          const v = {
            input: {
              id: ID,
              uri2D: data.Location,
            },
          }

          axios({
            url: urlApi,
            method: 'post',
            data: {
              query: q,
              variables: v,
            },
          })
            .then((res) => {
              console.log(res)
            })
            .catch((err) => {
              console.log(err)
            })
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  return (
    <>
      <div className={styles.pageWrapper}>
        <h1 className={styles.headerTitle}>เรียกดูสินค้า</h1>
        <section className={styles.sectionItem}>
          <div>
            <h4 style={{ margin: '0 0 10px 0' }}>รูปภาพสินค้า</h4>
            <div className={styles.skuImage}>
              <img src={imageUri ? imageUri : 'https://fakeimg.pl/190x150'} alt="SKU Image" width="190" height="150" />
              <input
                type="file"
                id="file"
                onChange={(e) => handleUploadImage(e)}
                accept="image/*"
                disabled={ID ? false : true}
              />
              <label htmlFor="file" className={`${styles.buttonUploadFile}`}>
                เลือกรูปภาพ
              </label>
            </div>
            <h4 style={{ margin: '10px 0' }}>รูปภาพ 2D</h4>
            <div className={styles.skuImage}>
              <img
                src={imageUri2D ? imageUri2D : 'https://fakeimg.pl/190x150'}
                alt="SKU Image"
                width="190"
                height="150"
              />
              <input
                type="file"
                id="file2"
                onChange={(e) => handleUploadImage2D(e)}
                accept="image/*"
                disabled={ID ? false : true}
              />
              <label htmlFor="file2" className={`${styles.buttonUploadFile}`}>
                เลือกรูปภาพ
              </label>
            </div>
          </div>

          <div>
            {/* <h4 style={{ margin: '10px 0 0' }}>ชื่อสินค้า</h4>
            <input
              type="text"
              className="inputForSearch"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input> */}
            <div className={styles.groupInput}>
              <div className={styles.colInput}>
                <h4>รายละเอียด</h4>
                <input
                  list="browsers"
                  name="browser"
                  id="browser"
                  className="inputForSearch"
                  onChange={(e) => setCode(e.target.value)}
                />

                <datalist id="browsers">
                  {listProduct.map((data: any, index) => (
                    <option key={index} value={data.name}>
                      {data.name}
                    </option>
                  ))}
                </datalist>
                {/* <select className={styles.select} name="code" id="code" onChange={(e) => setCode(e.target.value)}>
                  <option value=""></option>
                  {listProduct.map((data: any, index) => (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select> */}
                <h4>สี</h4>
                <select className={styles.select} name="color" id="color" onChange={(e) => setColor(e.target.value)}>
                  <option value=""></option>
                  {listColor.map((data: any, index) => (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <h4>กว้าง</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                ></input>
                <h4>ยาว</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={lenght}
                  onChange={(e) => setLenght(e.target.value)}
                ></input>
                <h4>น้ำหนักต่อเมตร</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={weightPerM}
                  onChange={(e) => setWeightPerM(e.target.value)}
                ></input>
              </div>
              <div className={styles.colInput}>
                <h4>ประเภท</h4>
                <select
                  className={styles.select}
                  name="profile"
                  id="profile"
                  onChange={(e) => setProfile(e.target.value)}
                >
                  <option value=""></option>
                  {listType.map((data: any, index) => (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <h4>ผิว</h4>
                <select className={styles.select} name="finish" id="finish" onChange={(e) => setFinish(e.target.value)}>
                  <option value=""></option>
                  {listSurface.map((data: any, index) => (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <h4>หนา</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={depth}
                  onChange={(e) => setDepth(e.target.value)}
                ></input>
                <h4>จำนวนแผ่นต่อห่อ</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={piecePerP}
                  onChange={(e) => setPiecePerP(e.target.value)}
                ></input>
                <h4>ราคาต่อเมตร</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={pricePerM}
                  onChange={(e) => setPricePerM(e.target.value)}
                ></input>
              </div>
            </div>
            <h4 style={{ margin: '10px 0 0' }}>รหัสสินค้า</h4>
            <input type="text" className="inputForSearch" value={productCode} disabled></input>
          </div>
        </section>
      </div>
      <section className={styles.groupButtonDestop}>
        <button type="button" className="styleBtn" onClick={() => handleSubmit()} disabled={!ID ? false : true}>
          บันทึก
        </button>
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/item-master-data">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
      {ModalAlert(isModalOpen, hideModal, successSubmit, errMsg)}
    </>
  )
}

export default InventoryItemMasterDataListAddPage
