import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import styles from './index.module.scss'
import DocumentTitle from '../common/document-title'
import { useFormikContext, withFormik, Form, Field } from 'formik'

import validateIgnoreField from '../../common/validateIgnoreField'
import axios from 'axios'
import ModalAlert from '../common/modal'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from '../../common/configAws'
import { urlApi } from '../common/configUrlApi'

import useTokenInitializer from '../../hooks/token-initializer'

const InventoryDeliveryNoteDetailAddPage: React.FC = (props: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const [loading, setLoading] = useState<any>(true)

  DocumentTitle('Inventory', 'การส่งสินค้า')
  useTokenInitializer()

  // set rawDataFromProps and selected after load page
  useEffect(() => {
    const rawDataFromProps: any = []
    console.log('props.location.state', props.location.state)
    props.location.state.map((data: any) => {
      const mapper = {
        id: data.id,
        internalDocumentId: data.internalDocumentId,
        remark: '',
        receiveGoodAt: '',
        appointReceiveAt: '',
        receiverName: '',
        phoneNumber: '',
        googleMap: '',
        fullAddress: '',
        otherRemark: '',
        status: 'รอดำเนินการ',
        listImageUri: [],
        goodIssueInternalDocumentId: data.goodIssueInternalDocumentId,
      }
      rawDataFromProps.push(mapper)
    })
    setFieldValue('rawDataFromProps', rawDataFromProps, false)
    setLoading(false)
  }, [])

  // set Field values after click change card
  useEffect(() => {
    const data = values.rawDataFromProps[values.selected]
    console.log('data', data)
    if (data) {
      setLoading(false)
      setFieldValue('id', data.id, false)
      setFieldValue('receiveGoodAt', data.receiveGoodAt, false)
      setFieldValue('appointReceiveAt', data.appointReceiveAt, false)
      setFieldValue('receiverName', data.receiverName, false)
      setFieldValue('phoneNumber', data.phoneNumber, false)
      setFieldValue('googleMap', data.googleMap, false)
      setFieldValue('fullAddress', data.fullAddress, false)
      setFieldValue('remark', data.remark, false)
      setFieldValue('listImageUri', data.listImageUri, false)
    } else {
      return
    }
  }, [values.selected, values.rawDataFromProps])

  const handleSubmit = () => {
    const q = `mutation ($input: [ID!]!) {
      deliveryOrderSetSubmit(input: $input) {
        id
      }
    }`
    const listID: number[] = []
    values.rawDataFromProps.map((data: any) => {
      listID.push(data.id)
    })
    // Where to put the variables
    const v = {
      input: listID,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('result', result)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'สำเร็จ', false)
      })
      .catch((err) => {
        console.log('err', err.response)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
      })
  }

  const handleUploadImage = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]

    if (file.length !== 0) {
      const fileName = file.name
      const albumPhotosKey = encodeURIComponent('deliveryNote') + '/' + encodeURIComponent(`${values.id}`) + '/'

      const photoKey = albumPhotosKey + fileName

      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })

      const promise = upload.promise()
      const tzoffset = new Date().getTimezoneOffset() * 60000
      const localISOTime = new Date(Date.now() - tzoffset).toISOString()
      promise.then(
        function (data) {
          alert('Successfully uploaded photo.')
          const mapper = {
            imageUri: data.Location,
            createdAt: localISOTime,
          }
          setFieldValue('listImageUri', [...values.listImageUri, mapper], false)
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  if (!props.location.state) {
    return <Redirect to="/inventory/delivery-note/list/order" />
  }
  return (
    <Form>
      <section className={styles.headerWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>การส่งสินค้า</h1>
        <p className={styles.subHeaderTitle}>กรุณาเลือกรายการ</p>
      </section>
      <section className={styles.cards}>
        {values.rawDataFromProps.map((data: any, index: any) => (
          <article
            className={`${styles.card} ${values.selected === index ? styles.active : ''}`}
            key={data.id}
            onClick={() => setFieldValue('selected', index, false)}
          >
            <h4>รหัสการนำออก</h4>
            <p>{data.internalDocumentId}</p>
            <p className={data.status === 'รอดำเนินการ' ? styles.statusWaiting : styles.statusDone}>{data.status}</p>
          </article>
        ))}
      </section>
      <section className={styles.bodyWrapper}>
        <p>
          <b>รายการนำออก {values.rawDataFromProps[values.selected]?.internalDocumentId}</b>
        </p>
        <p>อ้างอิง {values.rawDataFromProps[values.selected]?.goodIssueInternalDocumentId}</p>
        <p style={{ marginBottom: '10px' }}>ชื่อลูกค้า ABCD EFGH</p>
        <div className={styles.flexWrap}>
          <Field
            name="receiveGoodAt"
            className="inputForSearch"
            type="datetime-local"
            placeholder="วันที่ เวลา รับสินค้า"
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              ...(touched?.receiveGoodAt && errors?.receiveGoodAt && { border: '1px solid red' }),
            }}
          />
          <Field
            name="appointReceiveAt"
            className="inputForSearch"
            type="datetime-local"
            placeholder="วันที่ เวลา นัดรับ"
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              ...(touched?.appointReceiveAt && errors?.appointReceiveAt && { border: '1px solid red' }),
            }}
          />
        </div>
        <div className={styles.flexWrap}>
          <Field
            name="receiverName"
            className="inputForSearch"
            type="text"
            placeholder="ชื่อผู้รับ"
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              ...(touched?.receiverName && errors?.receiverName && { border: '1px solid red' }),
            }}
          />
          <Field
            name="phoneNumber"
            className="inputForSearch"
            type="string"
            placeholder="เบอร์โทรศัพท์"
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              ...(touched?.phoneNumber && errors?.phoneNumber && { border: '1px solid red' }),
            }}
          />
          <div style={{ display: 'flex', width: '-webkit-fill-available' }}>
            <Field
              name="googleMap"
              className="inputForSearch"
              type="string"
              placeholder="Google Map"
              style={{
                maxWidth: '385.6px',
                display: 'flex',
                ...(touched?.googleMap && errors?.googleMap && { border: '1px solid red' }),
              }}
            />
          </div>
        </div>
        <div className={styles.flexWrap}>
          <Field
            name="fullAddress"
            as="textarea"
            className="inputForSearch"
            rows={4}
            placeholder="ที่อยู่"
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              height: 'auto',
              ...(touched?.fullAddress && errors?.fullAddress && { border: '1px solid red' }),
            }}
          />
          <Field
            name="remark"
            as="textarea"
            className="inputForSearch"
            rows={4}
            placeholder="รายละเอียด"
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              height: 'auto',
              ...(touched?.remark && errors?.remark && { border: '1px solid red' }),
            }}
          />
        </div>
        <input type="file" id="file" onChange={(e) => handleUploadImage(e)} accept="image/*" />
        <label htmlFor="file" className={`${styles.buttonUploadFile}`}>
          เพิ่มรูป
        </label>
        <div className={styles.groupImages}>
          {values.listImageUri.map((data: any, index: number) => (
            <div className={styles.groupImage} key={index}>
              <img src={data.imageUri} width="200" height="100" />
              <p style={{ fontSize: '10px' }}>เมื่อ {data.createdAt.replace('T', ' ').split('.')[0]}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/delivery-note/list/employee">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>

        <button type="submit" className="styleBtn">
          บันทึก
        </button>
        <button
          type="button"
          className="styleBtn"
          onClick={() => handleSubmit()}
          disabled={!values.checkAllDocumentDone}
        >
          ส่งเอกสาร
        </button>
      </section>
      {ModalAlert(values.isModalOpen, values.successSubmit, setFieldValue)}
    </Form>
  )
}

const EnhancedInventoryDeliveryNoteDetailAddPage = withFormik({
  mapPropsToValues: (props) => ({
    rawDataFromProps: [],
    selected: 0,
    checkAllDocumentDone: false,
    successSubmit: '',
    isModalOpen: false,

    id: '',
    remark: '',
    receiveGoodAt: '',
    appointReceiveAt: '',
    receiverName: '',
    phoneNumber: '',
    googleMap: '',
    fullAddress: '',
    listImageUri: [],
  }),
  validate: (values) => {
    return validateIgnoreField(
      values,
      [
        'addMode',
        'rawDataFromProps',
        'selected',
        'checkAllDocumentDone',
        'successSubmit',
        'isModalOpen',
        'listImageUri',
      ],
      '*',
    )
  },
  handleSubmit: (values, { setFieldValue }) => {
    const q = `mutation ($input: DeliveryOrderEdit!) {
      deliveryOrderEdit(input: $input) {
        id
      }
    }`
    // Where to put the variables
    const v = {
      input: {
        id: parseInt(values.id),
        remark: values.remark,
        receiveGoodAt: new Date(values.receiveGoodAt).toISOString(),
        appointReceiveAt: new Date(values.appointReceiveAt).toISOString(),
        receiverName: values.receiverName,
        phoneNumber: values.phoneNumber,
        googleMap: values.googleMap,
        fullAddress: values.fullAddress,
        otherRemark: '',
        imageUri: [...values.listImageUri],
      },
    }
    console.log('v', v)
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'สำเร็จ', false)
        values.rawDataFromProps[values.selected].status = 'เรียบร้อย'
        values.rawDataFromProps[values.selected].remark = values.remark
        values.rawDataFromProps[values.selected].receiveGoodAt = values.receiveGoodAt
        values.rawDataFromProps[values.selected].appointReceiveAt = values.appointReceiveAt
        values.rawDataFromProps[values.selected].receiverName = values.receiverName
        values.rawDataFromProps[values.selected].phoneNumber = values.phoneNumber
        values.rawDataFromProps[values.selected].googleMap = values.googleMap
        values.rawDataFromProps[values.selected].fullAddress = values.fullAddress
        values.rawDataFromProps[values.selected].listImageUri = values.listImageUri
        setFieldValue('rawDataFromProps', values.rawDataFromProps, false)
        const findStatusDoc = values.rawDataFromProps.find((element: any) => element.status === 'รอดำเนินการ')
        if (!findStatusDoc) {
          return setFieldValue('checkAllDocumentDone', true, false)
        }
      })
      .catch((err) => {
        console.log('err', err.response)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
      })
  },
})(InventoryDeliveryNoteDetailAddPage)

export default EnhancedInventoryDeliveryNoteDetailAddPage
