import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useHistory, Link } from 'react-router-dom'

import SaleSVG from '../../main/sale.svg'
import HomeSVG from '../main-sale/home.svg'
import CRMSVG from '../main-sale/crm.svg'
import AddSVG from '../main-sale/add.svg'
import RemoveSVG from '../main-sale/remove.svg'
import JobOrderSVG from '../main-sale/joborder.svg'
import ScheduleSVG from '../main-sale/schedule.svg'
import ApprovedSVG from '../main-sale/approved.svg'

const SaleNavSideComponent: React.FunctionComponent = (props: any) => {
  const [pathname, setPathname] = useState('')

  const history = useHistory()
  // Solve choose NavtopBar & Navigation for Route to new page
  history.listen((location, action) => {
    setPathname(history.location.pathname)
  })

  // Solve choose NavtopBar & Navigation for Reload
  useEffect(() => {
    setPathname(history.location.pathname)
  }, [history])

  return (
    <nav className={styles.navSideBar}>
      <Link className={styles.moduleSelected} to="/">
        <h3>โมดูล</h3>
        <img src={SaleSVG} width="25" height="25" />
        <p>การขาย</p>
      </Link>

      <h3 className={styles.textMenu}>เมนู</h3>
      {menu.map(({ name, to, img }: any) => {
        return (
          <Link
            to={to}
            className={`${styles.buttonLink} ${
              pathname.indexOf(`${to}`) !== -1 && to !== '/sale' ? styles.active : ''
            }`}
            key={name}
          >
            <img src={img} width="30" height="30" />
            <p>{name}</p>
          </Link>
        )
      })}
    </nav>
  )
}

export default SaleNavSideComponent

const menu: any = [
  { name: 'หน้าแรก', img: HomeSVG, to: '/sale' },
  { name: 'CRM', img: CRMSVG, to: '/sale/crm' },
  { name: 'ตารางทำงาน', img: ScheduleSVG, to: '/sale/calendar' },
  { name: 'งานถอดแบบ', img: JobOrderSVG, to: '#' },
  { name: 'ใบเสนอราคา', img: AddSVG, to: '/sale/quotation' },
  { name: 'การอนุมัติ', img: ApprovedSVG, to: '#' },
]
