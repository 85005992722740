import React, { useEffect, useState } from 'react'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import validateIgnoreField from '../../common/validateIgnoreField'

import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../../sale/common/table'
import styles from './index.module.scss'
import DocumentTitle from '../../inventory/common/document-title'
import { urlApiSale } from '../common/configApi'
import axios from 'axios'
import { boardID } from '../common/configBoardID'
import { findProperties } from '../common/findAdditionalProperties'
import ModalAlert from '../common/modal'
import { useSelector } from 'react-redux'

import useTokenInitializer from '../../hooks/token-initializer'

const SaleQuotationPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const [viewMode, setViewMode] = useState(false)
  const token: any = useSelector((state: any) => state.token)

  const rowBody: any = values.productList
  const colHead: any = [
    'เพิ่ม/ลด',
    'ลำดับ',
    'รหัสสินค้า',
    'สี',
    'ผิว',
    'รายละเอียดสินค้า',
    'หนา',
    'กว้าง',
    'ยาว',
    'ความยาวที่ใช้',
    'แผ่น',
    'รวม (เมตร)',
    'ราคาต่อเมตร',
    'ส่วนลด',
    'จำนวนเงินสุทธิ',
  ]
  useTokenInitializer()
  // Check View Mode
  useEffect(() => {
    setFieldValue('token', token, false)

    if (props.match.params.id) {
      setViewMode(true)

      const v = {
        docID: props.match.params.id,
      }

      axios({
        url: urlApiSale,
        method: 'post',
        data: {
          query: qQuotation,
          variables: v,
        },
      })
        .then((result) => {
          const quotation = result.data.data.quotation[0]
          console.log('quotation', quotation)
          setLoading(false)
          setFieldValue('customerName', quotation.customerName, true)
          setFieldValue('customerPhone', quotation.customerPhone, true)
          setFieldValue('customerEmal', quotation.customerEmal, true)
          setFieldValue('customerAddress', quotation.customerAddress, true)
          setFieldValue('customerFax', quotation.customerFax, true)
          setFieldValue('taxID', quotation.taxID, true)
          setFieldValue('saleName', quotation.saleName, true)

          setFieldValue('deadLine', quotation.deadLine, true)
          setFieldValue('projectCode', quotation.projectCode, true)
          setFieldValue('condition', quotation.condition, true)
          setFieldValue('conditionOfPayment', quotation.conditionOfPayment, true)
          setFieldValue('remark', quotation.remark, true)
          setFieldValue('remarkInternal', quotation.remarkInternal, true)

          const orderList: any[] = []
          quotation.orderList.map((data: any, index: number) => {
            const mapper = {
              id: index + 1,
              seq: index + 1,
              productCode: data.productCode,
              color: data.color,
              surface: data.surface,
              name: data.name,
              thickness: data.thickness,
              width: data.width,
              length: data.length,
              lengthActual: data.lengthActual,
              sheet: data.sheet,
              total: data.total,
              totalPerMeter: data.totalPerMeter,
              discount: data.discount,
              totalNet: data.totalNet,
            }
            orderList.push(mapper)
          })
          setFieldValue('productList', orderList, true)
        })
        .catch((err) => {
          console.log(err.response)
        })
    } else {
      setLoading(false)
    }
  }, [])

  const propsTitle = props.location.state?.propsTitle
  // If click for Card must auto Fill value
  useEffect(() => {
    if (!propsTitle) return
    handleChangeProjectCode(propsTitle)
  }, [propsTitle])

  // คำนวนเงิน
  useEffect(() => {
    let SubTotal = 0
    let Discount = 0
    let Total = 0
    let VAT = 0
    let GrandTotal = 0
    values.productList.map((data: any) => {
      SubTotal = SubTotal + parseFloat(data.lengthActual) * parseFloat(data.sheet) * data.totalPerMeter
      Discount = Discount + data.discount
    })
    Total = SubTotal - Discount
    VAT = parseFloat(((SubTotal - Discount) * 0.07).toFixed(2))
    GrandTotal = (SubTotal - Discount) * 1.07

    // parseFloat(someNumber.toFixed(2))
    setFieldValue(
      'summarySubTotal',
      SubTotal.toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      false,
    )
    setFieldValue('summaryDiscount', Discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), false)
    setFieldValue(
      'summaryTotal',
      Total.toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      false,
    )
    setFieldValue(
      'summaryVat',
      VAT.toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      false,
    )
    setFieldValue('summaryGrandTotal', GrandTotal.toFixed(2), false)
  }, [values.productList])

  useEffect(() => {
    if (props.match.params.id) return
    const v = {
      boardID: boardID,
      title: values.projectCode,
    }
    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: qSuggestProject,
        variables: v,
      },
    })
      .then((result) => {
        const suggestCard = result.data.data.card
        setFieldValue('suggestCard', suggestCard, false)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [values.projectCode])

  const handleChangeProjectCode = (value: string) => {
    setFieldValue('projectCode', value, false)

    const v = {
      boardID: boardID,
      title: value,
    }
    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: qSuggestProject,
        variables: v,
      },
    })
      .then((result) => {
        const card = result.data.data.card
        if (card.length === 1) {
          if (card[0].title == value) {
            setFieldValue('rawDataCard', card[0], false)
            setFieldValue('customerName', findProperties(card[0].additionalProperties, 'contactName'), true)
            setFieldValue('customerPhone', findProperties(card[0].additionalProperties, 'contactNumber'), true)
            setFieldValue('customerEmal', findProperties(card[0].additionalProperties, 'contactEmail'), true)
            setFieldValue('customerAddress', findProperties(card[0].additionalProperties, 'contactAddress'), true)
            setFieldValue('customerFax', findProperties(card[0].additionalProperties, 'billingFax'), true)
            setFieldValue('taxID', findProperties(card[0].additionalProperties, 'billingTaxID'), true)
            setFieldValue('saleName', card[0].assignee[0], true)
          } else {
            console.log('no>>')
            setFieldValue('customerName', '', true)
            setFieldValue('customerPhone', '', true)
            setFieldValue('customerEmal', '', true)
            setFieldValue('customerAddress', '', true)
            setFieldValue('customerFax', '', true)
            setFieldValue('taxID', '', true)
            setFieldValue('saleName', '', true)
          }
        } else {
          console.log('no')
          setFieldValue('customerName', '', true)
          setFieldValue('customerPhone', '', true)
          setFieldValue('customerEmal', '', true)
          setFieldValue('customerAddress', '', true)
          setFieldValue('customerFax', '', true)
          setFieldValue('taxID', '', true)
          setFieldValue('saleName', '', true)
        }
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  DocumentTitle('Sale', 'ใบเสนอราคา')

  return (
    <Form>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>{!viewMode ? 'สร้างใบเสนอราคา' : 'ใบเสนอราคา'}</h1>
        <p>ยินดีต้อนรับ [{values.token?.name}]</p>

        <button type="button" className="styleBtn" disabled={viewMode ? false : true}>
          สร้างใบจอง
        </button>
        <button type="button" className="styleBtn" disabled={viewMode ? false : true}>
          สร้างใบวางบิล
        </button>
        <button
          type="button"
          className="styleBtn"
          onClick={() => props.history.push(`/sale/quotation/pdf/${props.match.params.id}`)}
          disabled={viewMode ? false : true}
        >
          พิมพ์
        </button>

        <div className={styles.rowWrap}>
          <div className={styles.sectionHalf}>
            <div className={styles.rowWrap}>
              {/* Row#1 */}
              <div className={styles.groupInput}>
                <h4>รหัสโปรเจค</h4>
                <Field
                  type="text"
                  name="projectCode"
                  list="projectCode"
                  className="inputForSearch"
                  placeholder="รหัสโปรเจค"
                  onChange={(e: any) => handleChangeProjectCode(e.target.value)}
                  style={{
                    ...(touched?.projectCode && errors?.projectCode && { border: '1px solid red' }),
                  }}
                  disabled={!viewMode ? false : true}
                />
                <datalist id="projectCode">
                  {values.suggestCard.map((card: any, indexCard: number) => {
                    return (
                      <option key={indexCard} value={card.title}>
                        {card.status}/{findProperties(card.additionalProperties, 'contactCompany')}/
                        {findProperties(card.additionalProperties, 'contactName')}
                      </option>
                    )
                  })}
                </datalist>
              </div>
              <div className={styles.groupInput}>
                <h4>ชื่อลูกค้า</h4>
                <Field
                  name="customerName"
                  type="text"
                  className="inputForSearch"
                  placeholder="ชื่อลูกค้า"
                  disabled={true}
                  style={{
                    ...(touched?.customerName && errors?.customerName && { border: '1px solid red' }),
                  }}
                />
              </div>
              <div className={styles.groupInput}>
                <h4>Tax ID</h4>
                <Field
                  name="taxID"
                  type="text"
                  className="inputForSearch"
                  placeholder="Tax ID"
                  disabled={true}
                  style={{
                    ...(touched?.taxID && errors?.taxID && { border: '1px solid red' }),
                  }}
                />
              </div>
              {/* Row#2 */}
              <div className={styles.groupInput}>
                <h4>เบอร์โทรศัพท์</h4>
                <Field
                  name="customerPhone"
                  type="text"
                  className="inputForSearch"
                  placeholder="เบอร์โทรศัพท์"
                  disabled={true}
                  style={{
                    ...(touched?.customerPhone && errors?.customerPhone && { border: '1px solid red' }),
                  }}
                />
              </div>
              <div className={styles.groupInput}>
                <h4>โทรสาร/Fax</h4>
                <Field
                  name="customerFax"
                  type="text"
                  className="inputForSearch"
                  placeholder="ชื่อลูกค้า"
                  disabled={true}
                  style={{
                    ...(touched?.customerFax && errors?.customerFax && { border: '1px solid red' }),
                  }}
                />
              </div>
              <div className={styles.groupInput}>
                <h4>Email</h4>
                <Field
                  name="customerEmal"
                  type="text"
                  className="inputForSearch"
                  placeholder="Email"
                  disabled={true}
                  style={{
                    ...(touched?.customerEmal && errors?.customerEmal && { border: '1px solid red' }),
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.sectionHalf}>
            <div className={styles.rowWrap}>
              <div className={styles.groupInputAddress}>
                <h4>ที่อยู่</h4>
                <Field
                  as="textarea"
                  name="customerAddress"
                  className={styles.styleTextarea}
                  disabled={true}
                  rows={4}
                  cols={140}
                  placeholder="ที่อยู่"
                  style={{
                    ...(touched?.customerAddress && errors?.customerAddress && { border: '1px solid red' }),
                  }}
                />
              </div>
              <div className={styles.groupInputAddress}>
                <h4>วันครบกำหนดชำระ</h4>
                <Field
                  name="deadLine"
                  type="date"
                  className="inputForSearch"
                  placeholder=""
                  style={{
                    ...(touched?.deadLine && errors?.deadLine && { border: '1px solid red' }),
                  }}
                  disabled={!viewMode ? false : true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.tableList}>
        <TableCommonCompanant
          colHead={colHead}
          rowBodyData={rowBody}
          typeHead={TABLE_HEAD_TYPES.NONE}
          typeBody={TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON}
          Field={Field}
          setFieldValue={setFieldValue}
          values={values}
          viewMode={viewMode}
        />
      </section>

      <section className={styles.headerWrapper}>
        <div className={styles.rowWrap}>
          <div className={styles.colWrap}>
            {/* New Row */}
            <div className={styles.rowWrap}>
              <div className={styles.groupInputOnly}>
                <h4>เงื่อนไข</h4>
                <Field
                  as="textarea"
                  name="condition"
                  className={styles.styleTextarea}
                  rows={6}
                  cols={40}
                  placeholder="เงื่อนไข"
                  style={{
                    ...(touched?.condition && errors?.condition && { border: '1px solid red' }),
                  }}
                  disabled={!viewMode ? false : true}
                />
              </div>
              <div className={styles.groupInputOnly}>
                <h4>เงื่อนไขการชำระเงิน</h4>
                <Field
                  as="textarea"
                  name="conditionOfPayment"
                  className={styles.styleTextarea}
                  rows={6}
                  cols={40}
                  placeholder="เงื่อนไขการชำระเงิน"
                  style={{
                    ...(touched?.conditionOfPayment && errors?.conditionOfPayment && { border: '1px solid red' }),
                  }}
                  disabled={!viewMode ? false : true}
                />
              </div>
            </div>

            {/* New Row */}
            <div className={styles.rowWrap}>
              <div className={styles.groupInputOnly}>
                <h4>หมายเหตุ</h4>
                <Field
                  as="textarea"
                  name="remark"
                  className={styles.styleTextarea}
                  rows={6}
                  cols={40}
                  placeholder="หมายเหตุ"
                  disabled={!viewMode ? false : true}
                />
              </div>
              <div className={styles.groupInputOnly}>
                <h4>หมายเหตุ ภายใน</h4>
                <Field
                  as="textarea"
                  name="remarkInternal"
                  className={styles.styleTextarea}
                  rows={6}
                  cols={40}
                  placeholder="หมายเหตุ ภายใน"
                  disabled={!viewMode ? false : true}
                />
              </div>
            </div>
          </div>

          {/* New Col */}
          <div className={styles.colWrap}>
            <div className={styles.groupInputRowRight}>
              <div className={styles.textTitle}>
                <h4>จำนวนเงิน (Sub Total)</h4>
              </div>
              <div>
                <Field name="summarySubTotal" type="text" className="inputForSearch" disabled />
              </div>
            </div>
            <div className={styles.groupInputRowRight}>
              <div className={styles.textTitle}>
                <h4>หักส่วนลด (Discount)</h4>
              </div>
              <div>
                <Field name="summaryDiscount" type="text" className="inputForSearch" disabled />
              </div>
            </div>
            <div className={styles.groupInputRowRight}>
              <div className={styles.textTitle}>
                <h4>จำนวนเงินหลังหักส่วนลด (Total)</h4>
              </div>
              <div>
                <Field name="summaryTotal" type="text" className="inputForSearch" disabled />
              </div>
            </div>
            <div className={styles.groupInputRowRight}>
              <div className={styles.textTitle}>
                <h4>จำนวนภาษีมูลค่าเพิ่ม 7% (VAT)</h4>
              </div>
              <div>
                <Field name="summaryVat" type="text" className="inputForSearch" disabled />
              </div>
            </div>
            <div className={styles.groupInputRowRight}>
              <div className={styles.textTitle}>
                <h4>จำนวนเงินทั้งสิ้น (Grand Total)</h4>
              </div>
              <div>
                <Field
                  name="summaryGrandTotal"
                  value={values.summaryGrandTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  type="text"
                  className="inputForSearch"
                  disabled
                />
              </div>
            </div>
            <div className={styles.groupInputRowRight}>
              <button type="submit" className="styleBtn" disabled={!viewMode ? false : true}>
                บันทึก
              </button>
            </div>
          </div>
        </div>

        {/* New Row */}
        <div className={styles.rowWrap}>
          <div className={styles.groupInput}>
            <h4>Sale</h4>
            <Field
              name="saleName"
              type="text"
              className="inputForSearch"
              placeholder="ชื่อเซล"
              disabled={true}
              style={{
                ...(touched?.saleName && errors?.saleName && { border: '1px solid red' }),
              }}
            />
          </div>
        </div>
      </section>
      {ModalAlert(values.isModalOpen, values.successSubmit, setFieldValue)}
    </Form>
  )
}

const EnhancedSaleQuotationPage = withFormik({
  mapPropsToValues: (props) => ({
    // Validate Field
    projectCode: '',
    customerName: '',
    taxID: '',
    customerPhone: '',
    customerFax: '',
    customerEmal: '',
    customerAddress: '',
    deadLine: '',
    condition: '',
    conditionOfPayment: '',
    saleName: '',

    // Not Validate Field
    token: null,
    successSubmit: '',
    isModalOpen: '',
    remark: '',
    remarkInternal: '',
    summarySubTotal: 0.0,
    summaryDiscount: 0.0,
    summaryTotal: 0.0,
    summaryVat: 0.0,
    summaryGrandTotal: 0.0,
    suggestCard: [],
    rawDataCard: null,
    productList: [
      {
        id: 1,
        seq: 1,
        productCode: null,
        color: '',
        surface: '',
        name: '',
        thickness: '',
        width: '',
        length: '',
        lengthActual: 0,
        sheet: 0,
        total: 0,
        totalPerMeter: 0,
        discount: 0,
        totalNet: 0,
      },
    ],
  }),
  validate: (values) => {
    return validateIgnoreField(
      values,
      [
        'token',
        'remark',
        'remarkInternal',
        'summarySubTotal',
        'summaryDiscount',
        'summaryTotal',
        'summaryVat',
        'summaryGrandTotal',
        'productList',
        'suggestCard',
        'successSubmit',
        'isModalOpen',
        'rawDataCard',
      ],
      '*',
    )
  },
  handleSubmit: (values, { setFieldValue }) => {
    const v = {
      input: {
        projectCode: values.projectCode,
      },
    }
    console.log('qQuotation', createQuotation)
    console.log('v', JSON.stringify(v))
    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: createQuotation,
        variables: v,
      },
    })
      .then((result1) => {
        const docID = result1.data.data.createQuotation.docID
        const AfterProductList: any[] = []
        values.productList
          .filter((ele: any) => ele.surface !== '')
          .map((data: any) => {
            const mapprt = {
              docID: docID,
              projectCode: values.projectCode,
              productCode: data.productCode,
              color: data.color,
              surface: data.surface,
              description: data.name,
              thickness: data.thickness,
              width: data.width,
              length: data.length,
              lengthActual: parseFloat(data.lengthActual),
              sheet: parseFloat(data.sheet),
              total: parseFloat(data.lengthActual) * parseFloat(data.sheet),
              totalPerMeter: parseFloat(data.totalPerMeter),
              discount: parseFloat(data.discount),
              totalNet:
                parseFloat(data.lengthActual) * parseFloat(data.sheet) * parseFloat(data.totalPerMeter) -
                parseFloat(data.discount),
            }
            AfterProductList.push(mapprt)
          })
        const v = {
          input: {
            docID: docID,
            internalDocumentID: result1.data.data.createQuotation.internalDocumentID,
            projectCode: values.projectCode,
            customerName: values.customerName,
            taxID: values.taxID,
            customerPhone: values.customerPhone,
            customerFax: values.customerFax,
            customerEmal: values.customerEmal,
            customerAddress: values.customerAddress,
            deadLine: values.deadLine,
            orderList: AfterProductList,
            condition: values.condition,
            conditionOfPayment: values.conditionOfPayment,
            remark: values.remark,
            remarkInternal: values.remarkInternal,
            summaryGrandTotal: parseFloat(values.summaryGrandTotal),
            saleName: values.saleName,
          },
        }
        console.log('v', v)
        axios({
          url: urlApiSale,
          method: 'post',
          data: {
            query: updateQuotation,
            variables: v,
          },
        })
          .then((result2) => {
            const tzoffset = new Date().getTimezoneOffset() * 60000
            const localISOTime = new Date(Date.now() - tzoffset).toISOString()
            const vUpdateCard = {
              input: {
                boardID: boardID,
                cardID: values.rawDataCard.cardID,
                title: values.rawDataCard.title,
                status: values.rawDataCard.status,
                assignee: values.rawDataCard.assignee,
                additionalProperties: values.rawDataCard.additionalProperties,
                comments: [
                  ...values.rawDataCard.comments,
                  {
                    commentType: 'refDocument',
                    description: 'ใบเสนอราคา',
                    note: docID + '-' + result1.data.data.createQuotation.internalDocumentID,
                    createdAt: localISOTime,
                    by: values.token.name,
                  },
                ],
              },
            }

            axios({
              url: urlApiSale,
              method: 'post',
              data: {
                query: qUpdateCard,
                variables: vUpdateCard,
              },
            })
              .then((result3) => {
                setFieldValue('successSubmit', 'เพิ่มสำเร็จ', false)
                setFieldValue('isModalOpen', true, false)
              })
              .catch((err3) => {
                setFieldValue('successSubmit', 'เกิดปัญหา', false)
                setFieldValue('isModalOpen', true, false)
                console.log(err3.response)
              })
          })
          .catch((err2) => {
            console.log('updateQuotation', err2.response)
            setFieldValue('successSubmit', 'เกิดปัญหา', false)
            setFieldValue('isModalOpen', true, false)
          })
      })
      .catch((err1) => {
        console.log('createQuotation', err1.response)
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
        setFieldValue('isModalOpen', true, false)
      })
  },
})(SaleQuotationPage)

export default EnhancedSaleQuotationPage

const qSuggestProject = `query($boardID: ID!, $title: String){
  card(boardID: $boardID, title: $title) {
    cardID
    title
    status
    assignee
    createdAt
    updatedAt
    comments {
			commentType
      description
      attachments
      createdAt
      datetime
      location
      googleMap
      number
      lat
      long
      note
    }
    additionalProperties {
			Key
    	Value
    }
  }
}`

// internalDocumentID
const createQuotation = `mutation($input: CreateQuotationInput!) {
  createQuotation(input: $input) {
    docID
    internalDocumentID
  }
}`

const updateQuotation = `mutation($input: UpdateQuotationInput!) {
  updateQuotation(input: $input) {
    docID
  }
}`

const qUpdateCard = `mutation($input: UpdateCardInput!) {
  updateCard(input: $input) {
    cardID
  }
}`

const qQuotation = `query($docID: String){
  quotation(docID: $docID) {
    docID
    internalDocumentID
    projectCode
    customerName
    taxID
    customerPhone
    customerFax
    customerEmal
    customerAddress
    deadLine
    orderList{
      productCode
      color
      surface
      name: description
      thickness
      width
      length
      lengthActual
      sheet
      total
      totalPerMeter
      discount
      totalNet
    }
    condition
    conditionOfPayment
    remark
    remarkInternal
    summaryGrandTotal
    saleName
  }
}`
