import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useHistory, Link } from 'react-router-dom'

import biowoodLogo from './logo.svg'
import profileSVG from './profile.svg'
import logoutSVG from './logout.svg'
import { useSelector } from 'react-redux'

const NavTopComponent: React.FC = () => {
  const [pathname, setPathname] = useState('')

  const history = useHistory()
  // Solve choose NavtopBar & Navigation for Route to new page
  history.listen((location, action) => {
    setPathname(history.location.pathname)
  })

  const decodeToken = useSelector((state: any) => state.token.decoded_token)

  // Solve choose NavtopBar & Navigation for Reload
  useEffect(() => {
    setPathname(history.location.pathname)
  }, [history])

  const returnTitle = () => {
    if (pathname === '/') return <h3 className={styles.title}></h3>
    if (pathname.indexOf('/inventory') !== -1) return <h3 className={styles.title}>คลังสินค้า</h3>
    if (pathname.indexOf('/sale') !== -1) return <h3 className={styles.title}>การขาย</h3>
  }

  return (
    <header>
      <nav className={styles.navBar}>
        <Link to="/">
          <img className={styles.logo} src={biowoodLogo} alt="Biowood Logo" />
        </Link>

        {returnTitle()}

        {localStorage.getItem('accessToken') && (
          <div className={styles.group}>
            <Link to={`/hr/view/${decodeToken.username}`} className={styles.profile}>
              <img src={profileSVG} alt="Biowood Logo" width="20" height="20" />
            </Link>
            <Link
              to="/login"
              className={styles.logout}
              onClick={() => {
                localStorage.removeItem('accessToken')
              }}
            >
              <img src={logoutSVG} alt="Biowood Logo" width="20" height="20" />
              ออกจากระบบ
            </Link>
          </div>
        )}
      </nav>
    </header>
  )
}

export default NavTopComponent
