import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import DocumentTitle from '../common/document-title'

import axios from 'axios'
import { debounce } from 'lodash'
import { urlApi } from '../common/configUrlApi'
import { FormatDateTime } from '../../common/dateFormat'
import useTokenInitializer from '../../hooks/token-initializer'
import EnhancedTable from '../../common/table/item/paginationByBack/firstColCheckbox'
import { HeadCell } from '../../common/table/config'

const InventoryDeliveryNoteListOrderPage: React.FC = () => {
  const [loading, setLoading] = useState<any>(true)

  const [datalistGoodIssue, setDatalistGoodIssue] = useState<any>([])
  const [selected, setSelected] = useState<any>([]) // สำหรับส้ง post

  const [currentQueryString, setCurrentQueryString] = useState('')

  // Pgaination
  const [page, setPage] = useState(0)
  const [pageList, setPageList] = React.useState<any>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = React.useState(0)

  DocumentTitle('Inventory', 'การส่งสินค้า')
  useTokenInitializer()

  useEffect(() => {
    // Where to put the query
    const q = `query listGoodIssuePage($pageIndex: Int, $maxResults: Int){
      listGoodIssue(pageIndex: $pageIndex, maxResults: $maxResults) {
        totalCount
        pageIndex
        maxResults
        results {
          id
          internalDocumentId
          documentTypeId
          documentStatusId
          referenceNumber
          remark
          createdAt
          updatedAt
          salesOrderDocumentId
        }
      }
    }`
    // Where to put the variables
    const v = { pageIndex: page, maxResults: rowsPerPage }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const listGoodIssue = result.data.data.listGoodIssue.results
      const listGoodIssueAfterMapper: any[] = []
      listGoodIssue.map((data: any) => {
        const mapper = {
          id: data.id,
          internalDocumentID: data.internalDocumentId,
          timeStamp: FormatDateTime(data.updatedAt),
        }
        return listGoodIssueAfterMapper.push(mapper)
      })
      setLoading(false)
      setTotalCount(result.data.data.listGoodIssue.totalCount)
      setDatalistGoodIssue([...datalistGoodIssue, ...listGoodIssueAfterMapper])

      setPageList([...pageList, page]) // For not reload new page if dowloaded
    })
  }, [page, rowsPerPage, currentQueryString])

  const handleDisabled = () => {
    if (selected.length !== 0) {
      return false
    } else {
      return true
    }
  }

  const request = debounce((value) => {
    setPage(0)
    setPageList([])
    setDatalistGoodIssue([])
    setCurrentQueryString(value)
  }, 1000)

  const debouceRequest = useCallback((value) => request(value), [])

  const onChange = (e: any) => {
    debouceRequest(e.target.value)
  }

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'product', numeric: false, disablePadding: false, label: 'ดู' },
    { id: 'amountPack', numeric: false, disablePadding: false, label: 'เลขที่รายการนำออก' },
    { id: 'profile', numeric: false, disablePadding: false, label: 'วันที่พิมพ์' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  const rowBody: any = [...datalistGoodIssue]

  return (
    <>
      <section className={styles.headerWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>การส่งสินค้า</h1>
        <p>กรุณาเลือกรายการ</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสเอกสาร"
          onChange={onChange}
          style={{ maxWidth: '385.6px', display: 'flex' }}
        />
      </section>
      <section className="tableListMui">
        <EnhancedTable
          colHead={headCells}
          rows={rowBody}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
          selected={selected}
          setSelected={setSelected}
          link="/inventory/issue/document/list"
          // onChangeCheckOrderLineItems={onChangeCheckOrderLineItems}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/delivery-note">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
        <Link
          to={{
            pathname: `/inventory/delivery-note/list/employee`,
            state: selected,
          }}
        >
          <button type="button" className="styleBtn" disabled={handleDisabled()}>
            ถัดไป
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventoryDeliveryNoteListOrderPage
