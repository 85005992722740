import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Text, ITextProps } from 'office-ui-fabric-react'

import styles from './index.module.scss'
import menuItems from './menuItems'
import AddSVG from './add.svg'
import ScheduleSVG from './schedule.svg'
import { FormatDateTime } from '../../common/dateFormat'
import parse from 'html-react-parser'
import DocumentTitle from '../../inventory/common/document-title'
import axios from 'axios'
import { urlApiSale } from '../common/configApi'
import { boardID } from '../common/configBoardID'

import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'

interface BoardStatus {
  statusName: string
  statusDisplay: string
  color: string
}

interface Card {
  additionalProperties: any
  assignee: string[]
  updatedAt: string
  cardID: string
  title: string
  status: string
}

interface BoardInformation {
  boardID: string
  boardName: string
  cardList: {
    additionalProperties: any
    assignee: string[]
    updatedAt: string
    cardID: string
    title: string
    status: string
  }[]
  statusList: BoardStatus[]
  // [key: string]: any
}

const InventoryMainPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const [changeView, setChangeView] = useState<any>(true)
  const [changeViewOnlyStatus, setChangeViewOnlyStatus] = useState<any>('')
  const [stringFilter, setStringFilter] = useState<any>('all')

  // Fact Status
  const [statusList, setStatusList] = useState<BoardStatus[]>([])

  // Combined Card Status
  const [cards, setCards] = useState<Card[]>([])

  const iconList: { [key: string]: string } = {
    AddSVG: AddSVG,
    ScheduleSVG: ScheduleSVG,
  }

  const [fullname, setFullname] = useState('')
  const token = useSelector((state: any) => state.token)
  useTokenInitializer()

  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
  }, [token.name])

  useEffect(() => {
    // Where to put the variables
    const v = {
      input: boardID,
    }

    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: qBoard,
        variables: v,
      },
    })
      .then((result: { data: { data: { board: BoardInformation[] } } }) => {
        const board = result.data.data.board[0]
        setStatusList(board.statusList)
        setCards(board.cardList)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [])

  DocumentTitle('Sale', 'CRM')

  const returnComponantSomeStatus = (statusProps: string) => {
    return (
      <>
        <div className={styles.menuItemsWrapper}>
          {statusList.map((status, index) => {
            if (status.statusName === statusProps) {
              let price = 0
              cards
                .filter(
                  (card) =>
                    card.status === status.statusName &&
                    (fullname === 'Admin' || token.position === 'หัวหน้า Sales' ? true : fullname === card.assignee[0]),
                )
                .forEach((element) => {
                  price =
                    price +
                    parseInt(element.additionalProperties.find((element: any) => element.Key === 'dealPrice').Value)
                })
              return (
                <div key={index} className={styles.newCol}>
                  <div className={`${styles.tag} ${styles[`${status.color}Tag`]}`}>
                    <h4>{status.statusDisplay}</h4>
                    <p>
                      {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} บาท (
                      {
                        cards.filter(
                          (card) =>
                            card.status === status.statusName &&
                            (fullname === 'Admin' ? true : fullname === card.assignee[0]),
                        ).length
                      }
                      โปรเจค)
                    </p>
                  </div>
                </div>
              )
            }
          })}
        </div>
        <div style={{ overflowX: 'scroll' }}>
          <table style={{ margin: '5px 0', minWidth: '288px' }}>
            <thead>
              <tr>
                <th className={`borderLeftRadius ${styles.textAlignCenter}`}></th>
                <th>ชื่อโปรเจค</th>
                <th>ชื่อลูกค้า</th>
                <th>ราคา</th>
                <th>Sale Name</th>
                <th>อัพเดทล่าสุด</th>
                <th className="borderRightRadius">สถานะ</th>
              </tr>
            </thead>
            <tbody>
              {cards
                .filter((card) =>
                  fullname === 'Admin' || token.position === 'หัวหน้า Sales' ? true : fullname === card.assignee[0],
                )
                .map((data, index) => {
                  if (data.status === statusProps)
                    return (
                      <tr key={index}>
                        <td className={`${styles.textAlignCenter}`}>
                          <button
                            type="button"
                            className="styleBtnForTable"
                            onClick={() => props.history.push(`/sale/crm/card/${data.cardID}`)}
                          >
                            ดู
                          </button>
                        </td>
                        <td className={`${styles.textAlignCenter}`}>{data.title}</td>
                        <td className={`${styles.textAlignCenter}`}>
                          {data.additionalProperties.find((element: any) => element.Key === 'contactName').Value}
                        </td>
                        <td className={`${styles.textAlignCenter}`}>
                          {data.additionalProperties
                            .find((element: any) => element.Key === 'dealPrice')
                            .Value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </td>
                        <td className={`${styles.textAlignCenter}`}>{data.assignee[0]}</td>
                        <td className={`${styles.textAlignCenter}`}>{data.updatedAt.split('T')[0]}</td>
                        <td className={`${styles.textAlignCenter}`}>
                          {statusList.find((ele) => ele.statusName === data.status)?.statusDisplay}
                        </td>
                      </tr>
                    )
                })}
            </tbody>
          </table>
        </div>
      </>
    )
  }
  return (
    <section className={styles.section1}>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <h1 className={styles.headerTitle}>CRM</h1>
      <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [{fullname}]</span>
      <div className={styles.menuItemsWrapper1}>
        {menuItems.map(({ name, imgName, linkTo }, index) => (
          <Link key={index} to={linkTo}>
            <button key={index} style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}>
              <img src={iconList[imgName]} />
              <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
                {parse(name)}
              </Text>
            </button>
          </Link>
        ))}
      </div>

      <button
        type="button"
        className="styleBtn"
        onClick={() => {
          setChangeView(!changeView)
          setChangeViewOnlyStatus('')
        }}
      >
        เปลี่ยนมุมมอง
      </button>
      <button
        type="button"
        className={`styleBtn ${styles.greenTag}`}
        onClick={() => {
          setChangeViewOnlyStatus('PROJECT_OK')
        }}
      >
        ปิดได้
      </button>
      <button
        type="button"
        className={`styleBtn ${styles.redTag}`}
        onClick={() => {
          setChangeViewOnlyStatus('PROJECT_FAIL')
        }}
      >
        ปิดไม่ได้
      </button>

      {changeViewOnlyStatus !== 'PROJECT_OK' && changeViewOnlyStatus !== 'PROJECT_FAIL' ? (
        changeView ? (
          // view card
          <div className={styles.menuItemsWrapper} style={{ overflowX: 'scroll' }}>
            {statusList.map((status, index) => {
              if (status.statusName === 'PROJECT_OK' || status.statusName === 'PROJECT_FAIL') return
              let price = 0
              cards
                .filter(
                  (card) =>
                    card.status === status.statusName &&
                    (fullname === 'Admin' || token.position === 'หัวหน้า Sales' ? true : fullname === card.assignee[0]),
                )
                .forEach((element) => {
                  price =
                    price +
                    parseInt(element.additionalProperties.find((element: any) => element.Key === 'dealPrice').Value)
                })
              return (
                <div key={index} className={styles.newCol}>
                  <div className={`${styles.tag} ${styles[`${status.color}Tag`]}`}>
                    <h4>{status.statusDisplay}</h4>
                    <p>
                      {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} บาท (
                      {
                        cards.filter(
                          (card) =>
                            card.status === status.statusName &&
                            (fullname === 'Admin' ? true : fullname === card.assignee[0]),
                        ).length
                      }
                      โปรเจค)
                    </p>
                  </div>
                  {cards.filter(
                    (card) =>
                      card.status === status.statusName &&
                      (fullname === 'Admin' || token.position === 'หัวหน้า Sales'
                        ? true
                        : fullname === card.assignee[0]),
                  ).length === 0 && <h3>ไม่มีรายการ</h3>}
                  {cards
                    .filter(
                      (card) =>
                        card.status === status.statusName &&
                        (fullname === 'Admin' || token.position === 'หัวหน้า Sales'
                          ? true
                          : fullname === card.assignee[0]),
                    )
                    .map((data, index) => {
                      return (
                        <div
                          className={`${styles.tagCard} ${styles.borderGreen}`}
                          key={index}
                          onClick={() => props.history.push(`/sale/crm/card/${data.cardID}`)}
                        >
                          <h4>ชื่อโปรเจค: {data.title}</h4>
                          <p>
                            ชื่อลูกค้า:
                            {data.additionalProperties.find((element: any) => element.Key === 'contactName').Value}
                          </p>
                          <p>
                            {data.additionalProperties
                              .find((element: any) => element.Key === 'dealPrice')
                              .Value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            บาท
                          </p>
                          <p>Sale: {data.assignee[0]}</p>
                          <p className={styles.timeStamp}>อัพเดทล่าสุด {FormatDateTime(data.updatedAt)}</p>
                        </div>
                      )
                    })}
                </div>
              )
            })}
          </div>
        ) : (
          // view Table
          <>
            <select
              className="inputForSearch"
              style={{ maxWidth: '200px', margin: '0 10px 0 0' }}
              onChange={(e) => setStringFilter(e.target.value)}
            >
              <option value="all">ทั้งหมด</option>
              {statusList.map((data, index) => {
                if (data.statusName === 'PROJECT_OK' || data.statusName === 'PROJECT_FAIL') return
                return (
                  <option key={index} value={data.statusName}>
                    {data.statusDisplay}
                  </option>
                )
              })}
            </select>
            <div style={{ overflowX: 'scroll' }}>
              <table style={{ margin: '5px 0', minWidth: '288px' }}>
                <thead>
                  <tr>
                    <th className={`borderLeftRadius ${styles.textAlignCenter}`}></th>
                    <th>ชื่อโปรเจค</th>
                    <th>ชื่อลูกค้า</th>
                    <th>ราคา</th>
                    <th>Sale Name</th>
                    <th>อัพเดทล่าสุด</th>
                    <th className="borderRightRadius">สถานะ</th>
                  </tr>
                </thead>
                <tbody>
                  {cards
                    .filter((card) =>
                      fullname === 'Admin' || token.position === 'หัวหน้า Sales' ? true : fullname === card.assignee[0],
                    )
                    .map((data, index) => {
                      if (stringFilter === 'all' || stringFilter === data.status)
                        return (
                          <tr key={index}>
                            <td className={`${styles.textAlignCenter}`}>
                              <button
                                type="button"
                                className="styleBtnForTable"
                                onClick={() => props.history.push(`/sale/crm/card/${data.cardID}`)}
                              >
                                ดู
                              </button>
                            </td>
                            <td className={`${styles.textAlignCenter}`}>{data.title}</td>
                            <td className={`${styles.textAlignCenter}`}>
                              {data.additionalProperties.find((element: any) => element.Key === 'contactName').Value}
                            </td>
                            <td className={`${styles.textAlignCenter}`}>
                              {data.additionalProperties
                                .find((element: any) => element.Key === 'dealPrice')
                                .Value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </td>
                            <td className={`${styles.textAlignCenter}`}>{data.assignee[0]}</td>
                            <td className={`${styles.textAlignCenter}`}>{FormatDateTime(data.updatedAt)}</td>
                            <td className={`${styles.textAlignCenter}`}>
                              {statusList.find((ele) => ele.statusName === data.status)?.statusDisplay}
                            </td>
                          </tr>
                        )
                    })}
                </tbody>
              </table>
            </div>
          </>
        )
      ) : null}

      {changeViewOnlyStatus === 'PROJECT_OK' && returnComponantSomeStatus('PROJECT_OK')}
      {changeViewOnlyStatus === 'PROJECT_FAIL' && returnComponantSomeStatus('PROJECT_FAIL')}
    </section>
  )
}

export default InventoryMainPage

const qBoard = `query($input: ID){
  board(boardID: $input) {
    boardID
        boardName
        statusList {
            statusName
            statusDisplay
            color
        }
        priorityList
        sprintList
        cardList {
          cardID
          title
          status
          updatedAt
          assignee
          additionalProperties {
             Key
            Value
          }
        }
  }
}`
