import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import styles from './index.module.scss'
import DocumentTitle from '../common/document-title'
import { useFormikContext, withFormik, Form, Field } from 'formik'

import validateIgnoreField from '../../common/validateIgnoreField'
import axios from 'axios'
import ModalAlert from '../common/modal'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from '../../common/configAws'
import { urlApi } from '../common/configUrlApi'

import useTokenInitializer from '../../hooks/token-initializer'

const InventoryDeliveryNoteDetailAddPage: React.FC = (props: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const [loading, setLoading] = useState<any>(true)

  DocumentTitle('Inventory', 'การส่งสินค้า')
  useTokenInitializer()

  useEffect(() => {
    const q = `query viewDeliveryOrder($id: ID!) {
      viewDeliveryOrder(id: $id) {
        id
        internalDocumentId
        documentTypeId
        documentStatusId
        referenceNumber
        remark
        createdAt
        updatedAt
        receiveGoodAt
        appointReceiveAt
        receiverName
        phoneNumber
        googleMap
        fullAddress
        otherRemark
        imageUri {
          imageUri
          createdAt
        }
        checkedInAt
        deliveryImageUri {
          imageUri
          createdAt
        }
        imageUri {
          imageUri
        }
      }
    }`

    const v = {
      id: props.match.params.id,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const viewDeliveryOrde = result.data.data['viewDeliveryOrder']
        setFieldValue('id', viewDeliveryOrde.id, false)
        setFieldValue(
          'receiveGoodAt',
          viewDeliveryOrde.receiveGoodAt ? viewDeliveryOrde.receiveGoodAt.split('.')[0] : '',
          false,
        )
        setFieldValue(
          'appointReceiveAt',
          viewDeliveryOrde.appointReceiveAt ? viewDeliveryOrde.appointReceiveAt.split('.')[0] : '',
          false,
        )
        setLoading(false)
        setFieldValue('receiverName', viewDeliveryOrde.receiverName ? viewDeliveryOrde.receiverName : '', false)
        setFieldValue('phoneNumber', viewDeliveryOrde.phoneNumber ? viewDeliveryOrde.phoneNumber : '', false)
        setFieldValue('googleMap', viewDeliveryOrde.googleMap ? viewDeliveryOrde.googleMap : '', false)
        setFieldValue('fullAddress', viewDeliveryOrde.fullAddress ? viewDeliveryOrde.fullAddress : '', false)
        setFieldValue('listImageUri', viewDeliveryOrde.imageUri ? viewDeliveryOrde.imageUri : [], false)
        setFieldValue('rawDataFromProps', [viewDeliveryOrde], false)
        if (viewDeliveryOrde.documentStatusId === 'DRAFT') {
          setFieldValue('editMode', true, false)
        }
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [])

  const handleSubmit = () => {
    const q = `mutation ($input: [ID!]!) {
      deliveryOrderSetSubmit(input: $input) {
        id
      }
    }`
    const listID: number[] = []
    values.rawDataFromProps.map((data: any) => {
      listID.push(data.id)
    })
    // Where to put the variables
    const v = {
      input: listID,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('result', result)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'สำเร็จ', false)
      })
      .catch((err) => {
        console.log('err', err.response)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
      })
  }

  const handleUploadImage = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]

    if (file.length !== 0) {
      const fileName = file.name
      const albumPhotosKey = encodeURIComponent('deliveryNote') + '/' + encodeURIComponent(`${values.id}`) + '/'

      const photoKey = albumPhotosKey + fileName

      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })

      const promise = upload.promise()
      const tzoffset = new Date().getTimezoneOffset() * 60000
      const localISOTime = new Date(Date.now() - tzoffset).toISOString()

      promise.then(
        function (data) {
          alert('Successfully uploaded photo.')
          const mapper = {
            imageUri: data.Location,
            createdAt: localISOTime,
          }
          setFieldValue('listImageUri', [...values.listImageUri, mapper], false)
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  const onClickRemoveDoc = () => {
    const q = `mutation deliveryOrderRemove($id: ID!) {
      deliveryOrderRemove(id: $id) {
        id
      }
    }`
    // Where to put the variables
    const v = {
      id: values.id,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'สำเร็จ', false)
        props.history.goBack()
      })
      .catch((err) => {
        console.log('err', err.response)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
      })
  }

  return (
    <Form>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>การส่งสินค้า</h1>
        <p className={styles.subHeaderTitle}>กรุณาเลือกรายการ</p>
      </section>
      <section className={styles.cards}>
        {values.rawDataFromProps.map((data: any, index: any) => (
          <article
            className={`${styles.card} ${values.selected === index ? styles.active : ''}`}
            key={data.id}
            onClick={() => setFieldValue('selected', index, false)}
          >
            <h4>เลขที่รายการส่งสินค้า</h4>
            <p>{data.internalDocumentId}</p>
            {data.documentStatusId === 'DRAFT' ? <p className={styles.statusWaiting}>รอดำเนินการ</p> : ''}
            {data.documentStatusId === 'PENDING' ? <p className={styles.statusDone}>เรียบร้อย</p> : ''}
          </article>
        ))}
      </section>
      <section className={styles.bodyWrapper}>
        <p>
          <b>เลขที่รายการส่งสินค้า IS01561654</b>
        </p>
        <p>อ้างอิง 15615648</p>
        <p style={{ marginBottom: '10px' }}>ชื่อลูกค้า ABCD EFGH</p>
        <div className={styles.flexWrap}>
          <Field
            name="receiveGoodAt"
            className="inputForSearch"
            type="datetime-local"
            placeholder="วันที่ เวลา รับสินค้า"
            disabled={!values.editMode}
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              ...(touched?.receiveGoodAt && errors?.receiveGoodAt && { border: '1px solid red' }),
            }}
          />
          <Field
            name="appointReceiveAt"
            className="inputForSearch"
            type="datetime-local"
            placeholder="วันที่ เวลา นัดรับ"
            disabled={!values.editMode}
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              ...(touched?.appointReceiveAt && errors?.appointReceiveAt && { border: '1px solid red' }),
            }}
          />
        </div>
        <div className={styles.flexWrap}>
          <Field
            name="receiverName"
            className="inputForSearch"
            type="text"
            disabled={!values.editMode}
            placeholder="ชื่อผู้รับ"
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              ...(touched?.receiverName && errors?.receiverName && { border: '1px solid red' }),
            }}
          />
          <Field
            name="phoneNumber"
            className="inputForSearch"
            type="string"
            placeholder="เบอร์โทรศัพท์"
            disabled={!values.editMode}
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              ...(touched?.phoneNumber && errors?.phoneNumber && { border: '1px solid red' }),
            }}
          />
          {!values.editMode ? (
            <div style={{ display: 'flex', width: '-webkit-fill-available' }}>
              <Field
                name="googleMap"
                className="inputForSearch"
                type="string"
                placeholder="Google Map"
                disabled={!values.editMode}
                style={{
                  maxWidth: '385.6px',
                  display: 'flex',
                  ...(touched?.googleMap && errors?.googleMap && { border: '1px solid red' }),
                }}
              />
              <a className={styles.linkTogoogleMap} href={values.googleMap}>
                นำทาง
              </a>
            </div>
          ) : (
            <>
              <Field
                name="googleMap"
                className="inputForSearch"
                type="string"
                placeholder="Google Map"
                disabled={!values.editMode}
                style={{
                  maxWidth: '385.6px',
                  display: 'flex',
                  ...(touched?.googleMap && errors?.googleMap && { border: '1px solid red' }),
                }}
              />
            </>
          )}
        </div>
        <div className={styles.flexWrap}>
          <Field
            name="fullAddress"
            as="textarea"
            className="inputForSearch"
            disabled={!values.editMode}
            rows={4}
            placeholder="ที่อยู่"
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              height: 'auto',
              ...(touched?.fullAddress && errors?.fullAddress && { border: '1px solid red' }),
            }}
          />
          <Field
            name="remark"
            as="textarea"
            className="inputForSearch"
            disabled={!values.editMode}
            rows={4}
            placeholder="รายละเอียด"
            style={{
              maxWidth: '385.6px',
              display: 'flex',
              height: 'auto',
              ...(touched?.remark && errors?.remark && { border: '1px solid red' }),
            }}
          />
        </div>
        <input
          type="file"
          id="file"
          onChange={(e) => handleUploadImage(e)}
          accept="image/*"
          disabled={!values.editMode}
        />
        <label htmlFor="file" className={`${styles.buttonUploadFile}`}>
          เพิ่มรูป
        </label>
        <div className={styles.groupImages}>
          {values.listImageUri.map((data: any, index: number) => (
            <div className={styles.groupImage} key={index}>
              <img src={data.imageUri} width="200" height="100" />
              <p style={{ fontSize: '10px' }}>เมื่อ {data.createdAt.replace('T', ' ').split('.')[0]}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/delivery-note/document/list">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
        {values.editMode ? (
          <>
            <button
              type="button"
              className="styleBtn"
              onClick={() => onClickRemoveDoc()}
              style={{ border: '1px solid #F44336', backgroundColor: 'white', color: 'black' }}
            >
              ลบเอกสาร
            </button>
            <button type="submit" className="styleBtn">
              บันทึก
            </button>
            <button
              type="button"
              className="styleBtn"
              onClick={() => handleSubmit()}
              disabled={!values.checkAllDocumentDone}
            >
              ส่งเอกสาร
            </button>
          </>
        ) : (
          <Link to={`/inventory/delivery-note/detail/check-in/${props.match.params.id}`}>
            <button type="button" className="styleBtn">
              ถัดไป
            </button>
          </Link>
        )}
      </section>
      {ModalAlert(values.isModalOpen, values.successSubmit, setFieldValue)}
    </Form>
  )
}

const EnhancedInventoryDeliveryNoteDetailAddPage = withFormik({
  mapPropsToValues: (props) => ({
    editMode: false,
    rawDataFromProps: [],
    selected: 0,
    checkAllDocumentDone: false,
    successSubmit: '',
    isModalOpen: false,

    id: '',
    remark: '',
    receiveGoodAt: '',
    appointReceiveAt: '',
    receiverName: '',
    phoneNumber: '',
    googleMap: '',
    fullAddress: '',
    listImageUri: [],
  }),
  validate: (values) => {
    return validateIgnoreField(
      values,
      [
        'editMode',
        'rawDataFromProps',
        'selected',
        'checkAllDocumentDone',
        'successSubmit',
        'isModalOpen',
        'listImageUri',
      ],
      '*',
    )
  },
  handleSubmit: (values, { setFieldValue }) => {
    const q = `mutation ($input: DeliveryOrderEdit!) {
      deliveryOrderEdit(input: $input) {
        id
      }
    }`
    // Where to put the variables
    const v = {
      input: {
        id: parseInt(values.id),
        remark: values.remark,
        receiveGoodAt: new Date(values.receiveGoodAt).toISOString(),
        appointReceiveAt: new Date(values.appointReceiveAt).toISOString(),
        receiverName: values.receiverName,
        phoneNumber: values.phoneNumber,
        googleMap: values.googleMap,
        fullAddress: values.fullAddress,
        otherRemark: '',
        imageUri: [...values.listImageUri],
      },
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'สำเร็จ', false)
        values.rawDataFromProps[values.selected].status = 'เรียบร้อย'
        values.rawDataFromProps[values.selected].remark = values.remark
        values.rawDataFromProps[values.selected].receiveGoodAt = values.receiveGoodAt
        values.rawDataFromProps[values.selected].appointReceiveAt = values.appointReceiveAt
        values.rawDataFromProps[values.selected].receiverName = values.receiverName
        values.rawDataFromProps[values.selected].phoneNumber = values.phoneNumber
        values.rawDataFromProps[values.selected].googleMap = values.googleMap
        values.rawDataFromProps[values.selected].fullAddress = values.fullAddress
        values.rawDataFromProps[values.selected].listImageUri = values.listImageUri
        setFieldValue('rawDataFromProps', values.rawDataFromProps, false)
        const findStatusDoc = values.rawDataFromProps.find((element: any) => element.status === 'รอดำเนินการ')
        if (!findStatusDoc) {
          return setFieldValue('checkAllDocumentDone', true, false)
        }
      })
      .catch((err) => {
        console.log('err', err.response)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
      })
  },
})(InventoryDeliveryNoteDetailAddPage)

export default EnhancedInventoryDeliveryNoteDetailAddPage
