import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { Text, ITextProps } from 'office-ui-fabric-react'

import styles from './index.module.scss'
import menuItems from './menuItems'
import HomeSVG from './home.svg'
import CRMSVG from './crm.svg'
import AddSVG from './add.svg'
import RemoveSVG from './remove.svg'
import JobOrderSVG from './joborder.svg'
import ScheduleSVG from './schedule.svg'
import ApprovedSVG from './approved.svg'
import HomeModuleSVG from './homeModule.svg'

import parse from 'html-react-parser'
import DocumentTitle from '../../inventory/common/document-title'

import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'

const SaleMainPage: React.FC = () => {
  const iconList: { [key: string]: string } = {
    HomeSVG: HomeSVG,
    CRMSVG: CRMSVG,
    AddSVG: AddSVG,
    RemoveSVG: RemoveSVG,
    JobOrderSVG: JobOrderSVG,
    ApprovedSVG: ApprovedSVG,
    ScheduleSVG: ScheduleSVG,
    HomeModuleSVG: HomeModuleSVG,
  }

  const [fullname, setFullname] = useState('')
  const token = useSelector((state: any) => state.token)

  useTokenInitializer()

  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
  }, [token.name])

  DocumentTitle('Sale', '')

  return (
    <section className={styles.section1}>
      <h1 className={styles.headerTitle}>หน้าแรก</h1>
      <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [{fullname}]</span>
      <div className={styles.menuItemsWrapper}>
        {menuItems.map(({ name, imgName, linkTo }, index) => (
          <Link key={index} to={linkTo}>
            <button key={index} style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}>
              <img src={iconList[imgName]} />
              <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
                {parse(name)}
              </Text>
            </button>
          </Link>
        ))}
      </div>
    </section>
  )
}

export default SaleMainPage
