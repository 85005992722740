import React, { useState } from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import ArrowDownSVG from './arrowDown.svg'
import ArrowUpSVG from './arrowUp.svg'
import { FormatDateTime } from '../../common/dateFormat'

export const componantChildComment = () => {
  const { values, errors, touched, setFieldValue, submitForm } = useFormikContext<any>()
  const [sort, setSort] = useState(false)

  const functionRenderComment = (data: any, index: number) => {
    if (data.commentType === 'textImage' || data.commentType === 'changeStatus') {
      return (
        <div key={index} className={styles.comment}>
          <span className={styles.dateName}>วันที่ {FormatDateTime(data.createdAt)} - โดย Salename</span>
          <p>{data.description}</p>
          <div className={`${styles.width100} ${styles.newRow}`}>
            {data.attachments.length !== 0
              ? data.attachments.map((uri: string, index: number) => (
                  <img key={index} src={uri} width="70" height="70" />
                ))
              : ''}
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <h4 style={{ cursor: 'pointer', marginTop: '10px' }} onClick={() => setSort(sort ? false : true)}>
        {sort ? (
          <>
            เรียงจากใหม่ไปเก่า <img src={ArrowUpSVG} />
          </>
        ) : (
          <>
            เรียงจากเก่าไปใหม่ <img src={ArrowDownSVG} />
          </>
        )}
      </h4>

      <div className={styles.groupComment}>
        {sort
          ? values.comments
              .slice(0)
              .reverse()
              .map((data: any, index: number) => {
                return functionRenderComment(data, index)
              })
          : values.comments.map((data: any, index: number) => {
              return functionRenderComment(data, index)
            })}
      </div>
    </>
  )
}
