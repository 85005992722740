const validateIgnoreField = (values: any, listIgnore: string[], errMsg: string) => {
  const errors: any = {}
  Object.entries(values)
    .filter((element) => {
      const key = element[0]
      return !listIgnore.includes(key)
    })
    .forEach((element) => {
      if (element[1] === '') {
        errors[element[0]] = errMsg
      }
    })
  return errors
}

export default validateIgnoreField
