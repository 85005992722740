import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { Text, ITextProps } from 'office-ui-fabric-react'

import styles from './index.module.scss'
import menuItems from './menuItems'
import AddSVG from './add.svg'
import ListSVG from './list.svg'

import parse from 'html-react-parser'
import DocumentTitle from '../common/document-title'

import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'

const InventoryDeliveryNotePage: React.FC = (props: any) => {
  const iconList: { [key: string]: string } = {
    AddSVG: AddSVG,
    ListSVG: ListSVG,
  }
  DocumentTitle('Inventory', 'การส่งสินค้า')
  useTokenInitializer()

  const [division, setDivision] = useState('')
  const token = useSelector((state: any) => state.token)
  useEffect(() => {
    if (!token.name) return
    setDivision(token.division)
  }, [token])

  return (
    <section className={styles.section1}>
      <h1 className={styles.headerTitle}>การส่งสินค้า</h1>
      <p className={styles.subHeaderTitle}>กรุณาเลือกรายการ</p>
      <div className={styles.menuItemsWrapper}>
        {menuItems.map(({ name, imgName, linkTo }, index) => {
          if (division === 'ขนส่ง' && name === 'สร้างงานส่ง') return
          return (
            <Link key={index} to={linkTo}>
              <button key={index} style={!name ? { backgroundColor: 'white', boxShadow: 'none' } : {}}>
                <img src={iconList[imgName]} />
                <Text variant={'mediumPlus' as ITextProps['variant']} className={styles.fontRobotoRegular}>
                  {parse(name)}
                </Text>
              </button>
            </Link>
          )
        })}
      </div>
    </section>
  )
}

export default InventoryDeliveryNotePage
