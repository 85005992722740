interface IMenuItem {
  name: string
  imgName: string
  linkTo: string
  fuction: boolean
}
const menuItems: IMenuItem[] = [
  { name: 'สร้างรายการใหม่', imgName: 'AddSVG', linkTo: '', fuction: true },
  { name: 'ดูรายการเช็ค Stock', imgName: 'ListSVG', linkTo: '/inventory/stock/document/list', fuction: false },
  { name: 'ย้อนกลับ', imgName: '', linkTo: '/inventory', fuction: false },
  { name: '', imgName: '', linkTo: '#', fuction: false },
]

export default menuItems
