import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
import axios from 'axios'
import { urlApi } from '../../inventory/common/configUrlApi'

interface IAtableFormatSaleModule {
  colHead: string[]
  rowBodyData: any[]
  typeHead: number
  typeBody: number
  Field: any
  setFieldValue: any
  values: any
  viewMode: boolean
}

export const TABLE_HEAD_TYPES = {
  NONE: 0,
  FIRST_COLUMN_IS_BUTTON: 1,
}

export const TABLE_BODY_TYPES = {
  NONE: 0,
  FIRST_COLUMN_IN_ROW_IS_BUTTON: 1,
}

const TableCommonCompanant: React.FC<IAtableFormatSaleModule> = ({
  colHead,
  rowBodyData,
  typeBody,
  Field,
  setFieldValue,
  values,
  viewMode,
}) => {
  const [listSuggestSku, setListSuggestSku] = useState([])

  const emptyRow: any = {
    id: rowBodyData.length,
    seq: rowBodyData.length,
    productCode: null,
    color: '',
    surface: '',
    name: '',
    thickness: '',
    width: '',
    length: '',
    lengthActual: '',
    sheet: '',
    total: '',
    totalPerMeter: '',
    discount: '',
    totalNet: '',
  }

  useEffect(() => {
    const v = {
      input: '',
      pageIndex: 0,
      maxResults: 5,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: qSuggestSku,
        variables: v,
      },
    })
      .then((result) => {
        const suggestSku = result.data.data.suggestSku
        setListSuggestSku(suggestSku.results)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [rowBodyData])

  const addRows = (): void => {
    emptyRow.id = rowBodyData.length + 1
    emptyRow.seq = rowBodyData.length + 1
    setFieldValue('productList', [...rowBodyData, emptyRow], false)
  }

  const deleteRows = (e: any): void => {
    if (e.target.id === '0') return
    rowBodyData.splice(e.target.id, 1)
    console.log(rowBodyData)
    setFieldValue('productList', [...rowBodyData], false)
  }

  const handleChangeProductCode = (key: string, value: string) => {
    const afterCutString: string = value.substring(0, 9)
    setFieldValue(`${key}.productCode`, afterCutString, false)

    if (afterCutString.length === 9) {
      const v = {
        productCodePartial: afterCutString,
      }

      axios({
        url: urlApi,
        method: 'post',
        data: {
          query: qOnlyOneSKU,
          variables: v,
        },
      })
        .then((result) => {
          const OnlyOneSku = result.data.data.listSku.results[0]
          console.log('OnlyOneSku', OnlyOneSku)
          setFieldValue(`${key}.productCode`, OnlyOneSku.productCode, false)
          setFieldValue(`${key}.color`, OnlyOneSku.color.name, false)
          setFieldValue(`${key}.surface`, OnlyOneSku.surface.name, false)
          setFieldValue(`${key}.name`, OnlyOneSku.name, false)

          setFieldValue(`${key}.total`, 0, false)
          setFieldValue(`${key}.discount`, 0, false)
          setFieldValue(`${key}.totalNet`, 0, false)
          // รอ เรื่อง SPec และ จำนวนแผ่น
          setFieldValue(`${key}.thickness`, OnlyOneSku.depth, false)
          setFieldValue(`${key}.width`, OnlyOneSku.width, false)
          setFieldValue(`${key}.length`, OnlyOneSku.length, false)
          setFieldValue(`${key}.totalPerMeter`, OnlyOneSku.pricePerMeter, false)
          // setFieldValue(`${key}.productCode`, OnlyOneSku.productCode, false)
        })
        .catch((err) => {
          console.log('err', err.response)
        })
    } else {
      // setFieldValue(`${key}.productCode`, '', false)
      setFieldValue(`${key}.color`, '', false)
      setFieldValue(`${key}.surface`, '', false)
      setFieldValue(`${key}.name`, '', false)

      setFieldValue(`${key}.total`, 0, false)
      setFieldValue(`${key}.totalPerMeter`, 0, false)
      setFieldValue(`${key}.discount`, 0, false)
      setFieldValue(`${key}.totalNet`, 0, false)
      // รอ เรื่อง SPec และ จำนวนแผ่น
      setFieldValue(`${key}.thickness`, 0, false)
      setFieldValue(`${key}.width`, 0, false)
      setFieldValue(`${key}.length`, 0, false)
      setFieldValue(`${key}.lengthActual`, 0, false)
      setFieldValue(`${key}.sheet`, 0, false)
      // setFieldValue(`${key}.productCode`, OnlyOneSku.productCode, false)
    }
  }

  return (
    <table>
      <thead>
        <tr>
          {colHead.map((data, index) => {
            let borderTable = ''
            if (index === 0) {
              borderTable = 'borderLeftRadius'
            } else if (colHead.length === index + 1) {
              borderTable = 'borderRightRadius'
            }
            return (
              <th className={borderTable} key={index}>
                {data}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {rowBodyData.map((data, rowIndex) => {
          return (
            <tr key={rowIndex}>
              {Object.keys(data).map(function (key, index) {
                if (index === 0 && typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON) {
                  return (
                    <td key={index}>
                      {rowBodyData.length - 1 === rowIndex ? (
                        <button
                          className="styleBtnForTable"
                          type="button"
                          onClick={addRows}
                          style={{ marginRight: '5px' }}
                          disabled={!viewMode ? false : true}
                        >
                          เพิ่ม
                        </button>
                      ) : (
                        ''
                      )}
                      <button
                        id={`${rowIndex}`}
                        className="styleBtnForTable"
                        type="button"
                        onClick={deleteRows}
                        disabled={!viewMode ? false : true}
                      >
                        ลบ
                      </button>
                    </td>
                  )
                } else if (index === 1 && typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON) {
                  return (
                    <td key={index}>
                      <h4>{rowIndex + 1}</h4>
                    </td>
                  )
                } else if (index === 2 && typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON) {
                  return (
                    <td key={index}>
                      <Field
                        type="text"
                        name={`productList[${rowIndex}].productCode`}
                        list="browsers"
                        className="inputOriginal"
                        onChange={(e: any) => handleChangeProductCode(`productList[${rowIndex}]`, e.target.value)}
                        disabled={!viewMode ? false : true}
                      />
                      <datalist id="browsers">
                        {listSuggestSku.map((sku: any, indexSku: number) => {
                          return (
                            <option key={indexSku} value={`${sku.productCode}-${sku.name}`}>
                              {sku.profile}/{sku.type.name}/{sku.color.name}/{sku.spec}/{sku.surface.name}
                            </option>
                          )
                        })}
                      </datalist>
                    </td>
                  )
                } else if (
                  (index === 9 || index === 10 || index === 13) &&
                  typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON
                ) {
                  return (
                    <td key={index}>
                      <Field
                        id={`${rowIndex}`}
                        name={`productList[${rowIndex}].${key}`}
                        type="number"
                        className="inputOriginal"
                        min={0}
                        disabled={!viewMode ? (values.productList[rowIndex].surface !== '' ? false : true) : true}
                      />
                    </td>
                  )
                } else if (index === 11 && typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON) {
                  return (
                    <td key={index}>
                      {(values.productList[rowIndex].lengthActual * values.productList[rowIndex].sheet)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </td>
                  )
                } else if (index === 14 && typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON) {
                  return (
                    <td key={index}>
                      {(
                        values.productList[rowIndex].lengthActual *
                          values.productList[rowIndex].sheet *
                          values.productList[rowIndex].totalPerMeter -
                        values.productList[rowIndex].discount
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </td>
                  )
                } else {
                  return (
                    <td key={index}>
                      <h4>{data[key]}</h4>
                    </td>
                  )
                }
                // End
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
TableCommonCompanant.propTypes = {
  colHead: PropTypes.any,
  rowBodyData: PropTypes.any,
  typeHead: PropTypes.number.isRequired,
  typeBody: PropTypes.number.isRequired,
  Field: PropTypes.any,
  setFieldValue: PropTypes.any,
  values: PropTypes.any,
  viewMode: PropTypes.any,
}

export default TableCommonCompanant

const qSuggestSku = `query ($input: String, $maxResults: Int, $pageIndex: Int) {
  suggestSku(input: $input, pageIndex: $pageIndex, maxResults: $maxResults) {
    totalCount
    pageIndex
    maxResults
    results {
      productCode
      name
      profile
      spec
      product {
        name
      }
      color {
        name
      }
      surface {
        name
      }
      type {
        name
      }
    }
  }
}`

const qOnlyOneSKU = `query ($productCodePartial: String) {
  listSku(productCodePartial: $productCodePartial) {
    results {
      id
      name
      productCode
      profile
      spec
      length
      width
      depth
      pricePerMeter
      kilogramPerMeter
      color {
        name
      }
      surface {
        name
      }
      type {
        name
      }
    }
  }
}`
