import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import axios from 'axios'
import { Link } from 'react-router-dom'

import DocumentTitle from '../common/document-title'

import { useBoolean } from '@uifabric/react-hooks'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from '../../common/configAws'
import { urlApi } from '../common/configUrlApi'
import ModalAlert from '../common/modalNotFormik'

import useTokenInitializer from '../../hooks/token-initializer'

const InventoryItemMasterDataListEditIDPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const [productCode, setProductCode] = useState('')
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [profile, setProfile] = useState('')
  const [color, setColor] = useState('')

  const [width, setWidth] = useState('')
  const [length, setlength] = useState('')
  const [depth, setDepth] = useState('')

  const [finish, setFinish] = useState('')

  const [piecePerP, setPiecePerP] = useState('')
  const [weightPerM, setWeightPerM] = useState('')
  const [pricePerM, setPricePerM] = useState('')

  const [imageUri, setImageUri] = useState<string>('')
  const [imageUri2D, setImageUri2D] = useState<string>('')

  const [listColor, setListColor] = useState([])
  const [listProduct, setListProduct] = useState([])
  const [listSurface, setListSurface] = useState([])
  const [listType, setListType] = useState([])

  const [successSubmit, setSuccessSubmit] = useState('')
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  DocumentTitle('Inventory', 'เรียกดูสินค้า')
  useTokenInitializer()

  // Fetch Fact Item
  useEffect(() => {
    // Where to put the query
    const q = `query viewSkuPropertyAllListType($pgIdx: Int, $pgSz: Int) {
      viewSkuPropertyAll {
        listType(pageIndex: $pgIdx, maxResults: $pgSz) {
          totalCount
          pageIndex
          results {
            id
            code
            name
          }
        }
        listColor(pageIndex: 0, maxResults: 9999) {
          totalCount
          pageIndex
          results {
            id
            code
            name
          }
        }
        listProduct(pageIndex: 0, maxResults: 9999) {
          totalCount
          pageIndex
          results {
            id
            code
            name
          }
        }
        listSurface(pageIndex: 0, maxResults: 9999) {
          totalCount
          pageIndex
          results {
            id
            code
            name
          }
        }
      }
    }`
    // Where to put the variables
    const v = {}

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const viewSkuPropertyAll = result.data.data.viewSkuPropertyAll
      // console.log('viewSkuPropertyAll', viewSkuPropertyAll)
      setListColor(viewSkuPropertyAll.listColor.results)
      setListProduct(viewSkuPropertyAll.listProduct.results)
      setListSurface(viewSkuPropertyAll.listSurface.results)
      setListType(viewSkuPropertyAll.listType.results)
    })
  }, [])

  // Fetch Info item
  useEffect(() => {
    // Where to put the query
    const q = `query viewOneSku($id: ID!) {
      viewSku(id: $id) {
        id
        name
        productCode
        profile
        spec
        uri
        uri2D
        colorId
        length
        width
        depth
        qtyPerPack(packId: 1)
        pricePerMeter
        kilogramPerMeter
        onHandStatistic {
          available
          ordered
          inStock
          committed
        }
        product {
          id
        }
        color {
          id
          code
          name
        }
        surfaceId
        surface {
          id
          code
          name
        }
        typeId
        type {
          id
          code
          name
        }
        updatedAt
        createdAt
      }
    }`
    // Where to put the variables
    const v = {
      id: props.match.params.id,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const viewSku = result.data.data.viewSku
      console.log('viewSku', viewSku)
      setProductCode(viewSku.productCode)
      setName(viewSku.name)
      setImageUri(viewSku.uri)
      setImageUri2D(viewSku.uri2D)
      setCode(viewSku.product.id)
      setProfile(viewSku.type.id)
      setColor(viewSku.color.id)
      setFinish(viewSku.surface.id)
      setWidth(viewSku.width)
      setlength(viewSku.length)
      setDepth(viewSku.depth)
      setPiecePerP(viewSku.qtyPerPack)
      setWeightPerM(viewSku.kilogramPerMeter)
      setPricePerM(viewSku.pricePerMeter)
      setLoading(false)
    })
  }, [])

  const handleSubmit = () => {
    const q = `mutation editSku($input: EditSku!) {
      editSku(input: $input) {
        id
        isActive
        name
        productCode
        profile
        productId
        colorId
        surfaceId
        typeId
      }
    }`
    // Where to put the variables
    const v = {
      input: {
        id: props.match.params.id,
        uri: imageUri,
        uri2D: imageUri2D,
        name: name,
        productId: code,
        surfaceId: finish,
        colorId: color,
        typeId: profile,
        spec: `${depth}x${width}x${length}`,
        qtyPerPack: parseFloat(piecePerP),
        length: parseInt(length),
        width: parseInt(width),
        depth: parseInt(depth),
        pricePerMeter: parseFloat(pricePerM),
        kilogramPerMeter: parseFloat(weightPerM),
        uomGroupId: 1,
        packGroupId: 1,
        profile: 'any',
      },
    }
    console.log(v)

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((res) => {
        console.log('res', res.data.data)
        showModal()
        setSuccessSubmit('แก้ไขสำเร็จ')
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
      })
  }

  const handleUploadImage = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]

    if (file.length !== 0) {
      const albumPhotosKey = encodeURIComponent('ItemMasterData') + '/'
      const photoKey = albumPhotosKey + props.match.params.id
      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })

      const promise = upload.promise()

      promise.then(
        function (data) {
          console.log('data', data)
          alert('Successfully uploaded photo.')
          setImageUri(data.Location)
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  const handleUploadImage2D = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]

    if (file.length !== 0) {
      const albumPhotosKey = encodeURIComponent('ItemMasterData') + '/'

      const photoKey = albumPhotosKey + props.match.params.id + '2D'

      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })

      const promise = upload.promise()

      promise.then(
        function (data) {
          alert('Successfully uploaded photo.')
          console.log('data', data)
          setImageUri2D(data.Location)
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  return (
    <>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>
      <div className={styles.pageWrapper}>
        <h1 className={styles.headerTitle}>เรียกดูสินค้า</h1>
        <section className={styles.sectionItem}>
          <div>
            <h4 style={{ margin: '0 0 10px 0' }}>รูปภาพสินค้า</h4>
            <div className={styles.skuImage}>
              <img src={imageUri ? imageUri : 'https://fakeimg.pl/190x150'} alt="SKU Image" width="190" height="150" />
              <input type="file" id="file" onChange={(e) => handleUploadImage(e)} accept="image/*" />
              <label htmlFor="file" className={`${styles.buttonUploadFile}`}>
                เลือกรูปภาพ
              </label>
            </div>
            <h4 style={{ margin: '10px 0' }}>รูปภาพ 2D</h4>
            <div className={styles.skuImage}>
              <img
                src={imageUri2D ? imageUri2D : 'https://fakeimg.pl/190x150'}
                alt="SKU Image"
                width="190"
                height="150"
              />
              <input type="file" id="file2" onChange={(e) => handleUploadImage2D(e)} accept="image/*" />
              <label htmlFor="file2" className={`${styles.buttonUploadFile}`}>
                เลือกรูปภาพ
              </label>
            </div>
          </div>

          <div>
            {/* <h4 style={{ margin: '10px 0 0' }}>ชื่อสินค้า</h4>
            <input
              type="text"
              className="inputForSearch"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input> */}
            <div className={styles.groupInput}>
              <div className={styles.colInput}>
                <h4>รายละเอียด</h4>
                <select
                  className={styles.select}
                  name="code"
                  id="code"
                  onChange={(e) => setCode(e.target.value)}
                  disabled
                >
                  <option value=""></option>
                  {listProduct.map((data: any, index) => (
                    <option key={index} value={data.id} selected={data.id === code ? true : false}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <h4>สี</h4>
                <select
                  className={styles.select}
                  name="color"
                  id="color"
                  onChange={(e) => setColor(e.target.value)}
                  disabled
                >
                  <option value=""></option>
                  {listColor.map((data: any, index) => (
                    <option key={index} value={data.id} selected={data.id === color ? true : false}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <h4>กว้าง</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                ></input>
                <h4>ยาว</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={length}
                  onChange={(e) => setlength(e.target.value)}
                ></input>
                <h4>น้ำหนักต่อเมตร</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={weightPerM}
                  onChange={(e) => setWeightPerM(e.target.value)}
                ></input>
              </div>
              <div className={styles.colInput}>
                <h4>ประเภท</h4>
                <select
                  className={styles.select}
                  name="profile"
                  id="profile"
                  onChange={(e) => setProfile(e.target.value)}
                  disabled
                >
                  <option value=""></option>
                  {listType.map((data: any, index) => (
                    <option key={index} value={data.id} selected={data.id === profile ? true : false}>
                      {data.name}
                    </option>
                  ))}
                </select>

                <h4>ผิว</h4>
                <select
                  className={styles.select}
                  name="finish"
                  id="finish"
                  onChange={(e) => setFinish(e.target.value)}
                  disabled
                >
                  <option value=""></option>
                  {listSurface.map((data: any, index) => {
                    return (
                      <option key={index} value={data.id} selected={data.id === finish ? true : false}>
                        {data.name}
                      </option>
                    )
                  })}
                </select>
                <h4>หนา</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={depth}
                  onChange={(e) => setDepth(e.target.value)}
                ></input>
                <h4>จำนวนแผ่นต่อห่อ</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={piecePerP}
                  onChange={(e) => setPiecePerP(e.target.value)}
                ></input>
                <h4>ราคาต่อเมตร</h4>
                <input
                  type="number"
                  min={0}
                  className="inputForSearch"
                  value={pricePerM}
                  onChange={(e) => setPricePerM(e.target.value)}
                ></input>
              </div>
            </div>
            <h4 style={{ margin: '10px 0 0' }}>รหัสสินค้า</h4>
            <input
              type="text"
              className="inputForSearch"
              value={productCode}
              disabled
              // onChange={(e) => setProductCode(e.target.value)}
            ></input>
          </div>
        </section>
      </div>
      <section className={styles.groupButtonDestop}>
        <button type="button" className="styleBtn" onClick={() => handleSubmit()}>
          บันทึก
        </button>
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <button type="button" className="styleBtn" onClick={() => props.history.goBack()}>
          ย้อนกลับ
        </button>
      </section>
      {ModalAlert(isModalOpen, hideModal, successSubmit)}
    </>
  )
}

export default InventoryItemMasterDataListEditIDPage
