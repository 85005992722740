export function addTeamIfNotExists(originalTeamString: string, teamId: string): string {
  if (!originalTeamString) return teamId
  const teamIdList = originalTeamString?.match(/.{13}/g)
  const foundTeam = teamIdList?.find((ele) => ele === teamId)

  return foundTeam ? originalTeamString : originalTeamString + teamId
}

export function removeTeamIfNotExists(originalTeamString: string | undefined | null, teamId: string): string {
  if (!originalTeamString) return teamId
  const teamIdList = originalTeamString?.match(/.{13}/g)
  const foundTeam = teamIdList?.find((ele) => ele === teamId)

  return foundTeam ? originalTeamString.replace(teamId, '') : originalTeamString
}

export function decodeTeamIdList(originalTeamString: string) {
  if (!originalTeamString) return
  const teamIdList = originalTeamString?.match(/.{13}/g)

  return teamIdList
}
