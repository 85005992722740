import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import BinSVG from './bin.svg'

import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'
import { useId } from '@uifabric/react-hooks'

interface IAmeliaFace {
  colHead: string[]
  rowBody: any[]
  typeHead: number
  typeBody: number
  onChangequantityLineItems?: CallableFunction
  link?: string
  linkForEditDoc?: string
  removeButtonClassUniqueSKU?: boolean
  handleRemoveLineItem?: CallableFunction
  onChangeCheckboxItem?: CallableFunction
  fuctionForFirstButton?: CallableFunction
  onClickPrintOneRow?: CallableFunction
  onClickPrintSKURow?: CallableFunction
  onClickPrintTotalRow?: CallableFunction
  onChangeFinanceLineItems?: CallableFunction
  disabledBool?: boolean
  listReason?: any[]
  onChangeReasonIdLineItems?: CallableFunction
  onChangeReamekLineItems?: CallableFunction
  onChangeManagerLineItems?: CallableFunction
  checkBooleanFormStatus?: boolean
  linkPDFReceive?: string
  onChangeCheckOrderLineItems?: CallableFunction
  onClickAssignDeliveryMan?: CallableFunction
  linkViewSchedule?: string
  lineItemsActiveForChecked?: any[]
}

export const TABLE_HEAD_TYPES = {
  NONE: 0,
  FIRST_COLUMN_IS_BUTTON: 1,
  HEAD_FOR_ITEM_MASTER_DATA: 2,
}

export const TABLE_BODY_TYPES = {
  NONE: 0,
  FIRST_COLUMN_IN_ROW_IS_BUTTON: 1,
  FIRST_COLUMN_IN_ROW_IS_BUTTON_FOR_PRINT_AND_FOUR_IS_INPUT: 2,
  FIRST_COLUMN_IN_ROW_IS_BUTTON_FOR_REMOVE_AND_FOUR_IS_INPUT: 3,
  FIRST_COLUMN_IN_ROW_IS_CHECKBOX_AND_THREE_IS_INPUT: 4,
  FOUR_IS_INPUT: 5,
  FOUR_IS_INPUT_AND_REASON: 6,
  FIRST_AND_END_COLUMN_IN_ROW_IS_BUTTON: 7,
  CHECKBOK_PAGE_APPROVE: 8,
  DELIVERY_NOTE_LIST_ORDER: 9,
  DELIVERY_NOTE_LIST_EMPLOYEE: 10,
  DELIVERY_NOTE_LIST: 11,
}

const dialogStyles = { main: { maxWidth: 450 } }

const dialogContentProps = {
  type: DialogType.normal,
  title: 'กรุณายืนยันคำสั่ง',
  closeButtonAriaLabel: 'Close',
  subText: 'คุณยังคงต้องการทำรายการดังต่อไปนี้ต่อไปหรือไม่?',
}

const TableCommonCompanant: React.FC<IAmeliaFace> = ({
  colHead,
  rowBody,
  typeBody,
  typeHead,
  onChangequantityLineItems,
  link,
  linkForEditDoc,
  removeButtonClassUniqueSKU,
  handleRemoveLineItem,
  onChangeCheckboxItem,
  fuctionForFirstButton,
  onClickPrintOneRow,
  onClickPrintSKURow,
  onClickPrintTotalRow,
  onChangeFinanceLineItems,
  disabledBool,
  listReason,
  onChangeReasonIdLineItems,
  onChangeReamekLineItems,
  onChangeManagerLineItems,
  checkBooleanFormStatus,
  linkPDFReceive,
  onChangeCheckOrderLineItems,
  onClickAssignDeliveryMan,
  linkViewSchedule,
  lineItemsActiveForChecked,
}) => {
  const [hideDialogPrintTotalRow, setHideDialogPrintTotalRow] = useState(true)
  const [hideDialogPrintSKURow, setHideDialogPrintSKURow] = useState(true)
  const [indexgPrintSKURow, setIndexPrintSKURow] = useState(null)

  const [hideDialogPrintOneRow, setHideDialogPrintOneRow] = useState(true)
  const [indexgPrintOneRow, setIndexPrintOneRow] = useState(null)

  const labelId: string = useId('dialogLabel')
  const subTextId: string = useId('subTextLabel')

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
    }),
    [labelId, subTextId],
  )

  return (
    <>
      <Dialog
        hidden={hideDialogPrintTotalRow}
        onDismiss={() => setHideDialogPrintTotalRow(hideDialogPrintTotalRow ? false : true)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setHideDialogPrintTotalRow(hideDialogPrintTotalRow ? false : true)
              if (onClickPrintTotalRow) return onClickPrintTotalRow()
            }}
            text="ยืนยัน"
          />
          <DefaultButton
            onClick={() => setHideDialogPrintTotalRow(hideDialogPrintTotalRow ? false : true)}
            text="ยกเลิก"
          />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideDialogPrintSKURow}
        onDismiss={() => setHideDialogPrintSKURow(hideDialogPrintSKURow ? false : true)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setHideDialogPrintSKURow(hideDialogPrintSKURow ? false : true)
              if (onClickPrintSKURow) return onClickPrintSKURow(indexgPrintSKURow)
            }}
            text="ยืนยัน"
          />
          <DefaultButton onClick={() => setHideDialogPrintSKURow(hideDialogPrintSKURow ? false : true)} text="ยกเลิก" />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideDialogPrintOneRow}
        onDismiss={() => setHideDialogPrintOneRow(hideDialogPrintOneRow ? false : true)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setHideDialogPrintOneRow(hideDialogPrintOneRow ? false : true)
              if (onClickPrintOneRow) return onClickPrintOneRow(indexgPrintOneRow)
            }}
            text="ยืนยัน"
          />
          <DefaultButton onClick={() => setHideDialogPrintOneRow(hideDialogPrintOneRow ? false : true)} text="ยกเลิก" />
        </DialogFooter>
      </Dialog>

      <table>
        <thead>
          <tr>
            {colHead.map((data, index) => {
              let borderTable = ''
              if (index === 0) {
                borderTable = 'borderLeftRadius'
              } else if (colHead.length === index + 1) {
                borderTable = 'borderRightRadius'
              }
              if (TABLE_HEAD_TYPES.HEAD_FOR_ITEM_MASTER_DATA === typeHead) {
                if (data === 'คงเหลือสุทธิ' || data === 'คงคลัง' || data === 'จอง' || data === 'ซื้อ') {
                  return (
                    <th className={borderTable} key={index} colSpan={2} style={{ textAlign: 'center', padding: '8px' }}>
                      {data}
                    </th>
                  )
                }

                return (
                  <th className={borderTable} key={index} rowSpan={2} style={{ padding: '0 5px 3px 5px' }}>
                    {data}
                  </th>
                )
              }
              return (
                <th className={borderTable} key={index}>
                  {data === 'พิมพ์ทั้งหมด' ? (
                    <button
                      className="styleBtnForTable"
                      style={{ marginRight: '10px' }}
                      type="button"
                      onClick={() => {
                        setHideDialogPrintTotalRow(hideDialogPrintTotalRow ? false : true)
                      }}
                    >
                      {data}
                    </button>
                  ) : (
                    data
                  )}
                </th>
              )
            })}
          </tr>
          {TABLE_HEAD_TYPES.HEAD_FOR_ITEM_MASTER_DATA === typeHead && (
            <tr>
              <th style={{ textAlign: 'center', padding: '0 5px 3px 5px' }}>แผ่น</th>
              <th style={{ textAlign: 'center', padding: '0 5px 3px 5px' }}>ห่อ</th>
              <th style={{ textAlign: 'center', padding: '0 5px 3px 5px' }}>แผ่น</th>
              <th style={{ textAlign: 'center', padding: '0 5px 3px 5px' }}>ห่อ</th>
              <th style={{ textAlign: 'center', padding: '0 5px 3px 5px' }}>แผ่น</th>
              <th style={{ textAlign: 'center', padding: '0 5px 3px 5px' }}>ห่อ</th>
              <th style={{ textAlign: 'center', padding: '0 5px 3px 5px' }}>แผ่น</th>
              <th style={{ textAlign: 'center', padding: '0 5px 3px 5px' }}>ห่อ</th>
            </tr>
          )}
        </thead>
        <tbody>
          {rowBody.map((data, rowIndex) => {
            if (typeof data === 'string') {
              return (
                <tr key={rowIndex}>
                  <td colSpan={colHead.length} className="uniqueSKU">
                    {!removeButtonClassUniqueSKU && (
                      <button
                        className="styleBtnForTable"
                        style={{ marginRight: '10px', backgroundColor: 'white', color: 'black', padding: '3px 5px' }}
                        type="button"
                        onClick={(e) => {
                          setHideDialogPrintSKURow(hideDialogPrintSKURow ? false : true)
                          setIndexPrintSKURow(rowBody[rowIndex + 1])
                        }}
                      >
                        พิมพ์
                      </button>
                    )}
                    {data}
                  </td>
                </tr>
              )
            }
            return (
              <tr key={rowIndex}>
                {Object.keys(data).map(function (key, index) {
                  if (
                    index === 0 &&
                    data[key] !== '-' &&
                    (typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON ||
                      typeBody === TABLE_BODY_TYPES.FIRST_AND_END_COLUMN_IN_ROW_IS_BUTTON)
                  ) {
                    return (
                      <td key={index}>
                        {!fuctionForFirstButton ? (
                          <Link to={`${data.documentStatusId !== 'DRAFT' ? link : linkForEditDoc}/${data[key]}`}>
                            <button className="styleBtnForTable" type="button">
                              เลือก
                            </button>
                          </Link>
                        ) : (
                          <button
                            className="styleBtnForTable"
                            type="button"
                            onClick={() => {
                              fuctionForFirstButton(data[key])
                            }}
                          >
                            เลือก
                          </button>
                        )}
                      </td>
                    )
                  } else if (
                    index === 0 &&
                    typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON_FOR_PRINT_AND_FOUR_IS_INPUT
                  ) {
                    return (
                      <td key={index}>
                        <button
                          className="styleBtnForTable"
                          type="button"
                          onClick={(e) => {
                            setHideDialogPrintOneRow(hideDialogPrintOneRow ? false : true)
                            setIndexPrintOneRow(data['lineNumber'])
                          }}
                        >
                          พิมพ์
                        </button>
                      </td>
                    )
                  } else if (
                    index === 0 &&
                    typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON_FOR_REMOVE_AND_FOUR_IS_INPUT
                  ) {
                    return (
                      <td key={index}>
                        <img
                          id={`${rowIndex}`}
                          src={BinSVG}
                          onClick={(e) => {
                            handleRemoveLineItem && handleRemoveLineItem((e.target as HTMLElement).id)
                          }}
                        />
                      </td>
                    )
                  } else if (
                    index === 0 &&
                    typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_CHECKBOX_AND_THREE_IS_INPUT
                  ) {
                    const matchLineItem =
                      lineItemsActiveForChecked &&
                      lineItemsActiveForChecked.find((ele) => `${ele.id}` === `${data['id']}`)
                    return (
                      <td key={index}>
                        <input
                          id={`${rowIndex}`}
                          type="checkbox"
                          value={`${data['itemID']}`}
                          onChange={(e) => {
                            onChangeCheckboxItem && onChangeCheckboxItem((e.target as HTMLElement).id, e.target.value)
                          }}
                          checked={matchLineItem?.isActive ? true : false}
                        />
                      </td>
                    )
                  } else if (
                    index === 3 &&
                    typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_CHECKBOX_AND_THREE_IS_INPUT
                  ) {
                    const matchLineItem =
                      lineItemsActiveForChecked &&
                      lineItemsActiveForChecked.find((ele) => `${ele.id}` === `${data['id']}`)
                    return (
                      <td key={index}>
                        <input
                          id={`${rowIndex}`}
                          type="number"
                          value={matchLineItem ? matchLineItem.amountPack : 0}
                          min={0}
                          className="inputOriginal"
                          onChange={(e) => {
                            onChangequantityLineItems &&
                              onChangequantityLineItems(
                                matchLineItem?.id,
                                Number.parseInt(e.target.value),
                                (e.target as HTMLElement).id,
                              )
                          }}
                          disabled={matchLineItem ? false : true}
                        />
                      </td>
                    )
                  } else if (
                    index === 3 &&
                    (typeBody === TABLE_BODY_TYPES.FOUR_IS_INPUT ||
                      typeBody === TABLE_BODY_TYPES.FOUR_IS_INPUT_AND_REASON)
                  ) {
                    return (
                      <td key={index}>
                        <input
                          id={`${rowIndex}`}
                          type="number"
                          min={0}
                          value={data[key]}
                          className="inputOriginal"
                          disabled={disabledBool || checkBooleanFormStatus}
                          onChange={(e) => {
                            onChangequantityLineItems &&
                              onChangequantityLineItems((e.target as HTMLElement).id, Number.parseInt(e.target.value))
                          }}
                        />
                      </td>
                    )
                  } else if (
                    index === 4 &&
                    (typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON_FOR_PRINT_AND_FOUR_IS_INPUT ||
                      typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON_FOR_REMOVE_AND_FOUR_IS_INPUT)
                  ) {
                    return (
                      <td key={index}>
                        <input
                          id={`${rowIndex}`}
                          type="number"
                          min={0}
                          value={data[key]}
                          className="inputOriginal"
                          onChange={(e) => {
                            onChangequantityLineItems &&
                              onChangequantityLineItems((e.target as HTMLElement).id, Number.parseInt(e.target.value))
                          }}
                        />
                      </td>
                    )
                  } else if (index === 5 && typeBody === TABLE_BODY_TYPES.CHECKBOK_PAGE_APPROVE) {
                    return (
                      <td key={index}>
                        <input
                          id={`${rowIndex}`}
                          type="checkbox"
                          value={data[key] ? '' : 'ACCOUNTANT'}
                          disabled={checkBooleanFormStatus}
                          checked={data[key] ? true : false}
                          onChange={(e) => {
                            onChangeFinanceLineItems &&
                              onChangeFinanceLineItems((e.target as HTMLElement).id, e.target.value)
                          }}
                        />
                      </td>
                    )
                  } else if (index === 6 && typeBody === TABLE_BODY_TYPES.CHECKBOK_PAGE_APPROVE) {
                    return (
                      <td key={index}>
                        <input
                          id={`${rowIndex}`}
                          type="checkbox"
                          value={data[key] ? '' : 'MANAGER'}
                          disabled={checkBooleanFormStatus}
                          checked={data[key] ? true : false}
                          onChange={(e) => {
                            onChangeManagerLineItems &&
                              onChangeManagerLineItems((e.target as HTMLElement).id, e.target.value)
                          }}
                        />
                      </td>
                    )
                  } else if (data[key] === 'สแกนแล้ว' || data[key] === 'ส่งแล้ว') {
                    return (
                      <td key={index}>
                        <label className="statusScanerGreen">{data[key]}</label>
                      </td>
                    )
                  } else if (data[key] === 'รอสแกน') {
                    return (
                      <td key={index}>
                        <label className="statusScanerRed">{data[key]}</label>
                      </td>
                    )
                  } else if (data[key] === 'ไม่ครบ' || data[key] === 'รอส่งงาน') {
                    return (
                      <td key={index}>
                        <label className="statusScanerOrange">{data[key]}</label>
                      </td>
                    )
                  } else if (data[key] === 'หาย' || data[key] === 'ยกเลิก') {
                    return (
                      <td key={index}>
                        <label className="statusScanerRed">{data[key]}</label>
                      </td>
                    )
                  } else if (
                    key === 'total' ||
                    key === 'totalPack' ||
                    key === 'stock' ||
                    key === 'stockPack' ||
                    key === 'commited' ||
                    key === 'commitedPack' ||
                    key === 'purchase' ||
                    key === 'purchasePack' ||
                    key === 'totalQtyUpToThisDocPack' ||
                    key === 'stockPack' ||
                    key === 'orderedIn' ||
                    key === 'orderedInPack' ||
                    key === 'reservedOut' ||
                    key === 'reservedOutPack' ||
                    key === 'soldOut' ||
                    key === 'soldOutPack'
                  ) {
                    return (
                      <td key={index} style={{ textAlign: 'center' }}>
                        {data[key]}
                      </td>
                    )
                  } else if (
                    // FOR DOWLOAD PDF
                    index === 5 &&
                    typeBody === TABLE_BODY_TYPES.FIRST_AND_END_COLUMN_IN_ROW_IS_BUTTON
                  ) {
                    return (
                      <td key={index}>
                        <Link to={`${linkPDFReceive}/${data[key]}`}>
                          <button className="styleBtnForTable" type="button">
                            พิมพ์
                          </button>
                        </Link>
                      </td>
                    )
                  } else if (index === 8 && typeBody === TABLE_BODY_TYPES.FOUR_IS_INPUT_AND_REASON) {
                    return (
                      <td key={index} style={{ padding: '2px 0' }}>
                        <select
                          id={`${rowIndex}`}
                          name="reasonId"
                          style={{ height: '30px', marginBottom: '5px', padding: '0 5px', maxWidth: '100px' }}
                          onChange={(e) => {
                            onChangeReasonIdLineItems &&
                              onChangeReasonIdLineItems((e.target as HTMLElement).id, e.target.value)
                          }}
                          disabled={checkBooleanFormStatus}
                        >
                          <option value=""></option>
                          {listReason &&
                            listReason.map((dataReason, index) => {
                              return (
                                <option
                                  value={dataReason.id}
                                  key={index}
                                  selected={data[key] && data[key].reasonId == dataReason.id ? true : false}
                                >
                                  {dataReason.name}
                                </option>
                              )
                            })}
                        </select>
                        <input
                          id={`${rowIndex}`}
                          type="text"
                          value={data[key].remark}
                          className="inputOriginal"
                          style={
                            data[key].reasonId == '1' ? { display: 'block', minWidth: '200px' } : { display: 'none' }
                          }
                          onChange={(e) => {
                            onChangeReamekLineItems &&
                              onChangeReamekLineItems((e.target as HTMLElement).id, e.target.value)
                          }}
                        />
                      </td>
                    )
                  } else if (key === 'isActive' || key === 'description' || key === 'key') {
                    // Not return this key
                    return
                  } else if (index === 0 && typeBody === TABLE_BODY_TYPES.DELIVERY_NOTE_LIST_ORDER) {
                    // DELIVERY_NOTE_LIST_ORDER
                    const matchLineItem =
                      lineItemsActiveForChecked &&
                      lineItemsActiveForChecked.find((ele) => `${ele.documentID}` === `${data['documentID']}`)
                    return (
                      <td key={index}>
                        <input
                          id={`${rowIndex}`}
                          type="checkbox"
                          value={data[key] ? '' : 'true'}
                          onChange={(e) => {
                            onChangeCheckOrderLineItems &&
                              onChangeCheckOrderLineItems((e.target as HTMLElement).id, e.target.value)
                          }}
                          checked={matchLineItem?.checkbox ? true : false}
                        />
                      </td>
                    )
                  } else if (index === 1 && typeBody === TABLE_BODY_TYPES.DELIVERY_NOTE_LIST_ORDER) {
                    // DELIVERY_NOTE_LIST_ORDER
                    return (
                      <td key={index}>
                        <Link to={`${link}/${data[key]}`}>
                          <button className="styleBtnForTable" type="button">
                            ดู
                          </button>
                        </Link>
                      </td>
                    )
                  } else if (index === 0 && typeBody === TABLE_BODY_TYPES.DELIVERY_NOTE_LIST_EMPLOYEE) {
                    // DELIVERY_NOTE_LIST_EMPLOYEE
                    return (
                      <td key={index}>
                        <button
                          className="styleBtnForTable"
                          id={`${rowIndex}`}
                          type="button"
                          onClick={(e) =>
                            onClickAssignDeliveryMan && onClickAssignDeliveryMan((e.target as HTMLElement).id)
                          }
                        >
                          เลือก
                        </button>
                      </td>
                    )
                  } else if (index === 1 && typeBody === TABLE_BODY_TYPES.DELIVERY_NOTE_LIST_EMPLOYEE) {
                    // DELIVERY_NOTE_LIST_EMPLOYEE
                    return (
                      <td key={index}>
                        <Link to={`${link}/${data[key]}`}>
                          <button className="styleBtnForTable" type="button">
                            ดูตาราง
                          </button>
                        </Link>
                      </td>
                    )
                  } else if (index === 0 && typeBody === TABLE_BODY_TYPES.DELIVERY_NOTE_LIST) {
                    return (
                      <td key={index}>
                        <Link to={`${link}/${data[key]}`}>
                          <button className="styleBtnForTable" type="button">
                            ดู
                          </button>
                        </Link>
                      </td>
                    )
                  }
                  return <td key={index}>{data[key]}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
TableCommonCompanant.propTypes = {
  colHead: PropTypes.any,
  rowBody: PropTypes.any,
  typeHead: PropTypes.number.isRequired,
  typeBody: PropTypes.number.isRequired,
  onChangequantityLineItems: PropTypes.func,
  link: PropTypes.string,
  linkForEditDoc: PropTypes.string,
  removeButtonClassUniqueSKU: PropTypes.bool,
  handleRemoveLineItem: PropTypes.func,
  onChangeCheckboxItem: PropTypes.func,
  fuctionForFirstButton: PropTypes.func,
  onClickPrintOneRow: PropTypes.func,
  onClickPrintSKURow: PropTypes.func,
  onClickPrintTotalRow: PropTypes.func,
  onChangeFinanceLineItems: PropTypes.func,
  disabledBool: PropTypes.bool,
  listReason: PropTypes.any,
  onChangeReasonIdLineItems: PropTypes.func,
  onChangeReamekLineItems: PropTypes.func,
  checkBooleanFormStatus: PropTypes.bool,
  linkPDFReceive: PropTypes.string,
  onChangeManagerLineItems: PropTypes.func,
  onChangeCheckOrderLineItems: PropTypes.func,
  onClickAssignDeliveryMan: PropTypes.func,
  linkViewSchedule: PropTypes.string,
  lineItemsActiveForChecked: PropTypes.any,
}

export default TableCommonCompanant
