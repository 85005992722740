import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import styles from './index.module.scss'
import DocumentTitle from '../common/document-title'
import { geolocated, GeolocatedProps } from 'react-geolocated'
import axios from 'axios'
import { urlApi } from '../common/configUrlApi'
import GoogleMapReact from 'google-map-react'
import * as AWS from 'aws-sdk'
import { FormatDateTime } from '../../common/dateFormat'
import useTokenInitializer from '../../hooks/token-initializer'

interface IDemoProps {
  label: string
}

const InventoryDeliveryNoteDetailCheckInPage: React.FC<IDemoProps & GeolocatedProps> = (props: any) => {
  const [loading, setLoading] = useState<any>(true)

  const [data, setData] = useState<any>([])
  const [deliveryImageUri, setDeliveryImageUri] = useState<any>([])
  const [fetch, setFetch] = useState<any>(0)
  DocumentTitle('Inventory', 'การส่งสินค้า')
  useTokenInitializer()

  useEffect(() => {
    const q = `query viewDeliveryOrder($id: ID!) {
        viewDeliveryOrder(id: $id) {
          id
          internalDocumentId
          documentTypeId
          documentStatusId
          referenceNumber
          remark
          longitude
          latitude
          createdAt
          updatedAt
          receiveGoodAt
          appointReceiveAt
          receiverName
          phoneNumber
          googleMap
          fullAddress
          otherRemark
          employeeId
          imageUri {
            imageUri
            createdAt
          }
          checkedInAt
          deliveryImageUri {
            imageUri
            createdAt
          }
        }
      }`

    const v = {
      id: props.match.params.id,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const viewDeliveryOrde = result.data.data['viewDeliveryOrder']
        console.log('viewDeliveryOrde', viewDeliveryOrde)
        setData([viewDeliveryOrde])
        setLoading(false)
        setDeliveryImageUri(viewDeliveryOrde.deliveryImageUri)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [fetch])

  const handleCheckIn = () => {
    const q = `mutation {
      deliveryOrderCheckin(id: ${data[0].id}, longitude: ${props.coords.longitude}, latitude: ${props.coords.latitude}) {
        id
        latitude
        longitude
      }
    }`
    const v = {}

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('result', result)
        setFetch(fetch + 1)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }

  const handleUploadImage = (e: any) => {
    const albumBucketName = 'neno-test-bucket'
    const bucketRegion = 'ap-southeast-1'
    const IdentityPoolId = 'ap-southeast-1:1142cda0-d843-4510-9e21-d35a6821cfde'

    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]
    if (file.length !== 0) {
      const fileName = file.name
      const albumPhotosKey =
        encodeURIComponent('deliveryNote') + '/' + encodeURIComponent(`${props.match.params.id}`) + '/'

      const photoKey = albumPhotosKey + fileName

      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: 'image/png',
        },
      })

      const promise = upload.promise()
      const tzoffset = new Date().getTimezoneOffset() * 60000
      const localISOTime = new Date(Date.now() - tzoffset).toISOString()

      promise.then(
        function (img) {
          alert('Successfully uploaded photo.')
          const mapper = {
            imageUri: img.Location,
            createdAt: localISOTime,
          }
          setDeliveryImageUri([...deliveryImageUri, mapper])
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  const returnMap = () => {
    if (data[0]?.checkedInAt) {
      return <SimpleMap longitude={data[0].longitude} latitude={data[0].latitude} />
    } else return
  }

  useEffect(() => {
    if (deliveryImageUri.length !== 0) {
      const q = `mutation ($input: DeliveryOrderEdit!) {
        deliveryOrderEdit(input: $input) {
          id
          deliveryImageUri{
            imageUri
          }
          imageUri {
            imageUri
          }
        }
      }`
      // Where to put the variables
      const v = {
        input: {
          id: parseInt(props.match.params.id),
          deliveryImageUri: [...deliveryImageUri],
        },
      }
      console.log(JSON.stringify(v))
      axios({
        url: urlApi,
        method: 'post',
        data: {
          query: q,
          variables: v,
        },
      })
        .then((result) => {
          console.log(result)
        })
        .catch((err) => {
          console.log('err', err.response)
        })
    }
  }, [deliveryImageUri])

  return (
    <>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>การส่งสินค้า</h1>
        <p className={styles.subHeaderTitle}>พนักงาน {data[0]?.employeeId}</p>
      </section>
      <section className={styles.cards}>
        <article className={styles.card}>
          <h4>เลขที่รายการส่งสินค้า</h4>
          <p>{data[0]?.internalDocumentId}</p>
          <p className={styles.statusDone}>เรียบร้อย</p>
        </article>
      </section>
      <section className={styles.bodyWrapper}>
        <div style={{ maxWidth: '450px' }}>{returnMap()}</div>
        <button
          type="button"
          className="styleBtn"
          style={{ margin: '10px 0', maxWidth: '425px', width: '-webkit-fill-available' }}
          onClick={() => handleCheckIn()}
          disabled={data[0]?.checkedInAt ? true : false}
        >
          Checkin
        </button>
        <p>เมื่อ {data[0]?.checkedInAt ? FormatDateTime(data[0]?.checkedInAt) : null}</p>
        <input type="file" id="file" onChange={(e) => handleUploadImage(e)} accept="image/*" />
        <label
          htmlFor="file"
          className={`${styles.buttonUploadFile}`}
          style={{ margin: '10px 0', maxWidth: '425px', width: '-webkit-fill-available', justifyContent: 'center' }}
        >
          เพิ่มรูป
        </label>
        <div className={styles.groupImages}>
          {deliveryImageUri.map((data: any, index: number) => (
            <div className={styles.groupImage} key={index}>
              <img src={data.imageUri} width="200" height="100" />
              <p style={{ fontSize: '10px' }}>เมื่อ {FormatDateTime(data.createdAt)}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to={`/inventory/delivery-note/detail/${props.match.params.id}`}>
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default geolocated()(InventoryDeliveryNoteDetailCheckInPage)

const AnyReactComponent = (props: any) => {
  const { name } = props
  return (
    <div>
      <div
        className={`${styles.pin} ${styles.bounce}`}
        style={{ backgroundColor: 'red', cursor: 'pointer' }}
        title={name}
      />
      <div className={`${styles.pulse}`} />
    </div>
  )
}

const SimpleMap = (props: any) => {
  const [point, setPoint] = useState({
    lat: 0,
    lng: 0,
  })
  const [zoom] = useState(11)

  useEffect(() => {
    setPoint({
      lat: props.latitude,
      lng: props.longitude,
    })
  }, [props.latitude, props.longitude])

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCGrCo4Od9pA3Z_W8JUbbgF28a1j6yVV4Q' }}
        defaultCenter={point}
        defaultZoom={zoom}
      >
        {point.lat && point.lng ? <AnyReactComponent lat={point.lat} lng={point.lng} name="My Marker" /> : ''}
      </GoogleMapReact>
    </div>
  )
}
