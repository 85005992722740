import React, { useRef, useState, useEffect, createRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import styles from './index.module.scss'
import biowoodLogo from '../../navtop/logo.svg'
import axios from 'axios'
import { urlApiSale } from '../common/configApi'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'

const WhyComponentToPrint: React.FC<{ data: any }> = (data: any) => {
  const [split, setSplit] = useState<any[]>([])

  useEffect(() => {
    setSplit(_.chunk<any>(data.data, 13))
  }, [data.data])

  const makePage = (dataList: any, keyProp: number) => {
    return (
      <div className={styles.page} key={keyProp}>
        <div className={styles.subpage}>
          {/* Section 1 My Company */}
          <div className={styles.row}>
            <div className={styles.col8}>
              <div className={styles.logo}>
                <img src={biowoodLogo} width="180" />
              </div>
              <div className={styles.myAddressCompony}>
                <p className={styles.margin5}>บริษัท จีอาร์เอ็ม (ประเทศไทย) จำกัด</p>
                <p className={styles.margin5}>(สำนักงานใหญ่) เลขที่ 431 ถนนประชานฤมิตร</p>
                <p className={styles.margin5}>แขวงบางซื่อ กรุงเทพมหานคร 10800</p>
                <p className={styles.margin5}>เลขประจำตัวผู้เสียภาษีอากร 0105554121810</p>
                <p className={styles.margin5}>โทร. 02-1581165-6 แฟกซ์ 02-1581165-6</p>
              </div>
            </div>

            <div className={styles.col4}>
              <p className={styles.margin5}>ต้นฉบับ/ Original</p>
              <h2 className={styles.margin5}>ใบเสนอราคา</h2>
              <p className={styles.margin5}>(Quotation)</p>
              <div className={styles.groupNoAndDate}>
                <div className={styles.groupNo}>
                  <div className={styles.subGroupNo}>
                    <p className={styles.margin5}>เลขที่ / No.</p>
                  </div>
                  <div>
                    <p className={styles.margin5}> {data.internalDocumentID} </p>
                  </div>
                </div>
                <div className={styles.groupDate}>
                  <div className={styles.subGroupNo}>
                    <p className={styles.margin5}>วันที่ / Date.</p>
                  </div>
                  <div>
                    <p className={styles.margin5}> {data.documentDate} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Section 2 Info Customer */}
          <div className={styles.row}>
            <div className={styles.col8}>
              <div className={styles.infoCustomer}>
                <p className={styles.margin5}>ชื่อ ลูก ค้า/Customer : {data.customerName}</p>
                <p className={styles.margin5}>เลขประจำตัวผู้เสียภาษีอากร/Tax ID. : {data.taxID}</p>
                <p className={styles.margin5}>ที่อยู่/ Address: {data.customerAddress}</p>
              </div>
            </div>
          </div>

          {/* Section 5 Table */}
          <div className={styles.row}>
            <table className={styles.tablePDF}>
              <thead>
                <tr>
                  <th>
                    ลำดับ <br /> (Item)
                  </th>
                  <th>
                    รหัสสินคา <br /> (Code)
                  </th>
                  <th>
                    สี <br /> (Color)
                  </th>
                  <th>
                    ผิว <br /> (Surface)
                  </th>
                  <th>
                    รายละเอียดสินค้า <br /> (Desciption)
                  </th>
                  <th>ขนาด(หนา x กว้าง x ยาว) </th>
                  <th>
                    แผ่น <br /> (Pieces)
                  </th>
                  <th>
                    รวม (เมตร/แผ่น) <br /> (Meter/Pcs)
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((data: any, index: any) => {
                  console.log(data)
                  return (
                    <tr key={index}>
                      <td>{data.index === '-' ? '-' : index + 1}</td>
                      <td>{data.productCode}</td>
                      <td>{data.color}</td>
                      <td>{data.surface}</td>
                      <td>{data.name}</td>
                      <td>{data.spec}</td>
                      <td>{data.qty}</td>
                      <td>{data.totalPerMeter}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          {/* Section 6 Remark */}
          <div className={styles.row} style={{ borderBottom: '1px solid black' }}>
            <div className={styles.col6} style={{ alignItems: 'flex-start' }}>
              <p className={styles.margin5}>หมายเหตุ/Remark : {data.remark}</p>
            </div>

            <div className={styles.col6} style={{ alignItems: 'flex-end' }}>
              <div className={styles.newRow} style={{ justifyContent: 'flex-end' }}>
                <div className={styles.groupFinance}>
                  <p className={styles.margin5}>จำนวนเงิน (Sub Total)</p>
                  <p className={styles.margin5}>หักส่วนลด (Discount)</p>
                  <p className={styles.margin5}>จำนวนเงินหลังหักส่วนลด (Total)</p>
                  <p className={styles.margin5}>จำนวนภาษีมูลค่า เพิ้ม 7% (Vat)</p>
                  <p className={styles.margin5}>จำนวนเงินทั้งสิ้น (Grand Total)</p>
                </div>
                <div className={styles.groupFinancePrice}>
                  <p className={styles.margin5} style={{ textAlign: 'right' }}>
                    {data.summarySubTotal}
                  </p>
                  <p className={styles.margin5} style={{ textAlign: 'right' }}>
                    {data.summaryDiscount}
                  </p>
                  <p className={styles.margin5} style={{ textAlign: 'right' }}>
                    {data.summaryTotal}
                  </p>
                  <p className={styles.margin5} style={{ textAlign: 'right' }}>
                    {data.summaryVat}
                  </p>
                  <p className={styles.margin5} style={{ textAlign: 'right' }}>
                    {data.summaryGrandTotal}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Section 7 Thai Bath */}
          <div className={styles.row} style={{ borderBottom: '1px solid black' }}>
            <div className={styles.col8} style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
              <p className={styles.margin5}>บาท (Baht)</p>
            </div>

            <div className={styles.col4} style={{ alignItems: 'flex-start', justifyContent: 'start' }}></div>
          </div>
          {/* Section 8 Rule */}
          <div className={styles.row} style={{ borderBottom: '1px solid black' }}>
            <div className={styles.col8} style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
              <p className={styles.margin5}>เงื่อนไข :</p>
              {/* <p className={styles.margin5}>
                1. กรุณาสั่งจ่ายเช็คขีดคร่อมในนาม บริษัท จีอารเ์อ็ม(ประเทศไทย) จำกัด ขีดฆ่าผู้ถือเท่านัน
              </p>
              <p className={styles.margin5}>2. กำหนดส่ง สินค้าภายใน 30 วันนับจากวันชำระเงินมัดจำค่าสินค้า</p>
              <p className={styles.margin5}>3. ค่าสินค้าทเี่หลือชำระกอ่นจัดส่งสินค้าเข้าหน้างาน</p>
              <p className={styles.margin5}>4. การชำระเงินิข้าบัญชี</p>
              <p className={styles.margin5} style={{ marginLeft: '30px' }}>
                ชอื่บญัชีบจกจอีารเ์อ็ม (ประเทศไทย)
              </p>
              <p className={styles.margin5} style={{ marginLeft: '30px' }}>
                ธนาคารไทยพาณชิ ย์ สาขาประชานเิวศน์1 บญั ชอีอมทรัพย์
              </p>
              <p className={styles.margin5} style={{ marginLeft: '30px' }}>
                เลขทบี่ ญั ชี 085-247548-5
              </p> */}
            </div>

            <div className={styles.col4} style={{ alignItems: 'flex-start', justifyContent: 'start' }}>
              <div className={styles.newRow} style={{ flexDirection: 'column' }}>
                <p className={styles.margin5}>ในนาม (For)</p>
                <p className={styles.margin5} style={{ display: 'flex', justifyContent: 'center' }}>
                  บริษัท จีอารเ์อ็ม (ประเทศไทย) จำกัด
                </p>
                <p className={styles.margin5} style={{ display: 'flex', justifyContent: 'center' }}>
                  GRM (Thailand) Co.,Ltd.
                </p>
                <p
                  className={styles.margin5}
                  style={{ display: 'flex', justifyContent: 'center', height: '80px', alignItems: 'flex-end' }}
                >
                  ผู้มีอำนาจ (Authorized)
                </p>
              </div>
            </div>
          </div>
          {/* Section 9 Sign */}
          <div className={styles.row} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <div>
              <p style={{ fontSize: '13px', margin: '10px' }}>ลงชื่อ...................................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>วันที่/Date........................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>ผู้อนุมัติสั่งซื้อ</p>
            </div>
            <div>
              <p style={{ fontSize: '13px', margin: '10px' }}>ลงชื่อ...................................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>วันที่/Date........................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>ผู้เสนอราคา</p>
            </div>
            <div>
              <p style={{ fontSize: '13px', margin: '10px' }}>ลงชื่อ...................................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>วันที่/Date........................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>ผู้ตรวจสอบ</p>
            </div>
            <div>
              <p style={{ fontSize: '13px', margin: '10px' }}>ลงชื่อ...................................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>วันที่/Date........................</p>
              <p style={{ fontSize: '13px', margin: '10px' }}>ผู้ออกเอกสาร</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <div>{split.map((e, i) => makePage(e, i))}</div>
}

/**
 * This is a wrapper, as the react-to-printer requires a component
 * in order to render and print.
 * There might be other/better ways of doing this, but for now, this
 * works.
 * NOTE: If no such wrapper, react-to-print complains about:
 * "work only Class based components"
 */
class FunctionalComponentWrapper extends React.Component<{
  data: any
  internalDocumentID: string
  documentDate: string
  taxID: string
  customerAddress: string
  customerName: string
  remark: string
  summarySubTotal: string
  summaryDiscount: string
  summaryTotal: string
  summaryVat: string
  summaryGrandTotal: string
}> {
  constructor(props: {
    data: any
    internalDocumentID: string
    documentDate: string
    taxID: string
    customerAddress: string
    customerName: string
    remark: string
    summarySubTotal: string
    summaryDiscount: string
    summaryTotal: string
    summaryVat: string
    summaryGrandTotal: string
  }) {
    super(props)
  }
  render() {
    return <WhyComponentToPrint {...this.props} />
  }
}

export const SalePdfQuotation = (props: any) => {
  const componentRef: { current: any } = useRef()
  const [internalDocumentID, setInternalDocumentID] = useState('')
  const [documentDate, setDocumentDate] = useState('')
  const [taxID, setTaxID] = useState('')
  const [customerAddress, setCustomerAddress] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [remark, setRemark] = useState('')

  const [summarySubTotal, setSummarySubTotal] = useState('')
  const [summaryDiscount, setSummaryDiscount] = useState('')
  const [summaryTotal, setSummaryTotal] = useState('')
  const [summaryVat, setSummaryVat] = useState('')
  const [summaryGrandTotal, setSummaryGrandTotal] = useState('')

  const [data, setData] = useState<any[]>([])
  const [lineItems, setLineItems] = useState<any[]>([])

  // Fetch Docuement Receive
  useEffect(() => {
    const q = `query($docID: String){
      quotation(docID: $docID) {
        docID
        projectCode
        customerName
        taxID
        customerPhone
        customerFax
        customerEmal
        customerAddress
        deadLine
        createdAt
        orderList{
          productCode
          color
          surface
          name: description
          thickness
          width
          length
          sheet
          total
          totalPerMeter
          discount
          totalNet
        }
        condition
        conditionOfPayment
        remark
        remarkInternal
        summaryGrandTotal
        saleName
      }
    }`
    // Where to put the variables
    const v = {
      docID: props.match.params.id,
    }

    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const quotation = result.data.data.quotation[0]
        setInternalDocumentID(quotation.docID)
        setDocumentDate(quotation.createdAt ? quotation.createdAt : '10/10/2564')
        setTaxID(quotation.taxID)
        setCustomerAddress(quotation.customerAddress)
        setCustomerName(quotation.customerName)
        setRemark(quotation.remark)
        setLineItems(quotation.orderList)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [])

  const handlePrint = useReactToPrint({
    content: () => {
      return componentRef.current
    },
  })

  // คำนวนเงิน
  useEffect(() => {
    let SubTotal = 0
    let Discount = 0
    let Total = 0
    let VAT = 0
    let GrandTotal = 0
    lineItems.map((data: any) => {
      SubTotal = SubTotal + data.total * data.totalPerMeter
      Discount = Discount + data.discount
    })
    Total = SubTotal - Discount
    VAT = parseFloat(((SubTotal - Discount) * 0.07).toFixed(2))
    GrandTotal = (SubTotal - Discount) * 1.07

    setSummarySubTotal(SubTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    setSummaryDiscount(Discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    setSummaryTotal(Total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    setSummaryVat(VAT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    setSummaryGrandTotal(GrandTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }, [lineItems])

  function padArray<T = any>(arr: T[], padToSize: number, padderObject: T): T[] {
    const copyOfArray = [...arr]
    const needToPad = copyOfArray.length % padToSize !== 0
    const padAmount = 13 - (copyOfArray.length % padToSize)
    if (!needToPad) {
      return copyOfArray
    }
    for (let i = 0; i < padAmount; i++) {
      copyOfArray.push(padderObject)
    }
    return copyOfArray
  }

  useEffect(() => {
    setData(
      padArray([...lineItems], 13, {
        index: '-',
        productCode: '-',
        color: '-',
        surface: '-',
        description: '-',
        spec: '-',
        qty: '-',
        uom: '-',
      }),
    )
  }, [lineItems])

  return (
    <>
      <button
        onClick={handlePrint}
        type="button"
        className="styleBtn"
        style={{ margin: '20px auto -40px', display: 'flex' }}
      >
        พิมพ์!
      </button>
      <FunctionalComponentWrapper
        ref={componentRef}
        data={data}
        internalDocumentID={internalDocumentID}
        documentDate={documentDate}
        taxID={taxID}
        customerAddress={customerAddress}
        customerName={customerName}
        remark={remark}
        summarySubTotal={summarySubTotal}
        summaryDiscount={summaryDiscount}
        summaryTotal={summaryTotal}
        summaryVat={summaryVat}
        summaryGrandTotal={summaryGrandTotal}
      />
    </>
  )
}
