import React, { useEffect, useState } from 'react'
import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../common/table'
import * as AWS from 'aws-sdk'
import { bucketRegion } from '../../common/configAws'
import { accessKeyId, secretAccessKey, UserPoolId } from '../common/UserPool'

import styles from './index.module.scss'
import DocumentTitle from '../common/document-title'
import { findPropertiesHr } from '../common/fildProperties'
import useTokenInitializer from '../../hooks/token-initializer'
import axios from 'axios'
import { urlApiHr } from '../common/configApiHr'
import { useFormikContext, withFormik, Field } from 'formik'
import PaginationContainer from '../../pagination/index'
import ModalAlert from '../common/modal'
import { addTeamIfNotExists, removeTeamIfNotExists } from '../common/addTeamIfNotExists'
import _ from 'lodash'
import { decodeTeamIdList } from '../common/addTeamIfNotExists'
import { useSelector } from 'react-redux'
import useFactInitializer from '../../hooks/fact-initializer'

const HrMainPage: React.FC = (props: any) => {
  const { values, setFieldValue } = useFormikContext<any>()

  const colHead: string[] = [
    'เลือก',
    'รหัสพนักงาน',
    'ชื่อ นามสกุล',
    'อีเมล',
    'แผนก',
    'ตำแหน่ง',
    'ทีม',
    'สถานะ',
    'ใช้งานล่าสุด',
    'สมัครเมื่อ',
  ]
  const listTeam = useSelector((state: any) => state.api.fact.listTeam.items.listTeam?.results)

  // First Run get all list user in cognito
  useEffect(() => {
    const params = {
      UserPoolId: UserPoolId,
    }
    const test = new Promise((resolve, reject) => {
      AWS.config.update({
        region: bucketRegion,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      })
      const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
      cognitoidentityserviceprovider.listUsers(params, (err, data: any) => {
        if (err) {
          console.log(err)
          reject(err)
        } else {
          const listUser: any = []
          data.Users.map((user: any, index: number) => {
            const mapper = {
              isChecked: false,
              key: user.Username,
              Employee: user.Username.slice(0, 8),
              Fullname: findPropertiesHr(user.Attributes, 'name'),
              Email: findPropertiesHr(user.Attributes, 'email'),
              // แผนก ตำแหน่ง ทีม
              Division: findPropertiesHr(user.Attributes, 'custom:division')
                ? findPropertiesHr(user.Attributes, 'custom:division')
                : 'Admin',
              Position: findPropertiesHr(user.Attributes, 'custom:position')
                ? findPropertiesHr(user.Attributes, 'custom:position')
                : 'Admin',
              Team: findPropertiesHr(user.Attributes, 'custom:team')
                ? decodeTeamIdList(findPropertiesHr(user.Attributes, 'custom:team'))?.map((data) => {
                    return listTeam?.find((ele: any) => data === ele.teamId).teamName + ' '
                  })
                : '',
              status: user.UserStatus,
              updatedAt: new Date(user.UserLastModifiedDate).toISOString().replace('T', ' ').split('.')[0],
              createdAt: new Date(user.UserCreateDate).toISOString().replace('T', ' ').split('.')[0],
            }
            listUser.push(mapper)
          })
          setFieldValue('listData', listUser, false)
          resolve(data)
        }
      })
    })
  }, [listTeam])

  // Second Run Get list Team in gql
  useEffect(() => {
    if (values.listData.length === 0) return

    const q = `query($teamId: ID!) {
      viewTeam(teamId: $teamId) {
        teamId
        teamName
        memberList
        deletedAt
      }
    }
    
    `
    const v = {
      teamId: props.match.params.id,
    }

    axios({
      url: urlApiHr,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((res) => {
        const viewTeam = res.data.data.viewTeam

        const prepareList: any = []
        const doneList: any = []
        viewTeam.memberList.map((member: any) => {
          prepareList.push(values.listData.find((ele: any) => ele.key === member))
        })

        // Mapper Data
        prepareList.map((data: any) => {
          data.isChecked = true
          doneList.push(data)
        })

        const originalList: { key: string }[] = values.listData
        const excludeUsernameList: string[] = doneList.map((ele: { key: string }) => {
          return ele.key
        })
        const excludedList: any[] = originalList.filter((ele) => {
          return !excludeUsernameList.includes(ele.key)
        })

        const dd = [...doneList, ...excludedList].map((data, index) => {
          data.index = index
          return data
        })
        setFieldValue('teamName', viewTeam.teamName, false)
        setFieldValue('rawTeam', viewTeam, false)
        setFieldValue('listDataSecond', dd, false)
        setFieldValue('listDataThird', dd.slice(0, 7), false)
        setFieldValue('loading', false, false)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [values.listData])

  const handleClickSave = () => {
    const memberList = values.listDataSecond
      .filter((ele: any) => ele.isChecked)
      .map((data: any) => {
        return data.key
      })

    const q = `mutation($input: TeamEdit!) {
      editTeam(input: $input) {
        teamId
        teamName
        memberList
        deletedAt
      }
    }`
    const v = {
      input: {
        teamId: props.match.params.id,
        teamName: values.teamName,
        memberList: memberList,
      },
    }

    axios({
      url: urlApiHr,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((res) => {
        const originalMemberList: string[] = values.rawTeam.memberList
        const updatedMemberList: string[] = memberList

        AWS.config.update({
          region: bucketRegion,
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
        })
        const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

        // Remove team id
        _.difference(originalMemberList, updatedMemberList).map((Username: string) => {
          const params = {
            UserPoolId: UserPoolId /* required */,
            Username: Username /* required */,
          }
          cognitoidentityserviceprovider.adminGetUser(params, (err, data: any) => {
            if (err) {
              console.log(err)
            } else {
              const paramsUpdate = {
                UserAttributes: [
                  /* required */
                  {
                    Name: 'custom:team' /* required */,
                    Value: removeTeamIfNotExists(
                      findPropertiesHr(data.UserAttributes ? data.UserAttributes : '', 'custom:team'),
                      props.match.params.id,
                    ),
                  },
                  /* more items */
                ],
                UserPoolId: UserPoolId /* required */,
                Username: Username /* required */,
              }
              cognitoidentityserviceprovider.adminUpdateUserAttributes(paramsUpdate, function (err, data) {
                if (err) console.log(err, err.stack)
                // an error occurred
                else console.log(data) // successful response
              })
            }
          })
        })

        // Add team id
        memberList.map((Username: string) => {
          const params = {
            UserPoolId: UserPoolId /* required */,
            Username: Username /* required */,
          }
          cognitoidentityserviceprovider.adminGetUser(params, (err, data: any) => {
            if (err) {
              console.log(err)
            } else {
              const paramsUpdate = {
                UserAttributes: [
                  /* required */
                  {
                    Name: 'custom:team' /* required */,
                    Value: addTeamIfNotExists(
                      findPropertiesHr(data.UserAttributes ? data.UserAttributes : '', 'custom:team'),
                      props.match.params.id,
                    ),
                  },
                  /* more items */
                ],
                UserPoolId: UserPoolId /* required */,
                Username: Username /* required */,
              }
              cognitoidentityserviceprovider.adminUpdateUserAttributes(paramsUpdate, function (err, data) {
                if (err) console.log(err, err.stack)
                // an error occurred
                else console.log(data) // successful response
              })
            }
          })
        })

        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'เพิ่มสำเร็จ', false)
      })
      .catch((err) => {
        console.log(err.response)
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
        setFieldValue('isModalOpen', true, false)
      })
  }

  useTokenInitializer()
  useFactInitializer()

  // Pgaination
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const handlePages = (updatePage: number) => setPage(updatePage)
  useEffect(() => {
    if (values.listDataSecond.length === 0) return
    const limit = 7
    setTotalPages(Math.ceil(values.listDataSecond.length / limit))
  }, [values.listDataSecond, values.listDataThird])

  useEffect(() => {
    const currentIdx = page - 1
    const limit = 7
    setFieldValue('listDataThird', values.listDataSecond.slice(currentIdx * limit, currentIdx * limit + limit), false)
  }, [page])

  DocumentTitle('Sale', 'CRM')
  return (
    <>
      <section className={styles.section1}>
        <div className={`loader loader-default ${values.loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>เพิ่ม/แก้ไขทีม</h1>
        <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [USER NAME]</span>
      </section>
      <section className={styles.section2}>
        <div>
          <p>ชื่อทีม</p>
          <input
            type="text"
            className="inputForSearch"
            placeholder="ชื่อทีม"
            value={values.teamName}
            onChange={(e) => setFieldValue('teamName', e.target.value, false)}
            style={{ maxWidth: '300px', display: 'flex' }}
          />
        </div>
      </section>
      <div style={{ margin: '0 25px' }}>
        <TableCommonCompanant
          colHead={colHead}
          rowBody={values.listDataThird}
          typeHead={TABLE_HEAD_TYPES.NONE}
          typeBody={TABLE_BODY_TYPES.FIRST_COLUMN_IS_CHECKBOX_TEAM}
          link="/hr/view"
          Field={Field}
          setFieldValue={setFieldValue}
          values={values}
        />
        <PaginationContainer page={page} totalPages={totalPages} handlePages={handlePages} />
      </div>
      <section className={styles.section2}>
        <div>
          <button className="styleBtn" type="button" onClick={() => handleClickSave()}>
            บันทึก
          </button>
        </div>
      </section>
      {ModalAlert(values.isModalOpen, values.successSubmit, setFieldValue)}
    </>
  )
}

const EnhancedHrMainPage = withFormik({
  mapPropsToValues: () => ({
    loading: true,

    listData: [],
    listDataSecond: [],
    listDataThird: [],
    rawTeam: [],
    teamName: '',

    // Modal
    isModalOpen: false,
    successSubmit: '',
  }),
  validate: (values) => {
    console.log(values)
  },
  handleSubmit: (values, { setFieldValue }) => {
    console.log(values)
  },
})(HrMainPage)

export default EnhancedHrMainPage

// DELETE
// const params = {
//   UserAttributeNames: [
//     /* required */
//     'custom:team',
//     /* more items */
//   ],
//   UserPoolId: UserPoolId /* required */,
//   Username: Username /* required */,
// }
// cognitoidentityserviceprovider.adminDeleteUserAttributes(params, function (err, data) {
//   if (err) console.log(err, err.stack)
//   // an error occurred
//   else console.log(data) // successful response
// })
