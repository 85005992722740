import React from 'react'

const NotFoundComponent: React.FC = () => {
  return (
    <>
      <p>Not Found!! </p>
    </>
  )
}

export default NotFoundComponent
