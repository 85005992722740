import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

interface IAmeliaFace {
  colHead: string[]
  rowBody: any[]
  typeHead: number
  typeBody: number
  link?: string
  Field?: any
  setFieldValue?: any
  values?: any
}

export const TABLE_HEAD_TYPES = {
  NONE: 0,
  FIRST_COLUMN_IS_BUTTON: 1,
  FIRST_COLUMN_IS_BUTTON_TEAM: 2,
}

export const TABLE_BODY_TYPES = {
  NONE: 0,
  FIRST_COLUMN_IN_ROW_IS_BUTTON: 1,
  FIRST_COLUMN_IS_CHECKBOX_TEAM: 2,
}

const TableCommonCompanant: React.FC<IAmeliaFace> = ({
  colHead,
  rowBody,
  typeBody,
  typeHead,
  link,
  Field,
  setFieldValue,
  values,
}) => {
  return (
    <>
      <table>
        <thead>
          <tr>
            {colHead.map((data, index) => {
              let borderTable = ''
              if (index === 0) {
                borderTable = 'borderLeftRadius'
              } else if (colHead.length === index + 1) {
                borderTable = 'borderRightRadius'
              }
              return (
                <th className={borderTable} key={index}>
                  {data}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {rowBody.map((data, rowIndex) => {
            if (typeof data === 'string' && typeHead === TABLE_HEAD_TYPES.FIRST_COLUMN_IS_BUTTON_TEAM) {
              return (
                <tr key={rowIndex}>
                  <td colSpan={colHead.length} className="uniqueSKU">
                    <Link
                      to={`${link}/${data.slice(0, 13)}`}
                      style={{
                        color: 'black',
                        backgroundColor: 'white',
                        fontWeight: 'bold',
                        borderRadius: '8px',
                        padding: '0px 3px',
                        marginRight: '5px',
                        fontSize: '10px',
                      }}
                    >
                      เลือก
                    </Link>
                    {data.slice(13)}
                  </td>
                </tr>
              )
            }
            if (typeof data === 'string') {
              return (
                <tr key={rowIndex}>
                  <td colSpan={colHead.length} className="uniqueSKU">
                    {data}
                  </td>
                </tr>
              )
            }
            return (
              <tr key={rowIndex}>
                {Object.keys(data).map(function (key, index) {
                  if (key === 'key' || key === 'index') return
                  if (index === 0 && typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IS_CHECKBOX_TEAM) {
                    return (
                      <td key={index}>
                        <Field
                          name={`listDataThird[${rowIndex}].${key}`}
                          style={{ width: '18px', height: '18px' }}
                          type="checkbox"
                          value={values.listDataThird[rowIndex].isChecked}
                          checked={values.listDataThird[rowIndex].isChecked ? true : false}
                          onChange={() => {
                            const objectData = values.listDataSecond.find(
                              (ele: any) => ele.key === values.listDataThird[rowIndex].key,
                            )
                            setFieldValue(
                              `listDataSecond[${objectData.index}].isChecked`,
                              values.listDataSecond[objectData.index].isChecked ? false : true,
                              false,
                            )

                            setFieldValue(
                              `listDataThird[${rowIndex}].${key}`,
                              values.listDataThird[rowIndex].isChecked ? false : true,
                              false,
                            )
                          }}
                        />
                      </td>
                    )
                  }
                  if (index === 0 && typeBody === TABLE_BODY_TYPES.FIRST_COLUMN_IN_ROW_IS_BUTTON) {
                    return (
                      <td key={index}>
                        <Link to={`${link}/${data[key]}`}>
                          <button className="styleBtnForTable" type="button">
                            เลือก
                          </button>
                        </Link>
                      </td>
                    )
                  }
                  return <td key={index}>{data[key]}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
TableCommonCompanant.propTypes = {
  colHead: PropTypes.any,
  rowBody: PropTypes.any,
  typeHead: PropTypes.number.isRequired,
  typeBody: PropTypes.number.isRequired,
  link: PropTypes.any,
  Field: PropTypes.any,
  setFieldValue: PropTypes.any,
  values: PropTypes.any,
}

export default TableCommonCompanant
