import React, { useEffect, useState } from 'react'
import { getTheme, mergeStyleSets, FontWeights, Modal, IconButton, IIconProps } from 'office-ui-fabric-react'
import { useId } from '@uifabric/react-hooks'
import axios from 'axios'
import { boardID } from '../common/configBoardID'
import { urlApiSale } from '../common/configApi'
import { FormatDateTime } from '../../common/dateFormat'

const ModalAlert = (props: any, isModalOpen: any, hideModal: any, data: any, styles: any) => {
  const cancelIcon: IIconProps = { iconName: 'Cancel' }
  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const titleId = useId('title')

  const [statusProcess, setStatusProcess] = useState<any>('')
  const [colorStatusProcess, setColorStatusProcess] = useState<any>('')

  const [card, setCard] = useState<any>(null)
  const [findIndexMeeting, setFindIndexMeeting] = useState(0)

  const [statusMeeting, setStatusMeeting] = useState('')
  const [styleCss, setStyleCss] = useState('')

  const tzoffset = new Date().getTimezoneOffset() * 60000
  const localISOTime = new Date(Date.now() - tzoffset).toISOString()

  useEffect(() => {
    setStatusProcess('')
    setColorStatusProcess('')
    if (!data) return
    // Where to put the variables
    const v = {
      boardID: boardID,
      cardID: data.cardID,
    }

    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: qCard,
        variables: v,
      },
    })
      .then((result) => {
        const card = result.data.data.card[0]
        setCard(card)

        const findIndexMeeting = card.comments
          .filter((ele: any) => ele.commentType === 'meeting')
          .findIndex((element: any) => element.datetime === data.datetime)
        setFindIndexMeeting(findIndexMeeting)

        const findCheckIn = card.comments
          .filter((ele: any) => ele.commentType === 'checkIn')
          .find((element: any) => element.number === findIndexMeeting)

        const findCheckOut = card.comments
          .filter((ele: any) => ele.commentType === 'checkOut')
          .find((element: any) => element.number === findIndexMeeting)

        const tzoffset = new Date().getTimezoneOffset() * 60000
        const localISOTime = new Date(Date.now() - tzoffset).toISOString()

        if (data.datetime < localISOTime) {
          setStatusMeeting('ไม่เข้าประชุม')
          setStyleCss('statusRed')
        }
        if (findCheckIn) {
          setStatusMeeting('ผ่านไปแล้ว')
          setStyleCss('statusBlack')
        }
        if (findCheckOut) {
          setStatusMeeting('ยกเลิก')
          setStyleCss('statusBlack')
        }
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [data])

  const handleCheckIn = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const mapper = {
        commentType: 'checkIn',
        lat: position.coords.latitude.toString(),
        long: position.coords.longitude.toString(),
        createdAt: localISOTime,
        number: findIndexMeeting,
      }
      card.comments = [...card.comments, mapper]
      setCard(card)

      const vUpdateCard = {
        input: {
          boardID: boardID,
          cardID: card.cardID,
          title: card.title,
          status: card.status,
          assignee: card.assignee,
          additionalProperties: card.additionalProperties,
          comments: card.comments,
        },
      }

      axios({
        url: urlApiSale,
        method: 'post',
        data: {
          query: qUpdateCard,
          variables: vUpdateCard,
        },
      })
        .then((res) => {
          console.log(res)
          setStatusProcess('Check In เรียบร้อย')
          setColorStatusProcess('green')
        })
        .catch((err) => {
          console.log(err.response)
          setStatusProcess('Check In ไม่สำเร็จ')
          setColorStatusProcess('red')
        })
    })
  }

  const handleClickCancleMeeting = () => {
    const mapper = {
      commentType: 'checkOut',
      createdAt: localISOTime,
      number: findIndexMeeting,
    }

    card.comments = [...card.comments, mapper]
    setCard(card)

    const vUpdateCard = {
      input: {
        boardID: boardID,
        cardID: card.cardID,
        title: card.title,
        status: card.status,
        assignee: card.assignee,
        additionalProperties: card.additionalProperties,
        comments: card.comments,
      },
    }

    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: qUpdateCard,
        variables: vUpdateCard,
      },
    })
      .then((res) => {
        console.log(res)
        setStatusProcess('ยกเลิกนัด เรียบร้อย')
        setColorStatusProcess('green')
      })
      .catch((err) => {
        console.log(err.response)
        setStatusProcess('ยกเลิกนัด ไม่สำเร็จ')
        setColorStatusProcess('red')
      })
  }

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <h2 id={titleId}>ชื่อโปรเจค: {data?.title}</h2>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={hideModal}
        />
      </div>
      <div className={contentStyles.body}>
        <p style={{ margin: '7px 0' }}>
          <label className={`${styles[styleCss]}`}>{statusMeeting}</label>
          {data?.datetime ? FormatDateTime(data?.datetime) : '-'}
        </p>
        <p>
          <b>ข้อมูล</b>
        </p>
        <p>ชื่อผู้ติดต่อ: {data?.contactName}</p>
        <p>เบอร์โทรศัพท์: {data?.contactNumber}</p>
        <p>บริษัท: {data?.contactCompany}</p>
        <p>
          <b>สถานที่</b>
        </p>
        <p>{data?.location}</p>
        <p>
          <b>ประเภท</b>
        </p>
        <p>{data?.description}</p>
        <p style={{ textAlign: 'right', marginTop: '10px', color: colorStatusProcess }}>{statusProcess}</p>
      </div>

      <div className={contentStyles.footer}>
        <a href={data?.googleMap}>
          <button type="button" className="styleBtn">
            นำทาง
          </button>
        </a>
        <button type="button" className="styleBtn" onClick={() => handleClickCancleMeeting()}>
          ยกเลิก
        </button>
        <button type="button" className="styleBtn" onClick={() => handleCheckIn()}>
          เช็คอิน
        </button>
        <button type="button" className="styleBtn" onClick={() => props.history.push(`/sale/crm/card/${data.cardID}`)}>
          ดูโปรเจค
        </button>
      </div>
    </Modal>
  )
}

export default ModalAlert

const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    textAlign: 'left',
    marginTop: '-15px',
    flex: '4 4 auto',
    gap: '6px',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      h1: { margin: '0' },
      p: {
        margin: '0',
        b: {
          fontSize: '14px',
        },
      },
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '0px 10px 10px',

    button: {
      fontSize: '12px',
      padding: '5px 8px',
      margin: '4px 2px',
    },
  },
})
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}

const qCard = `query($boardID: ID!, $cardID: ID){
  card(boardID: $boardID, cardID: $cardID) {
    cardID
    title
    status
    assignee
    createdAt
    updatedAt
    comments {
			commentType
      description
      attachments
      createdAt
      datetime
      location
      googleMap
      number
      lat
      long
      note
    }
    additionalProperties {
			Key
    	Value
    }
  }
}`

const qUpdateCard = `mutation($input: UpdateCardInput!) {
  updateCard(input: $input) {
    cardID
  }
}`
