import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import { debounce } from 'lodash'
import { urlApi } from '../common/configUrlApi'

import useTokenInitializer from '../../hooks/token-initializer'
import { FormatDateTime } from '../../common/dateFormat'
import EnhancedTable from '../../common/table/doc/firstAndLastColBtnPaginationBack'
import { HeadCell } from '../../common/table/config'

const InventoryPOListPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)

  const [dataListPO, setDataListPO] = useState<any>([])
  const [currentQueryString, setCurrentQueryString] = useState('')
  const [currentQueryDate, setCurrentQueryDate] = useState(null)

  // Pgaination
  const [page, setPage] = React.useState(0)
  const [pageList, setPageList] = React.useState<any>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = React.useState(0)

  DocumentTitle('Inventory', 'เอกสาร PO')
  useTokenInitializer()

  useEffect(() => {
    // For not dowload page if dowloaded
    const findPage = pageList.find((ele: any) => `${ele}` === `${page}`)
    if (findPage || findPage === 0) return

    // Where to put the query
    const q = `query listPurchaseOrderPage($pageIndex: Int, $maxResults: Int, $internalDocumentIdPartial: String, $createdAtDay: DateTime){
      listPurchaseOrder(pageIndex: $pageIndex, maxResults: $maxResults, internalDocumentIdPartial: $internalDocumentIdPartial, createdAtDay: $createdAtDay) {
        totalCount
        pageIndex
        maxResults
        results {
          id
          internalDocumentId
          documentTypeId
          documentStatusId
          referenceNumber
          remark
          createdAt
          updatedAt
          lineItem {
            id
            skuId
            qty
            uomId
            packId
            lineNumber
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      pageIndex: page,
      maxResults: rowsPerPage,
      createdAtDay: currentQueryDate,
      internalDocumentIdPartial: currentQueryString,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const listPurchaseOrder = result.data.data.listPurchaseOrder.results
      const listPO: any[] = []
      listPurchaseOrder.map((data: any) => {
        const mapper = {
          id: data.id,
          poCode: data.internalDocumentId,
          poDate: FormatDateTime(data.updatedAt),
        }
        return listPO.push(mapper)
      })
      setLoading(false)
      setDataListPO([...dataListPO, ...listPO])
      setTotalCount(result.data.data.listPurchaseOrder.totalCount)

      setPageList([...pageList, page]) // For not reload new page if dowloaded
    })
  }, [page, rowsPerPage, currentQueryDate, currentQueryString])

  const request = debounce((value, setState) => {
    setPage(0)
    setPageList([])
    setDataListPO([])
    setState(value)
  }, 1000)

  const debouceRequest = useCallback((value, setState) => request(value, setState), [])

  const onChangeString = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryString)
  }

  const onChangeDate = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryDate)
  }

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'productCode', numeric: false, disablePadding: false, label: 'เลขที่เอกสารจัดซื้อ' },
    { id: 'product', numeric: false, disablePadding: false, label: 'วันที่' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------
  const rowBody: any = dataListPO

  return (
    <>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>เอกสารจัดซื้อ</h1>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสเอกสาร"
          onChange={onChangeString}
          style={{ maxWidth: '385.6px', display: 'flex' }}
        />
        <input type="date" className="inputForSearch" onChange={onChangeDate} style={{ maxWidth: '385.6px' }} />
      </section>
      <section className="tableListMui">
        <EnhancedTable
          colHead={headCells}
          rows={rowBody}
          link="/inventory/purchase-orders"
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventoryPOListPage
