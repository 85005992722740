import { crc16 } from 'crc'

enum SERIALNUMBER_STATUS {
  VALID = 0b0000,
  INVALID = 0b0001,
  INCOMPLETE = 0b0010,
  CORRUPT = 0b0100,
}
export function serialNumberComplete(serialNumber: string) {
  let serialNumberStatus = 0b0000

  const regex = /^(?<PRODUCT_CODE>[\d-]+)(?<DOC_NAME>\w{2}\d{2}\d{2}\d{2}\d{3})PK(?<PACK_NUMBER>\d{3})CK(?<CRC16_SUMCHECK>[\w\d]+)$/i
  const matchList = regex.exec(serialNumber)
  // console.log(matchList, "matchList")

  if (!matchList || !matchList.groups) {
    serialNumberStatus ^= SERIALNUMBER_STATUS.INCOMPLETE ^ SERIALNUMBER_STATUS.INVALID
    return serialNumberStatus
  }

  const {
    groups: { PRODUCT_CODE, DOC_NAME, PACK_NUMBER, CRC16_SUMCHECK },
  } = matchList

  const barCodeSerialNumber = `${PRODUCT_CODE}${DOC_NAME}PK${PACK_NUMBER}`
  // console.log(barCodeSerialNumber, "barCodeSerialNumber")

  if (crc16(barCodeSerialNumber).toString(16).toUpperCase().padStart(4, '0') !== CRC16_SUMCHECK.toUpperCase()) {
    serialNumberStatus ^= SERIALNUMBER_STATUS.CORRUPT ^ SERIALNUMBER_STATUS.INVALID
    return serialNumberStatus
  }

  return serialNumberStatus
}

export function serialNumberProductCode(serialNumber: string) {
  let serialNumberStatus = 0b0000

  const regex = /^(?<PRODUCT_CODE>[\d-]+)(?<DOC_NAME>\w{2}\d{2}\d{2}\d{2}\d{3})PK(?<PACK_NUMBER>\d{3})CK(?<CRC16_SUMCHECK>[\w\d]+)$/i
  const matchList = regex.exec(serialNumber)
  // console.log(matchList, "matchList")

  if (!matchList || !matchList.groups) {
    serialNumberStatus ^= SERIALNUMBER_STATUS.INCOMPLETE ^ SERIALNUMBER_STATUS.INVALID
    return serialNumberStatus
  }

  const {
    groups: { PRODUCT_CODE, DOC_NAME, PACK_NUMBER, CRC16_SUMCHECK },
  } = matchList

  const barCodeSerialNumber = `${PRODUCT_CODE}${DOC_NAME}PK${PACK_NUMBER}`
  // console.log(barCodeSerialNumber, "barCodeSerialNumber")

  if (crc16(barCodeSerialNumber).toString(16).toUpperCase().padStart(4, '0') !== CRC16_SUMCHECK.toUpperCase()) {
    serialNumberStatus ^= SERIALNUMBER_STATUS.CORRUPT ^ SERIALNUMBER_STATUS.INVALID
    return serialNumberStatus
  }

  return PRODUCT_CODE
}
