export const listStatus = [
  {
    statusName: 'PROJECT_NEW',
    statusDisplay: 'เปิดงาน',
    color: 'gray',
  },
  {
    statusName: 'PROJECT_APPROVE_SALES_MANAGER',
    statusDisplay: 'Approve โดย Sales Manager',
    color: 'orange',
  },
  {
    statusName: 'PROJECT_APPROVE_ENG_MANAGER',
    statusDisplay: 'Approve โดย Eng. Manager',
    color: 'purple',
  },
  {
    statusName: 'PROJECT_ONGOING',
    statusDisplay: 'ดำเนินการ',
    color: 'yellow',
  },
  {
    statusName: 'PROJECT_REVIEW_APPROVE_ENG_MANAGER',
    statusDisplay: 'Review Approve โดย Eng. Manager',
    color: 'green',
  },
  {
    statusName: 'PROJECT_REVIEW_APPROVE_SALES_MANAGER',
    statusDisplay: 'Review Approve โดย Sales. Manager',
    color: 'green',
  },
  {
    statusName: 'PROJECT_REVIEW_APPROVE_SALES_DONE',
    statusDisplay: 'Review Approve โดย Sales',
    color: 'green',
  },
]
