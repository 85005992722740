import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useTokenInitializer from './components/hooks/token-initializer'

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  if (localStorage.getItem('accessToken')) {
    return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  } else {
    return <Redirect to="/login" />
  }
}

export default ProtectedRoute

// all module in inventory
export const ProtectedRouteInventory = ({ component: Component, ...rest }: any) => {
  const token = useSelector((state: any) => state.token)
  const [division, setDivision] = useState('คลังสินค้า')
  useTokenInitializer()

  useEffect(() => {
    if (!token.raw_token) return
    setDivision(!token.division ? 'admin' : token.division)
  }, [token])

  if (division === 'admin' || division === 'คลังสินค้า') {
    return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  } else {
    return <Redirect to="/" />
  }
}

// หน้าหลักของ inventory and list item master data
export const ProtectedRouteInventoryHaveSale = ({ component: Component, ...rest }: any) => {
  const token = useSelector((state: any) => state.token)
  const [division, setDivision] = useState('คลังสินค้า')
  useTokenInitializer()

  useEffect(() => {
    if (!token.raw_token) return
    setDivision(!token.division ? 'admin' : token.division)
  }, [token])

  if (division === 'admin' || division === 'คลังสินค้า' || division === 'การขาย') {
    return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  } else {
    return <Redirect to="/" />
  }
}

export const ProtectedRouteDelivery = ({ component: Component, ...rest }: any) => {
  const token = useSelector((state: any) => state.token)
  const [division, setDivision] = useState('คลังสินค้า')
  useTokenInitializer()

  useEffect(() => {
    if (!token.raw_token) return
    setDivision(!token.division ? 'admin' : token.division)
  }, [token])

  if (division === 'admin' || division === 'คลังสินค้า' || division === 'ขนส่ง') {
    return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  } else {
    return <Redirect to="/" />
  }
}

export const ProtectedRouteSale = ({ component: Component, ...rest }: any) => {
  const token = useSelector((state: any) => state.token)
  const [division, setDivision] = useState('การขาย')
  useTokenInitializer()

  useEffect(() => {
    if (!token.raw_token) return
    setDivision(!token.division ? 'admin' : token.division)
  }, [token])

  if (division === 'admin' || division === 'การขาย') {
    return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  } else {
    return <Redirect to="/" />
  }
}

export const ProtectedRouteHr = ({ component: Component, ...rest }: any) => {
  const token = useSelector((state: any) => state.token)
  const [division, setDivision] = useState('บุคคล')
  useTokenInitializer()

  useEffect(() => {
    if (!token.raw_token) return
    setDivision(!token.division ? 'admin' : token.division)
  }, [token])

  if (division === 'admin' || division === 'บุคคล') {
    return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  } else {
    return <Redirect to="/" />
  }
}

export const ProtectedRouteEngineer = ({ component: Component, ...rest }: any) => {
  const token = useSelector((state: any) => state.token)
  const [division, setDivision] = useState('ติดตั้ง/ถอดแบบ')
  useTokenInitializer()

  useEffect(() => {
    if (!token.raw_token) return
    setDivision(!token.division ? 'admin' : token.division)
  }, [token])

  if (division === 'admin' || division === 'ติดตั้ง/ถอดแบบ') {
    return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  } else {
    return <Redirect to="/" />
  }
}
