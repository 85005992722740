export const divisionList = [
  {
    divisionName: 'SALES',
    name: 'การขาย',
  },
  {
    divisionName: 'INVENTORY',
    name: 'คลังสินค้า',
  },
  {
    divisionName: 'PURCHASE',
    name: 'จัดซื้อ',
  },
  {
    divisionName: 'ACCOUNTING',
    name: 'บัญชี',
  },
  {
    divisionName: 'LOGISTIC',
    name: 'ขนส่ง',
  },
  {
    divisionName: 'INSTALLATION',
    name: 'ติดตั้ง/ถอดแบบ',
  },
  {
    divisionName: 'HUMANRESOURCE',
    name: 'บุคคล',
  },
  {
    divisionName: 'MANAGER',
    name: 'ผู้บริหาร',
  },
]
