import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { serialNumberProductCode } from '../common/serialNumberComplete'
import DocumentTitle from '../common/document-title'
import useFactInitializer from '../../hooks/fact-initializer'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { urlApi } from '../common/configUrlApi'
import useTokenInitializer from '../../hooks/token-initializer'
import EnhancedTable from '../../common/table/item/paginationByBack/firstColBtn'
import { HeadCell } from '../../common/table/config'

const InventoryItemMasterDataListPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)

  const [dataListSku, setDataListSku] = useState<any>([])
  const [currentQueryString, setCurrentQueryString] = useState('')
  const [seclectType, setSeclectType] = useState('all')
  const [seclectColor, setSeclectColor] = useState('all')
  const [seclectFinish, setSeclectFinish] = useState('all')

  // FACT TYPE
  const [factTypes, setFactTypes] = useState<any>([])
  const [factColors, setFactColors] = useState<any>([])
  const [factFinishs, setFactFinishs] = useState<any>([])
  const viewSkuPropertyAllListType = useSelector(
    (state: any) => state.api.fact.viewSkuPropertyAllListType.items.viewSkuPropertyAll?.['listType'].results,
  )
  const viewSkuPropertyAllListColor = useSelector(
    (state: any) => state.api.fact.viewSkuPropertyAllListType.items.viewSkuPropertyAll?.['listColor'].results,
  )
  const viewSkuPropertyAllListFinish = useSelector(
    (state: any) => state.api.fact.viewSkuPropertyAllListType.items.viewSkuPropertyAll?.['listSurface'].results,
  )

  // Pgaination
  const [page, setPage] = React.useState(0)
  const [pageList, setPageList] = React.useState<any>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = React.useState(0)

  DocumentTitle('Inventory', 'เรียกดูสินค้า')
  useFactInitializer()
  useTokenInitializer()

  // GET LIST ITEM MASTER DATA
  useEffect(() => {
    // For not dowload page if dowloaded
    const findPage = pageList.find((ele: any) => `${ele}` === `${page}`)
    if (findPage || findPage === 0) return

    // Where to put the query
    const q = `query ($pageIndex: Int, $maxResults: Int, $productCodePartial: String, $typeId: Int, $colorId: Int, $surfaceId: Int) {
      listSku(pageIndex: $pageIndex, maxResults: $maxResults, productCodePartial: $productCodePartial, typeId: $typeId, colorId: $colorId, surfaceId: $surfaceId) {
        results {
          id
          name
          productCode
          profile
          spec
          colorId
          product {
            name
          }
          qtyPerPack(packId: 1)
          onHandStatistic {
            available
            ordered
            inStock
            committed
          }
          color {
            id
            code
            name
          }
          surfaceId
          surface {
            id
            code
            name
          }
          typeId
          type {
            id
            code
            name
          }
          updatedAt
          createdAt
        }
        totalCount
        pageIndex
        maxResults
      }
    }`
    // Where to put the variables
    const v = {
      pageIndex: page,
      maxResults: rowsPerPage,
      productCodePartial: currentQueryString,
      typeId: seclectType !== 'all' ? seclectType : null,
      colorId: seclectColor !== 'all' ? parseInt(seclectColor) : null,
      surfaceId: seclectFinish !== 'all' ? parseInt(seclectFinish) : null,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const listSku = result.data.data.listSku.results
        const listSkuMapper: any[] = []
        listSku.map((data: any) => {
          const mapper = {
            id: data.id,
            productCode: data.productCode,
            product: data.product.name,
            profile: data.type.name,
            spec: data.spec,
            finish: data.surface.name,
            color: data.color.name,
            qtyPerPack: data.qtyPerPack,

            purchase: data.onHandStatistic.ordered,
            purchasePack: Math.ceil(data.onHandStatistic.ordered / data.qtyPerPack),

            stock: data.onHandStatistic.inStock,
            stockPack: Math.ceil(data.onHandStatistic.inStock / data.qtyPerPack),

            total: data.onHandStatistic.available - data.onHandStatistic.committed,
            totalPack: Math.ceil((data.onHandStatistic.available - data.onHandStatistic.committed) / data.qtyPerPack),

            commited: data.onHandStatistic.committed,
            commitedPack: Math.ceil(data.onHandStatistic.committed / data.qtyPerPack),
          }
          return listSkuMapper.push(mapper)
        })
        setTotalCount(result.data.data.listSku.totalCount)
        setLoading(false)
        setDataListSku([...dataListSku, ...listSkuMapper])

        setPageList([...pageList, page]) // For not reload new page if dowloaded

        // Redirect Page if currentQueryString match productCode
        if (currentQueryString === listSku[0].productCode) {
          return props.history.push(`/inventory/item-master-data/list/${listSku[0].id}`)
        }
        if (typeof serialNumberProductCode(currentQueryString) === 'string') {
          return props.history.push(`/inventory/item-master-data/list/${listSku[0].id}`)
        }
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [page, seclectType, seclectColor, seclectFinish, currentQueryString])

  // GET LIST FACT TYPE
  useEffect(() => {
    if (viewSkuPropertyAllListType) setFactTypes(viewSkuPropertyAllListType)
    if (viewSkuPropertyAllListColor) setFactColors(viewSkuPropertyAllListColor)
    if (viewSkuPropertyAllListFinish) setFactFinishs(viewSkuPropertyAllListFinish)
  }, [viewSkuPropertyAllListType, viewSkuPropertyAllListColor, viewSkuPropertyAllListFinish])

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'productCode', numeric: false, disablePadding: false, label: 'รหัสสินค้า' },
    { id: 'product', numeric: false, disablePadding: false, label: 'รายละเอียด' },
    { id: 'profile', numeric: false, disablePadding: false, label: 'ประเภท' },
    { id: 'spec', numeric: false, disablePadding: false, label: 'ขนาด(หนา x กว้าง x ยาว)' },
    { id: 'finish', numeric: false, disablePadding: false, label: 'ผิว' },
    { id: 'color', numeric: false, disablePadding: false, label: 'สี' },
    { id: 'qtyPerPack', numeric: false, disablePadding: false, label: 'จำนวนแผ่นต่อห่อ' },
    { id: 'namePurchase', numeric: true, disablePadding: false, label: 'ซื้อ' },
    { id: 'nameStock', numeric: true, disablePadding: false, label: 'คงคลัง' },
    { id: 'nameTotal', numeric: true, disablePadding: false, label: 'คงเหลือสุทธิ' },
    { id: 'nameCommited', numeric: true, disablePadding: false, label: 'จอง' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  const rowBody: any = dataListSku.length ? dataListSku : []

  const request = debounce((value) => {
    setPage(0)
    setPageList([])
    setDataListSku([])
    setCurrentQueryString(value)
  }, 1000)

  const debouceRequest = useCallback((value) => request(value), [])

  const onChange = (e: any) => {
    debouceRequest(e.target.value)
  }

  const onClickDowloadFile = () => {
    setLoading(true)
    const q = `query ($pageIndex: Int, $maxResults: Int, $productCodePartial: String, $typeId: Int, $colorId: Int, $surfaceId: Int) {
      listSku(pageIndex: $pageIndex, maxResults: $maxResults, productCodePartial: $productCodePartial, typeId: $typeId, colorId: $colorId, surfaceId: $surfaceId) {
        results {
          id
            name
            productCode
            typeId
            type {
              id
              code
              name
            }
            uri
            spec
            uri2D
            width
            length
            depth
            surface {
              name
            }
            color {
              name
            }
            product {
              name
            }
            qtyPerPack(packId: 1)
            pricePerMeter
            kilogramPerMeter
            filename
            filename2D
        }
        totalCount
        pageIndex
        maxResults
      }
    }`
    // Where to put the variables
    const v = {
      pageIndex: page - 1,
      maxResults: 9999,
      productCodePartial: currentQueryString,
      typeId: seclectType !== 'all' ? seclectType : null,
      colorId: seclectColor !== 'all' ? parseInt(seclectColor) : null,
      surfaceId: seclectFinish !== 'all' ? parseInt(seclectFinish) : null,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const listSku = result.data.data.listSku.results
        console.log('listSku', listSku)
        const newArray: any = []

        listSku.map((data: any, index: number) => {
          const mapper = {
            รายละเอียด: data.product.name,
            ชื่อสินค้า: data.name,
            ประเภท: data.type.name, //ประเภท
            Width: data.width,
            Depth: data.depth,
            Lenght: data.length,
            Finish: data.surface.name, //FINISH
            Color: data.color.name, //COLOR
            จำนวนชิ้นต่อแพค: data.qtyPerPack,
            น้ำหนักต่อเมตร: data.kilogramPerMeter,
            ราคาต่อเมตร: data.pricePerMeter,
            รูปภาพสินค้า: data.filename,
            'รูปภาพ 2d': data.filename2D,
          }
          newArray.push(mapper)
        })
        setLoading(false)
        JSONToCSVConvertor(newArray, 'test', 'test')
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }

  const handleSetFilter = (value: any, setValue: any) => {
    setValue(value)
    setPage(0)
    setPageList([])
    setDataListSku([])
  }
  return (
    <>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>เรียกดูสินค้า</h1>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสสินค้า / Serial Number / รายละเอียด / ชื่อสินค้า"
          style={{ maxWidth: '385.6px', margin: '0 10px 0 0' }}
          onChange={onChange}
          autoFocus
        />

        <select
          name="type"
          className="inputForSearch"
          style={{ maxWidth: '200px', margin: '0 10px 0 0' }}
          onChange={(e) => handleSetFilter(e.target.value, setSeclectColor)}
        >
          <option value="all">ทุกสี</option>
          {factColors.map((data: any, index: any) => {
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            )
          })}
        </select>

        <select
          name="type"
          className="inputForSearch"
          style={{ maxWidth: '200px' }}
          onChange={(e) => handleSetFilter(e.target.value, setSeclectFinish)}
        >
          <option value="all">ทุกพื้นผิว</option>
          {factFinishs.map((data: any, index: any) => {
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            )
          })}
        </select>

        <select
          name="type"
          className={styles.selectFilter}
          onChange={(e) => handleSetFilter(e.target.value, setSeclectType)}
        >
          <option value="all">ทั้งหมด</option>
          {factTypes.map((data: any, index: any) => {
            return (
              <option value={data.name} key={index}>
                {data.name}
              </option>
            )
          })}
        </select>
      </section>
      <section className={styles.cardItems}>
        <button
          type="button"
          className={`${styles.buttonCardItem} ${seclectType === 'all' ? styles.active : ''}`}
          style={{ minWidth: '170px' }}
          onClick={() => handleSetFilter('all', setSeclectType)}
        >
          <p>ทั้งหมด</p>
        </button>
        {factTypes.map((data: any, index: any) => {
          return (
            <button
              key={index}
              type="button"
              className={`${styles.buttonCardItem} ${seclectType === data.code ? styles.active : ''}`}
              onClick={() => handleSetFilter(data.code, setSeclectType)}
            >
              <img src={data.uri ? data.uri : 'https://fakeimg.pl/150x90'} width="150" height="90" />
              <p>{data.name}</p>
            </button>
          )
        })}
      </section>
      <section className={styles.groupButtonDestop}>
        <button
          type="button"
          className="styleBtn"
          onClick={() => props.history.push('/inventory/item-master-data/add')}
        >
          เพิ่มสินค้า
        </button>
        <button
          type="button"
          className="styleBtn"
          onClick={() => props.history.push('/inventory/item-master-data/type')}
        >
          เพิ่มหมวดหมู่
        </button>
        <button
          type="button"
          className="styleBtn"
          onClick={() => props.history.push('/inventory/item-master-data/type/edit')}
        >
          แก้ไขหมวดหมู่
        </button>
        <button
          type="button"
          className="styleBtn"
          onClick={() => props.history.push('/inventory/item-master-data/import')}
        >
          นำเข้าข้อมูล
        </button>
        <button type="button" className="styleBtn" onClick={() => onClickDowloadFile()}>
          นำออกข้อมูล
        </button>
      </section>
      <section className={`tableListMui`}>
        <EnhancedTable
          colHead={headCells}
          rows={rowBody}
          link="/inventory/item-master-data/list"
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventoryItemMasterDataListPage

const JSONToCSVConvertor = (JSONData: any, ReportTitle: any, ShowLabel: any) => {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData

  let CSV = ''

  //This condition will generate the Label/Header
  if (ShowLabel) {
    let row = ''

    //This loop will extract the label from 1st index of on array
    for (const index in arrData[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ','
    }

    row = row.slice(0, -1)

    //append Label row with line break
    CSV += row + '\r\n'
  }

  //1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = ''

    //2nd loop will extract each column and convert it in string comma-seprated
    for (const index in arrData[i]) {
      row += '"' + arrData[i][index] + '",'
    }

    row.slice(0, row.length - 1)

    //add a line break after each row
    CSV += row + '\r\n'
  }

  if (CSV === '') {
    alert('Invalid data')
    return
  }

  //Generate a file name
  let fileName = 'MyReport_'
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, '_')

  //Initialize file format you want csv or xls
  const uri = 'data:text/csv;charset=utf-8,' + escape(CSV)

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  const link: any = document.createElement('a')
  link.href = uri

  //set the visibility hidden so it will not effect on your web-layout
  link.style = 'visibility:hidden'
  link.download = fileName + '.csv'

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
