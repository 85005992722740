import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Link, Redirect } from 'react-router-dom'
import DocumentTitle from '../common/document-title'
import axios from 'axios'
import { urlApi } from '../common/configUrlApi'

import useTokenInitializer from '../../hooks/token-initializer'
import * as AWS from 'aws-sdk'
import { bucketRegion } from '../../common/configAws'
import { accessKeyId, secretAccessKey, UserPoolId } from '../../hr/common/UserPool'
import { findPropertiesHr } from '../../hr/common/fildProperties'

import EnhancedTable from '../../common/table/item/paginationByFront/firstColBtnBtn'
import { HeadCell } from '../../common/table/config'

const InventoryDeliveryNoteListEmployeePage: React.FC = (props: any) => {
  const [currentQueryString, setCurrentQueryString] = useState('')
  const [responseDeliveryOrderSetAdd, setResponseDeliveryOrderSetAdd] = useState([])

  const [data, setData] = useState([])
  DocumentTitle('Inventory', 'การส่งสินค้า')
  useTokenInitializer()

  useEffect(() => {
    const params = {
      UserPoolId: UserPoolId,
    }

    const test = new Promise((resolve, reject) => {
      AWS.config.update({
        region: bucketRegion,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      })
      const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
      cognitoidentityserviceprovider.listUsers(params, (err, data: any) => {
        if (err) {
          console.log(err)
          reject(err)
        } else {
          const listUser: any = []
          data.Users.map((user: any, index: number) => {
            if (findPropertiesHr(user.Attributes, 'custom:division') === 'ขนส่ง') {
              const mapper = {
                index: `${index + 1}`,
                Username: user.Username,
                Fullname: findPropertiesHr(user.Attributes, 'name'),
                timeStamp: '',
              }
              listUser.push(mapper)
            }
          })
          setData(listUser)
          resolve(data)
        }
      })
    })
  }, [])

  const onClickAssignDeliveryMan = (index: any) => {
    const clonesLineItems = [...data]
    const lineItem: any = clonesLineItems[index]
    const goodIssueIdList: any = []

    props.location.state.map((data: any) => {
      goodIssueIdList.push(parseInt(data.id))
    })
    //goodIssueInternalDocumentId
    const q = `mutation deliveryOrderSetAdd($input: DeliveryOrderSetAdd!) {
      deliveryOrderSetAdd(input: $input) {
        id
        internalDocumentId
        
        documentTypeId
        documentStatusId
        referenceNumber
        remark
        createdAt
        updatedAt
        receiveGoodAt
        appointReceiveAt
        receiverName
        phoneNumber
        googleMap
        fullAddress
        otherRemark
        imageUri {
          imageUri
          createdAt
        }
        checkedInAt
        deliveryImageUri {
          imageUri
          createdAt
        }
        employeeId
      }
    }`
    // Where to put the variables
    const v = {
      input: {
        goodIssueIdList: goodIssueIdList,
        employeeId: lineItem.Username,
      },
    }
    console.log('v', JSON.stringify(v))
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('result', result)
        const deliveryOrderSetAdd = result.data.data.deliveryOrderSetAdd
        setResponseDeliveryOrderSetAdd(deliveryOrderSetAdd)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'createAt', numeric: false, disablePadding: false, label: 'ดูตาราง' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'พนักงานส่ง' },
    { id: 'buy', numeric: true, disablePadding: false, label: 'คิวล่าสุด' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  const rowBody: any = data

  if (!props.location.state) {
    return <Redirect to="/inventory/delivery-note/list/order" />
  }
  if (responseDeliveryOrderSetAdd.length > 0) {
    return (
      <Redirect
        to={{
          pathname: `/inventory/delivery-note/details/add`,
          state: responseDeliveryOrderSetAdd,
        }}
      />
    )
  }
  return (
    <>
      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>การส่งสินค้า</h1>
        <p>กรุณาเลือกรายการ</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสเอกสาร"
          onChange={(e) => setCurrentQueryString(e.target.value)}
          style={{ maxWidth: '385.6px', display: 'flex' }}
        />
      </section>
      <section className={`tableListMui`}>
        <h4 style={{ margin: '20px 0 10px 0px' }}>รายการนำเข้า</h4>
        <EnhancedTable
          colHead={headCells}
          rows={rowBody}
          link="#"
          linkViewSchedule="#"
          onClickAssignDeliveryMan={onClickAssignDeliveryMan}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/delivery-note/list/order">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
        {/* <Link to="/inventory/delivery-note/detail/1">
          <button type="button" className="styleBtn">
            ถัดไป
          </button>
        </Link> */}
      </section>
    </>
  )
}

export default InventoryDeliveryNoteListEmployeePage
