import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import DocumentTitle from '../common/document-title'
import axios from 'axios'
import useFactInitializer from '../../hooks/fact-initializer'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { urlApi } from '../common/configUrlApi'

import useTokenInitializer from '../../hooks/token-initializer'
import EnhancedTable from '../../common/table/item/paginationByBack/firstColCheckboxAndInput'
import { HeadCell } from '../../common/table/config'

const InventoryReceiveListSKUPage: React.FC = (props: any) => {
  DocumentTitle('Inventory', 'นำสินค้าเข้า')

  const [loading, setLoading] = useState<any>(true)

  const [lineItems, setLineItems] = useState<any>([])

  const [selected, setSelected] = React.useState([]) // สำหรับส้ง post

  const [currentQueryString, setCurrentQueryString] = useState('')
  const [seclectType, setSeclectType] = useState('all')
  const [seclectColor, setSeclectColor] = useState('all')
  const [seclectFinish, setSeclectFinish] = useState('all')

  // FACT TYPE
  const [factTypes, setFactTypes] = useState<any>([])
  const [factColors, setFactColors] = useState<any>([])
  const [factFinishs, setFactFinishs] = useState<any>([])
  const viewSkuPropertyAllListType = useSelector(
    (state: any) => state.api.fact.viewSkuPropertyAllListType.items.viewSkuPropertyAll?.['listType'].results,
  )
  const viewSkuPropertyAllListColor = useSelector(
    (state: any) => state.api.fact.viewSkuPropertyAllListType.items.viewSkuPropertyAll?.['listColor'].results,
  )
  const viewSkuPropertyAllListFinish = useSelector(
    (state: any) => state.api.fact.viewSkuPropertyAllListType.items.viewSkuPropertyAll?.['listSurface'].results,
  )

  // Pgaination
  const [page, setPage] = useState(0)
  const [pageList, setPageList] = React.useState<any>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = React.useState(0)

  useFactInitializer()
  useTokenInitializer()

  useEffect(() => {
    // For not dowload page if dowloaded
    const findPage = pageList.find((ele: any) => `${ele}` === `${page}`)
    if (findPage || findPage === 0) return

    // Where to put the query
    const q = `query ($pageIndex: Int, $maxResults: Int, $productCodePartial: String, $typeId: Int, $colorId: Int, $surfaceId: Int) {
      listSku(pageIndex: $pageIndex, maxResults: $maxResults, productCodePartial: $productCodePartial, typeId: $typeId, colorId: $colorId, surfaceId: $surfaceId) {
        results {
          id
          name
          productCode
          profile
          spec
          colorId
          product {
            name
          }
          qtyPerPack(packId: 1)
          onHandStatistic {
            available
            ordered
            inStock
            committed
          }
          color {
            id
            code
            name
          }
          surfaceId
          surface {
            id
            code
            name
          }
          typeId
          type {
            id
            code
            name
          }
          updatedAt
          createdAt
        }
        totalCount
        pageIndex
        maxResults
      }
    }`
    // Where to put the variables
    const v = {
      pageIndex: page,
      maxResults: rowsPerPage,
      productCodePartial: currentQueryString,
      typeId: seclectType !== 'all' ? parseInt(seclectType) : null,
      colorId: seclectColor !== 'all' ? parseInt(seclectColor) : null,
      surfaceId: seclectFinish !== 'all' ? parseInt(seclectFinish) : null,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const listSku = result.data.data.listSku.results
        const listSkuMapper: any[] = []
        listSku.map((data: any) => {
          const mapper = {
            id: data.id,
            itemID: data.productCode,
            product: data.product.name,
            amountPack: 0,
            profile: data.type.name,
            color: data.color.name,
            spec: data.spec,
            finish: data.surface.name,
            piecePerPack: data.qtyPerPack,

            purchase: data.onHandStatistic.ordered,
            stock: data.onHandStatistic.inStock,
            total: data.onHandStatistic.available - data.onHandStatistic.committed,
            commited: data.onHandStatistic.committed,
          }
          return listSkuMapper.push(mapper)
        })
        setLoading(false)
        setTotalCount(result.data.data.listSku.totalCount)
        setLineItems([...lineItems, ...listSkuMapper])

        setPageList([...pageList, page]) // For not reload new page if dowloaded
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [page, rowsPerPage, seclectType, seclectColor, seclectFinish, currentQueryString])

  // GET LIST FACT TYPE
  useEffect(() => {
    if (viewSkuPropertyAllListType) setFactTypes(viewSkuPropertyAllListType)
    if (viewSkuPropertyAllListColor) setFactColors(viewSkuPropertyAllListColor)
    if (viewSkuPropertyAllListFinish) setFactFinishs(viewSkuPropertyAllListFinish)
  }, [viewSkuPropertyAllListType, viewSkuPropertyAllListColor, viewSkuPropertyAllListFinish])

  const handleSubmit = () => {
    const filterLineItems = selected.filter(function (data: any) {
      return data.amountPack >= 1
    })

    const mapperLineItems: any = []
    filterLineItems.map((data: any, index: any) => {
      const mapperLineItem = {
        lineNumber: index + 1,
        skuId: data.id,
        qty: parseInt(data.amountPack),
        uomId: 2,
        packId: 1,
      }
      mapperLineItems.push(mapperLineItem)
    })
    // Where to put the query
    const q = `mutation createGoodReceive($input: CreateGoodReceive!) {
      createGoodReceive(input: $input) {
        id
        purchaseOrderDocumentId
        lotNumber
        packLineItem {
          id
          skuId
          qty
          uomId
          runningNumber
          scanBy
          scanAt
          serialNumber
        }
      }
    }`
    // Where to put the variables
    const v = {
      input: {
        purchaseOrderDocumentId: null,
        lineItem: mapperLineItems,
      },
    }
    if (mapperLineItems.length >= 1) {
      axios({
        url: urlApi,
        method: 'post',
        data: {
          query: q,
          variables: v,
        },
      }).then((res) => {
        console.log('res', res)
        props.history.push(`/inventory/receive/ref/po/${res.data.data.createGoodReceive.id}`)
      })
    } else {
      console.log('ไม่มี SKU ที่ส่ง')
      return
    }
  }

  const request = debounce((value) => {
    setPage(0)
    setPageList([])
    setLineItems([])
    setCurrentQueryString(value)
  }, 1000)

  const debouceRequest = useCallback((value) => request(value), [])

  const onChange = (e: any) => {
    debouceRequest(e.target.value)
  }

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'itemID', numeric: false, disablePadding: false, label: 'รหัสสินค้า' },
    { id: 'product', numeric: false, disablePadding: false, label: 'รายละเอียด' },
    { id: 'amountPack', numeric: false, disablePadding: false, label: 'จำนวนห่อ' },
    { id: 'profile', numeric: false, disablePadding: false, label: 'ประเภท' },
    { id: 'color', numeric: false, disablePadding: false, label: 'สี' },
    { id: 'spec', numeric: false, disablePadding: false, label: 'ขนาด(หนา x กว้าง x ยาว)' },
    { id: 'finish', numeric: false, disablePadding: false, label: 'ผิว' },
    { id: 'piecePerPack', numeric: false, disablePadding: false, label: 'จำนวนแผ่นต่อห่อ' },
    { id: 'purchase', numeric: false, disablePadding: false, label: 'ซื้อ' },
    { id: 'stock', numeric: false, disablePadding: false, label: 'คงคลัง' },
    { id: 'total', numeric: false, disablePadding: false, label: 'คงเหลือสุทธิ' },
    { id: 'commited', numeric: false, disablePadding: false, label: 'จอง' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  const rowBody: any = lineItems

  const handleSetFilter = (value: any, setValue: any) => {
    setValue(value)
    setPage(0)
    setPageList([])
    setLineItems([])
  }

  return (
    <>
      <section className={styles.headerWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>นำสินค้าเข้า</h1>
        <p>กรุณาเลือกรายการสำหรับนำเข้า</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสสินค้า / Serial Number / รายละเอียด / ชื่อสินค้า"
          style={{ maxWidth: '385.6px', margin: '10px 10px 0 0' }}
          onChange={onChange}
        />
        <select
          name="type"
          className={`${styles.selectFilter} ${styles.hiddenInDesktop}`}
          onChange={(e) => handleSetFilter(e.target.value, setSeclectType)}
          style={{ maxWidth: '385.6px', margin: '10px 10px 0 0' }}
        >
          <option value="all">ประเภท</option>
          {factTypes.map((data: any, index: any) => {
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            )
          })}
        </select>

        <select
          name="type"
          className={styles.selectFilter}
          style={{ maxWidth: '385.6px', margin: '10px 10px 0 0' }}
          onChange={(e) => handleSetFilter(e.target.value, setSeclectColor)}
        >
          <option value="all">สี</option>
          {factColors.map((data: any, index: any) => {
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            )
          })}
        </select>

        <select
          name="type"
          className={styles.selectFilter}
          style={{ maxWidth: '385.6px', margin: '10px 10px 0 0' }}
          onChange={(e) => handleSetFilter(e.target.value, setSeclectFinish)}
        >
          <option value="all">Finish</option>
          {factFinishs.map((data: any, index: any) => {
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            )
          })}
        </select>
      </section>
      <section className={styles.cardItems}>
        <button
          type="button"
          className={`${styles.buttonCardItem} ${seclectType === 'all' ? styles.active : ''}`}
          style={{ minWidth: '170px' }}
          onClick={() => handleSetFilter('all', setSeclectType)}
        >
          <p>ทั้งหมด</p>
        </button>
        {factTypes.map((data: any, index: any) => {
          return (
            <button
              key={index}
              type="button"
              className={`${styles.buttonCardItem} ${seclectType === data.code ? styles.active : ''}`}
              onClick={() => handleSetFilter(data.code, setSeclectType)}
            >
              <img src={data.uri ? data.uri : 'https://fakeimg.pl/150x90'} width="150" height="90" />
              <p>{data.name}</p>
            </button>
          )
        })}
      </section>
      <section className="tableListMui">
        <EnhancedTable
          colHead={headCells}
          rows={rowBody}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
          selected={selected}
          setSelected={setSelected}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        {/* <Link to="/inventory/receive"> */}
        <button type="button" className="styleBtn" onClick={() => props.history.goBack()}>
          ย้อนกลับ
        </button>
        {/* </Link> */}
        <button type="button" className="styleBtn" onClick={() => handleSubmit()}>
          ถัดไป
        </button>
      </section>
    </>
  )
}

export default InventoryReceiveListSKUPage
