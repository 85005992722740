import React from 'react'
import { getTheme, mergeStyleSets, FontWeights, Modal, IconButton, IIconProps } from 'office-ui-fabric-react'
import { useId } from '@uifabric/react-hooks'
import CorrectSVG from '../page-document-engineer/correct.svg'
import UnCorrectSVG from '../page-document-engineer/uncorrect.svg'

const ModalAlert = (isModalOpen: any, successSubmit: any, setFieldValue: any) => {
  const cancelIcon: IIconProps = { iconName: 'Cancel' }
  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const titleId = useId('title')
  return (
    <Modal
      titleAriaId={titleId}
      isOpen={isModalOpen}
      onDismiss={() => setFieldValue('isModalOpen', false, false)}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={() => setFieldValue('isModalOpen', false, false)}
        />
      </div>
      <div className={contentStyles.body}>
        {successSubmit === 'เพิ่มสำเร็จ' || successSubmit === 'แก้ไขสำเร็จ' ? (
          <>
            <h1>{successSubmit}</h1>
            <img src={CorrectSVG} width="70" style={{ margin: '10px' }} />
            <p>กรุณาตรวจสอบข้อมูล</p>

            <button
              type="button"
              className="styleBtn"
              style={{ float: 'right', margin: '20px 0 0', padding: '5px 8px' }}
              onClick={() => setFieldValue('isModalOpen', false, false)}
            >
              ปิดหน้าต่าง
            </button>
          </>
        ) : (
          <>
            <h1>{successSubmit}</h1>
            <img src={UnCorrectSVG} width="70" style={{ margin: '10px' }} />
            <p>กรุณาตรวจสอบข้อมูล</p>

            <button
              type="button"
              className="styleBtn"
              style={{ float: 'right', margin: '20px 0 0', padding: '5px 8px' }}
              onClick={() => setFieldValue('isModalOpen', false, false)}
            >
              ปิดหน้าต่าง
            </button>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ModalAlert

const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      // borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    textAlign: 'center',
    marginTop: '-25px',
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      h1: { margin: '0' },
      p: { margin: '0' },
    },
  },
})
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}
