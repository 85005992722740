import React, { useEffect, useState } from 'react'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import styles from './index.module.scss'
import DocumentTitle from '../../inventory/common/document-title'
import { divisionList } from '../common/AttributesDivision'
import { Link } from 'react-router-dom'
import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'

const HrDivisionPage: React.FC = (props: any) => {
  DocumentTitle('Hr', 'จัดการตำแหน่ง')
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()

  const [fullname, setFullname] = useState('')
  const token = useSelector((state: any) => state.token)

  useTokenInitializer()

  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
  }, [token])

  return (
    <>
      <section className={styles.section1}>
        <div className={`loader loader-default ${values.loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>รายชื่อพนักงาน</h1>
        <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [{fullname}]</span>
      </section>
      <div style={{ margin: '0 25px', maxWidth: '100px', minWidth: '100px', width: '100px' }}>
        <table className={styles.widthTable}>
          <thead className={styles.widthTable}>
            <tr className={styles.widthTable}>
              <th className={`borderLeftRadius ${styles.widthTable}`}>เลือก</th>
              <th className={`borderRightRadius ${styles.widthTable}`}>ชื่อแผนก</th>
            </tr>
          </thead>
          <tbody>
            {divisionList.map((data, index) => (
              <tr key={index}>
                <td>
                  <Link to={`/hr/division/${data.divisionName}`}>
                    <button className="styleBtnForTable" type="button">
                      เลือก
                    </button>
                  </Link>
                </td>
                <td>{data.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <section className={styles.section1}>
        <button type="button" className="styleBtn" onClick={() => props.history.push('/hr')}>
          ย้อนกลับ
        </button>
      </section>
    </>
  )
}

const EnhancedHrDivisionPage = withFormik({
  mapPropsToValues: () => ({
    loading: false,
  }),
  validate: (values) => {
    console.log(values)
  },
  handleSubmit: (values, { setFieldValue }) => {
    console.log(values)
  },
})(HrDivisionPage)

export default EnhancedHrDivisionPage
