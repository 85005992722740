import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useHistory, Link } from 'react-router-dom'

import HomeSVG from '../main-inventory/home.svg'
import SearchSVG from '../main-inventory/search.svg'
import AddSVG from '../main-inventory/add.svg'
import RemoveSVG from '../main-inventory/remove.svg'
import CheckStockSVG from '../main-inventory/check-stock.svg'
import PurchaseSVG from '../main-inventory/purchase.svg'
import SaleOrderSVG from '../main-inventory/sale-order.svg'
import DeliveryNoteSVG from '../main-inventory/delivery-note.svg'
import ApproveSVG from '../main-inventory/approve.svg'

const InventoryNavSideComponent: React.FunctionComponent = (props: any) => {
  const [pathname, setPathname] = useState('')

  const history = useHistory()
  // Solve choose NavtopBar & Navigation for Route to new page
  history.listen((location, action) => {
    setPathname(history.location.pathname)
  })

  // Solve choose NavtopBar & Navigation for Reload
  useEffect(() => {
    setPathname(history.location.pathname)
  }, [history])

  return (
    <nav className={styles.navSideBar}>
      <Link to="/">
        <div className={styles.moduleSelected}>
          <h3>โมดูล</h3>
          <img src={DeliveryNoteSVG} width="25" height="25" />
          <p>คลังสินค้า</p>
        </div>
      </Link>

      <h3 className={styles.textMenu}>เมนู</h3>
      {menu.map(({ name, to, img }: any) => (
        <Link
          to={to}
          className={`${styles.buttonLink} ${
            pathname.indexOf(`${to}`) !== -1 && to !== '/inventory' ? styles.active : ''
          }`}
          key={name}
        >
          <img src={img} width="30" height="30" />
          <p>{name}</p>
        </Link>
      ))}
    </nav>
  )
}

export default InventoryNavSideComponent

const menu: any = [
  {
    name: 'หน้าหลัก',
    to: '/inventory',
    img: HomeSVG,
  },
  {
    name: 'เรียกดูสินค้า',
    to: '/inventory/item-master-data',
    img: SearchSVG,
  },
  {
    name: 'นำสินค้าเข้า',
    to: '/inventory/receive',
    img: AddSVG,
  },
  {
    name: 'นำสินค้าออก',
    to: '/inventory/issue',
    img: RemoveSVG,
  },
  {
    name: 'เช็ค Stock',
    to: '/inventory/stock',
    img: CheckStockSVG,
  },
  {
    name: 'การอนุมัติ',
    to: '/inventory/approve',
    img: ApproveSVG,
  },
  {
    name: 'การส่งสินค้า',
    to: '/inventory/delivery-note',
    img: DeliveryNoteSVG,
  },
  {
    name: 'ดูข้อมูล PO',
    to: '/inventory/purchase-orders',
    img: PurchaseSVG,
  },
  {
    name: 'ดูข้อมูล SO',
    to: '/inventory/sale-orders',
    img: SaleOrderSVG,
  },
]
