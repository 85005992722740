import React, { useEffect } from 'react'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import styles from './index.module.scss'
import DocumentTitle from '../../inventory/common/document-title'
import validateIgnoreField from '../../common/validateIgnoreField'
import { accessKeyId, secretAccessKey, UserPoolId, ClientId } from '../common/UserPool'
import { useHistory } from 'react-router-dom'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from '../../common/configAws'
import { findPropertiesHr } from '../common/fildProperties'
import ModalAlert from '../common/modal'
import jwt_decode from 'jwt-decode'
import useFactInitializer from '../../hooks/fact-initializer'
import { useSelector } from 'react-redux'
import { divisionList } from '../common/AttributesDivision'

import useTokenInitializer from '../../hooks/token-initializer'

const HrAddPage: React.FC = (props: any) => {
  DocumentTitle('Hr', 'เพิ่มผู้ใช้งาน')
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()

  // Set MODE ACTION
  const history = useHistory()
  const pathname = history.location.pathname

  const listDivision = useSelector((state: any) => state.api.fact.listDivision.items.listDivision)
  const tokenDivision = useSelector((state: any) =>
    findPropertiesHr(state.token.userInfo.UserAttributes, 'custom:division'),
  )
  useFactInitializer()
  useTokenInitializer()

  const getInfoUsername = () => {
    const params = {
      UserPoolId: UserPoolId /* required */,
      Username: props.match.params.id /* required */,
    }

    const test = new Promise((resolve, reject) => {
      AWS.config.update({
        region: bucketRegion,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      })
      const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
      cognitoidentityserviceprovider.adminGetUser(params, (err, data: any) => {
        if (err) {
          console.log(err)
          reject(err)
        } else {
          if (data.UserAttributes.length === 0) return
          setFieldValue('username', props.match.params.id, true)
          setFieldValue('email', findPropertiesHr(data.UserAttributes, 'email'), true)
          setFieldValue('fullname', findPropertiesHr(data.UserAttributes, 'name'), true)
          setFieldValue('address', findPropertiesHr(data.UserAttributes, 'address'), true)

          setFieldValue('division', findPropertiesHr(data.UserAttributes, 'custom:division'), true)
          setFieldValue('position', findPropertiesHr(data.UserAttributes, 'custom:position'), true)
          setFieldValue('team', findPropertiesHr(data.UserAttributes, 'custom:team'), true)

          setFieldValue('phone', findPropertiesHr(data.UserAttributes, 'phone_number')?.replace('+66', '0'), true)
          setFieldValue('imageUri', findPropertiesHr(data.UserAttributes, 'picture'), true)
          setFieldValue('updatedAt', new Date(data.UserLastModifiedDate).toISOString().split('.')[0], true)
          setFieldValue('createdAt', new Date(data.UserCreateDate).toISOString().split('.')[0], true)

          // permission: '',
          setFieldValue('loading', false, false)
          resolve(data)
        }
      })
    })
  }

  useEffect(() => {
    if (pathname.indexOf('/hr/add') !== -1) {
      setFieldValue('mode', 'add', false)
      setFieldValue('isDisabled', false, false)
      setFieldValue('loading', false, false)
    }
    if (pathname.indexOf('/hr/view') !== -1) {
      setFieldValue('mode', 'view', false)
      setFieldValue('isDisabled', true, false)
      getInfoUsername()
    }
    if (pathname.indexOf('/hr/edit') !== -1) {
      setFieldValue('mode', 'edit', false)
      setFieldValue('isDisabled', false, false)
      getInfoUsername()
    }
  }, [values.mode])

  const headComponant = () => {
    if (values.mode === 'add') {
      return (
        <div>
          <h1 className={styles.headerTitle}>เพิ่มพนักงาน</h1>
        </div>
      )
    }
    if (values.mode === 'view') {
      return (
        <div>
          <h1 className={styles.headerTitle}>ข้อมูลพนักงาน</h1>
          <button
            type="button"
            className={`styleBtn`}
            style={{ padding: '5px 8px', margin: '10px 0 0' }}
            onClick={() => {
              props.history.push(`/hr/edit/${props.match.params.id}`)
              setFieldValue('mode', 'edit', false)
            }}
          >
            แก้ไขข้อมูล
          </button>
        </div>
      )
    }
    if (values.mode === 'edit') {
      return (
        <div>
          <h1 className={styles.headerTitle}>แก้ไขข้อมูลพนักงาน</h1>
          <button
            type="button"
            className={`styleBtn`}
            style={{ padding: '5px 8px', margin: '10px 0 0' }}
            onClick={() => {
              props.history.push(`/hr/view/${props.match.params.id}`)
              setFieldValue('mode', 'view', false)
            }}
          >
            ย้อนกลับ
          </button>
        </div>
      )
    }
  }

  const onClickChangePassword = () => {
    if (!values.oldPassword && !values.password && !values.passwordConfirm)
      return setFieldValue('errChangePass', 'กรอกข้อมูลไม่ครบถ้วน', false)
    if (values.password !== values.passwordConfirm)
      return setFieldValue('errChangePass', 'รหัสผ่านใหม่ กับ อีกครั้งไม่ตรงกัน', false)

    AWS.config.update({
      region: bucketRegion,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    })
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

    const params: any = {
      AccessToken: localStorage.getItem('accessToken') /* required */,
      PreviousPassword: values.oldPassword /* required */,
      ProposedPassword: values.password /* required */,
    }
    cognitoidentityserviceprovider.changePassword(params, function (err, data) {
      if (err) {
        console.log(err, err.stack)
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('errChangePass', '', false)
        setFieldValue('oldPassword', '', false)
        setFieldValue('password', '', false)
        setFieldValue('passwordConfirm', '', false)
      }
      // an error occurred
      else {
        console.log(data)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('successSubmit', 'เพิ่มสำเร็จ', false)
        setFieldValue('errChangePass', '', false)
        setFieldValue('oldPassword', '', false)
        setFieldValue('password', '', false)
        setFieldValue('passwordConfirm', '', false)
      } // successful response
    })
  }

  const passwordComponant = () => {
    if (values.mode === 'add') {
      return (
        <div className={`${styles.column}`}>
          <h3 className={`${styles.marginTop10}`}>สร้างรหัสผ่าน</h3>
          <p className={`${styles.marginTop10}`}>รหัสผ่าน</p>
          <Field
            name="password"
            className="inputForSearch"
            type="password"
            placeholder="รหัสผ่าน"
            disabled={values.isDisabled}
            style={{
              maxWidth: '390px',
              display: 'flex',
              ...(touched?.password && errors?.password && { border: '1px solid red' }),
            }}
          />
          <span style={{ color: 'red', fontSize: '11px' }}>- ตัวอักษรอย่างน้อย 8 ตัว</span>
          <span style={{ color: 'red', fontSize: '11px' }}>- ต้องมีตัวอักษรพิมพ์ใหญ่ เล็กและตัวเลข</span>
          <span style={{ color: 'red', fontSize: '11px' }}>- ต้องมีตัวอักษรพิเศษ เช่น @, #, $</span>
        </div>
      )
    }

    const token: any = localStorage.getItem('accessToken')
    const decoded: any = jwt_decode(token)

    if (values.mode === 'view') {
      return (
        <div className={`${styles.column}`}>
          <h3 className={`${styles.marginTop10}`}>สถานการใช้งาน</h3>
          {/* <h3 className={`${styles.marginTop10}`}>เปลี่ยนรหัสผ่าน</h3>
          <p style={{ color: 'red' }}>{values.errChangePass}</p>
          <p className={`${styles.marginTop10}`}>รหัสผ่านเดิม</p>
          <Field
            name="oldPassword"
            className="inputForSearch"
            type="password"
            placeholder="รหัสผ่าน"
            disabled={values.isDisabled}
            style={{
              maxWidth: '390px',
              display: 'flex',
            }}
          />
          <p className={`${styles.marginTop10}`}>รหัสผ่านใหม่</p>
          <Field
            name="password"
            className="inputForSearch"
            type="password"
            placeholder="รหัสผ่านใหม่"
            disabled={values.isDisabled}
            style={{
              maxWidth: '390px',
              display: 'flex',
            }}
          />
          <p className={`${styles.marginTop10}`}>รหัสผ่านใหม่อีกครั้ง</p>
          <Field
            name="passwordConfirm"
            className="inputForSearch"
            type="password"
            placeholder="รหัสผ่านใหม่อีกครั้ง"
            disabled={values.isDisabled}
            style={{
              maxWidth: '390px',
              display: 'flex',
            }}
          />
          <span style={{ color: 'red', fontSize: '11px' }}>- ตัวอักษรอย่างน้อย 8 ตัว</span>
          <span style={{ color: 'red', fontSize: '11px' }}>- ต้องมีตัวอักษรพิมพ์ใหญ่ เล็กและตัวเลข</span>
          <span style={{ color: 'red', fontSize: '11px' }}>- ต้องมีตัวอักษรพิเศษ เช่น @, #, $</span>
          <div>
            <button
              type="button"
              className={`styleBtn`}
              disabled={values.isDisabled}
              onClick={() => onClickChangePassword()}
            >
              บันทึก
            </button>
          </div> */}
          <p className={`${styles.marginTop10}`}>ใช้งานล่าสุด</p>
          <Field
            name="updatedAt"
            className="inputForSearch"
            type="datetime-local"
            disabled={true}
            style={{
              maxWidth: '390px',
              display: 'flex',
            }}
          />
          <p className={`${styles.marginTop10}`}>สมัครเมื่อ</p>
          <Field
            name="createdAt"
            className="inputForSearch"
            type="datetime-local"
            disabled={true}
            style={{
              maxWidth: '390px',
              display: 'flex',
            }}
          />
        </div>
      )
    }

    if (values.mode === 'edit') {
      return (
        <div className={`${styles.column}`}>
          <h3 className={`${styles.marginTop10}`}>เปลี่ยนรหัสผ่าน</h3>
          <p style={{ color: 'red' }}>{values.errChangePass}</p>
          <p className={`${styles.marginTop10}`}>รหัสผ่านเดิม</p>
          <Field
            name="oldPassword"
            className="inputForSearch"
            type="password"
            placeholder="รหัสผ่าน"
            disabled={decoded.username === values.username ? false : true}
            style={{
              maxWidth: '390px',
              display: 'flex',
            }}
          />
          <p className={`${styles.marginTop10}`}>รหัสผ่านใหม่</p>
          <Field
            name="password"
            className="inputForSearch"
            type="password"
            placeholder="รหัสผ่านใหม่"
            disabled={decoded.username === values.username ? false : true}
            style={{
              maxWidth: '390px',
              display: 'flex',
            }}
          />
          <p className={`${styles.marginTop10}`}>รหัสผ่านใหม่อีกครั้ง</p>
          <Field
            name="passwordConfirm"
            className="inputForSearch"
            type="password"
            placeholder="รหัสผ่านใหม่อีกครั้ง"
            disabled={decoded.username === values.username ? false : true}
            style={{
              maxWidth: '390px',
              display: 'flex',
            }}
          />
          <div>
            <button
              type="button"
              className={`styleBtn`}
              disabled={decoded.username === values.username ? false : true}
              onClick={() => onClickChangePassword()}
            >
              บันทึก
            </button>
          </div>
          <p className={`${styles.marginTop10}`}>ใช้งานล่าสุด</p>
          <Field
            name="updatedAt"
            className="inputForSearch"
            type="datetime-local"
            disabled={true}
            style={{
              maxWidth: '390px',
              display: 'flex',
            }}
          />
          <p className={`${styles.marginTop10}`}>สมัครเมื่อ</p>
          <Field
            name="createdAt"
            className="inputForSearch"
            type="datetime-local"
            disabled={true}
            style={{
              maxWidth: '390px',
              display: 'flex',
            }}
          />
        </div>
      )
    }
  }

  const handleUploadImage = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]

    if (file.length !== 0) {
      const albumPhotosKey = encodeURIComponent('ProfileImage') + '/'
      const photoKey = albumPhotosKey + values.username

      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })

      const promise = upload.promise()

      promise.then(
        function (data) {
          alert('Successfully uploaded photo.')
          setFieldValue('imageUri', data.Location, true)
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }
  return (
    <Form>
      <div className={`loader loader-default ${values.loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        {headComponant()}

        <div className={`${styles.marginTop10} ${styles.row}`}>
          <div className={`${styles.column}`}>
            <h3 className={`${styles.marginTop10}`}>ข้อมูลทั่วไป</h3>
            <p className={`${styles.marginTop10}`}>อีเมลผู้ใช้งาน</p>
            <Field
              name="email"
              className="inputForSearch"
              type="email"
              placeholder="อีเมลผู้ใช้งาน"
              disabled={values.mode !== 'add' ? true : false}
              style={{
                maxWidth: '390px',
                display: 'flex',
                ...(touched?.email && errors?.email && { border: '1px solid red' }),
              }}
            />
            <p className={`${styles.marginTop10}`}>ชื่อจริง นามสกุล</p>
            <Field
              name="fullname"
              className="inputForSearch"
              type="text"
              placeholder="ชื่อจริง นามสกุล"
              disabled={values.isDisabled}
              style={{
                maxWidth: '390px',
                display: 'flex',
                ...(touched?.fullname && errors?.fullname && { border: '1px solid red' }),
              }}
            />
            <p className={`${styles.marginTop10}`}>เบอร์โทรศัพท์</p>
            <Field
              name="phone"
              className="inputForSearch"
              type="text"
              placeholder="เบอร์โทรศัพท์"
              disabled={values.isDisabled}
              style={{
                maxWidth: '390px',
                display: 'flex',
              }}
            />
            <p className={`${styles.marginTop10}`}>ที่อยู่</p>
            <Field
              name="address"
              className={styles.styleTextarea}
              disabled={values.isDisabled}
              placeholder="ที่อยู่"
              as="textarea"
              rows={4}
              style={{
                maxWidth: '390px',
                display: 'flex',
              }}
            />
            <h3 className={`${styles.marginTop10}`}>จัดการหน้าที่</h3>
            <p className={`${styles.marginTop10}`}>แผนก</p>
            <Field
              as="select"
              name="division"
              className="inputForSearch"
              disabled={
                values.isDisabled ? values.isDisabled : tokenDivision === 'บุคคล' || !tokenDivision ? false : true
              }
              style={{
                maxWidth: '390px',
                display: 'flex',
                ...(touched?.fullname && errors?.fullname && { border: '1px solid red' }),
              }}
            >
              <option value="">...กรุณาเลือก</option>
              {divisionList.map((data: any, index: number) => (
                <option value={data.name} key={index} selected={values.division === data.name}>
                  {data.name}
                </option>
              ))}
            </Field>
            <p className={`${styles.marginTop10}`}>ตำแหน่ง</p>
            <Field
              as="select"
              name="position"
              className="inputForSearch"
              disabled={
                values.isDisabled ? values.isDisabled : tokenDivision === 'บุคคล' || !tokenDivision ? false : true
              }
              style={{
                maxWidth: '390px',
                display: 'flex',
                ...(touched?.fullname && errors?.fullname && { border: '1px solid red' }),
              }}
            >
              <option value="">...กรุณาเลือก</option>
              {listDivision
                ?.find(
                  (parent: any) =>
                    parent.divisionName === divisionList.find((ele) => ele.name === values.division)?.divisionName,
                )
                ?.positionList.map((data: any, index: number) => (
                  <option value={data.name} key={index}>
                    {data.name}
                  </option>
                ))}
            </Field>
            <p className={`${styles.marginTop10}`}>ทีม</p>
            <Field
              name="team"
              disabled={true}
              className="inputForSearch"
              style={{
                maxWidth: '390px',
                display: 'flex',
              }}
            />
            <div>
              <button type="submit" className={`styleBtn`} disabled={values.isDisabled}>
                บันทึก
              </button>
            </div>
          </div>
          {values.mode !== 'add' && (
            <div className={`${styles.column}`}>
              <h3 className={`${styles.marginTop10}`}>รูปภาพโปรไฟล์</h3>
              <p className={`${styles.marginTop10}`}>อัพโหลดรุปภาพ</p>
              <img src={values.imageUri ? values.imageUri : 'https://fakeimg.pl/250x250'} width="250" height="250" />
              <input
                type="file"
                id="file"
                disabled={values.isDisabled}
                onChange={(e) => handleUploadImage(e)}
                accept="image/*"
              />

              <div>
                {values.mode === 'view' && (
                  <button type="button" className={`styleBtn`} disabled={values.isDisabled}>
                    เลือกรูปภาพ
                  </button>
                )}
              </div>
              <div style={{ marginTop: '20px' }}>
                {values.mode !== 'view' && (
                  <label htmlFor="file" className={`styleBtn`}>
                    เลือกรูปภาพ
                  </label>
                )}
              </div>
            </div>
          )}
          {passwordComponant()}
        </div>
      </section>
      {ModalAlert(values.isModalOpen, values.successSubmit, setFieldValue)}
    </Form>
  )
}

const EnhancedHrAddPage = withFormik({
  mapPropsToValues: () => ({
    username: '',
    email: '',
    password: '',

    fullname: '',
    division: '',
    position: '',

    // NOT VALIDATE
    phone: '',
    address: '',
    team: '',
    imageUri: '',
    updatedAt: '',
    createdAt: '',
    permission: '',

    // view, edit, add
    mode: '',

    // Modal
    isModalOpen: false,
    successSubmit: '',

    // Msg Status ChangePAssword
    errChangePass: '',

    loading: true,
    isDisabled: false,
  }),
  validate: (values) => {
    if (values.mode === 'add') {
      return validateIgnoreField(
        values,
        [
          'phone',
          'address',
          'team',
          'imageUri',
          'fullname',
          'division',
          'position',
          'updatedAt',
          'createdAt',
          'mode',
          'isDisabled',
          'username',
          'permission',
          'isModalOpen',
          'successSubmit',
          'loading',
          'errChangePass',
        ],
        '*',
      )
    }
  },
  handleSubmit: (values, { setFieldValue }) => {
    AWS.config.update({
      region: bucketRegion,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    })
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
    const userAttributes: any[] = [
      {
        Name: 'name' /* required */,
        Value: values.fullname,
      },
      {
        Name: 'phone_number' /* required */,
        Value: `+66${values.phone.slice(1, 10)}`,
      },
      {
        Name: 'address' /* required */,
        Value: values.address,
      },
      {
        Name: 'picture' /* required */,
        Value: values.imageUri ? values.imageUri : '',
      },
      {
        Name: 'custom:division' /* required */,
        Value: values.division ? values.division : '',
      },
      {
        Name: 'custom:position' /* required */,
        Value: values.position ? values.position : '',
      },
      {
        Name: 'custom:team' /* required */,
        Value: values.team ? values.team : '',
      },
      {
        Name: 'custom:permission' /* required */,
        Value: '',
      },
      /* more items */
    ]
    console.log('userAttributes', userAttributes)
    if (values.mode === 'add') {
      const params: any = {
        ClientId: ClientId /* required */,
        Username: values.email /* required */,
        Password: values.password /* required */,
        UserAttributes: userAttributes,
      }

      cognitoidentityserviceprovider.signUp(params, function (err, data) {
        if (err) {
          setFieldValue('successSubmit', 'เกิดปัญหา', false)
          setFieldValue('isModalOpen', true, false)
          console.log(err, err.stack)
        }
        // an error occurred
        else {
          setFieldValue('isModalOpen', true, false)
          setFieldValue('successSubmit', 'เพิ่มสำเร็จ', false)
          console.log(data)
        } // successful response
      })
    }
    if (values.mode === 'edit') {
      const params = {
        UserAttributes: userAttributes,
        UserPoolId: UserPoolId /* required */,
        Username: values.username /* required */,
      }

      cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function (err, data) {
        if (err) {
          setFieldValue('successSubmit', 'เกิดปัญหา', false)
          setFieldValue('isModalOpen', true, false)
          console.log(err, err.stack)
        }
        // an error occurred
        else {
          setFieldValue('isModalOpen', true, false)
          setFieldValue('successSubmit', 'เพิ่มสำเร็จ', false)
          console.log(data)
        } // successful response
      })
    }
  },
})(HrAddPage)

export default EnhancedHrAddPage
