import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import { debounce } from 'lodash'
import { urlApi } from '../common/configUrlApi'
import { FormatDateTime } from '../../common/dateFormat'

import useTokenInitializer from '../../hooks/token-initializer'
import EnhancedTable from '../../common/table/doc/firstAndLastColBtnPaginationBack'
import { HeadCell } from '../../common/table/config'

const InventoryIssueDocumentPage: React.FC = () => {
  const [datalistGoodIssue, setDatalistGoodIssue] = useState<any>([])
  const [currentQueryString, setCurrentQueryString] = useState('')
  const [currentQueryDate, setCurrentQueryDate] = useState(null)

  // Pgaination
  const [page, setPage] = React.useState(0)
  const [pageList, setPageList] = React.useState<any>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = React.useState(0)

  DocumentTitle('Inventory', 'นำสินค้าออก')
  useTokenInitializer()

  useEffect(() => {
    // For not dowload page if dowloaded
    const findPage = pageList.find((ele: any) => `${ele}` === `${page}`)
    if (findPage || findPage === 0) return

    // Where to put the query
    const q = `query listGoodIssuePage($pageIndex: Int, $maxResults: Int, $internalDocumentIdPartial: String, $createdAtDay: DateTime){
      listGoodIssue(pageIndex: $pageIndex, maxResults: $maxResults, internalDocumentIdPartial: $internalDocumentIdPartial, createdAtDay: $createdAtDay) {
        totalCount
        pageIndex
        maxResults
        results {
          id
          internalDocumentId
          documentTypeId
          documentStatusId
          referenceNumber
          remark
          createdAt
          updatedAt
          salesOrderDocumentId
          salesOrder {
            internalDocumentId
          }
          lineItem {
            id
            skuId
            qty
            uomId
            packId
            lineNumber
            packLineItem {
              id
              skuId
              runningNumber
              qty
              uomId
              packId
              scanBy
              scanAt
              voided
            }
          }
        }
      }
    }`
    // Where to put the variables
    const v = {
      pageIndex: page,
      maxResults: rowsPerPage,
      createdAtDay: currentQueryDate,
      internalDocumentIdPartial: currentQueryString,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const listGoodIssue = result.data.data.listGoodIssue.results
        const listGoodIssueAfterMapper: any[] = []
        listGoodIssue.map((data: any) => {
          const mapper = {
            id: data.id,
            internalDocumentID: data.internalDocumentId,
            salesOrderDocumentId: data.salesOrder ? data.salesOrder.internalDocumentId : '-',
            documentStatusId: data.documentStatusId,
            date: FormatDateTime(data.updatedAt),
            dowload: data.id,
          }
          return listGoodIssueAfterMapper.push(mapper)
        })
        setDatalistGoodIssue([...datalistGoodIssue, ...listGoodIssueAfterMapper])
        setTotalCount(result.data.data.listGoodIssue.totalCount)

        setPageList([...pageList, page]) // For not reload new page if dowloaded
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [page, rowsPerPage, currentQueryDate, currentQueryString])

  const request = debounce((value, setState) => {
    setPage(0)
    setPageList([])
    setDatalistGoodIssue([])
    setState(value)
  }, 1000)

  const debouceRequest = useCallback((value, setState) => request(value, setState), [])

  const onChangeString = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryString)
  }

  const onChangeDate = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryDate)
  }

  const rowBody: any = datalistGoodIssue

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'productCode', numeric: false, disablePadding: false, label: 'เลขที่รายการนำออก' },
    { id: 'product', numeric: false, disablePadding: false, label: 'อ้างอิง SO' },
    { id: 'status', numeric: false, disablePadding: false, label: 'สถานะ' },
    { id: 'date', numeric: false, disablePadding: false, label: 'วันที่พิมพ์' },
    { id: 'btnPrint', numeric: false, disablePadding: true, label: 'พิมพ์' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  return (
    <>
      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>เอกสารนำสินค้าออก</h1>
        <p>ค้นหา</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสเอกสาร"
          onChange={onChangeString}
          style={{ maxWidth: '385.6px', display: 'flex' }}
        />
        <input type="date" className="inputForSearch" onChange={onChangeDate} style={{ maxWidth: '385.6px' }} />
      </section>
      <section className={`tableListMui`}>
        <EnhancedTable
          colHead={headCells}
          rows={rowBody}
          link="/inventory/issue/document/list"
          linkForEditDoc="/inventory/issue/ref/so"
          linkPDF="/inventory/pdf/issue"
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/issue">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventoryIssueDocumentPage
