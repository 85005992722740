interface IMenuItem {
  name: string
  imgName: string
  linkTo: string
}
const menuItems: IMenuItem[] = [
  { name: 'เลือก Module', imgName: 'HomeModuleSVG', linkTo: '/' },
  { name: 'CRM', imgName: 'CRMSVG', linkTo: '/sale/crm' },
  { name: 'ตารางทำงาน', imgName: 'ScheduleSVG', linkTo: '/sale/calendar' },
  { name: 'สร้างใบเสนอราคา', imgName: 'AddSVG', linkTo: '/sale/quotation' },
  { name: 'เปิดงานถอดแบบ', imgName: 'JobOrderSVG', linkTo: '#' },

  { name: 'การอนุมัติ', imgName: 'ApprovedSVG', linkTo: '#' },
]

export default menuItems
