import React, { useEffect, useState } from 'react'
import { Pivot, PivotItem } from '@fluentui/react'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import validateIgnoreField from '../../common/validateIgnoreField'

import styles from './index.module.scss'
import DocumentTitle from '../../inventory/common/document-title'
import CheckSVG from './check.svg'
import PenSVG from './pen.svg'
import axios from 'axios'
import { urlApiSale } from '../common/configApi'
import GoogleMapReact from 'google-map-react'
import { findProperties } from '../common/findAdditionalProperties'
import { listStatus } from '../common/configStatus'
import ModalAlert from '../common/modal'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from '../../common/configAws'
import { boardID } from '../common/configBoardID'
import ArrowDownSVG from './arrowDown.svg'
import ArrowUpSVG from './arrowUp.svg'
import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'

import { accessKeyId, secretAccessKey, UserPoolId } from '../../hr/common/UserPool'

import { findPropertiesHr } from '../../hr/common/fildProperties'
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'
import { useId } from '@uifabric/react-hooks'
import { FormatDateTime } from '../../common/dateFormat'

const tzoffset = new Date().getTimezoneOffset() * 60000
const localISOTime = new Date(Date.now() - tzoffset).toISOString()

const InventoryMainPage: React.FC = (props: any) => {
  DocumentTitle('Sale', 'CRM')
  // const [loading, setLoading] = useState<any>(true)

  const { values, errors, touched, setFieldValue, submitForm } = useFormikContext<any>()
  const [selectedKey, setSelectedKey] = React.useState(0)
  const [selectedKeyMobile, setSelectedKeyMobile] = React.useState(0)
  const [editTitle, setEditTitle] = React.useState(!props.match.params.id ? true : false)
  const [sort, setSort] = useState(false)

  const [hideDialogRemoveDoc, setHideDialogRemoveDoc] = useState(true)

  const token: any = useSelector((state: any) => state.token)

  useTokenInitializer()

  useEffect(() => {
    if (!token.decoded_token.username) return
    if (props.match.params.id) return
    AWS.config.update({
      region: bucketRegion,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    })
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

    const params = {
      UserPoolId: UserPoolId /* required */,
      Username: token.decoded_token.username /* required */,
    }

    cognitoidentityserviceprovider.adminGetUser(params, (err, data: any) => {
      if (err) {
        console.log(err)
      } else {
        setFieldValue('assignee', findPropertiesHr(data.UserAttributes, 'name'), false)
      }
    })
  }, [token.decoded_token])

  useEffect(() => {
    if (!props.match.params.id) return setFieldValue('loading', false, false)
    // Where to put the variables
    const v = {
      boardID: boardID,
      cardID: props.match.params.id,
    }

    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: qCard,
        variables: v,
      },
    })
      .then((result) => {
        const card = result.data.data.card[0]
        setFieldValue('loading', false, false)
        setFieldValue('cardID', card.cardID, true)
        setFieldValue('title', card.title, true)
        setFieldValue('status', card.status, true)
        setFieldValue('createdAt', card.updatedAt.split('.')[0], true)
        setFieldValue('updatedAt', card.updatedAt.split('.')[0], true)
        setFieldValue('comments', card.comments ? card.comments : [], true)
        setFieldValue('assignee', card.assignee[0], true)
        setFieldValue('contactName', findProperties(card.additionalProperties, 'contactName'), true)
        setFieldValue('contactNumber', findProperties(card.additionalProperties, 'contactNumber'), true)
        setFieldValue('contactEmail', findProperties(card.additionalProperties, 'contactEmail'), true)
        setFieldValue('contactCompany', findProperties(card.additionalProperties, 'contactCompany'), true)
        setFieldValue('contactGoogleMap', findProperties(card.additionalProperties, 'contactGoogleMap'), true)
        setFieldValue('contactWorkType', findProperties(card.additionalProperties, 'contactWorkType'), true)
        setFieldValue('contactAddress', findProperties(card.additionalProperties, 'contactAddress'), true)
        setFieldValue('dealPrice', findProperties(card.additionalProperties, 'dealPrice'), true)
        setFieldValue('dealPercentage', findProperties(card.additionalProperties, 'dealPercentage'), true)
        setFieldValue('dealExpectedCloseDate', findProperties(card.additionalProperties, 'dealExpectedCloseDate'), true)
        setFieldValue('descContractorName', findProperties(card.additionalProperties, 'descContractorName'), true)
        setFieldValue('descContractorNumber', findProperties(card.additionalProperties, 'descContractorNumber'), true)
        setFieldValue('descDesignerName', findProperties(card.additionalProperties, 'descDesignerName'), true)
        setFieldValue('descDesignerNumber', findProperties(card.additionalProperties, 'descDesignerNumber'), true)
        setFieldValue('descPMName', findProperties(card.additionalProperties, 'descPMName'), true)
        setFieldValue('descPMNumber', findProperties(card.additionalProperties, 'descPMNumber'), true)
        setFieldValue('descAVName', findProperties(card.additionalProperties, 'descAVName'), true)
        setFieldValue('descAVNumber', findProperties(card.additionalProperties, 'descAVNumber'), true)
        setFieldValue('descDescription', findProperties(card.additionalProperties, 'descDescription'), true)
        setFieldValue('billingName', findProperties(card.additionalProperties, 'billingName'), true)
        setFieldValue('billingTaxID', findProperties(card.additionalProperties, 'billingTaxID'), true)
        setFieldValue('billingAddress', findProperties(card.additionalProperties, 'billingAddress'), true)
        setFieldValue('billingTelNumber', findProperties(card.additionalProperties, 'billingTelNumber'), true)
        setFieldValue('billingFax', findProperties(card.additionalProperties, 'billingFax'), true)
        setFieldValue('billingEmail', findProperties(card.additionalProperties, 'billingEmail'), true)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [])

  const handlePivotClick = (item: PivotItem | undefined) => {
    if (item !== undefined && item.props.itemKey) {
      setSelectedKey(parseInt(item.props.itemKey))
    }
  }

  const handlePivotClickMobile = (item: PivotItem | undefined) => {
    if (item !== undefined && item.props.itemKey) {
      setSelectedKeyMobile(parseInt(item.props.itemKey))
    }
  }

  const returnMap = () => {
    if (values.latitude && values.longitude) {
      return <SimpleMap longitude={values.longitude} latitude={values.latitude} />
    } else return <div style={{ width: '200px', height: '200px', backgroundColor: 'gray' }} />
  }

  const handleCheckIn = () => {
    setFieldValue('loading', true, false)
    if (!values.numberMeeting) return setFieldValue('loading', false, false)
    navigator.geolocation.getCurrentPosition(function (position) {
      setFieldValue('latitude', position.coords.latitude, false)
      setFieldValue('longitude', position.coords.longitude, false)

      const infoMeeting = values.comments.filter((ele: any) => ele.commentType === 'meeting')[values.numberMeeting]

      const mapper = {
        commentType: 'checkIn',
        lat: position.coords.latitude.toString(),
        long: position.coords.longitude.toString(),
        createdAt: localISOTime,
        number: parseInt(values.numberMeeting),
        datetime: `กำหนดการ ${infoMeeting.datetime.replace('T', ' ').split('.')[0]}`,
        location: infoMeeting.location,
        by: token.name,
      }

      if (values.comments.length !== 0) {
        setFieldValue('comments', [...values.comments, mapper], false)
        // setFieldValue('numberMeeting', '', false)
        setFieldValue('loading', false, false)
        submitForm()
      } else {
        setFieldValue('comments', [mapper], false)
        // setFieldValue('numberMeeting', '', false)
        setFieldValue('loading', false, false)
        submitForm()
      }
    })
  }

  const handleSubmitComment = (commentType: any, value: string) => {
    const mapper = {
      commentType: commentType,
      description: value,
      attachments: values.imageUri ? values.imageUri : [],
      createdAt: localISOTime,
      by: token.name,
    }

    if (values.comments.length !== 0) {
      setFieldValue('comments', [...values.comments, mapper], false)
      setFieldValue('comment', '', false)
      setFieldValue('imageUri', [], false)
      submitForm()
    } else {
      setFieldValue('comments', [mapper], false)
      setFieldValue('comment', '', false)
      setFieldValue('imageUri', [], false)
      submitForm()
    }
  }

  const handleUploadImage = (e: any) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]

    if (file.length !== 0) {
      const fileName = file.name
      const albumPhotosKey = encodeURIComponent('CRM') + '/' + encodeURIComponent(`${values.title}`) + '/'

      const photoKey = albumPhotosKey + fileName
      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })

      const promise = upload.promise()

      promise.then(
        function (data) {
          console.log('data', data)
          if (values.imageUri.length !== 0) {
            setFieldValue('imageUri', [...values.imageUri, data.Location], false)
          } else {
            setFieldValue('imageUri', [data.Location], false)
          }
        },
        function (err) {
          console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  const handleChangeStatus = (value: string) => {
    setFieldValue('status', value, false)
    const result = listStatus.find((ele) => ele.statusName === value)
    if (result) {
      const mapper = {
        commentType: 'changeStatus',
        description: result.statusDisplay,
        attachments: [],
        createdAt: localISOTime,
        by: token.name,
      }

      if (values.comments.length !== 0) {
        setFieldValue('comments', [...values.comments, mapper], false)
      } else {
        setFieldValue('comments', [mapper], false)
      }
    }
  }

  const handleClickSubmitMeeting = () => {
    const mapper = {
      commentType: 'meeting',
      createdAt: localISOTime,
      datetime: values.datetimeMeeting,
      location: values.locationMeeting,
      googleMap: values.linkMapMeeting,
      note: values.noteMeeting,
      by: token.name,
    }

    if (values.comments.length !== 0) {
      setFieldValue('comments', [...values.comments, mapper], false)
      setFieldValue('datetimeMeeting', '', false)
      setFieldValue('locationMeeting', '', false)
      setFieldValue('linkMapMeeting', '', false)
      setFieldValue('noteMeeting', '', false)
      submitForm()
    } else {
      setFieldValue('datetimeMeeting', '', false)
      setFieldValue('locationMeeting', '', false)
      setFieldValue('linkMapMeeting', '', false)
      setFieldValue('noteMeeting', '', false)
      submitForm()
    }
  }

  const handleClickCancleMeeting = (index: any) => {
    const mapper = {
      commentType: 'checkOut',
      createdAt: localISOTime,
      number: index,
      by: token.name,
    }
    setFieldValue('comments', [...values.comments, mapper], false)
    submitForm()
  }

  const handleClickSubmitJobTracking = () => {
    const mapper = {
      commentType: 'jobTracking',
      createdAt: localISOTime,
      datetime: values.datetimeJobTracking,
      note: values.noteJobTracking,
      description: values.typeJobTracking,
      by: token.name,
    }

    if (values.comments.length !== 0) {
      setFieldValue('comments', [...values.comments, mapper], false)
      setFieldValue('datetimeJobTracking', '', false)
      setFieldValue('noteJobTracking', '', false)
      setFieldValue('typeJobTracking', '', false)
      submitForm()
    } else {
      setFieldValue('datetimeJobTracking', '', false)
      setFieldValue('noteJobTracking', '', false)
      setFieldValue('typeJobTracking', '', false)
      submitForm()
    }
  }
  const labelId: string = useId('dialogLabel')
  const subTextId: string = useId('subTextLabel')

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
    }),
    [labelId, subTextId],
  )
  const handleClickRemoveCardID: any = () => {
    const q = `mutation($input: DeleteCardInput!) {
      deleteCard(input: $input) {
        cardID
      }
    }`

    const v = {
      input: {
        boardID: boardID,
        cardID: values.cardID,
      },
    }

    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('result', result)
        props.history.push('/sale/crm')
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  // For Table Status
  const findProjectStatus = (projectName: string) => {
    return values.comments.filter((ele: any) => ele.commentType === 'changeStatus' && ele.description === projectName)
  }

  // For Table Meeting
  const findMeeting = () => {
    return values.comments.filter((ele: any) => ele.commentType === 'meeting')
  }

  // For Table job Tracking
  const findJobTracking = () => {
    return values.comments.filter((ele: any) => ele.commentType === 'jobTracking')
  }

  // For Table Document
  const findDocument = () => {
    return values.comments.filter((ele: any) => ele.commentType === 'refDocument')
  }

  const componantChildComment = () => {
    return (
      <>
        <h4 style={{ cursor: 'pointer', marginTop: '10px' }} onClick={() => setSort(sort ? false : true)}>
          {sort ? (
            <>
              เรียงจากใหม่ไปเก่า <img src={ArrowUpSVG} />
            </>
          ) : (
            <>
              เรียงจากเก่าไปใหม่ <img src={ArrowDownSVG} />
            </>
          )}
        </h4>
        <div className={styles.groupComment}>
          {sort
            ? values.comments
                .slice(0)
                .reverse()
                .map((data: any, index: number) => {
                  if (data.commentType === 'textImage' || data.commentType === 'changeStatus') {
                    return (
                      <div key={index} className={styles.comment}>
                        <span className={styles.dateName}>
                          วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                        </span>
                        <p>{data.description}</p>
                        <div className={`${styles.width100} ${styles.newRow}`}>
                          {data.attachments.length !== 0
                            ? data.attachments.map((uri: string, index: number) => (
                                <img className={styles.img} key={index} src={uri} width="70" height="70" />
                              ))
                            : ''}
                        </div>
                      </div>
                    )
                  } else if (data.commentType === 'meeting') {
                    return (
                      <div key={index} className={styles.comment}>
                        <span className={styles.dateName}>
                          วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                        </span>
                        <p>การนัดหมายวันที่: {FormatDateTime(data.datetime)}</p>
                        <p>สถานที่: {data.location}</p>
                        <p>Link Map: {data.googleMap}</p>
                        <p>Note: {data.note}</p>
                      </div>
                    )
                  } else if (data.commentType === 'jobTracking') {
                    return (
                      <div key={index} className={styles.comment}>
                        <span className={styles.dateName}>
                          วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                        </span>
                        <p>ตามงานวันที่: {FormatDateTime(data.datetime)}</p>
                        <p>ประเภทการตามงาน: {data.description}</p>
                        <p>ความคืบหน้า: {data.note}</p>
                      </div>
                    )
                  } else if (data.commentType === 'checkIn') {
                    return (
                      <div key={index} className={styles.comment}>
                        <span className={styles.dateName}>
                          วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                        </span>
                        <p>เช็คอินการนัดหมายครั้งที่ #{data.number + 1}</p>
                        <p>{data.datetime}</p>
                        <p>สถานที่: {data.location}</p>
                        <p>เช็คอินเมื่อ: {FormatDateTime(data.createdAt)}</p>
                        {<SimpleMap longitude={parseFloat(data.long)} latitude={parseFloat(data.lat)} />}
                      </div>
                    )
                  } else if (data.commentType === 'refDocument') {
                    return (
                      <div key={index} className={styles.comment}>
                        <span className={styles.dateName}>
                          วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                        </span>
                        <p>{data.description}</p>
                        <p className={styles.refDocument}>{data.note}</p>
                      </div>
                    )
                  }
                })
            : values.comments.map((data: any, index: number) => {
                if (data.commentType === 'textImage' || data.commentType === 'changeStatus') {
                  return (
                    <div key={index} className={styles.comment}>
                      <span className={styles.dateName}>
                        วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                      </span>
                      <p>{data.description}</p>
                      <div className={`${styles.width100} ${styles.newRow}`}>
                        {data.attachments.length !== 0
                          ? data.attachments.map((uri: string, index: number) => (
                              <img key={index} src={uri} width="70" height="70" />
                            ))
                          : ''}
                      </div>
                    </div>
                  )
                } else if (data.commentType === 'meeting') {
                  return (
                    <div key={index} className={styles.comment}>
                      <span className={styles.dateName}>
                        วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                      </span>
                      <p>การนัดหมายวันที่: {FormatDateTime(data.datetime)}</p>
                      <p>สถานที่: {data.location}</p>
                      <p>Link Map: {data.googleMap}</p>
                      <p>Note: {data.note}</p>
                    </div>
                  )
                } else if (data.commentType === 'jobTracking') {
                  return (
                    <div key={index} className={styles.comment}>
                      <span className={styles.dateName}>
                        วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                      </span>
                      <p>ตามงานวันที่: {FormatDateTime(data.datetime)}</p>
                      <p>ประเภทการตามงาน: {data.description}</p>
                      <p>ความคืบหน้า: {data.note}</p>
                    </div>
                  )
                } else if (data.commentType === 'checkIn') {
                  return (
                    <div key={index} className={styles.comment}>
                      <span className={styles.dateName}>
                        วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                      </span>
                      <p>เช็คอินการนัดหมายครั้งที่ #{data.number + 1}</p>
                      <p>{data.datetime}</p>
                      <p>สถานที่: {data.location}</p>
                      <p>เช็คอินเมื่อ: {FormatDateTime(data.createdAt)}</p>
                      {<SimpleMap longitude={parseFloat(data.long)} latitude={parseFloat(data.lat)} />}
                    </div>
                  )
                } else if (data.commentType === 'checkOut') {
                  return (
                    <div key={index} className={styles.comment}>
                      <span className={styles.dateName}>
                        วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                      </span>
                      <p>ยกเลิกการประชุมที่: {data.number + 1}</p>
                    </div>
                  )
                } else if (data.commentType === 'refDocument') {
                  return (
                    <div key={index} className={styles.comment}>
                      <span className={styles.dateName}>
                        วันที่ {FormatDateTime(data.createdAt)} - โดย {data.by}
                      </span>
                      <p>{data.description}</p>
                      <p className={styles.refDocument}>{data.note}</p>
                    </div>
                  )
                }
              })}
        </div>
      </>
    )
  }

  const componantInfoCard = () => {
    return (
      <>
        <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
          <div className={styles.width100}>
            <p>ชื่อผู้ติดต่อ</p>
            <Field
              name="contactName"
              className="inputForSearch"
              placeholder="ชื่อผู้ติดต่อ"
              type="text"
              // disabled={!values.editMode}
              style={{
                ...(touched?.contactName && errors?.contactName && { border: '1px solid red' }),
              }}
            />
          </div>
          <div className={styles.width100}>
            <p>เบอร์โทรศัพท์</p>
            <Field
              name="contactNumber"
              className="inputForSearch"
              type="text"
              placeholder="เบอร์โทรศัพท์"
              // disabled={!values.editMode}
              style={{
                ...(touched?.contactNumber && errors?.contactNumber && { border: '1px solid red' }),
              }}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>อีเมล</p>
            <Field
              name="contactEmail"
              className="inputForSearch"
              placeholder="อีเมล"
              type="text"
              // disabled={!values.editMode}
              style={{
                ...(touched?.contactEmail && errors?.contactEmail && { border: '1px solid red' }),
              }}
            />
          </div>
          <div className={styles.width100}>
            <p>บริษัท</p>
            <Field
              name="contactCompany"
              className="inputForSearch"
              placeholder="บริษัท"
              type="text"
              // disabled={!values.editMode}
              style={{
                ...(touched?.contactCompany && errors?.contactCompany && { border: '1px solid red' }),
              }}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>Google Map</p>
            <Field
              name="contactGoogleMap"
              className="inputForSearch"
              placeholder="Google Map"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
          <div className={styles.width100}>
            <p>ประเภทงาน</p>
            <Field
              name="contactWorkType"
              className="inputForSearch"
              placeholder="ประเภทงาน"
              as="select"
              style={{
                ...(touched?.contactWorkType && errors?.contactWorkType && { border: '1px solid red' }),
              }}
            >
              <option value=""></option>
              <option value="บ้านส่วนตัว">บ้านส่วนตัว</option>
              <option value="คอนโด">คอนโด</option>
              <option value="หมู่บ้าน">หมู่บ้าน</option>
              <option value="โรงแรม/รีสอร์ท">โรงแรม/รีสอร์ท</option>
              <option value="ห้างสรรพสินค้า">ห้างสรรพสินค้า</option>
              <option value="มหาวิทยาลัย">มหาวิทยาลัย</option>
              <option value="ราชการ(หน่วยงาน)">ราชการ(หน่วยงาน)</option>
              <option value="โรงพยาบาล">โรงพยาบาล</option>
              <option value="อื่น ๆ">อื่น ๆ</option>
            </Field>
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>ที่อยู่</p>
            <Field
              name="contactAddress"
              className={styles.styleTextarea}
              placeholder="ที่อยู่"
              as="textarea"
              rows={4}
              // disabled={!values.editMode}
            />
          </div>
        </div>

        <h3 style={{ margin: '20px 0 0' }}>ดีล</h3>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>มูลค่า</p>
            <Field
              name="dealPrice"
              className="inputForSearch"
              placeholder="มูลค่า"
              type="number"
              min="0"
              step="0.01"
              // disabled={!values.editMode}
              style={{
                ...(touched?.dealPrice && errors?.dealPrice && { border: '1px solid red' }),
              }}
            />
          </div>
          <div className={styles.width100}>
            <p>% โอกาสสำเร็จ</p>
            <Field
              name="dealPercentage"
              className="inputForSearch"
              placeholder="% โอกาสสำเร็จ"
              type="number"
              min="0"
              max="100"
              // disabled={!values.editMode}
              style={{
                ...(touched?.dealPercentage && errors?.dealPercentage && { border: '1px solid red' }),
              }}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>คาดการณ์ปิดดีล</p>
            <Field
              name="dealExpectedCloseDate"
              className="inputForSearch"
              placeholder="คาดการณ์ปิดดีล"
              type="date"
              style={{
                ...(touched?.dealExpectedCloseDate && errors?.dealExpectedCloseDate && { border: '1px solid red' }),
              }}
            />
          </div>
          <div className={styles.width100}>
            <p>STAGE</p>
            <Field
              name="status"
              className="inputForSearch"
              placeholder="STAGE"
              as="select"
              style={{
                ...(touched?.status && errors?.status && { border: '1px solid red' }),
              }}
              disabled={values.cardID ? false : true}
              onChange={(e: any) => handleChangeStatus(e.target.value)}
            >
              {listStatus.map((data, index) => (
                <option value={data.statusName} key={index}>
                  {data.statusDisplay}
                </option>
              ))}
            </Field>
          </div>
        </div>

        <h3 style={{ margin: '20px 0 0' }}>รายละเอียด</h3>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>ชื่อผู้รับเหมา</p>
            <Field
              name="descContractorName"
              className="inputForSearch"
              placeholder="ชื่อผู้รับเหมา"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
          <div className={styles.width100}>
            <p>เบอร์ติดต่อ</p>
            <Field
              name="descContractorNumber"
              className="inputForSearch"
              placeholder="เบอร์ติดต่อ"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>ชื่อผู้ออกแบบ</p>
            <Field
              name="descDesignerName"
              className="inputForSearch"
              placeholder="ชื่อผู้ออกแบบ"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
          <div className={styles.width100}>
            <p>เบอร์ติดต่อ</p>
            <Field
              name="descDesignerNumber"
              className="inputForSearch"
              placeholder="เบอร์ติดต่อ"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>ชื่อเจ้าของโปรเจค</p>
            <Field
              name="descPMName"
              className="inputForSearch"
              placeholder="ชื่อเจ้าของโปรเจค"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
          <div className={styles.width100}>
            <p>เบอร์ติดต่อ</p>
            <Field
              name="descPMNumber"
              className="inputForSearch"
              placeholder="เบอร์ติดต่อ"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>ชื่อเจ้าของโปรเจค</p>
            <Field
              name="descAVName"
              className="inputForSearch"
              placeholder="ชื่อที่ปรึกษาโครงการ"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
          <div className={styles.width100}>
            <p>เบอร์ติดต่อ</p>
            <Field
              name="descAVNumber"
              className="inputForSearch"
              placeholder="เบอร์ติดต่อ"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>รายละเอียด</p>
            <Field
              name="descDescription"
              className={styles.styleTextarea}
              placeholder="รายละเอียด"
              as="textarea"
              rows={4}
              // disabled={!values.editMode}
            />
          </div>
        </div>

        <h3 style={{ margin: '20px 0 0' }}>Billing</h3>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>ชื่อ นามสกุล</p>
            <Field
              name="billingName"
              className="inputForSearch"
              placeholder="ชื่อ นามสกุล"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
          <div className={styles.width100}>
            <p>เลขประจำตัวผู้เสียภาษีอากร (Tax ID)</p>
            <Field
              name="billingTaxID"
              className="inputForSearch"
              placeholder="เลขประจำตัวผู้เสียภาษีอากร (Tax ID)"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>ที่อยู่</p>
            <Field
              name="billingAddress"
              className={styles.styleTextarea}
              placeholder="ที่อยู่"
              as="textarea"
              rows={4}
              // disabled={!values.editMode}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>เบอร์ติดต่อ</p>
            <Field
              name="billingTelNumber"
              className="inputForSearch"
              placeholder="เบอร์ติดต่อ"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
          <div className={styles.width100}>
            <p>โทรสาร/Fax</p>
            <Field
              name="billingFax"
              className="inputForSearch"
              placeholder="โทรสาร/Fax"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>Email</p>
            <Field
              name="billingEmail"
              className="inputForSearch"
              placeholder="Email"
              type="text"
              // disabled={!values.editMode}
            />
          </div>
        </div>

        <h3 style={{ margin: '20px 0 0' }}>Stage</h3>
        <div className={`${styles.width100} ${styles.newRow}`} style={{ overflowX: 'scroll' }}>
          <table style={{ margin: '5px 0', minWidth: '288px' }}>
            <thead>
              <tr>
                <th className={`borderLeftRadius ${styles.textAlignCenter}`}>ครั้งที่</th>
                <th>Stage</th>
                <th className="borderRightRadius">เมื่อ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={`${styles.textAlignCenter}`}>1</td>
                <td className={`${styles.textAlignCenter}`}>
                  <label className={styles.statusNewCustomer}>โปรเจคใหม่</label>
                </td>
                <td>
                  {findProjectStatus('โปรเจคใหม่')[findProjectStatus('โปรเจคใหม่').length - 1]?.createdAt &&
                    FormatDateTime(
                      findProjectStatus('โปรเจคใหม่')[findProjectStatus('โปรเจคใหม่').length - 1]?.createdAt,
                    )}
                </td>
              </tr>
              <tr>
                <td className={`${styles.textAlignCenter}`}>2</td>
                <td className={`${styles.textAlignCenter}`}>
                  <label className={styles.statusPass}>ผ่าน</label>
                </td>
                <td>
                  {findProjectStatus('ผ่าน')[findProjectStatus('ผ่าน').length - 1]?.createdAt &&
                    FormatDateTime(findProjectStatus('ผ่าน')[findProjectStatus('ผ่าน').length - 1]?.createdAt)}
                </td>
              </tr>
              <tr>
                <td className={`${styles.textAlignCenter}`}>3</td>
                <td className={`${styles.textAlignCenter}`}>
                  <label className={styles.statusFarmimg}>ดำเนินการ</label>
                </td>
                <td>
                  {findProjectStatus('ดำเนินการ')[findProjectStatus('ดำเนินการ').length - 1]?.createdAt &&
                    FormatDateTime(
                      findProjectStatus('ดำเนินการ')[findProjectStatus('ดำเนินการ').length - 1]?.createdAt,
                    )}
                </td>
              </tr>
              <tr>
                <td className={`${styles.textAlignCenter}`}>4</td>
                <td className={`${styles.textAlignCenter}`}>
                  <label className={styles.statusQuotation}>เสนอราคา</label>
                </td>
                <td>
                  {findProjectStatus('เสนอราคา')[findProjectStatus('เสนอราคา').length - 1]?.createdAt &&
                    FormatDateTime(findProjectStatus('เสนอราคา')[findProjectStatus('เสนอราคา').length - 1]?.createdAt)}
                </td>
              </tr>
              <tr>
                <td className={`${styles.textAlignCenter}`}>5</td>
                <td className={`${styles.textAlignCenter}`}>
                  <label className={styles.statusClose}>ปิดได้</label>
                </td>
                <td>
                  {findProjectStatus('ปิดได้')[findProjectStatus('ปิดได้').length - 1]?.createdAt &&
                    FormatDateTime(findProjectStatus('ปิดได้')[findProjectStatus('ปิดได้').length - 1]?.createdAt)}
                </td>
              </tr>
              <tr>
                <td className={`${styles.textAlignCenter}`}>6</td>
                <td className={`${styles.textAlignCenter}`}>
                  <label className={styles.statusCannotClose}>ปิดไม่ได้</label>
                </td>
                <td>
                  {findProjectStatus('ปิดไม่ได้')[findProjectStatus('ปิดไม่ได้').length - 1]?.createdAt &&
                    FormatDateTime(
                      findProjectStatus('ปิดไม่ได้')[findProjectStatus('ปิดไม่ได้').length - 1]?.createdAt,
                    )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 style={{ margin: '20px 0 0' }}>ประวัติการประชุม</h3>
        <div className={`${styles.width100} ${styles.newRow}`} style={{ overflowX: 'scroll' }}>
          <table style={{ margin: '5px 0' }}>
            <thead>
              <tr>
                <th className={`borderLeftRadius ${styles.textAlignCenter}`}>ครั้งที่</th>
                <th>วันที่ เวลา</th>
                <th>สถานที่</th>
                <th>Note</th>
                <th>สถานะ</th>
                <th></th>
                <th className="borderRightRadius"></th>
              </tr>
            </thead>
            <tbody>
              {findMeeting().map((data: any, index: number) => {
                const findCheckIn = values.comments
                  .filter((ele: any) => ele.commentType === 'checkIn')
                  .find((element: any) => element.number === index)

                const findCheckOut = values.comments
                  .filter((ele: any) => ele.commentType === 'checkOut')
                  .find((element: any) => element.number === index)

                const tzoffset = new Date().getTimezoneOffset() * 60000
                const localISOTime = new Date(Date.now() - tzoffset).toISOString()
                let statusMeeting = 'นัดแล้ว'
                let styleCss = 'statusOrange'
                if (data.datetime < localISOTime) {
                  statusMeeting = 'ไม่เข้าประชุม'
                  styleCss = 'statusRed'
                }
                if (findCheckIn) {
                  statusMeeting = 'ผ่านไปแล้ว'
                  styleCss = 'statusBlack'
                }
                if (findCheckOut) {
                  statusMeeting = 'ยกเลิก'
                  styleCss = 'statusBlack'
                }
                return (
                  <tr key={index}>
                    <td className={`${styles.textAlignCenter}`}>{index + 1}</td>
                    <td>{FormatDateTime(data.datetime)}</td>
                    <td>{data.location}</td>
                    <td>{data.note}</td>
                    <td>
                      <label className={`${styles[styleCss]}`}>{statusMeeting}</label>
                    </td>
                    <td>
                      <a href={data.googleMap} className="styleBtnForTable">
                        นำทาง
                      </a>
                    </td>
                    {statusMeeting === 'นัดแล้ว' && (
                      <td>
                        <button
                          className="styleBtnForTable"
                          type="button"
                          onClick={() => handleClickCancleMeeting(index)}
                        >
                          ยกเลิก
                        </button>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <h3 style={{ margin: '20px 0 0' }}>ประวัติการตามงาน</h3>
        <div className={`${styles.width100} ${styles.newRow}`} style={{ overflowX: 'scroll' }}>
          <table style={{ margin: '5px 0' }}>
            <thead>
              <tr>
                <th className={`borderLeftRadius ${styles.textAlignCenter}`}>ครั้งที่</th>
                <th>วันที่ เวลา</th>
                <th>สถานะโครงการ</th>
                <th className="borderRightRadius">ความคืบหน้า</th>
              </tr>
            </thead>
            <tbody>
              {findJobTracking().map((data: any, index: number) => {
                return (
                  <tr key={index}>
                    <td className={`${styles.textAlignCenter}`}>{index + 1}</td>
                    <td>{FormatDateTime(data.datetime)}</td>
                    <td>{data.description}</td>
                    <td>{data.note}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className={`${styles.width100} ${styles.newRow}`}>
          <h3 style={{ margin: '20px 0 10px' }}>เอกสาร</h3>
        </div>
        <div className={`${styles.width100} ${styles.newRow} ${styles.documentLists}`}>
          {findDocument().map((doc: any, index: number) => (
            <div
              key={index}
              className={`${styles.documentList}`}
              onClick={() => props.history.push(`/sale/quotation/${doc.note.split('-')[0]}`)}
            >
              <p className={styles.typeDocument}>{doc.description}</p>
              <p className={styles.greenDocument}>{doc.note.split('-')[1]}</p>
              <p className={styles.timeStampUser}>
                วันที่ {FormatDateTime(doc.createdAt)} - โดย {doc.by}
              </p>
            </div>
          ))}
        </div>

        <div className={`${styles.width100} ${styles.newRow}`}>
          <h3 style={{ margin: '20px 0 0' }}>Sale</h3>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.width100}>
            <p>ชื่อเซล</p>
            <Field
              name="assignee"
              className="inputForSearch"
              placeholder="ชื่อเซล"
              type="text"
              disabled={true}
              style={{
                ...(touched?.assignee && errors?.assignee && { border: '1px solid red' }),
              }}
            />
          </div>
          <div className={styles.width100}>
            <p>เพิ่มข้อมูลเมื่อ</p>
            <Field
              name="createdAt"
              className="inputForSearch"
              placeholder="เพิ่มข้อมูลเมื่อ"
              type="datetime-local"
              disabled={true}
              style={{
                ...(touched?.createdAt && errors?.createdAt && { border: '1px solid red' }),
              }}
            />
          </div>
        </div>
        <div className={`${styles.width100} ${styles.newRow}`} style={{ marginBottom: '10px' }}>
          <div className={styles.width100}>
            <p>อัพเดทล่าสุดเมื่อ</p>
            <Field
              name="updatedAt"
              className="inputForSearch"
              placeholder="อัพเดทล่าสุดเมื่อ"
              type="datetime-local"
              disabled={true}
              style={{
                ...(touched?.updatedAt && errors?.updatedAt && { border: '1px solid red' }),
              }}
            />
          </div>
          <button type="submit" className="styleBtn" style={{ margin: 'auto 0 auto auto' }}>
            บันทึก
          </button>
        </div>
      </>
    )
  }

  const componantParentComment = () => {
    return (
      <div className={styles.logComment}>
        <h1 className={`${styles.headerTitleBody} ${styles.hiddenInMpbile}`}>การเคลื่อนไหว</h1>
        <Pivot
          className={styles.tabWrapper}
          aria-label="Pivot Tab Selection"
          selectedKey={String(selectedKey)}
          onLinkClick={handlePivotClick}
        >
          <PivotItem headerText="บันทึก" itemKey="0" />
          <PivotItem headerText="การนัดหมาย" itemKey="1" />
          <PivotItem headerText="เช็คอิน" itemKey="2" />
          <PivotItem headerText="ตามงาน" itemKey="3" />
        </Pivot>
        <div className={`${styles.width100} ${styles.newRow}`}>
          <div className={styles.mainWrapper}>
            {selectedKey == 0 && (
              <>
                <div className={`${styles.width100} ${styles.newRow}`}>
                  <Field
                    name="comment"
                    className={styles.styleTextarea}
                    placeholder="รายละเอียด"
                    as="textarea"
                    rows={3}
                  />
                  <div>
                    <input type="file" id="file" onChange={(e) => handleUploadImage(e)} accept="image/*" />
                    <label htmlFor="file" className={`${styles.buttonUploadFile} ${styles.width100}`}>
                      เพิ่มรูป
                    </label>
                    <button
                      className={`styleBtn ${styles.width100}`}
                      style={{ padding: '5px' }}
                      onClick={() => handleSubmitComment('textImage', values.comment)}
                      type="button"
                      disabled={values.cardID ? false : true}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
                <div className={`${styles.width100} ${styles.newRow}`}>
                  {values.imageUri.length !== 0
                    ? values.imageUri.map((data: any, index: number) => (
                        <img key={index} src={data} width="70" height="70" />
                      ))
                    : ''}
                </div>
                {componantChildComment()}
              </>
            )}
            {selectedKey == 1 && (
              <>
                <div className={`${styles.width100} ${styles.newRow}`}>
                  <Field
                    name="datetimeMeeting"
                    className="inputForSearch"
                    placeholder="วันที่ เวลา"
                    type="datetime-local"
                  />
                  <Field name="locationMeeting" className="inputForSearch" placeholder="สถานที่" type="text" />
                </div>
                <div className={`${styles.width100} ${styles.newRow}`}>
                  <Field name="linkMapMeeting" className="inputForSearch" placeholder="Link Google Map" type="text" />
                </div>
                <div className={`${styles.width100} ${styles.newRow}`}>
                  <Field
                    name="noteMeeting"
                    className={styles.styleTextarea}
                    placeholder="เรื่อง"
                    as="textarea"
                    rows={4}
                  />
                </div>
                <div className={`${styles.width100} ${styles.newRow}`}>
                  <button
                    className="styleBtn"
                    style={{ margin: '0 0 0 auto' }}
                    onClick={() => handleClickSubmitMeeting()}
                    disabled={values.cardID ? false : true}
                  >
                    การนัดหมาย
                  </button>
                </div>
                {componantChildComment()}
              </>
            )}
            {selectedKey == 2 && (
              <>
                <div className={`${styles.width100} ${styles.newRow}`}>
                  <div className={styles.map}>{returnMap()}</div>
                  <div className={styles.width100}>
                    <Field as="select" name="numberMeeting">
                      <option></option>
                      {values.comments
                        .filter((ele: any) => ele.commentType === 'meeting')
                        .map((data: any, index: any) => {
                          const findCheckIn = values.comments.filter((ele: any) => ele.commentType === 'checkIn')
                          const checked = findCheckIn.find((ele: any) => ele.number === index)
                          if (checked) return
                          return (
                            <option key={index} value={index}>
                              {index + 1}
                            </option>
                          )
                        })}
                    </Field>
                    <button
                      className="styleBtn"
                      onClick={() => handleCheckIn()}
                      type="button"
                      disabled={values.cardID ? false : true}
                    >
                      Checkin
                    </button>
                  </div>
                </div>

                {componantChildComment()}
              </>
            )}
            {selectedKey == 3 && (
              <>
                <div className={`${styles.width100} ${styles.newRow}`}>
                  <Field
                    name="datetimeJobTracking"
                    className="inputForSearch"
                    placeholder="วันที่ เวลา"
                    type="datetime-local"
                  />
                  <Field as="select" name="typeJobTracking" className="inputForSearch">
                    <option value="" disabled selected>
                      สถานะโครงการ
                    </option>
                    <option value="ออกแบบ">ออกแบบ</option>
                    <option value="ประมูล">ประมูล</option>
                    <option value="เริ่มก่อสร้าง">เริ่มก่อสร้าง</option>
                    <option value="ระหว่างก่อสร้าง">ระหว่างก่อสร้าง</option>
                  </Field>
                </div>
                <div className={`${styles.width100} ${styles.newRow}`}>
                  <Field
                    name="noteJobTracking"
                    className={styles.styleTextarea}
                    placeholder="ความคืบหน้า"
                    as="textarea"
                    rows={4}
                  />
                </div>
                <div className={`${styles.width100} ${styles.newRow}`}>
                  <button
                    className="styleBtn"
                    style={{ margin: '0 0 0 auto' }}
                    onClick={() => handleClickSubmitJobTracking()}
                    disabled={values.cardID ? false : true}
                  >
                    ติดตามงาน
                  </button>
                </div>
                {componantChildComment()}
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Form>
      <div className={`loader loader-default ${values.loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <div className={styles.hiddenInMpbile}>
          <h1 className={styles.headerTitle}>CRM</h1>
          <span className={styles.subHeaderTitle}>กรุณาเลือกรายการ</span>

          <div className={styles.newRow}>
            <button className={styles.editStyleBtn} disabled={values.cardID ? false : true} type="button">
              สร้างใบถอดแบบ
            </button>
            <button
              className={styles.editStyleBtn}
              type="button"
              disabled={values.cardID ? false : true}
              onClick={() =>
                props.history.push({
                  pathname: '/sale/quotation',
                  state: { propsTitle: values.title },
                })
              }
            >
              สร้างใบเสนอราคา
            </button>
          </div>

          <div className={styles.newRow}>
            <button
              className={styles.styleBtnGreen}
              onClick={() => handleChangeStatus('PROJECT_OK')}
              disabled={values.cardID ? false : true}
              type="button"
            >
              ปิดดีลสำเร็จ
            </button>
            <button
              className={styles.styleBtnRed}
              onClick={() => handleChangeStatus('PROJECT_FAIL')}
              disabled={values.cardID ? false : true}
              type="button"
            >
              ปิดดีลไม่สำเร็จ
            </button>
            <button
              className={styles.styleBtnRed}
              onClick={() => setHideDialogRemoveDoc(hideDialogRemoveDoc ? false : true)}
              disabled={values.cardID ? false : true}
              type="button"
            >
              ลบโปรเจค
            </button>
          </div>
        </div>

        <div style={{ marginTop: '10px' }} className={styles.hiddenInDesktop}>
          {[...listStatus]
            .slice(
              listStatus.length - listStatus.findIndex((ele) => ele.statusName === values.status) === 2
                ? listStatus.findIndex((ele) => ele.statusName === values.status) - 1
                : listStatus.length - listStatus.findIndex((ele) => ele.statusName === values.status) === 1
                ? listStatus.findIndex((ele) => ele.statusName === values.status) - 2
                : listStatus.findIndex((ele) => ele.statusName === values.status),
              listStatus.findIndex((ele) => ele.statusName === values.status) + 3,
            )
            .map((data, index) => {
              if (index === 0) {
                return (
                  <button
                    className={`${styles.styleBtnProgress} ${
                      values.status === data.statusName && styles[`${data.color}Tag`]
                    }`}
                    type="button"
                  >
                    <img
                      src={CheckSVG}
                      width="20"
                      style={values.status === data.statusName ? {} : { display: 'none' }}
                    />
                    {data.statusDisplay}
                  </button>
                )
              } else {
                return (
                  <button
                    type="button"
                    className={`${styles.styleBtnProgress} ${styles.styleBtnProgressShip} ${
                      values.status === data.statusName && styles[`${data.color}Tag`]
                    }`}
                  >
                    <img
                      src={CheckSVG}
                      width="20"
                      style={values.status === data.statusName ? {} : { display: 'none' }}
                    />
                    {data.statusDisplay}
                  </button>
                )
              }
            })}
        </div>

        <div style={{ marginTop: '10px' }} className={styles.hiddenInMpbile}>
          {listStatus.map((data, index) => {
            if (index === 0) {
              return (
                <button
                  className={`${styles.styleBtnProgress} ${
                    values.status === data.statusName && styles[`${data.color}Tag`]
                  }`}
                  type="button"
                >
                  <img src={CheckSVG} width="20" style={values.status === data.statusName ? {} : { display: 'none' }} />
                  {data.statusDisplay}
                </button>
              )
            } else {
              return (
                <button
                  type="button"
                  className={`${styles.styleBtnProgress} ${styles.styleBtnProgressShip} ${
                    values.status === data.statusName && styles[`${data.color}Tag`]
                  }`}
                >
                  <img src={CheckSVG} width="20" style={values.status === data.statusName ? {} : { display: 'none' }} />
                  {data.statusDisplay}
                </button>
              )
            }
          })}
        </div>

        <section className={styles.newRow} style={{ flexWrap: 'wrap', marginTop: '20px', gap: '50px' }}>
          <article className={styles.newCol50} style={{ height: 'fit-content', width: '-webkit-fill-available' }}>
            <div className={`${styles.width100} ${styles.newRow}`}>
              {!editTitle ? (
                <h1 className={styles.headerTitleBody}>{values.title}</h1>
              ) : (
                <Field
                  name="title"
                  className="inputForSearch"
                  type="text"
                  placeholder="ชื่องาน"
                  // disabled={!values.editMode}
                  style={{
                    maxWidth: '300px',
                    display: 'flex',
                    ...(touched?.title && errors?.title && { border: '1px solid red' }),
                  }}
                />
              )}
              <img
                src={PenSVG}
                style={{ marginLeft: '10px', cursor: 'pointer' }}
                onClick={() => setEditTitle(editTitle ? false : true)}
              />
            </div>

            <Pivot
              className={styles.tabWrapperMobile}
              aria-label="Pivot Tab Selection"
              selectedKey={String(selectedKeyMobile)}
              onLinkClick={handlePivotClickMobile}
              styles={IPivotStyles}
            >
              <PivotItem headerText="ข้อมูล" itemKey="0" />
              <PivotItem headerText="การเคลื่อนไหว" itemKey="1" />
            </Pivot>
            {selectedKeyMobile == 0 && (
              <div className={styles.hiddenInDesktop} style={{ width: '-webkit-fill-available' }}>
                {componantInfoCard()}
              </div>
            )}
            {selectedKeyMobile == 1 && (
              <div className={`${styles.hiddenInDesktop} ${styles.specialThisOne}`}>{componantParentComment()}</div>
            )}

            <div className={styles.hiddenInMpbile}>{componantInfoCard()}</div>
          </article>

          {values.cardID ? (
            <article className={`${styles.newCol50}`} style={{ height: 'fit-content' }}>
              <div className={styles.hiddenInMpbile} style={{ width: '100%' }}>
                {componantParentComment()}
              </div>
            </article>
          ) : (
            ''
          )}
        </section>
        {ModalAlert(
          values.isModalOpen,
          values.successSubmit,
          setFieldValue,
          props.match.params.id,
          props.history.push,
          true,
        )}
      </section>

      <Dialog
        hidden={hideDialogRemoveDoc}
        onDismiss={() => setHideDialogRemoveDoc(hideDialogRemoveDoc ? false : true)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setHideDialogRemoveDoc(hideDialogRemoveDoc ? false : true)
              handleClickRemoveCardID()
            }}
            text="ยืนยัน"
          />
          <DefaultButton onClick={() => setHideDialogRemoveDoc(hideDialogRemoveDoc ? false : true)} text="ยกเลิก" />
        </DialogFooter>
      </Dialog>
    </Form>
  )
}

const EnhancedInventoryMainPage = withFormik({
  mapPropsToValues: (props) => ({
    // Variable card
    title: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    contactCompany: '',
    contactGoogleMap: '',
    contactWorkType: '',
    contactAddress: '',
    dealPrice: '',
    dealPercentage: '',
    dealExpectedCloseDate: '',
    status: 'PROJECT_NEW',
    descContractorName: '',
    descContractorNumber: '',
    descDesignerName: '',
    descDesignerNumber: '',
    descPMName: '',
    descPMNumber: '',
    descAVName: '',
    descAVNumber: '',
    descDescription: '',
    billingName: '',
    billingTaxID: '',
    billingAddress: '',
    billingTelNumber: '',
    billingFax: '',
    billingEmail: '',
    assignee: '',

    // Not need validate
    cardID: '',
    comments: [
      {
        commentType: 'changeStatus',
        description: 'โปรเจคใหม่',
        attachments: [],
        createdAt: localISOTime,
      },
    ],
    successSubmit: '',
    isModalOpen: false,
    latitude: '',
    longitude: '',
    commentText: '',
    imageUri: [],
    createdAt: '',
    updatedAt: '',
    datetimeMeeting: '',
    locationMeeting: '',
    linkMapMeeting: '',
    noteMeeting: '',
    numberMeeting: '',
    datetimeJobTracking: '',
    noteJobTracking: '',
    typeJobTracking: '',
    comment: '',

    loading: true,
  }),
  validate: (values) => {
    return validateIgnoreField(
      values,
      [
        'billingName',
        'billingTaxID',
        'billingAddress',
        'billingTelNumber',
        'billingFax',
        'billingEmail',
        'descContractorName',
        'descContractorNumber',
        'descDesignerName',
        'descDesignerNumber',
        'descPMName',
        'descPMNumber',
        'descAVName',
        'descAVNumber',
        'descDescription',
        'contactGoogleMap',
        'contactAddress',
        'updatedAt',
        'createdAt',
        'latitude',
        'longitude',
        'comments',
        'cardID',
        'successSubmit',
        'isModalOpen',
        'commentText',
        'imageUri',
        'datetimeMeeting',
        'locationMeeting',
        'linkMapMeeting',
        'noteMeeting',
        'numberMeeting',
        'datetimeJobTracking',
        'noteJobTracking',
        'typeJobTracking',
        'comment',
      ],
      '*',
    )
  },
  handleSubmit: (values, { setFieldValue }) => {
    setFieldValue('loading', true, false)
    const additionalProperties = {
      additionalProperties: [
        { Key: 'contactName', Value: values.contactName },
        { Key: 'contactNumber', Value: values.contactNumber },
        { Key: 'contactEmail', Value: values.contactEmail },
        { Key: 'contactCompany', Value: values.contactCompany },
        { Key: 'contactGoogleMap', Value: values.contactGoogleMap },
        { Key: 'contactWorkType', Value: values.contactWorkType },
        { Key: 'contactAddress', Value: values.contactAddress },
        { Key: 'dealPrice', Value: values.dealPrice.toString() },
        { Key: 'dealPercentage', Value: values.dealPercentage.toString() },
        { Key: 'dealExpectedCloseDate', Value: values.dealExpectedCloseDate },
        { Key: 'descContractorName', Value: values.descContractorName },
        { Key: 'descContractorNumber', Value: values.descContractorNumber },
        { Key: 'descDesignerName', Value: values.descDesignerName },
        { Key: 'descDesignerNumber', Value: values.descDesignerNumber },
        { Key: 'descPMName', Value: values.descPMName },
        { Key: 'descPMNumber', Value: values.descPMNumber },
        { Key: 'descAVName', Value: values.descAVName },
        { Key: 'descAVNumber', Value: values.descAVNumber },
        { Key: 'descDescription', Value: values.descDescription },
        { Key: 'billingName', Value: values.billingName },
        { Key: 'billingTaxID', Value: values.billingTaxID },
        { Key: 'billingAddress', Value: values.billingAddress },
        { Key: 'billingTelNumber', Value: values.billingTelNumber },
        { Key: 'billingFax', Value: values.billingFax },
        { Key: 'billingEmail', Value: values.billingEmail },
        { Key: 'isDealSuccess', Value: values.isDealSuccess },
      ],
    }

    const vAddCard = {
      input: {
        boardID: boardID,
        title: values.title,
        status: values.status,
        assignee: [`${values.assignee}`],
        additionalProperties: additionalProperties.additionalProperties,
        comments: values.comments,
      },
    }

    const vUpdateCard = {
      input: {
        boardID: boardID,
        cardID: values.cardID,
        title: values.title,
        status: values.status,
        assignee: [`${values.assignee}`],
        additionalProperties: additionalProperties.additionalProperties,
        comments: values.comments,
      },
    }

    axios({
      url: urlApiSale,
      method: 'post',
      data: {
        query: !values.cardID ? qAddCard : qUpdateCard,
        variables: !values.cardID ? vAddCard : vUpdateCard,
      },
    })
      .then((result) => {
        setFieldValue('isModalOpen', true, false)
        if (!values.cardID) {
          setFieldValue('cardID', result.data.data.createCard.cardID, true)
          setFieldValue('successSubmit', 'เพิ่มสำเร็จ', false)
          setFieldValue('loading', false, false)
        }
        if (values.cardID) {
          setFieldValue('cardID', result.data.data.updateCard.cardID, true)
          setFieldValue('successSubmit', 'แก้ไขสำเร็จ', false)
          setFieldValue('loading', false, false)
        }
      })
      .catch((err) => {
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('loading', false, false)
        console.log(err.response)
      })
  },
})(InventoryMainPage)

export default EnhancedInventoryMainPage

const qCard = `query($boardID: ID!, $cardID: ID){
  card(boardID: $boardID, cardID: $cardID) {
    cardID
    title
    status
    assignee
    createdAt
    updatedAt
    comments {
			commentType
      description
      attachments
      createdAt
      datetime
      location
      googleMap
      number
      lat
      long
      note
      by
    }
    additionalProperties {
			Key
    	Value
    }
  }
}`

const qAddCard = `mutation($input: CreateCardInput!) {
  createCard(input: $input) {
    cardID
  }
}`

const qUpdateCard = `mutation($input: UpdateCardInput!) {
  updateCard(input: $input) {
    cardID
  }
}`

const AnyReactComponent = (props: any) => {
  const { name } = props
  return (
    <div>
      <div
        className={`${styles.pin} ${styles.bounce}`}
        style={{ backgroundColor: 'red', cursor: 'pointer' }}
        title={name}
      />
      <div className={`${styles.pulse}`} />
    </div>
  )
}

const SimpleMap = (props: any) => {
  const [point, setPoint] = useState({
    lat: 0,
    lng: 0,
  })
  const [zoom] = useState(11)
  useEffect(() => {
    setPoint({
      lat: props.latitude,
      lng: props.longitude,
    })
  }, [props.latitude, props.longitude])

  return (
    <div style={{ height: '200px', width: '200px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCGrCo4Od9pA3Z_W8JUbbgF28a1j6yVV4Q' }}
        defaultCenter={point}
        defaultZoom={zoom}
      >
        <AnyReactComponent lat={point.lat} lng={point.lng} name="My Marker" />
      </GoogleMapReact>
    </div>
  )
}

const dialogStyles = { main: { maxWidth: 450 } }

const dialogContentProps = {
  type: DialogType.normal,
  title: 'กรุณายืนยันคำสั่ง',
  closeButtonAriaLabel: 'Close',
  subText: 'คุณยังคงต้องการทำรายการดังต่อไปนี้ต่อไปหรือไม่?',
}

const IPivotStyles = {
  root: {},
  linkIsSelected: {
    backgroundColor: '#F1F1F1',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
}
