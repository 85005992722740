import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import { debounce } from 'lodash'
import { urlApi } from '../common/configUrlApi'
import { FormatDateTime } from '../../common/dateFormat'

import useTokenInitializer from '../../hooks/token-initializer'
import EnhancedTable from '../../common/table/doc/firstAndLastColBtnPaginationBack'
import { HeadCell } from '../../common/table/config'

const InventoryReceiveListPOPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const [dataListPO, setDataListPO] = useState<any>([])
  const [currentQueryString, setCurrentQueryString] = useState('')
  const [currentQueryDate, setCurrentQueryDate] = useState(null)

  // Pgaination
  const [page, setPage] = React.useState(0)
  const [pageList, setPageList] = React.useState<any>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = React.useState(0)

  DocumentTitle('Inventory', 'นำสินค้าเข้า')
  useTokenInitializer()

  useEffect(() => {
    // For not dowload page if dowloaded
    const findPage = pageList.find((ele: any) => `${ele}` === `${page}`)
    if (findPage || findPage === 0) return

    // Where to put the query
    const q = `query listPurchaseOrderPage($pageIndex: Int, $maxResults: Int){
      listPurchaseOrder(pageIndex: $pageIndex, maxResults: $maxResults) {
        totalCount
        pageIndex
        maxResults
        results {
          id
          internalDocumentId
          documentTypeId
          documentStatusId
          referenceNumber
          remark
          createdAt
          updatedAt
          lineItem {
            id
            skuId
            qty
            uomId
            packId
            lineNumber
          }
        }
      }
    }`
    // Where to put the variables
    const v = { pageIndex: page, maxResults: rowsPerPage }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const listPurchaseOrder = result.data.data.listPurchaseOrder.results
      const listPO: any[] = []
      listPurchaseOrder.map((data: any) => {
        const mapper = {
          id: data.id,
          poCode: data.internalDocumentId,
          poDate: FormatDateTime(data.updatedAt),
          key: data.lineItem,
        }
        return listPO.push(mapper)
      })
      setLoading(false)
      setDataListPO([...dataListPO, ...listPO])
      setTotalCount(result.data.data.listPurchaseOrder.totalCount)

      setPageList([...pageList, page]) // For not reload new page if dowloaded
    })
  }, [page, rowsPerPage, currentQueryDate, currentQueryString])

  const createDocReciptRefPO = (value: number) => {
    // Where to put the query
    const q = `mutation createOrEditGoodReceive($input: CreateGoodReceive!) {
      createGoodReceive: createOrEditGoodReceive(input: $input) {
        id
        purchaseOrderDocumentId
        lotNumber
        packLineItem {
          id
          skuId
          qty
          uomId
          runningNumber
          scanBy
          scanAt
          serialNumber
        }
      }
    }`
    // Where to put the variables
    const matchDoc = dataListPO.find((doc: any) => `${doc.id}` === `${value}`)

    const lineItem: any[] = []
    matchDoc.key.map((data: any) => {
      const mapperLineItem = {
        lineNumber: data.lineNumber,
        skuId: data.skuId,
        qty: data.qty,
        uomId: data.uomId,
        packId: data.packId,
      }
      lineItem.push(mapperLineItem)
    })

    const v = {
      input: {
        purchaseOrderDocumentId: value,
        lineItem: lineItem,
      },
    }
    console.log('v', v)
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('post doc', result.data.data)
        props.history.push(`/inventory/receive/ref/po/${result.data.data.createGoodReceive.id}`)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }

  const request = debounce((value, setState) => {
    setPage(0)
    setPageList([])
    setDataListPO([])
    setState(value)
  }, 1000)

  const debouceRequest = useCallback((value, setState) => request(value, setState), [])

  const onChangeString = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryString)
  }

  const onChangeDate = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryDate)
  }

  const rowBody: any = dataListPO
  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'docID', numeric: false, disablePadding: false, label: 'เลขที่เอกสารจัดซื้อ' },
    { id: 'date', numeric: false, disablePadding: false, label: 'วันที่' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------
  return (
    <>
      <section className={styles.headerWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>นำสินค้าเข้า</h1>
        <p>กรุณาเลือกรายการ</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสเอกสาร"
          onChange={onChangeString}
          style={{ maxWidth: '385.6px', display: 'flex' }}
        />
        <input type="date" className="inputForSearch" onChange={onChangeDate} style={{ maxWidth: '385.6px' }} />
      </section>
      <section className="tableListMui">
        <EnhancedTable
          colHead={headCells}
          rows={rowBody}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
          fuctionForFirstButton={createDocReciptRefPO}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/receive">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventoryReceiveListPOPage
