import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
import axios from 'axios'
import { urlApi } from '../../inventory/common/configUrlApi'

interface IAtableFormatSaleModule {
  colHead: string[]
  rowBodyData: any[]
  typeHead: number
  typeBody: number
  Field: any
  setFieldValue: any
  values: any
  name?: string
  emptyRow?: any
  // viewMode: boolean
}

export const TABLE_HEAD_TYPES = {
  NONE: 0,
  FIRST_COLUMN_IS_BUTTON: 1,
}

export const TABLE_BODY_TYPES = {
  NONE: 0,
  BODY_FOR_LIST_ITEM: 1,
  BODY_FOR_POSITION_AREA: 2,
}

const TableCommonCompanant: React.FC<IAtableFormatSaleModule> = ({
  colHead,
  rowBodyData,
  typeBody,
  Field,
  setFieldValue,
  values,
  name,
  emptyRow,
  // viewMode,
}) => {
  const [listSuggestSku, setListSuggestSku] = useState([])

  const addRows = (): void => {
    emptyRow.id = rowBodyData.length + 1
    emptyRow.seq = rowBodyData.length + 1
    setFieldValue(name, [...rowBodyData, emptyRow], false)
  }

  const deleteRows = (e: any): void => {
    if (e.target.id === '0') {
      Object.keys(rowBodyData[0]).forEach((key: string) => {
        rowBodyData[0][`${key}`] = ''
      })
      setFieldValue([...rowBodyData], false)
      return
    }
    rowBodyData.splice(e.target.id, 1)
    console.log(rowBodyData)
    setFieldValue(name, [...rowBodyData], false)
  }

  return (
    <table style={{ marginLeft: '0' }}>
      <thead>
        <tr>
          {colHead.map((data, index) => {
            let borderTable = ''
            if (index === 0) {
              borderTable = 'borderLeftRadius'
            } else if (colHead.length === index + 1) {
              borderTable = 'borderRightRadius'
            }
            return (
              <th className={borderTable} key={index}>
                {data}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {rowBodyData.map((data, rowIndex) => {
          return (
            <tr key={rowIndex}>
              {Object.keys(data).map(function (key, index) {
                if (
                  index === 0 &&
                  (typeBody === TABLE_BODY_TYPES.BODY_FOR_LIST_ITEM ||
                    typeBody === TABLE_BODY_TYPES.BODY_FOR_POSITION_AREA)
                ) {
                  return (
                    <td key={index}>
                      {rowBodyData.length - 1 === rowIndex ? (
                        <button
                          className="styleBtnForTable"
                          type="button"
                          onClick={addRows}
                          style={{ marginRight: '5px' }}
                          // disabled={!viewMode ? false : true}
                        >
                          เพิ่ม
                        </button>
                      ) : (
                        ''
                      )}
                      <button
                        id={`${rowIndex}`}
                        className="styleBtnForTable"
                        type="button"
                        onClick={deleteRows}
                        // disabled={!viewMode ? false : true}
                      >
                        ลบ
                      </button>
                    </td>
                  )
                } else if (
                  index === 1 &&
                  (typeBody === TABLE_BODY_TYPES.BODY_FOR_LIST_ITEM ||
                    typeBody === TABLE_BODY_TYPES.BODY_FOR_POSITION_AREA)
                ) {
                  return (
                    <td key={index}>
                      <h4>{rowIndex + 1}</h4>
                    </td>
                  )
                } else if (index === 2 && typeBody === TABLE_BODY_TYPES.BODY_FOR_LIST_ITEM) {
                  return (
                    <td key={index}>
                      <Field
                        type="text"
                        name={`${name}[${rowIndex}].productCode`}
                        list="browsers"
                        className="inputOriginal"
                        // onChange={(e: any) => handleChangeProductCode(`listItems[${rowIndex}]`, e.target.value)}
                        // disabled={!viewMode ? false : true}
                      />
                      <datalist id="browsers">
                        {listSuggestSku.map((sku: any, indexSku: number) => {
                          return (
                            <option key={indexSku} value={`${sku.productCode}-${sku.name}`}>
                              {sku.profile}/{sku.type.name}/{sku.color.name}/{sku.spec}/{sku.surface.name}
                            </option>
                          )
                        })}
                      </datalist>
                    </td>
                  )
                } else if (index === 9 && typeBody === TABLE_BODY_TYPES.BODY_FOR_LIST_ITEM) {
                  return (
                    <td key={index}>
                      <Field
                        id={`${rowIndex}`}
                        name={`${name}[${rowIndex}].${key}`}
                        as="select"
                        className="inputOriginal"
                        style={{ height: 'fit-content' }}
                        // disabled={!viewMode ? (values.listItems[rowIndex].surface !== '' ? false : true) : true}
                      >
                        <option value=""></option>
                        <option value="ภายนอก">ภายนอก</option>
                        <option value="ภายใน">ภายใน</option>
                        <option value="ทั้งคู่">ทั้งคู่</option>
                      </Field>
                    </td>
                  )
                } else if (index === 10 && typeBody === TABLE_BODY_TYPES.BODY_FOR_LIST_ITEM) {
                  return (
                    <td key={index}>
                      <Field
                        id={`${rowIndex}`}
                        name={`${name}[${rowIndex}].${key}`}
                        as="select"
                        className="inputOriginal"
                        style={{ height: 'fit-content' }}
                        // disabled={!viewMode ? (values.listItems[rowIndex].surface !== '' ? false : true) : true}
                      >
                        <option value=""></option>
                        <option value="พื้น">พื้น</option>
                        <option value="ผนัง">ผนัง</option>
                        <option value="เสา">เสา</option>
                        <option value="อื่นๆ">อื่นๆ</option>
                      </Field>
                    </td>
                  )
                } else if (index === 11 && typeBody === TABLE_BODY_TYPES.BODY_FOR_LIST_ITEM) {
                  return (
                    <td key={index}>
                      <Field
                        id={`${rowIndex}`}
                        name={`${name}[${rowIndex}].${key}`}
                        type="text"
                        className="inputOriginal"
                        // disabled={!viewMode ? (values.listItems[rowIndex].surface !== '' ? false : true) : true}
                      />
                    </td>
                  )
                } else if (
                  (index === 12 || index === 13 || index === 14) &&
                  typeBody === TABLE_BODY_TYPES.BODY_FOR_LIST_ITEM
                ) {
                  return (
                    <td key={index}>
                      <Field
                        id={`${rowIndex}`}
                        name={`${name}[${rowIndex}].${key}`}
                        type="number"
                        className="inputOriginal"
                        min={0}
                        // disabled={!viewMode ? (values.listItems[rowIndex].surface !== '' ? false : true) : true}
                      />
                    </td>
                  )
                } else if (index === 2 && typeBody === TABLE_BODY_TYPES.BODY_FOR_POSITION_AREA) {
                  return (
                    <td key={index}>
                      <Field
                        type="text"
                        name={`${name}[${rowIndex}].namePosition`}
                        className="inputOriginal"
                        placeholder="ชื่อ"
                        // onChange={(e: any) => handleChangeProductCode(`listItems[${rowIndex}]`, e.target.value)}
                        // disabled={!viewMode ? false : true}
                      />
                    </td>
                  )
                } else if (index === 3 && typeBody === TABLE_BODY_TYPES.BODY_FOR_POSITION_AREA) {
                  return (
                    <td key={index}>
                      <Field
                        as="select"
                        name={`${name}[${rowIndex}].distanceType`}
                        className="inputOriginal"
                        placeholder="ประเภท"
                        style={{ height: 'auto' }}
                        // onChange={(e: any) => handleChangeProductCode(`listItems[${rowIndex}]`, e.target.value)}
                        // disabled={!viewMode ? false : true}
                      >
                        <option value=""></option>
                        <option value="C/C">C/C</option>
                        <option value="ด้าน/ด้าน">ด้าน/ด้าน</option>
                      </Field>
                    </td>
                  )
                } else if (index === 4 && typeBody === TABLE_BODY_TYPES.BODY_FOR_POSITION_AREA) {
                  return (
                    <td key={index}>
                      <Field
                        type="text"
                        name={`${name}[${rowIndex}].distance`}
                        className="inputOriginal"
                        placeholder="ระยะ"
                        // onChange={(e: any) => handleChangeProductCode(`listItems[${rowIndex}]`, e.target.value)}
                        // disabled={!viewMode ? false : true}
                      />
                    </td>
                  )
                } else if (index === 5 && typeBody === TABLE_BODY_TYPES.BODY_FOR_POSITION_AREA) {
                  return (
                    <td key={index}>
                      <Field
                        as="select"
                        name={`${name}[${rowIndex}].axisType`}
                        className="inputOriginal"
                        placeholder="แนว"
                        style={{ height: 'auto' }}
                        // onChange={(e: any) => handleChangeProductCode(`listItems[${rowIndex}]`, e.target.value)}
                        // disabled={!viewMode ? false : true}
                      >
                        <option value=""></option>
                        <option value="แนวตั้ง">แนวตั้ง</option>
                        <option value="แนวนอน">แนวนอน</option>
                        <option value="อื่นๆ">อื่นๆ</option>
                      </Field>
                    </td>
                  )
                } else if (index === 6 && typeBody === TABLE_BODY_TYPES.BODY_FOR_POSITION_AREA) {
                  return (
                    <td key={index}>
                      <Field
                        type="text"
                        name={`${name}[${rowIndex}].remark`}
                        className="inputOriginal"
                        placeholder="อื่นๆ"
                        // onChange={(e: any) => handleChangeProductCode(`listItems[${rowIndex}]`, e.target.value)}
                        // disabled={!viewMode ? false : true}
                      />
                    </td>
                  )
                } else {
                  return (
                    <td key={index}>
                      <h4>{data[key]}</h4>
                    </td>
                  )
                }
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
TableCommonCompanant.propTypes = {
  colHead: PropTypes.any,
  rowBodyData: PropTypes.any,
  typeHead: PropTypes.number.isRequired,
  typeBody: PropTypes.number.isRequired,
  Field: PropTypes.any,
  setFieldValue: PropTypes.any,
  values: PropTypes.any,
  name: PropTypes.any,
  emptyRow: PropTypes.any,
  // viewMode: PropTypes.any,
}

export default TableCommonCompanant
