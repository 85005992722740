// Action Creators
// Reducing Boilerplate with makeActionCreator https://redux.js.org/recipes/reducing-boilerplate#generating-action-creators
export function makeActionCreator(type: any, ...argNames: any) {
  return function (...args: any) {
    const action: any = { type }
    argNames.forEach((arg: any, index: any) => {
      action[argNames[index]] = args[index]
    })
    return action
  }
}
