import React, { useEffect, useState } from 'react'
import { useBoolean } from '@uifabric/react-hooks'
import styles from './index.module.scss'
import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../common/table'
import SummaryTopTableComponant from '../common/summaryTopTable'
import axios from 'axios'
import DocumentTitle from '../common/document-title'
import { urlApi } from '../common/configUrlApi'
import ModalAlert from '../common/modalNotFormik'
import GroupBtnDocument from '../common/groupBtnDocuemnt'

import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'
import { FormatDateTime } from '../../common/dateFormat'

import EnhancedTable from '../../common/table/item/paginationByFront/firstColBtnandInput'
import { HeadCell } from '../../common/table/config'

export interface ILineItems {
  lineNumber: number
  itemID: string
  pack: string
  quantity: number
  qtyExpected: number
  status: string
  by: string
  timeStamp: string
  reasons: any
  description: string
}

const InventorySummaryStockPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)

  const [checkBooleanFormStatus, setCheckBooleanFormStatus] = useState(false)

  const [documentID, setDocumentID] = useState('')
  const [internalDocumentID, setInternalDocumentID] = useState('')
  const [documentStatusId, setDocumentStatusId] = useState('')
  const [lineItems, setLineItems] = useState<(string | ILineItems)[]>([])

  const [summaryTopTable, setSummaryTopTable] = useState<any>([])

  const [correctSN, setCorrectSN] = useState<string>('')

  const [actionIput, setActionIput] = useState(false)
  const [fetchData, setFetchData] = useState<number>(0)
  const [fetchData2, setFetchData2] = useState<number>(0)

  const [factReason, setFactReason] = useState([])

  const [successSubmit, setSuccessSubmit] = useState('')
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  DocumentTitle('Inventory', 'เช็ค Stock')

  const [fullname, setFullname] = useState('')
  const token = useSelector((state: any) => state.token)

  useTokenInitializer()

  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
  }, [token.name])

  // For Query Doc Stock
  useEffect(() => {
    const q = `query ($pi: Int, $mr: Int, $pcDocId: ID!) {
      document: viewPhysicalCount(id: $pcDocId) {
        id
        internalDocumentId
        createdAt
        updatedAt
        documentTypeId
        documentStatusId
        remark
        lastScanAt
        lineItem(pageIndex: $pi, maxResults: $mr, showSummary: true) {
          totalCount
          pageIndex
          maxResults
          results {
            id
            skuId
            lotNumber
            runningNumber
            qty
            qtyExpected
            updatedAt
            createdAt
            uomId
            packId
            scanBy
            scanAt
            goodReceiveDate
            serialNumber
            remark
            reasonId
            sku {
              name
              productCode
            }
          }
        }
      }
    }
    `
    // Where to put the variables
    const v = {
      pi: 0,
      mr: 9999,
      pcDocId: props.match.params.id,
    }

    axios
      .post(urlApi, {
        query: q,
        variables: v,
      })
      .then((result) => {
        const document = result.data.data.document
        // console.log('document', document)
        const listMapper: any[] = []
        document.lineItem.results.map((data: any) => {
          console.log('data', data)
          const mapper = {
            itemID: `${data.sku.productCode}`,
            gooodReceiveDate: FormatDateTime(data.goodReceiveDate), //วันที่นำเข้า
            status: data.scanBy ? 'ไม่ครบ' : 'หาย',
            quantity: data.qty ? data.qty : 0,
            qtyExpected: data.qtyExpected, //คงคลัง
            serialNumber: data.serialNumber,
            by: data.scanBy,
            timeStamp: data.scanAt ? FormatDateTime(data.scanAt) : '',
            reasons: {
              reasonId: data.reasonId,
              remark: data.remark,
            },
            description: data.sku.name,
          }
          listMapper.push(mapper)
        })
        setLoading(false)
        setDocumentStatusId(document.documentStatusId)
        setLineItems(listMapper)
        setInternalDocumentID(document.internalDocumentId)
        setDocumentID(document.id)
        setFetchData2(fetchData2 + 1)

        // SET FEILD IF documentStatus === COMPLETE Return True Because Need to disable input
        setCheckBooleanFormStatus(document.documentStatusId === 'COMPLETE' ? true : false)
      })
      .catch((err) => {
        console.log('err', err)
        console.log('err.response', err.response)
      })
  }, [fetchData])

  useEffect(() => {
    // Pretend this is axios
    const clone_lineItems: ILineItems[] = []
    lineItems.map((data) => {
      if (typeof data !== 'string') {
        return clone_lineItems.push(data)
      }
    })

    const ameliaWatson = clone_lineItems.reduce<{ [itemId: string]: ILineItems[] }>(
      (previousValue, currentValue: any) => {
        const packNumberList: any[] = previousValue[currentValue.itemID] || []
        packNumberList.push(currentValue)
        previousValue[currentValue.itemID] = packNumberList
        return previousValue
      },
      {},
    )

    const packSKU: { packList: number; totalPiece: number; totalPieceAndPack: number; totalPackReceive: number }[] = []

    const plastic = Object.entries(ameliaWatson).reduce<(ILineItems | string)[]>(
      (lineItemListReducer, [itemId, packList]) => {
        const totalPiece = packList.reduce<number>((pv, cv) => {
          return pv + cv.quantity
        }, 0)
        const totalPieceAndPack = packList.reduce<number>((pv, cv) => {
          return pv + cv.qtyExpected
        }, 0)
        const totalPackReceive = packList.filter((ele) => {
          if (ele.quantity > 0) {
            return ele
          } else return
        }).length

        const warcraft3 = `รหัส ${itemId} - ${packList[0].description} - ${Math.ceil(
          totalPiece / (totalPieceAndPack / packList.length),
        )}/${packList.length} ห่อ = ${totalPiece}/${totalPieceAndPack} แผ่น`

        lineItemListReducer.push(warcraft3)
        lineItemListReducer.push(...packList)

        packSKU.push({
          packList: packList.length,
          totalPiece: totalPiece,
          totalPieceAndPack: totalPieceAndPack,
          totalPackReceive: totalPackReceive,
        })

        return lineItemListReducer
      },
      [],
    )

    setSummaryTopTable(packSKU)
    setLineItems(plastic)
    setActionIput(true)
  }, [actionIput, documentID, fetchData2])

  // For Query FACT REASON
  useEffect(() => {
    const q = `query {
        listPhysicalCountAdjustReason {
          id
          name
          description
        }
      }
      `
    // Where to put the variables
    const v = {}

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((res) => {
        const listPhysicalCountAdjustReason = res.data.data.listPhysicalCountAdjustReason
        setFactReason(listPhysicalCountAdjustReason)
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [fetchData])

  const onChangeReasonIdLineItems = (index: any, value: string) => {
    setActionIput(true)
    const clonesLineItems = [...lineItems]

    const lineItem = clonesLineItems[index]
    if (typeof lineItem === 'string') {
      return
    }
    lineItem.reasons.reasonId = value
    clonesLineItems.splice(index, 1, lineItem)
    setLineItems(clonesLineItems)
    setActionIput(false)
  }

  const onChangeReamekLineItems = (index: any, value: string) => {
    setActionIput(true)
    const clonesLineItems = [...lineItems]

    const lineItem = clonesLineItems[index]
    if (typeof lineItem === 'string') {
      return
    }
    lineItem.reasons.remark = value
    clonesLineItems.splice(index, 1, lineItem)
    setLineItems(clonesLineItems)
    setActionIput(false)
  }

  const onClickSaveDoc: any = () => {
    const prepairSna: any = []
    lineItems.map((data: any) => {
      console.log('data', data)
      if (typeof data === 'string') {
        return
      } else if (data.reasons.reasonId) {
        prepairSna.push({
          serialNumber: data.serialNumber,
          remark: data.reasons.remark,
          reasonId: parseInt(data.reasons.reasonId),
        })
      }
    })
    const q = `mutation physicalCountSerialNumberReason($pcDocId: ID!, $uid: String!, $snList: [PhysicalCountSerialNumberReasonLineItem!]!) {
      physicalCountSerialNumberReason(physicalCountDocumentId: $pcDocId, userUid: $uid, serialNumberReasonList: $snList) {
        id
      }
    }`
    // Where to put the variables
    const v = {
      pcDocId: documentID,
      uid: fullname,
      snList: prepairSna,
    }
    console.log('v', v)
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        console.log('result', result)
        setFetchData(fetchData + 1)
        showModal()
        setSuccessSubmit('สำเร็จ')
        return true
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
        return false
      })
  }

  const onClickSendDoc = () => {
    onClickSaveDoc()
    const q = `mutation {
      submitPhysicalCount(physicalCountDocumentId: ${documentID}) {
        id
      }
    }`
    // Where to put the variables
    const v = {}

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        showModal()
        setSuccessSubmit('สำเร็จ')
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
      })
  }

  const onClickRemoveDoc = () => {
    const q = `mutation physicalCountRemove($id: ID!) {
      physicalCountRemove(id: $id) {
        id
      }
    }`
    // Where to put the variables
    const v = {
      id: documentID,
    }
    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        // console.log('result', result)
        showModal()
        setSuccessSubmit('สำเร็จ')
        props.history.goBack()
      })
      .catch((err) => {
        console.log('err', err.response)
        showModal()
        setSuccessSubmit('เกิดปัญหา')
      })
  }

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'itemID', numeric: false, disablePadding: false, label: 'รหัสสินค้า' },
    { id: 'dateReceive', numeric: false, disablePadding: false, label: 'วันที่นำเข้า' },
    { id: 'scan', numeric: false, disablePadding: false, label: 'สแกน' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'จำนวนแผ่น' },
    { id: 'stock', numeric: false, disablePadding: false, label: 'คงคลัง' },
    { id: 'sn', numeric: false, disablePadding: false, label: 'S/N' },
    { id: 'by', numeric: false, disablePadding: false, label: 'โดย' },
    { id: 'date', numeric: false, disablePadding: false, label: 'เมื่อ' },
    { id: 'Action', numeric: false, disablePadding: false, label: 'Action' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  return (
    <>
      <div className={styles.pageWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>เช็คสินค้า</h1>
        <p>
          ใบเช็คสินค้า {internalDocumentID} - {documentStatusId}
        </p>
        <p className="errMsg">{correctSN ? correctSN : ''}</p>
      </div>
      <SummaryTopTableComponant summaryTopTable={summaryTopTable} />
      <section className={`tableListMui`}>
        <EnhancedTable
          colHead={headCells}
          rows={lineItems}
          checkBooleanFormStatus={checkBooleanFormStatus}
          removeButtonClassUniqueSKU={true}
          onChangeReasonIdLineItems={onChangeReasonIdLineItems}
          onChangeReamekLineItems={onChangeReamekLineItems}
          listReason={factReason}
          disabledBool={true}
        />
      </section>
      <section className={styles.groupbtn}>
        <GroupBtnDocument
          onClickSaveDoc={onClickSaveDoc}
          onClickSendDoc={onClickSendDoc}
          onClickRemoveDoc={onClickRemoveDoc}
          documentStatusId={documentStatusId}
          linkGoBack={`/inventory/stock/${props.match.params.id}`}
        />
      </section>

      {ModalAlert(isModalOpen, hideModal, successSubmit)}
    </>
  )
}

export default InventorySummaryStockPage
