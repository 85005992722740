import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../common/table'
import DocumentTitle from '../common/document-title'
import axios from 'axios'
import PaginationContainer from '../../pagination/index'
import { debounce } from 'lodash'
import { urlApi } from '../common/configUrlApi'

import useTokenInitializer from '../../hooks/token-initializer'
import { FormatDateTime } from '../../common/dateFormat'

import EnhancedTable from '../../common/table/doc/firstAndLastColBtnPaginationBack'
import { HeadCell } from '../../common/table/config'

const InventoryStockDocumentPage: React.FC = () => {
  const [loading, setLoading] = useState<any>(true)

  const [dataListStock, setDataListStock] = useState<any>([])
  const [currentQueryString, setCurrentQueryString] = useState('')
  const [currentQueryDate, setCurrentQueryDate] = useState(null)

  // Pgaination
  const [page, setPage] = React.useState(0)
  const [pageList, setPageList] = React.useState<any>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = React.useState(0)

  DocumentTitle('Inventory', 'เช็ค Stock')
  useTokenInitializer()

  useEffect(() => {
    // For not dowload page if dowloaded
    const findPage = pageList.find((ele: any) => `${ele}` === `${page}`)
    if (findPage || findPage === 0) return

    // Where to put the query
    const q = `query ($pi: Int, $mr: Int, $internalDocumentIdPartial: String, $createdAtDay: DateTime) {
      documentList: listPhysicalCount(pageIndex: $pi, maxResults: $mr, internalDocumentIdPartial: $internalDocumentIdPartial, createdAtDay: $createdAtDay) {
        totalCount
        pageIndex
        maxResults
        results {
          id
          internalDocumentId
          createdAt
          updatedAt
          documentTypeId
          documentStatusId
          remark
          lastScanAt
        }
      }
    }`
    // Where to put the variables
    const v = {
      pageIndex: page,
      maxResults: rowsPerPage,
      createdAtDay: currentQueryDate,
      internalDocumentIdPartial: currentQueryString,
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    }).then((result) => {
      const documentList = result.data.data.documentList.results
      const documentListAfterMapper: any[] = []
      documentList.map((data: any) => {
        const mapper = {
          id: data.id,
          internalDocumentID: data.internalDocumentId,
          documentStatusId: data.documentStatusId,
          date: FormatDateTime(data.updatedAt),
        }
        return documentListAfterMapper.push(mapper)
      })
      setLoading(false)
      setDataListStock([...dataListStock, ...documentListAfterMapper])
      setTotalCount(result.data.data.documentList.totalCount)

      setPageList([...pageList, page]) // For not reload new page if dowloaded
    })
  }, [page, rowsPerPage, currentQueryDate, currentQueryString])

  const request = debounce((value, setState) => {
    setPage(0)
    setPageList([])
    setDataListStock([])
    setState(value)
  }, 1000)

  const debouceRequest = useCallback((value, setState) => request(value, setState), [])

  const onChangeString = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryString)
  }

  const onChangeDate = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryDate)
  }

  // const colHead: any = ['เลือก', 'เลขที่รายการเช็คสินค้า', 'สถานะ', 'วันที่พิมพ์']
  const rowBody: any = dataListStock

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    { id: 'productCode', numeric: false, disablePadding: false, label: 'เลขที่รายการเช็คสินค้า' },
    { id: 'status', numeric: false, disablePadding: false, label: 'สถานะ' },
    { id: 'date', numeric: false, disablePadding: false, label: 'วันที่พิมพ์' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------

  return (
    <>
      <section className={styles.headerWrapper}>
        <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

        <h1 className={styles.headerTitle}>เอกสารเช็ค Stock</h1>
        <p>ค้นหา</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสเอกสาร"
          onChange={onChangeString}
          style={{ maxWidth: '385.6px', display: 'flex' }}
        />
        <input type="date" className="inputForSearch" onChange={onChangeDate} style={{ maxWidth: '385.6px' }} />
      </section>
      <section className={`tableListMui`}>
        <EnhancedTable
          colHead={headCells}
          rows={rowBody}
          link="/inventory/stock"
          linkForEditDoc="/inventory/stock"
          linkPDF="/inventory/pdf/issue"
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
        />
      </section>
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/stock">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventoryStockDocumentPage
