import React, { useEffect, useState } from 'react'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import validateIgnoreField from '../../common/validateIgnoreField'
import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../common/table'

import styles from './index.module.scss'
import DocumentTitle from '../../inventory/common/document-title'
import axios from 'axios'
import { findProperties } from '../common/findAdditionalProperties'
import { listStatus } from '../common/configStatus'
import ModalAlert from '../common/modal'
import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'

import CheckSVG from './check.svg'
import { componantParentComment } from './componantParentComment'
import { boardID, urlApiEngineer } from '../common/configAPIEngineer'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from '../../common/configAws'
import _ from 'lodash'
import { FormatDateTime } from '../../common/dateFormat'

const tzoffset = new Date().getTimezoneOffset() * 60000
const localISOTime = new Date(Date.now() - tzoffset).toISOString()

const EngineerCardIDPage: React.FC = (props: any) => {
  DocumentTitle('Engineer', 'ติดตั้ง/ถอดแบบ')

  const { values, errors, touched, setFieldValue, submitForm } = useFormikContext<any>()

  const token: any = useSelector((state: any) => state.token)
  useTokenInitializer()
  const [fullname, setFullname] = useState('')
  useEffect(() => {
    if (!token.name) return
    setFullname(token.name)
  }, [token.name])

  useEffect(() => {
    if (!props.match.params.id) return setFieldValue('loading', false, false)
    // Where to put the variables
    const v = {
      boardID: boardID,
      cardID: props.match.params.id,
    }

    axios({
      url: urlApiEngineer,
      method: 'post',
      data: {
        query: qCard,
        variables: v,
      },
    })
      .then((result) => {
        const card = result.data.data.card[0]
        console.log('card', card)
        setFieldValue('loading', false, false)
        setFieldValue('cardID', props.match.params.id, false)
        setFieldValue('title', card.title, false)
        setFieldValue('status', card.status, false)
        setFieldValue('dueDate', card.dueDate, false)
        setFieldValue('comments', card.comments, false)
        setFieldValue('filesEngineer', card.attachmentsEngineers, false)
        setFieldValue('filesSale', card.attachmentsSales, false)
        // setFieldValue('positionArea', card.areaPosition, false)
        setFieldValue(
          'positionArea',
          card.areaPosition.length === 0
            ? [
                {
                  id: 1,
                  seq: 1,
                  namePosition: '', // ชื่อตำแหน่ง
                  distanceType: '', // ระยะไม้ระแนง
                  distance: '', // ระยะ
                  axisType: '', // แนว
                  remark: '',
                },
              ]
            : card.areaPosition.map((position: any, index: number) => {
                return {
                  id: index + 1,
                  seq: index + 1,
                  namePosition: position.name, // ชื่อตำแหน่ง
                  distanceType: position.typeDistance, // ระยะไม้ระแนง
                  distance: position.distance, // ระยะ
                  axisType: position.direction, // แนว
                  remark: position.remark,
                }
              }),
          false,
        )

        setFieldValue('typeProject', findProperties(card.additionalProperties, 'typeProject'), false)
        setFieldValue('areaCustomer', findProperties(card.additionalProperties, 'amountSpaceCustomers'), false)
        setFieldValue('areaEngineer', findProperties(card.additionalProperties, 'amountSpaceEngineer'), false)
        setFieldValue('supports', findProperties(card.additionalProperties, 'support'), false)
        setFieldValue('remarkSupport', findProperties(card.additionalProperties, 'remarkSupport'), false)
        setFieldValue('loadingBy', findProperties(card.additionalProperties, 'loadingBy'), false)
        setFieldValue('loadingMethod', findProperties(card.additionalProperties, 'loadingMethod'), false)
        setFieldValue('dropOff', findProperties(card.additionalProperties, 'dropOff'), false)
        setFieldValue('detailDelivery', findProperties(card.additionalProperties, 'detailDelivery'), false)
        setFieldValue('nameReceive', findProperties(card.additionalProperties, 'nameReceive'), false)
        setFieldValue('phoneReceive', findProperties(card.additionalProperties, 'phoneReceive'), false)
        setFieldValue('datetimeReceive', findProperties(card.additionalProperties, 'datetimeReceive'), false)
        setFieldValue('googleMap', findProperties(card.additionalProperties, 'googleMap'), false)
        setFieldValue('address', findProperties(card.additionalProperties, 'address'), false)
        setFieldValue('dueDate', card.dueDate, false)
        setFieldValue('status', card.status, false)
        setFieldValue('reason', findProperties(card.additionalProperties, 'reason'), false)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [])

  const handleUploadAttachment = (e: any, nameField: string) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const file: any = e.target.files[0]

    if (file.length !== 0) {
      const fileName = file.name
      const albumPhotosKey = encodeURIComponent('Engineer') + '/' + encodeURIComponent(`${values.title}`) + '/'

      const photoKey = albumPhotosKey + fileName
      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })

      const promise = upload.promise()

      promise.then(
        function (data) {
          const newObject = {
            name: fileName,
            url: data.Location,
            by: token.name,
            createAt: new Date().toISOString(),
            updateAt: new Date().toISOString(),
          }
          if (values[`${nameField}`].length !== 0) {
            setFieldValue(`${nameField}`, [...values[`${nameField}`], newObject], false)
            submitForm()
          } else {
            setFieldValue(`${nameField}`, [newObject], false)
            submitForm()
          }
        },
        function (err) {
          // console.log('err', err)
          return alert('There was an error uploading your photo: ')
        },
      )
    }
  }

  const colHeadItem: string[] = [
    'เพิ่ม/ลด',
    'ลำดับ',
    'รหัสสินค้า',
    'สี',
    'ผิว',
    'PRODUCT CODE',
    'หนา',
    'กว้าง',
    'ยาว',
    'ประเภทงาน',
    'บริเวณติดตั้ง',
    '',
    'แผ่น',
    'รวม (เมตร/แผ่น)',
    'เมตร',
  ]

  function changeStatus(nextStatus: any) {
    setFieldValue('status', nextStatus.statusName, false)
    const mapper = {
      commentType: 'changeStatus',
      description: nextStatus.statusDisplay,
      attachments: [],
      note: '',
      createdAt: localISOTime,
      by: token.name,
    }

    if (values.comments.length !== 0) {
      setFieldValue('comments', [...values.comments, mapper], false)
      submitForm()
    } else {
      setFieldValue('comments', [mapper], false)
      submitForm()
    }
  }

  function changeStatusReject(status: string, note: string) {
    setFieldValue('status', status, false)
    const mapper = {
      commentType: 'changeStatus',
      description: note,
      attachments: [],
      createdAt: localISOTime,
      by: token.name,
    }
    if (values.comments.length !== 0) {
      setFieldValue('comments', [...values.comments, mapper], false)
      submitForm()
    } else {
      setFieldValue('comments', [mapper], false)
      submitForm()
    }
  }

  const handleApprove = () => {
    const index = _.findIndex(listStatus, { statusName: values.status })
    const nextStatus = listStatus[index + 1]
    if (
      nextStatus.statusName === 'PROJECT_APPROVE_SALES_MANAGER' &&
      (token.position === 'หัวหน้า Sales' || token.name === 'Admin')
    ) {
      changeStatus(nextStatus)
    } else if (
      nextStatus.statusName === 'PROJECT_APPROVE_ENG_MANAGER' &&
      (token.position === 'หัวหน้า Engineer' || token.name === 'Admin')
    ) {
      changeStatus(nextStatus)
    } else if (
      nextStatus.statusName === 'PROJECT_REVIEW_APPROVE_ENG_MANAGER' &&
      (token.position === 'หัวหน้า Engineer' || token.name === 'Admin')
    ) {
      changeStatus(nextStatus)
    } else if (
      nextStatus.statusName === 'PROJECT_REVIEW_APPROVE_SALES_MANAGER' &&
      (token.position === 'หัวหน้า Sales' || token.name === 'Admin')
    ) {
      changeStatus(nextStatus)
    }
  }

  const handleReject = () => {
    if (values.status === 'PROJECT_ONGOING' && (token.position === 'หัวหน้า Engineer' || token.name === 'Admin')) {
      changeStatusReject('PROJECT_APPROVE_ENG_MANAGER', 'Review Reject By Engineer Manager')
    } else if (
      values.status === 'PROJECT_REVIEW_APPROVE_ENG_MANAGER' &&
      (token.position === 'หัวหน้า Sales' || token.name === 'Admin')
    ) {
      changeStatusReject('PROJECT_APPROVE_ENG_MANAGER', 'Review Reject By Sale Manager')
    } else if (
      values.status === 'PROJECT_REVIEW_APPROVE_SALES_MANAGER' &&
      (token.position === 'พนักงานขาย' || token.name === 'Admin')
    ) {
      changeStatusReject('PROJECT_APPROVE_ENG_MANAGER', 'Review Reject By พนักงานขาย')
    }
  }

  const handleSubmitProject = () => {
    const index = _.findIndex(listStatus, { statusName: values.status })
    const nextStatus = listStatus[index + 1]
    if (
      values.status === 'PROJECT_REVIEW_APPROVE_SALES_MANAGER' &&
      (token.position === 'พนักงานขาย' || token.name === 'Admin')
    ) {
      changeStatus(nextStatus)
    }
  }

  const handleSendProject = () => {
    const index = _.findIndex(listStatus, { statusName: values.status })
    const nextStatus = listStatus[index + 1]
    if (
      nextStatus.statusName === 'PROJECT_ONGOING' &&
      (token.position === 'พนักงาน Engineer' || token.name === 'Admin')
    ) {
      changeStatus(nextStatus)
    }
  }

  // FOR TABLE STATUS
  useEffect(() => {
    const rawList = values.comments.filter((ele: any) => ele.commentType === 'changeStatus')
    console.log('rawList', rawList)
    const countReject = rawList.filter((ele: any) => ele.description.indexOf('Reject') !== -1)
    countReject.push({})
    const listStatus: any = {}

    countReject.map((reject: any, rejectIndex: number) => {
      listStatus[rejectIndex] = []

      let breaked = false
      rawList.map((data: any, index: number) => {
        if (breaked) return
        if (reject.description === data.description && reject.createdAt === data.createdAt) {
          listStatus[rejectIndex].push(data)
          breaked = true
          return
        }
        if (data.description.indexOf('Review Reject By Engineer Manager') !== -1) {
          listStatus[rejectIndex].pop()
          return
        }
        if (data.description.indexOf('Review Reject By Sale Manager') !== -1) {
          listStatus[rejectIndex].pop()
          listStatus[rejectIndex].pop()
          return
        }
        if (data.description.indexOf('Review Reject By พนักงานขาย') !== -1) {
          listStatus[rejectIndex].pop()
          listStatus[rejectIndex].pop()
          listStatus[rejectIndex].pop()
          return
        }
        if (
          data.description ===
          listStatus[rejectIndex].filter((ele: any) => ele.description === data.description)[0]?.description
        ) {
          listStatus[rejectIndex].pop()
        }
        listStatus[rejectIndex].push(data)
      })

      for (let i = listStatus[rejectIndex].length + 1; i < 8; i++) {
        listStatus[rejectIndex].push({
          by: '-',
          commentType: 'changeStatus',
          createdAt: '-',
          description: '-',
        })
      }
    })
    setFieldValue('stages', listStatus, false)
    console.log('listStatus', listStatus)
  }, [values.comments])

  const colHeadPosition: string[] = ['เพิ่ม/ลด', 'ลำดับ', 'ชื่อตำแหน่ง', 'ระยะไม้ระแนง', '', 'แนว', '']
  return (
    <Form>
      <div className={`loader loader-default ${values.loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <div>
          <h1 className={styles.headerTitle}>ติดตั้ง/ถอดแบบ</h1>
          <span className={styles.subHeaderTitle}>ยินดีต้อนรับ [{fullname}]</span>

          <div className={styles.newRow}>
            <button className={`${styles.editStyleBtn}`} type="button">
              ดูโปรเจค
            </button>
            <button className={`${styles.editStyleBtn}`} type="button">
              พิมพ์ใบถอดแบบ
            </button>

            <button className={`${styles.styleBtnGreen}`} type="button" onClick={() => handleApprove()}>
              Approve
            </button>
            <button className={`${styles.styleBtnRed}`} type="button" onClick={() => handleReject()}>
              ไม่ Approve
            </button>

            <button className={`${styles.editStyleBtn}`} type="button" onClick={() => handleSendProject()}>
              ส่งงาน
            </button>

            <button className={`${styles.styleBtnGreen}`} type="button" onClick={() => handleSubmitProject()}>
              รับงาน
            </button>
            <button className={`${styles.styleBtnRed}`} type="button" onClick={() => handleReject()}>
              แก้งาน
            </button>
          </div>
        </div>

        <div style={{ marginTop: '10px' }}>
          {listStatus.map((data, index) => {
            if (index === 0) {
              return (
                <button
                  className={`${styles.styleBtnProgress} ${
                    values.status === data.statusName && styles[`${data.color}Tag`]
                  }`}
                  type="button"
                >
                  <img src={CheckSVG} width="20" style={values.status === data.statusName ? {} : { display: 'none' }} />
                  {data.statusDisplay}
                </button>
              )
            } else {
              return (
                <button
                  type="button"
                  className={`${styles.styleBtnProgress} ${styles.styleBtnProgressShip} ${
                    values.status === data.statusName && styles[`${data.color}Tag`]
                  }`}
                >
                  <img src={CheckSVG} width="20" style={values.status === data.statusName ? {} : { display: 'none' }} />
                  {data.statusDisplay}
                </button>
              )
            }
          })}
        </div>

        <section className={styles.newRow} style={{ flexWrap: 'wrap', marginTop: '20px', gap: '50px' }}>
          <article className={styles.newCol50} style={{ height: 'fit-content', width: '-webkit-fill-available' }}>
            <div className={`${styles.width100} ${styles.newRow}`}>
              <h1 className={styles.headerTitleBody}>ข้อมูลทั่วไป</h1>
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>โปรเจค</p>
                <FormikInputText name="title" placeholder="โปรเจค" touched={touched} errors={errors} />
              </div>
              <div className={styles.width100}>
                <p>ลักษณะงาน</p>
                <FormikSelectOption
                  name="typeProject"
                  placeholder="ลักษณะงาน"
                  touched={touched}
                  errors={errors}
                  options={[
                    { name: 'ลูกค้าติดตั้ง', value: 'ลูกค้าติดตั้ง' },
                    { name: 'บริษัทติดตั้ง', value: 'บริษัทติดตั้ง' },
                    { name: 'ไม่ติดตั้ง', value: 'ไม่ติดตั้ง' },
                    { name: 'งานประมูล', value: 'งานประมูล' },
                    { name: 'งานประมาณการซื้อ', value: 'งานประมาณการซื้อ' },
                  ]}
                />
              </div>
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>ปริมาณพื้นที่จากลูกค้า</p>
                <FormikInputText name="areaCustomer" placeholder="ตร.ม." touched={touched} errors={errors} />
              </div>
              <div className={styles.width100}>
                <p>ปริมาณพื้นที่จากถอดแบบ</p>
                <FormikInputText name="areaEngineer" placeholder="ตร.ม." touched={touched} errors={errors} />
              </div>
            </div>
            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>ไฟล์จาก Sales</p>
                <div style={{ margin: '20px 0' }}>
                  <input type="file" id="fileSale" onChange={(e) => handleUploadAttachment(e, 'filesSale')} />
                  <label htmlFor="fileSale" className={`styleBtn`}>
                    อัพโหลดไฟล์
                  </label>
                </div>
              </div>
            </div>
            <div className={`${styles.width100} ${styles.newRow} ${styles.documentLists}`}>
              {values.filesSale.map((data: any, index: number) => {
                return (
                  <a key={index} className={`${styles.documentList}`} href={data.url}>
                    <p className={styles.typeDocument}>ไฟล์ {data.name}</p>
                    <p className={styles.timeStampUser}>
                      วันที่ {data.createAt?.replace('T', ' ').split('.')[0]} - โดย {data.by}
                    </p>
                  </a>
                )
              })}
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>ไฟล์ส่งงานจาก Engineer</p>
                <div style={{ margin: '20px 0' }}>
                  <input type="file" id="fileEngineer" onChange={(e) => handleUploadAttachment(e, 'filesEngineer')} />
                  <label htmlFor="fileEngineer" className={`styleBtn`}>
                    อัพโหลดไฟล์
                  </label>
                </div>
              </div>
            </div>
            <div className={`${styles.width100} ${styles.newRow} ${styles.documentLists}`}>
              {values.filesEngineer.map((data: any, index: number) => {
                return (
                  <a key={index} className={`${styles.documentList}`} href={data.url}>
                    <p className={styles.typeDocument}>ไฟล์ {data.name}</p>
                    <p className={styles.timeStampUser}>
                      วันที่ {data.createAt?.replace('T', ' ').split('.')[0]} - โดย {data.by}
                    </p>
                  </a>
                )
              })}
            </div>

            <div className={`${styles.width100} ${styles.newRow}`}>
              <h3 style={{ margin: '10px 0' }}>รายการวัสดุ</h3>
            </div>
            <div className={`${styles.width100} ${styles.newRow}`} style={{ width: '600px', overflowX: 'scroll' }}>
              <TableCommonCompanant
                colHead={colHeadItem}
                rowBodyData={values.listItems}
                typeHead={TABLE_HEAD_TYPES.NONE}
                typeBody={TABLE_BODY_TYPES.BODY_FOR_LIST_ITEM}
                Field={Field}
                setFieldValue={setFieldValue}
                values={values}
                name="listItems"
                emptyRow={{
                  id: values.listItems.length,
                  seq: values.listItems.length,
                  productCode: null,
                  color: '',
                  surface: '',
                  name: '',
                  depth: '',
                  width: '',
                  length: '',
                  type: '',
                  area: '',
                  areaRemark: '',
                  sheet: '',
                  meterPerPrice: '',
                  meter: '',
                }}
                // viewMode={viewMode}
              />
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>โครงเคร่า (Supports)</p>
              </div>
            </div>
            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px', flexWrap: 'wrap' }}>
              <Field type="radio" name="supports" value="เหล็ก" />
              <label>เหล็ก</label>
              <Field type="radio" name="supports" value="ไม้เทียม" />
              <label>ไม้เทียม</label>
              <Field type="radio" name="supports" value="อลูมิเนียม" />
              <label>อลูมิเนียม</label>
              <Field type="radio" name="supports" value="สแตนเลส" />
              <label>สแตนเลส</label>
              <Field type="radio" name="supports" value="C-line" />
              <label>C-line</label>
              <Field type="radio" name="supports" value="อื่นๆ" />
              <label>อื่นๆ</label>
              {values.supports === 'อื่นๆ' && (
                <FormikInputText name="remarkSupport" placeholder="อื่นๆ" touched={touched} errors={errors} />
              )}
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ width: '600px', overflowX: 'scroll' }}>
              <TableCommonCompanant
                colHead={colHeadPosition}
                rowBodyData={values.positionArea}
                typeHead={TABLE_HEAD_TYPES.NONE}
                typeBody={TABLE_BODY_TYPES.BODY_FOR_POSITION_AREA}
                Field={Field}
                setFieldValue={setFieldValue}
                values={values}
                name="positionArea"
                emptyRow={{
                  id: values.listItems.length,
                  seq: values.listItems.length,
                  namePosition: null,
                  distanceType: '',
                  distance: '',
                  axisType: '',
                  remark: '',
                }}
                // viewMode={viewMode}
              />
            </div>

            <div className={`${styles.width100} ${styles.newRow}`}>
              <h3 style={{ margin: '10px 0' }}>การส่งของ</h3>
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>การขึ้นของ (Loading by)</p>
                <FormikSelectOption
                  name="loadingBy"
                  placeholder="ลักษณะงาน"
                  touched={touched}
                  errors={errors}
                  options={[
                    { name: 'ลูกค้ารับเอง', value: 'ลูกค้ารับเอง' },
                    { name: 'บริษัทขนส่ง', value: 'บริษัทขนส่ง' },
                    { name: 'Biowood ส่ง', value: 'Biowood ส่ง' },
                  ]}
                />
              </div>
              <div className={styles.width100}>
                <p>วิธีการขึ้นของ (Loading Method)</p>
                <FormikSelectOption
                  name="loadingMethod"
                  placeholder="ลักษณะงาน"
                  touched={touched}
                  errors={errors}
                  options={[
                    { name: 'กระเช้า', value: 'กระเช้า' },
                    { name: 'ลิฟท์', value: 'ลิฟท์' },
                    { name: 'คนงานยก/แบก', value: 'คนงานยก/แบก' },
                  ]}
                />
              </div>
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>สถานที่เก็บของ (Drop off)</p>
                <FormikSelectOption
                  name="dropOff"
                  placeholder="ลักษณะงาน"
                  touched={touched}
                  errors={errors}
                  options={[
                    { name: 'ภายนอกอาคาร', value: 'ภายนอกอาคาร' },
                    { name: 'ภายในอาคาร', value: 'ภายในอาคาร' },
                  ]}
                />
              </div>
              <div className={styles.width100}>
                <p>รายละเอียด</p>
                <FormikInputText name="detailDelivery" placeholder="รายละเอียด" touched={touched} errors={errors} />
              </div>
            </div>
            <div className={`${styles.width100} ${styles.newRow}`}>
              <h3 style={{ margin: '10px 0' }}>Contact ผู้รับสินค้า</h3>
            </div>
            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>ชื่อผู้รับ</p>
                <FormikInputText name="nameReceive" placeholder="ชื่อผู้รับ" touched={touched} errors={errors} />
              </div>
              <div className={styles.width100}>
                <p>เบอร์โทรศัพท์</p>
                <FormikInputText name="phoneReceive" placeholder="เบอร์โทรศัพท์" touched={touched} errors={errors} />
              </div>
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>วันที่ เวลา</p>
                <FormikInputDate name="datetimeReceive" placeholder="วันที่ เวลา" touched={touched} errors={errors} />
              </div>
              <div className={styles.width100}>
                <p>Google Map</p>
                <FormikInputText name="googleMap" placeholder="Google Map" touched={touched} errors={errors} />
              </div>
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>ที่อยู่</p>
                <Field
                  name="address"
                  className={styles.styleTextarea}
                  placeholder="ที่อยู่"
                  as="textarea"
                  rows={4}
                  // disabled={!values.editMode}
                />
              </div>
            </div>
            <div className={`${styles.width100} ${styles.newRow}`}>
              <h3 style={{ margin: '10px 0' }}>Stage</h3>
            </div>

            <div
              className={`${styles.width100} ${styles.newRow}`}
              style={{ marginTop: '10px', width: '600px', overflowX: 'scroll' }}
            >
              {Object.keys(values.stages).map((keyReject: any, indexReject: number) => {
                return (
                  <table key={indexReject} style={{ margin: '5px 0', minWidth: '288px' }}>
                    <thead>
                      <tr>
                        <th className={`borderLeftRadius ${styles.textAlignCenter}`}>ครั้งที่</th>
                        <th>Stage</th>
                        <th className="borderRightRadius">เมื่อ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.stages[keyReject].map((data: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td className={`${styles.textAlignCenter}`}>{index + 1}</td>
                            <td className={``}>{data.description}</td>
                            <td>{data.createdAt ? FormatDateTime(data.createdAt) : '-'}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )
              })}
            </div>

            <div className={`${styles.width100} ${styles.newRow}`}>
              <h3 style={{ margin: '10px 0' }}>กำหนด</h3>
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>ต้องการงานวันที่</p>
                <FormikInputDate name="dueDate" placeholder="วันที่ เวลา" touched={touched} errors={errors} />
              </div>
              <div className={styles.width100}>
                <p>สถานะ</p>
                <FormikInputText name="status" placeholder="" touched={touched} errors={errors} />
              </div>
            </div>

            <div className={`${styles.width100} ${styles.newRow}`} style={{ marginTop: '10px' }}>
              <div className={styles.width100}>
                <p>เหตุผล</p>
                <Field
                  name="reason"
                  className={styles.styleTextarea}
                  placeholder="ที่อยู่"
                  as="textarea"
                  rows={4}
                  // disabled={!values.editMode}
                />
              </div>
            </div>

            <div className={`${styles.width100} ${styles.newRow}`}>
              <button type="submit" className="styleBtn">
                บันทึก
              </button>
            </div>
          </article>

          <article className={`${styles.newCol50}`} style={{ height: 'fit-content' }}>
            <div style={{ width: '100%' }}>{componantParentComment()}</div>
          </article>
        </section>

        {ModalAlert(values.isModalOpen, values.successSubmit, setFieldValue)}
      </section>
    </Form>
  )
}

const EnhancedEngineerCardIDPage = withFormik({
  mapPropsToValues: (props) => ({
    cardID: '',
    title: '', // โปรเจค
    typeProject: '', // ลักษณะงาน
    areaCustomer: '', // ปริมาณพื้นที่จากลูกค้า
    areaEngineer: '', // ปริมาณพื้นที่จากถอดแบบ

    filesSale: [], // ไฟล์จาก Sales
    filesEngineer: [], // ไฟล์ส่งงานจาก Engineer
    listItems: [
      // รายการวัสดุ
      {
        id: 1,
        seq: 1,
        productCode: null,
        color: '',
        surface: '',
        name: '',
        depth: '',
        width: '',
        length: '',
        type: '',
        area: '',
        areaRemark: '',
        sheet: '',
        meterPerPrice: '',
        meter: '',
      },
    ],

    supports: '', // โครงเคร่า (Supports)
    remarkSupport: '',
    positionArea: [
      {
        id: 1,
        seq: 1,
        namePosition: '', // ชื่อตำแหน่ง
        distanceType: '', // ระยะไม้ระแนง
        distance: '', // ระยะ
        axisType: '', // แนว
        remark: '',
      },
    ], // ตำแหน่ง

    loadingBy: '', // การขึ้นของ (Loading by)
    loadingMethod: '', // วิธีการขึ้นของ (Loading Method)
    dropOff: '', // สถานที่เก็บของ (Drop off)
    detailDelivery: '', // รายละเอียด

    nameReceive: '', // ชื่อผู้รับ
    phoneReceive: '', // เบอร์โทรศัพท์
    datetimeReceive: '', // วันที่ เวลา
    googleMap: '', // Google Map
    address: '', // ที่อยู่

    stages: {}, // Stage

    dueDate: '', // ต้องการงานวันที่
    status: '', // สถานะ
    reason: '', // เหตุผล

    comments: [],
    imageUri: [], // for render in parent comment

    loading: true,
  }),
  validate: (values) => {
    return validateIgnoreField(
      values,
      [
        'cardID',
        'areaEngineer',
        'supports',
        'remarkSupport',
        'filesSale',
        'filesEngineer',
        'listItems',
        'positionArea',
        'loadingBy',
        'loadingMethod',
        'dropOff',
        'detailDelivery',
        'nameReceive',
        'phoneReceive',
        'datetimeReceive',
        'googleMap',
        'address',
        'status',
        'comments',
        'loading',
        'imageUri',
      ],
      '*',
    )
  },
  handleSubmit: (values, { setFieldValue }) => {
    const additionalProperties = {
      additionalProperties: [
        { Key: 'typeProject', Value: values.typeProject },
        { Key: 'amountSpaceCustomers', Value: values.areaCustomer },
        { Key: 'amountSpaceEngineer', Value: values.areaEngineer },
        { Key: 'support', Value: values.supports },
        { Key: 'remarkSupport', Value: values.remarkSupport },
        { Key: 'loadingBy', Value: values.loadingBy },
        { Key: 'loadingMethod', Value: values.loadingMethod },
        { Key: 'dropOff', Value: values.dropOff },
        { Key: 'detailDelivery', Value: values.detailDelivery },
        { Key: 'nameReceive', Value: values.nameReceive },
        { Key: 'phoneReceive', Value: values.phoneReceive },
        { Key: 'datetimeReceive', Value: values.datetimeReceive },
        { Key: 'googleMap', Value: values.googleMap },
        { Key: 'address', Value: values.address },
        { Key: 'reason', Value: values.reason },
      ],
    }
    const newPositionArea: any = []
    values.positionArea.map((position: any, index: number) => {
      if (position.namePosition && position.distanceType && position.distance && position.axisType) {
        const mapper = {
          lineNumber: index + 1,
          name: position.namePosition,
          typeDistance: position.distanceType,
          distance: parseFloat(position.distance),
          direction: position.axisType,
          remark: position.remark,
        }
        newPositionArea.push(mapper)
      }
    })

    const vUpdateCard = {
      input: {
        boardID: boardID,
        cardID: values.cardID,
        title: values.title,
        status: values.status,
        dueDate: values.dueDate,
        assignee: [`${values.assignee}`],
        areaPosition: newPositionArea,
        attachmentsSales: values.filesSale,
        attachmentsEngineers: values.filesEngineer,
        additionalProperties: additionalProperties.additionalProperties,
        comments: values.comments,
      },
    }

    console.log('vUpdateCard', vUpdateCard)

    axios({
      url: urlApiEngineer,
      method: 'post',
      data: {
        query: qUpdateCard,
        variables: vUpdateCard,
      },
    })
      .then((result) => {
        setFieldValue('isModalOpen', true, false)
        console.log('result', result)
        if (values.cardID) {
          setFieldValue('cardID', result.data.data.updateCard.cardID, true)
          setFieldValue('successSubmit', 'แก้ไขสำเร็จ', false)
          setFieldValue('loading', false, false)
        }
      })
      .catch((err) => {
        setFieldValue('successSubmit', 'เกิดปัญหา', false)
        setFieldValue('isModalOpen', true, false)
        setFieldValue('loading', false, false)
        console.log(err.response)
      })
  },
})(EngineerCardIDPage)

export default EnhancedEngineerCardIDPage

const FormikInputText: any = ({ name, placeholder, touched, errors }: any) => {
  return (
    <Field
      name={name}
      className="inputForSearch"
      placeholder={placeholder}
      type="text"
      // disabled={!values.editMode}
      style={{
        ...(touched?.[name] && errors?.[name] && { border: '1px solid red' }),
      }}
    />
  )
}

const FormikInputDate: any = ({ name, placeholder, touched, errors }: any) => {
  return (
    <Field
      name={name}
      className="inputForSearch"
      placeholder={placeholder}
      type="datetime-local"
      // disabled={!values.editMode}
      style={{
        ...(touched?.[name] && errors?.[name] && { border: '1px solid red' }),
      }}
    />
  )
}

const FormikSelectOption: any = ({ name, placeholder, touched, errors, options }: any) => {
  return (
    <Field
      name={name}
      className="inputForSearch"
      placeholder={placeholder}
      as="select"
      style={{
        ...(touched?.[name] && errors?.[name] && { border: '1px solid red' }),
      }}
    >
      <option value=""></option>
      {options.map((data: any, index: number) => (
        <option key={index} value={data.value}>
          {data.name}
        </option>
      ))}
    </Field>
  )
}

const qCard = `query($boardID: ID!, $cardID: ID){
  card(boardID: $boardID, cardID: $cardID) {
    cardID
    title
    status
    assignee
    dueDate
    createdAt
    updatedAt
    areaPosition {
			lineNumber
      name
      typeDistance
      distance
      direction
      remark
    }
    attachmentsSales {
			name
      url
      by
      createAt
      updateAt
    }
    attachmentsEngineers {
      name
      url
      by
      createAt
      updateAt
    }
    comments {
			commentType
      description
      attachments
      note
      createdAt
      by
      assignTo
      statusProgress
    }
    additionalProperties {
			Key
    	Value
    }
  }
}`
// "Abstract type "AreaPosition" must resolve to an Object type at runtime for field "Card.areaPosition". Either the "AreaPosition" type should provide a "resolveType" function or each possible type should provide an "isTypeOf" function."
const qUpdateCard = `mutation($input: UpdateCardInput!) {
  updateCard(input: $input) {
    cardID
  }
}`
