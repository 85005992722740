import { makeActionCreator } from './generate_action_creator'
import jwt_decode from 'jwt-decode'
import * as AWS from 'aws-sdk'
import { bucketRegion } from '../../common/configAws'
import { accessKeyId, secretAccessKey, UserPoolId } from '../../hr/common/UserPool'
import { findPropertiesHr } from '../../hr/common/fildProperties'

// Actions
const DECODE_TOKEN = 'token/DECODE_TOKEN'

const initialState = {
  raw_token: '',
  decoded_token: {},
  userInfo: {},
  // Needs to check for token, else we will bounce back to login page everytime we refresh a page (initialState will be false)
  isLoggedIn: localStorage.getItem('accessToken') !== null ? true : false,
}

// Reducer
export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case DECODE_TOKEN:
      return {
        ...state,
        raw_token: action.token,
        decoded_token: jwt_decode(action.token),
        userInfo: action.userInfo,
        name: findPropertiesHr(action.userInfo.UserAttributes, 'name'),
        division: findPropertiesHr(action.userInfo.UserAttributes, 'custom:division'),
        position: findPropertiesHr(action.userInfo.UserAttributes, 'custom:position'),
      }
    default:
      return state
  }
}

// Action Creators
export const decodeToken = makeActionCreator(DECODE_TOKEN, 'token', 'userInfo')

function shouldDecodeToken(state: any, raw_token: any) {
  const current_token = state.token.raw_token
  return current_token !== raw_token
}

export function decodeTokenIfNeeded() {
  const raw_token = localStorage.getItem('accessToken')
  return (dispatch: any, getState: any) => {
    if (shouldDecodeToken(getState(), raw_token)) {
      // START

      const decoded_token: any = jwt_decode(raw_token as string)
      console.log('decoded_token', decoded_token)
      AWS.config.update({
        region: bucketRegion,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      })
      const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

      const params = {
        UserPoolId: UserPoolId /* required */,
        Username: decoded_token.username /* required */,
      }

      cognitoidentityserviceprovider.adminGetUser(params, (err: any, data: any) => {
        if (err) {
          console.log('err', err)
          const errJson = JSON.stringify(err)
          if (errJson.indexOf('Signature expired') !== -1) {
            localStorage.removeItem('accessToken')
            location.reload()
          }
        } else {
          // console.log('data', data)
          return dispatch(decodeToken(raw_token, data))
        }
      })
      // END
    } else {
      return Promise.resolve()
    }
  }
}
