import React from 'react'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import UserPool from '../hr/common/UserPool'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import styles from './index.module.scss'

import DocumentTitle from '../inventory/common/document-title'
import validateIgnoreField from '../common/validateIgnoreField'
import { Redirect } from 'react-router'

const LoginPage: React.FC = (props: any) => {
  DocumentTitle('Login', '')
  const { values, errors, touched } = useFormikContext<any>()

  if (localStorage.getItem('accessToken')) {
    return <Redirect to="/" />
  }
  return (
    <Form>
      <div className={styles.loginWrapper}>
        <h3 className={styles.titleHeader}>กรุณาเข้าสู่ระบบ</h3>
        {values.isError && <span style={{ color: 'red' }}>{values.msgError}</span>}
        <p style={{ margin: '0' }}>อีเมลผู้ใช้งาน</p>
        <Field
          name="email"
          className="inputForSearch"
          type="email"
          placeholder="อีเมลผู้ใช้งาน"
          style={{
            ...(touched?.email && errors?.email && { border: '1px solid red' }),
          }}
        />
        <p style={{ margin: '0' }}>รหัสผ่าน</p>
        <Field
          name="password"
          className="inputForSearch"
          type="password"
          placeholder="รหัสผ่าน"
          style={{
            ...(touched?.password && errors?.password && { border: '1px solid red' }),
          }}
        />

        <div className={styles.buttons}>
          <button type="submit" className="styleBtn" style={{ fontWeight: 'bold' }}>
            เข้าสู่ระบบ
          </button>
          <button
            type="button"
            className={`styleBtn ${styles.buttonForgot}`}
            onClick={() => props.history.push('/forgot/password')}
          >
            ลืมรหัสผ่าน
          </button>
        </div>
      </div>
    </Form>
  )
}

const EnhancedLoginPage = withFormik({
  mapPropsToValues: (props) => ({
    email: '',
    password: '',

    // NOT VALIDATE
    isSuccess: false,
    isError: false,
    msgError: '',
    props: props,
  }),
  validate: (values) => {
    return validateIgnoreField(values, ['isError', 'msgError', 'isSuccess'], '*')
  },
  handleSubmit: (values, { setFieldValue }) => {
    // console.log(values)
    const user = new CognitoUser({
      Username: values.email,
      Pool: UserPool,
    })

    const authDetails = new AuthenticationDetails({
      Username: values.email,
      Password: values.password,
    })

    user.authenticateUser(authDetails, {
      onSuccess: (data: any) => {
        console.log('onSuccess: ', data)
        localStorage.setItem('accessToken', data.accessToken.jwtToken)
        setFieldValue('isSuccess', true, false)
        values.props.history.push('/')
      },
      onFailure: (err: any) => {
        console.error('onFailure: ', err)
        setFieldValue('isError', true, false)
        setFieldValue('msgError', err.message, false)
      },
      newPasswordRequired: (data: any) => {
        console.log('newPasswordRequired: ', data)
      },
    })
  },
})(LoginPage)

export default EnhancedLoginPage
