import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

interface IAmeliaFace {
  summaryTopTable: any[]
}

const SummaryTopTableComponant: React.FC<IAmeliaFace> = ({ summaryTopTable }) => {
  const [amountSKU, setAmountSKU] = useState<number>(0)
  const [amountPackage, setAmountPackage] = useState<number>(0)
  const [amountPiece, setAmountPiece] = useState<number>(0)
  const [amountTotalPieceAndPack, setAmountTotalPieceAndPack] = useState<number>(0)
  const [amountPackReceive, setAmountPackReceive] = useState<number>(0)
  const [amountSKUReceive, setAmountSKUReceive] = useState<number>(0)

  useEffect(() => {
    let amountPackage = 0
    let amountPiece = 0
    let amountPiecePerPack = 0
    let amountPackReceive = 0
    let amountSKUReceive = 0
    summaryTopTable.map((data) => {
      amountPackage = data.packList + amountPackage
      amountPiece = data.totalPiece + amountPiece
      amountPiecePerPack = data.totalPieceAndPack + amountPiecePerPack
      amountPackReceive = data.totalPackReceive + amountPackReceive
      if (data.totalPackReceive > 0) {
        amountSKUReceive = amountSKUReceive + 1
      }
    })
    setAmountPackage(amountPackage)
    setAmountPiece(amountPiece)
    setAmountSKU(summaryTopTable.length)
    setAmountTotalPieceAndPack(amountPiecePerPack)
    setAmountPackReceive(amountPackReceive)
    setAmountSKUReceive(amountSKUReceive)
  }, [summaryTopTable])

  return (
    <table className="summaryTopTableStyle table">
      <tr className="tr">
        <th
          className="borderLeftRadius th"
          style={{ backgroundColor: 'white', textAlign: 'center', borderBottom: '1px solid #F1F1F1' }}
        >
          จำนวน SKU
        </th>
        <th className="th" style={{ backgroundColor: 'white', textAlign: 'center', borderBottom: '1px solid #F1F1F1' }}>
          จำนวนห่อ
        </th>
        <th
          className="borderRightRadius th"
          style={{ backgroundColor: 'white', textAlign: 'center', borderBottom: '1px solid #F1F1F1' }}
        >
          จำนวนแผ่น
        </th>
      </tr>
      <tr className="tr">
        <th
          className="borderBottomLeftRadius th"
          style={{ backgroundColor: 'white', textAlign: 'center', fontSize: '16px' }}
        >
          {amountSKUReceive}/{amountSKU ? amountSKU : 0}
        </th>
        <th className="th" style={{ backgroundColor: 'white', textAlign: 'center', fontSize: '16px' }}>
          {amountPackReceive}/{amountPackage ? amountPackage : 0}
        </th>
        <th
          className="borderBottomRightRadius th"
          style={{ backgroundColor: 'white', textAlign: 'center', fontSize: '16px' }}
        >
          {amountPiece ? amountPiece : 0}/{amountTotalPieceAndPack}
        </th>
      </tr>
    </table>
  )
}
SummaryTopTableComponant.propTypes = {
  summaryTopTable: PropTypes.any,
}

export default SummaryTopTableComponant
