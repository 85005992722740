import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import TableCommonCompanant, { TABLE_BODY_TYPES, TABLE_HEAD_TYPES } from '../common/table'
import DocumentTitle from '../common/document-title'
import axios from 'axios'
import PaginationContainer from '../../pagination/index'
import { debounce } from 'lodash'
import { urlApi } from '../common/configUrlApi'
import arraySort from 'array-sort'

import useTokenInitializer from '../../hooks/token-initializer'
import { useSelector } from 'react-redux'
import { FormatDateTime } from '../../common/dateFormat'

import EnhancedTable from '../../common/table/doc/firstAndLastColBtnPaginationBack'
import { HeadCell } from '../../common/table/config'

const InventoryDeliveryNoteListPage: React.FC = (props: any) => {
  const [loading, setLoading] = useState<any>(true)
  const [dataListTask, setDataListTask] = useState<any>([])
  const [dataListStock, setDataListStock] = useState<any>([])
  const [currentQueryString, setCurrentQueryString] = useState('')
  const [currentQueryDate, setCurrentQueryDate] = useState(null)
  const [total, setTotal] = useState(0)
  const [done, setDone] = useState(0)
  const [wait, setWait] = useState(0)

  // True เป็น Table, False เป็น Feed Facebook
  const [toggleView, setToggleView] = useState(true)

  // Pgaination
  const [page, setPage] = React.useState(0)
  const [pageList, setPageList] = React.useState<any>([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = React.useState(0)

  DocumentTitle('Inventory', 'การส่งสินค้า')
  useTokenInitializer()

  const [position, setPosition] = useState('')
  const token = useSelector((state: any) => state.token)
  useEffect(() => {
    if (!token.name) return
    console.log('token', token)
    setPosition(token.position)
  }, [token])

  useEffect(() => {
    // For not dowload page if dowloaded
    const findPage = pageList.find((ele: any) => `${ele}` === `${page}`)
    if (findPage || findPage === 0) return

    // Where to put the query
    const q = `query listDeliveryOrder($pageIndex: Int, $maxResults: Int, $internalDocumentIdPartial: String, $createdAtDay: DateTime, $employeeId: String) {
      listDeliveryOrder(pageIndex: $pageIndex, maxResults: $maxResults, internalDocumentIdPartial: $internalDocumentIdPartial, createdAtDay: $createdAtDay, employeeId: $employeeId) {
        totalCount
        pageIndex
        maxResults
        draftAmount
        pendingAmount
        deletedAmount
        completeAmount
        results {
          id
          internalDocumentId
          documentTypeId
          documentStatusId
          referenceNumber
          remark
          createdAt
          updatedAt
          receiveGoodAt
          appointReceiveAt
          receiverName
          phoneNumber
          googleMap
          fullAddress
          otherRemark
          imageUri {
            imageUri
            createdAt
          }
          checkedInAt
          deliveryImageUri {
            imageUri
            createdAt
          }
        }
      }
    }
    `
    // Where to put the variables
    const v = {
      pageIndex: page,
      maxResults: toggleView ? rowsPerPage : 9999999,
      createdAtDay: currentQueryDate,
      internalDocumentIdPartial: currentQueryString,
      employeeId: position === 'พนักงานขนส่ง' ? token.userInfo.Username : '',
    }

    axios({
      url: urlApi,
      method: 'post',
      data: {
        query: q,
        variables: v,
      },
    })
      .then((result) => {
        const listDeliveryOrder = result.data.data.listDeliveryOrder.results
        const documentListAfterMapper: any[] = []
        const dates: any[] = []

        listDeliveryOrder.map((data: any) => {
          const mapper = {
            id: data.id,
            checkedInAt:
              data.documentStatusId === 'DRAFT'
                ? 'DRAFT'
                : data.documentStatusId === 'PENDING'
                ? 'รอส่งงาน'
                : 'ส่งแล้ว',
            internalDocumentID: data.internalDocumentId,
            date: data.appointReceiveAt ? FormatDateTime(data.appointReceiveAt) : '-',
            fullAddress: data.fullAddress,
          }
          dates.push(data.appointReceiveAt && data.appointReceiveAt)
          return documentListAfterMapper.push(mapper)
        })

        setLoading(false)

        setDataListStock([...dataListStock, ...documentListAfterMapper])
        setTotalCount(result.data.data.listDeliveryOrder.totalCount)

        setPageList([...pageList, page]) // For not reload new page if dowloaded

        // setTotalPages(
        //   Math.ceil(result.data.data.listDeliveryOrder.totalCount / result.data.data.listDeliveryOrder.maxResults),
        // )
        setWait(result.data.data.listDeliveryOrder.pendingAmount)
        setDone(result.data.data.listDeliveryOrder.completeAmount)
        setTotal(result.data.data.listDeliveryOrder.totalCount)

        // setDataListStock(documentListAfterMapper)
        setDataListTask(listDeliveryOrder)

        const d = new Date()
        let n = -1
        let res = null

        while (++n < dates.length && new Date(dates[n]) < new Date(d));
        res = dates[n] || d
        const elmnt: any = document.getElementById(`${res}`)
        elmnt.scrollIntoView()
      })
      .catch((err) => {
        console.log('err', err.response)
      })
  }, [page, currentQueryDate, currentQueryString, toggleView])

  const request = debounce((value, setState) => {
    setPage(0)
    setPageList([])
    setDataListStock([])
    setState(value)
  }, 1000)

  const debouceRequest = useCallback((value, setState) => request(value, setState), [])

  const onChangeString = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryString)
  }

  const onChangeDate = (e: any) => {
    debouceRequest(e.target.value, setCurrentQueryDate)
  }

  // ---------------- START Head Data NEED PROPS ---------------------------
  const headCells: HeadCell[] = [
    // { id: 'productCode', numeric: false, disablePadding: false, label: 'ดู' },
    { id: 'status', numeric: false, disablePadding: false, label: 'สถานะ' },
    { id: 'date', numeric: false, disablePadding: false, label: 'เลขที่รายการส่งสินค้า' },
    { id: 'btnPrint', numeric: false, disablePadding: true, label: 'วันที่ส่ง' },
    { id: 'btnPrint1', numeric: false, disablePadding: true, label: 'ที่อยู่' },
  ]
  // ---------------- START Head Data NEED PROPS ---------------------------
  const rowBody: any = dataListStock

  return (
    <>
      <div className={`loader loader-default ${loading ? 'is-active' : ''}`}></div>

      <section className={styles.headerWrapper}>
        <h1 className={styles.headerTitle}>การส่งสินค้า</h1>
        <p>กรุณาเลือกรายการ</p>
        <input
          type="text"
          className="inputForSearch"
          placeholder="รหัสเอกสาร"
          onChange={onChangeString}
          style={{ maxWidth: '385.6px', display: 'flex' }}
        />
        <input type="date" className="inputForSearch" onChange={onChangeDate} style={{ maxWidth: '385.6px' }} />
      </section>
      <table className="summaryTopTableStyle table">
        <tr className="tr">
          <th
            className="borderLeftRadius th"
            style={{ backgroundColor: 'white', textAlign: 'center', borderBottom: '1px solid #F1F1F1' }}
          >
            งานทั้งหมด
          </th>
          <th
            className="th"
            style={{ backgroundColor: 'white', textAlign: 'center', borderBottom: '1px solid #F1F1F1' }}
          >
            ส่งงานแล้ว
          </th>
          <th
            className="borderRightRadius "
            style={{ backgroundColor: 'white', textAlign: 'center', borderBottom: '1px solid #F1F1F1' }}
          >
            รอส่งงาน
          </th>
        </tr>
        <tr className="tr">
          <th
            className="borderBottomLeftRadius th"
            style={{ backgroundColor: 'white', textAlign: 'center', fontSize: '16px' }}
          >
            {total}
          </th>
          <th className="th" style={{ backgroundColor: 'white', textAlign: 'center', fontSize: '16px' }}>
            {done}
          </th>
          <th
            className="borderBottomRightRadius th"
            style={{ backgroundColor: 'white', textAlign: 'center', fontSize: '16px' }}
          >
            {wait}
          </th>
        </tr>
      </table>
      <div className={styles.toggleView}>
        <button
          type="button"
          className={`styleBtn ${styles.toggleViewBtn}`}
          style={{ padding: '5px 8px' }}
          onClick={() => setToggleView(toggleView ? false : true)}
        >
          เปลี่ยนมุมมอง
        </button>
      </div>

      {!toggleView ? (
        <section className={styles.feedView}>
          {arraySort(dataListTask, 'appointReceiveAt').map((data: any, index: number) => (
            <a key={index} id={`${data.appointReceiveAt}`}>
              <div className={styles.cardInRow}>
                <div className={styles.card}>
                  <h4>รหัสงาน {data.internalDocumentId}</h4>
                  <p>
                    <b>งานวันที่</b> {data.appointReceiveAt?.replace('T', ' ').split('.')[0]}
                  </p>
                  <p>ลูกค้า {data.receiverName}</p>
                  <p>สถานที่ {data.fullAddress}</p>
                  {data.documentStatusId === 'DRAFT' && <p style={{ width: '70px', textAlign: 'center' }}>DRAFT</p>}

                  {data.documentStatusId === 'COMPLETE' && (
                    <p className="statusScanerGreen" style={{ width: '70px', textAlign: 'center' }}>
                      ส่งแล้ว
                    </p>
                  )}

                  {data.documentStatusId === 'PENDING' && (
                    <p className="statusScanerOrange" style={{ width: '70px', textAlign: 'center' }}>
                      รอส่งงาน
                    </p>
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => props.history.push(`/inventory/delivery-note/detail/${data.id}`)}
                  className="styleBtn"
                  style={{ margin: '0px 2px', borderRadius: '20px', fontSize: '11px', padding: '0px 10px' }}
                >
                  ดู
                </button>
                <a
                  href={data.googleMap}
                  className="styleBtn"
                  style={{
                    margin: '0px 2px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '20px',
                    fontSize: '11px',
                    padding: '0px 10px',
                  }}
                >
                  นำทาง
                </a>
                <a
                  href={`tel:${data.phoneNumber}`}
                  className="styleBtn"
                  style={{
                    margin: '0px 2px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '20px',
                    fontSize: '11px',
                    padding: '0px 10px',
                  }}
                >
                  โทร
                </a>
              </div>
            </a>
          ))}
        </section>
      ) : (
        ''
      )}

      {toggleView ? (
        // <section className={styles.tableList}>
        //   <TableCommonCompanant
        //     colHead={colHead}
        //     rowBody={rowBody}
        //     typeHead={TABLE_HEAD_TYPES.NONE}
        //     typeBody={TABLE_BODY_TYPES.DELIVERY_NOTE_LIST}
        //     link="/inventory/delivery-note/detail"
        //   />
        //   <PaginationContainer page={page} totalPages={totalPages} handlePages={handlePages} />
        // </section>
        <section className={`tableListMui`}>
          <EnhancedTable
            colHead={headCells}
            rows={rowBody}
            link="/inventory/delivery-note/detail"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
          />
        </section>
      ) : (
        ''
      )}
      <section className="groupButtonFooter">
        <Link to="/inventory">
          <button type="button" className="styleBtn">
            หน้าแรก
          </button>
        </Link>
        <Link to="/inventory/delivery-note">
          <button type="button" className="styleBtn">
            ย้อนกลับ
          </button>
        </Link>
      </section>
    </>
  )
}

export default InventoryDeliveryNoteListPage
