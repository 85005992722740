import React from 'react'
import classNames from 'classnames'
import styles from './pagination.module.scss'
export interface Props {
  page: number
  totalPages: number
  handlePagination: (page: number) => void
}
export const PaginationComponent: React.FC<Props> = ({ page, totalPages, handlePagination }: any) => {
  return (
    <div className={styles.pagination}>
      <div className={styles.paginationWrapper}>
        {page - 1 !== 1 && page - 1 !== 0 ? (
          <button onClick={() => handlePagination(1)} type="button" className={classNames(styles.pageItem)}>
            {1}
          </button>
        ) : (
          ''
        )}
        {page - 1 !== 2 && page - 1 !== 1 && page - 1 !== 0 ? (
          <div className={styles.separator}>
            <p>...</p>
          </div>
        ) : (
          ''
        )}
        {page - 1 > 0 ? (
          <button onClick={() => handlePagination(page - 1)} type="button" className={styles.pageItem}>
            {page - 1}
          </button>
        ) : (
          ''
        )}

        <button
          onClick={() => handlePagination(page)}
          type="button"
          className={[styles.pageItem, styles.active].join(' ')}
        >
          {page}
        </button>

        {totalPages === page ? (
          ''
        ) : (
          <button onClick={() => handlePagination(page + 1)} type="button" className={styles.pageItem}>
            {page + 1}
          </button>
        )}

        {totalPages - page !== 2 && totalPages - page !== 1 && totalPages - page !== 0 ? (
          <div className={styles.separator}>
            <p>...</p>
          </div>
        ) : (
          ''
        )}

        {totalPages - page !== 1 && totalPages - page !== 0 ? (
          <button
            onClick={() => handlePagination(totalPages)}
            type="button"
            className={classNames(styles.pageItem, {
              [styles.active]: page === totalPages,
            })}
          >
            {totalPages}
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
export const Pagination = PaginationComponent
