interface IMenuItem {
  name: string
  imgName: string
  linkTo: string
}
const menuItems: IMenuItem[] = [
  { name: 'การขาย', imgName: 'SaleSVG', linkTo: '/sale' },
  { name: 'คลังสินค้า', imgName: 'InventorySVG', linkTo: '/inventory' },
  { name: 'จัดซื้อ', imgName: 'PurchaseSVG', linkTo: '' },
  { name: 'บัญชี', imgName: 'AccoutingSVG', linkTo: '' },
  { name: 'ขนส่ง', imgName: 'DeliverySVG', linkTo: '/inventory/delivery-note' },
  { name: 'ติดตั้ง/ถอดแบบ', imgName: 'InstallSVG', linkTo: '/engineer' },
  { name: 'บุคคล', imgName: 'PersonSVG', linkTo: '/hr' },
  { name: 'ผู้บริหาร', imgName: 'ManagementSVG', linkTo: '' },
]

export default menuItems
